import { Badge } from 'react-bootstrap';
import React from 'react';
import { getBadgeColor } from 'app/utils';

export default function ConversationStatusBadge({ status }) {
  return (
    <Badge
      style={{
        backgroundColor: getBadgeColor(status),
        color: '#fff',
      }}
    >
      {status}
    </Badge>
  );
}
