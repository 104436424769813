import {
  assignConversationSaga,
  attendConversationSaga,
  updateConversationSaga,
} from 'app/features/conversations/sagas';
import { call, put } from 'redux-saga/effects';
import {
  getAllClientsFail,
  getAllClientsSuccess,
  getClientFail,
  getClientSuccess,
  updateClientFail,
  updateClientSuccess,
} from 'app/features/clients/clientsSlice';

import { formatDatesForFilter } from 'app/utils';
import { getLogs } from 'app/features/logs/logsSlice';
import moment from 'moment';
import { requestHandler } from '../../services/requestHandler';

export function* getAllClientsSaga(action) {
  try {
    const { page, pageSize, filtered } = action.payload;
    const data = {
      where: {
        updatedAt: {
          $gt: moment().subtract(2, 'months').startOf('day'),
        },
      },
      options: {
        // attributes: ['name', 'phoneNumber'],
        count: true,
        distinct: true,
      },
      pagination: {
        page,
        perPage: pageSize,
        order: [['createdAt', 'DESC']],
      },
      conversations: {
        where: {
          updatedAt: {
            $gt: moment().subtract(2, 'months').startOf('day'),
          },
        },
        agencyUsers: {
          users: {
            options: {
              attributes: ['name'],
            },
          },
          options: {
            attributes: ['type'],
          },
        },
      },
    };
    if (filtered.length) {
      filtered.forEach((filter) => {
        if (filter.id === 'id')
          data.where[filter.id] = {
            $in: [parseInt(filter.value)],
          };
        else if (filter.id === 'fbID') {
          if (filter.value === 'whatsapp')
            data.conversations = {
              ...data.conversations,
              where: {
                ...data.conversations.where,
                fbID: null,
                origin: { $or: [null, 'Whatsapp'] },
              },
            };
          else if (filter.value === 'facebook')
            data.where = {
              ...data.where,
              fbID: { $not: null },
            };
          else
            data.conversations = {
              ...data.conversations,
              where: {
                ...data.conversations.where,
                origin: filter.value,
              },
            };
        } else if (
          filter.id === 'conversations[0].campaignClick.campaign.name'
        ) {
          if (filter.value === 'dc')
            data.conversations = {
              ...data.conversations,
              where: {
                ...data.conversations.where,
                campaignID: null,
              },
            };
          else if (filter.value === 'campaign')
            data.conversations = {
              where: {
                ...data.conversations.where,
                campaignID: { $not: null },
              },
            };
        } else if (filter.id === 'conversations[0].agencyUser.type')
          data.conversations.agencyUsers = {
            ...data.agencyUsers,
            where: {
              type: {
                $ilike: `%${filter.value}%`,
              },
            },
          };
        else if (filter.id === 'client.phoneNumber')
          data.clients.where['phoneNumber'] = {
            $ilike: `%${filter.value}%`,
          };
        else if (filter.id === 'agencyUser.type') {
          data.agencyUsers = {
            ...data.agencyUsers,
            where: {
              type: {
                $ilike: `%${filter.value}%`,
              },
            },
          };
        } else if (filter.id === 'agencyUser.user.name') {
          data.agencyUsers.where = {
            ...data.agencyUsers.where,
            id: { $not: null },
          };
          data.agencyUsers.users = {
            where: {
              ...data.agencyUsers.users.where,
              name: {
                $ilike: `%${filter.value}%`,
              },
            },
          };
        } else if (filter.id === 'createdAt')
          data.where[filter.id] = formatDatesForFilter(filter.value);
        else data.where[filter.id] = { $ilike: `%${filter.value}%` };
      });
    }
    const clients = yield call(requestHandler, {
      method: 'POST',
      path: '/clients/getAll',
      data,
    });
    clients.pages = yield call(Math.ceil, clients.count / pageSize);
    yield put(getAllClientsSuccess({ clients }));
  } catch (e) {
    yield put(getAllClientsFail({}));
    console.log('error getting all clients: ', e);
  }
}

export function* getClientSaga(action) {
  try {
    const { id } = action.payload;
    const data = {
      where: {
        id,
      },
      conversations: {
        agencyUsers: {
          users: {
            options: {
              attributes: ['name'],
            },
          },
          options: {
            attributes: ['type'],
          },
        },
      },
    };
    const client = yield call(requestHandler, {
      method: 'POST',
      path: '/clients/getOne',
      data,
    });
    yield put(
      getLogs({
        object: 'clients',
        objectID: id,
      }),
    );
    yield put(getClientSuccess({ client }));
  } catch (e) {
    yield put(getClientFail({}));
    console.log('error in getting client: ', e);
  }
}

export function* updateClientSaga(action) {
  try {
    const data = {
      ...action.payload,
    };
    const client = yield call(requestHandler, {
      method: 'POST',
      path: '/clients/update',
      data,
    });
    const message = {
      title: 'Cliente actualizado',
      desc: `El cliente ha sido actualizado correctamente`,
    };
    yield put(updateClientSuccess({ message, client }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Error al tratar de actualizar el cliente, por favor intenta más tarde`,
    };
    yield put(updateClientFail({ message }));
    console.log('error updating client: ', e);
  }
}

export function* updateConversationFromClientSaga(action) {
  try {
    const { clientID, ...rest } = action.payload;
    yield call(updateConversationSaga, { payload: { ...rest } });
    yield call(getClientSaga, { payload: { id: clientID } });
  } catch (e) {
    console.log('error updating conversation: ', e);
  }
}

export function* assignConversationFromClientSaga(action) {
  try {
    const { clientID, ...rest } = action.payload;
    console.log('rest: ', { ...rest });
    yield call(assignConversationSaga, { payload: { ...rest } });
    yield call(getClientSaga, { payload: { id: clientID } });
  } catch (e) {
    console.log('error updating conversation: ', e);
  }
}

export function* attendConversationFromClientSaga(action) {
  try {
    const { clientID, ...rest } = action.payload;
    yield call(attendConversationSaga, { payload: { ...rest } });
    yield call(getClientSaga, { payload: { id: clientID } });
  } catch (e) {
    console.log('error updating conversation: ', e);
  }
}
