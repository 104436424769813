import {
  acceptInvitationFail,
  acceptInvitationSuccess,
  assignAgencyUserFail,
  assignAgencyUserSuccess,
  cleanUserDetail,
  createAdvisorFail,
  createAdvisorSuccess,
  deleteUserFail,
  deleteUserSuccess,
  emailUserExistsFail,
  emailUserExistsSuccess,
  getInvitationsFail,
  getInvitationsSuccess,
  getUserDetailFail,
  getUserDetailSuccess,
  getUsersCategoriesFail,
  getUsersCategoriesSuccess,
  getUsersFail,
  getUsersSuccess,
  inviteAdvisorFail,
  inviteAdvisorSuccess,
  resendInvitationFail,
  resendInvitationSuccess,
  updateAdvisorFail,
  updateAdvisorSuccess,
  updateMultipleAdvisorsFail,
  updateMultipleAdvisorsSuccess,
  updateUserFail,
  updateUserSuccess,
} from './userSlice.js';
import { call, put, select } from 'redux-saga/effects';
import {
  makeSelectAgencyId,
  makeSelectAgencyUserID,
  makeSelectUserCategories,
  makeSelectUserID,
  makeSelectUserRole,
} from '../auth/selectors.js';

import { errors } from './errors.js';
import { getAgencyUsers } from '../auth/authSlice.js';
import { getNested } from '../../utils/index.js';
import { isEmpty } from 'lodash';
import { requestHandler } from '../../services/requestHandler';

export function* getUsersSaga(action) {
  try {
    const { type, agencyID, showExternalUsers } = action.payload;
    const categories = yield select(makeSelectUserCategories());
    const agencyUserID = yield select(makeSelectAgencyUserID());
    const data = {
      where: {
        type,
        agencyID,
      },
      pagination: { order: [['createdAt', 'DESC']] },
      timeTables: {
        options: {
          attributes: [
            'id',
            'activated',
            'day',
            'hourA',
            'hourB',
            'minutesA',
            'minutesB',
          ],
        },
      },
      agencies: { options: { attributes: ['name'] } },
      users: {
        agencyUsers: {
          options: { attributes: ['agencyID'] },
        },
        where: {
          role: {
            $not: ['Userless', 'Normal User', 'Super Admin', 'Admin'],
          },
        },
        options: { attributes: ['id', 'email', 'name', 'role'] },
      },
    };
    if (agencyUserID)
      data.where = {
        ...data.where,
        $not: { id: agencyUserID },
      };
    if (showExternalUsers) {
      data.options = { multiAgency: true };
    }
    if (Array.isArray(categories) && categories.length > 0 && !type)
      data.where.type = {
        $or: [...categories, null, 'SIN INTERACCION'],
      };
    const users = yield call(requestHandler, {
      method: 'POST',
      path: '/agencyUsers/getAll',
      data,
    });
    yield put(getUsersSuccess({ users }));
  } catch (e) {
    yield put(getUsersFail('getUserBySuperAdmin'));
    console.log('error in get user BySuperAdmin saga', e);
  }
}

export function* getUsersCategoriesSaga(action) {
  try {
    const agencyUserID = yield select(makeSelectAgencyUserID());
    const data = {
      where: {},
      options: {
        attributes: ['type'],
        multiAgency: true,
      },
    };
    if (agencyUserID)
      data.where = {
        ...data.where,
        $not: { id: agencyUserID },
      };
    const userTypes = yield call(requestHandler, {
      method: 'POST',
      path: '/agencyUsers/getAll',
      data,
    });
    const categories = userTypes.reduce((acc, userType) => {
      if (userType.type && !acc.includes(userType.type))
        acc.push(userType.type);
      return acc;
    }, []);
    yield put(getUsersCategoriesSuccess({ categories }));
  } catch (e) {
    yield put(getUsersCategoriesFail('getUserBySuperAdmin'));
    console.log('error in get user BySuperAdmin saga', e);
  }
}

export function* getAgencyUsersInvitationsSaga(action) {
  try {
    const userID = yield select(makeSelectUserID());
    const data = {
      where: { userID, status: { $eq: null } },
      agencies: { options: { attributes: ['name'] } },
      users: { options: { attributes: ['phoneNumber'] } },
    };
    const invitations = yield call(requestHandler, {
      method: 'POST',
      path: '/invitations/getAll',
      data,
    });
    yield put(getInvitationsSuccess({ invitations }));
  } catch (e) {
    yield put(getInvitationsFail('getUserBySuperAdmin'));
    console.log('error in get user BySuperAdmin saga', e);
  }
}

export function* acceptInvitationSaga(action) {
  try {
    const data = {
      ...action.payload,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/invitations/accept',
      data,
    });
    const message = {
      title: 'Invitación aceptada',
      desc: `La invitación ha sido aceptada correctamente`,
    };
    yield call(getAgencyUsersInvitationsSaga);
    yield put(getAgencyUsers());
    yield put(acceptInvitationSuccess({ message }));
  } catch (e) {
    yield put(acceptInvitationFail('getUserBySuperAdmin'));
    console.log('error in get user BySuperAdmin saga', e);
  }
}

export function* deleteUserSaga(action) {
  try {
    const data = {
      id: action.payload.userID,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/user/remove',
      data,
    });
    yield call(getUsersSaga);
    const message = {
      title: 'Elimiado',
      desc: `El usuario se eliminó correctamente`,
    };
    yield put(deleteUserSuccess({ message }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Error al tratar de eliminar el usuario, por favor intenta más tarde`,
    };
    yield put(deleteUserFail({ message }));
    console.log('error', e);
  }
}

export function* deleteAgencyUserSaga(action) {
  try {
    const userID = getNested(null, action.payload, 'userID');
    const showExternalUsers = getNested(
      null,
      action.payload,
      'showExternalUsers',
    );

    let data = {
      id: action.payload.agencyUserID,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/agencyUsers/remove',
      data,
    });
    if (userID) {
      data = {
        id: userID,
      };
      yield call(requestHandler, {
        method: 'POST',
        path: '/user/remove',
        data,
      });
    }
    yield call(getUsersSaga, { payload: { showExternalUsers } });
    const message = {
      title: 'Elimiado',
      desc: `El usuario se eliminó correctamente`,
    };
    yield put(deleteUserSuccess({ message }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Error al tratar de eliminar el usuario, por favor intenta más tarde`,
    };
    yield put(deleteUserFail({ message }));
    console.log('error', e);
  }
}

export function* resendInvitationSaga(action) {
  try {
    const data = {
      id: action.payload.userID,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/user/resendInvitation',
      data,
    });
    yield call(getUsersSaga);
    const message = {
      title: '¡Enviado!',
      desc: `¡Correo enviado correctamente!`,
    };
    yield put(
      resendInvitationSuccess({
        message,
      }),
    );
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ha ocurrido un error al tratar de enviar el correo, por favor intente más tarde`,
    };
    yield put(
      resendInvitationFail({
        message,
      }),
    );
    console.log('error: ', e);
  }
}

export function* getUserDetailSaga(action) {
  try {
    yield put(cleanUserDetail());
    const data = {
      where: { id: action.payload.userID },
      timeTables: {
        options: {
          attributes: [
            'activated',
            'agencyUserID',
            'day',
            'hourA',
            'hourB',
            'id',
            'minutesA',
            'minutesB',
          ],
        },
      },
      users: true,
    };
    const user = yield call(requestHandler, {
      method: 'POST',
      path: '/agencyUsers/getOne',
      data,
    });
    yield put(getUserDetailSuccess({ user }));
  } catch (e) {
    yield put(getUserDetailFail('detailUser'));
    console.log('error in get detail user saga', e);
  }
}

export function* updateUserSaga(action) {
  try {
    const data = {
      ...action.payload,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/agencyUsers/update',
      data,
    });
    yield call(getUsersSaga);
    const message = {
      title: 'Usuario actualizado',
      desc: `El usuario ha sido acutalizado correctamente`,
    };
    yield put(updateUserSuccess({ message }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ha sucedido un error al tratar de actualizar el usuario, por favor intenta nuevamente`,
    };
    yield put(updateUserFail(message));
    console.log('error in update user saga', e);
  }
}

export function* createAdvisorSaga(action) {
  try {
    const userRole = yield select(makeSelectUserRole());
    const agencyId = yield select(makeSelectAgencyId());
    const {
      activated,
      phoneNumber,
      priority,
      type,
      timeTables,
      whatsConversations,
      fbConversations,
      ...rest
    } = action.payload;
    let tempagencyID;
    if (userRole === 'Super Admin')
      tempagencyID = action.payload.agencyID;
    else tempagencyID = agencyId;
    const data = {
      ...rest,
      agencyID: tempagencyID ? tempagencyID : undefined,
    };
    const response = yield call(requestHandler, {
      method: 'POST',
      path: '/user/createAdmin',
      data,
    });
    const agencyUserData = {
      userID: response.user.id,
      agencyID: tempagencyID || undefined,
      type: type || undefined,
      priority: priority || undefined,
      timeTables: timeTables || undefined,
      phoneNumber: phoneNumber || undefined,
      activated: activated || false,
      whatsConversations: whatsConversations || false,
      fbConversations: fbConversations || false,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/agencyUsers/create',
      data: agencyUserData,
    });
    const message = {
      title: 'Usuario creado',
      desc: `El usuario ha sido creado correctamente`,
    };
    const redirect = '/users';
    yield put(createAdvisorSuccess({ message, redirect }));
  } catch (e) {
    let message;
    if (e.code in errors)
      message = {
        title: 'Error',
        desc: errors[e.code],
      };
    else
      message = {
        title: 'Error',
        desc: `Ha sucedido un error al tratar de crear un usuario, por favor intente más tarde`,
      };
    yield put(createAdvisorFail({ message }));
    console.log('error: ', e);
  }
}

export function* assignAgencyUserSaga(action) {
  try {
    const { agencyName, userName, ...rest } = action.payload;
    console.log('userName: ', userName);
    const data = {
      ...rest,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/agencyUsers/create',
      data,
    });
    const message = {
      title: 'Usuario asigando',
      desc: `${userName} ha sido asignado correctamente.`,
    };
    yield put(assignAgencyUserSuccess({ message }));
  } catch (e) {
    let message;
    if (e.code in errors)
      message = {
        title: 'Error',
        desc: errors[e.code],
      };
    else
      message = {
        title: 'Error',
        desc: `Ha sucedido un error al tratar de asignar el usuario, por favor intente más tarde`,
      };
    yield put(assignAgencyUserFail({ message }));
    console.log('error: ', e);
  }
}

export function* updateAdvisorSaga(action) {
  try {
    const {
      name,
      email,
      userID,
      categories,
      ...rest
    } = action.payload;
    let data = {
      ...rest,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/agencyUsers/update',
      data,
    });
    data = {
      id: userID,
      email,
      name,
      categories,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/user/update',
      data,
    });
    const message = {
      title: 'Usuario actualizado',
      desc: `El usuario ha sido actualizado correctamente`,
    };
    const redirect = '/users';
    yield put(updateAdvisorSuccess({ message, redirect }));
  } catch (e) {
    let message;
    if (e.code in errors)
      message = {
        title: 'Error',
        desc: errors[e.code],
      };
    else
      message = {
        title: 'Error',
        desc: `Ha sucedido un error al tratar de actualizar un usuario, por favor intente más tarde`,
      };
    yield put(updateAdvisorFail({ message }));
    console.log('error: ', e);
  }
}

export function* updateMultipleAdvisorsSaga(action) {
  try {
    const { users, showExternalUsers } = action.payload;
    if (Array.isArray(users) && users.length > 0) {
      for (let user of users) {
        let data = {
          ...user,
        };
        yield call(requestHandler, {
          method: 'POST',
          path: '/agencyUsers/update',
          data,
        });
      }
    }
    const message = {
      title: 'Usuarios actualizados',
      desc: `Los usuarios han sido actualizados correctamente`,
    };
    yield call(getUsersSaga, { payload: { showExternalUsers } });
    yield put(updateMultipleAdvisorsSuccess({ message }));
  } catch (e) {
    let message;
    if (e.code in errors)
      message = {
        title: 'Error',
        desc: errors[e.code],
      };
    else
      message = {
        title: 'Error',
        desc: `Ha sucedido un error al tratar de actualizar los usuarios, por favor intente más tarde`,
      };
    yield put(updateMultipleAdvisorsFail({ message }));
    console.log('error: ', e);
  }
}

export function* inviteAdvisorSaga(action) {
  try {
    const { email, text, agencyID, ...rest } = action.payload;
    let data = {
      where: {
        email,
      },
    };
    const user = yield call(requestHandler, {
      method: 'POST',
      path: '/user/getOne',
      data,
    });
    if (!isEmpty(user)) {
      data = {
        text,
        userID: user.id,
        agencyID: agencyID || undefined,
        data: {
          ...rest,
        },
      };
      yield call(requestHandler, {
        method: 'POST',
        path: '/invitations/create',
        data,
      });
      const message = {
        title: 'Usuario creado',
        desc: `${user.name} ha sido invitado correctamente`,
      };
      const redirect = '/users';
      yield put(inviteAdvisorSuccess({ message, redirect }));
    } else {
      const message = {
        title: 'Usuario no encontrado',
        desc: `El correo proporcinado no corresponde a un Asesor`,
      };
      yield put(inviteAdvisorFail({ message }));
    }
  } catch (e) {
    let message;
    if (e.code in errors)
      message = {
        title: 'Error',
        desc: errors[e.code],
      };
    else
      message = {
        title: 'Error',
        desc: `Ha sucedido un error al tratar de invitar al asesor, por favor intente más tarde`,
      };
    yield put(updateAdvisorFail({ message }));
    console.log('error: ', e);
  }
}

export function* emailUserExistsSaga(action) {
  try {
    let data = {
      where: {
        ...action.payload,
      },
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/user/getOne',
      data,
    });
    yield put(emailUserExistsSuccess({}));
  } catch (e) {
    if (e.details.includes('Error: No results found')) {
      const message = {
        title: 'Usuario no encontrado',
        desc: `El correo que ha ingresado no corresponde a un asesor, por favor verifique el correo`,
      };
      yield put(emailUserExistsFail({ message }));
    }
    console.log('error: ', e);
  }
}

export function* closeModalsAndRedirectSaga(action) {
  const { history, redirect } = action.payload;
  try {
    if (redirect) yield call(history.push, redirect);
  } catch (e) {
    console.log('error: ', e);
  }
}
