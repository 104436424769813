import { createSelector } from 'reselect';
import { initialState } from './initialState';

export const selectEvents = (state) => state.events || initialState;

export const makeSelectEventsFromdb = createSelector(
  selectEvents,
  (eventsState) => eventsState.fromdb,
);

export const makeSelectEventsToDownload = createSelector(
  selectEvents,
  (eventsState) => eventsState.toDownload,
);

export const makeSelectEventsDetails = createSelector(
  selectEvents,
  (eventsState) => eventsState.eventDetails,
);

export const makeSelectEventsIsLoading = createSelector(
  selectEvents,
  (eventsState) => eventsState.isLoading,
);
