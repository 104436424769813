import { all, call, put } from 'redux-saga/effects';
import {
  notificationSendFail,
  notificationSendSuccess,
  sendNotificationsToAllUsersFail,
  sendNotificationsToAllUsersSuccess,
} from './notificationsSlice';

import { makeSelectAgencyId } from '../auth/selectors';
import { requestHandler } from '../../services/requestHandler';

export function* sendNotificationSaga(action) {
  try {
    const data = {
      ...action.payload,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/notification/send',
      data,
    });
    const message = {
      title: 'Enviada!',
      desc: `La notificación se envió correctamente.`,
    };
    yield put(notificationSendSuccess({ message }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba enviar la notifiación.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(notificationSendFail({ message }));
    console.log('error in send notification saga');
  }
}

export function* sendNotificationToAllUsersSaga(action) {
  try {
    let cellphones;
    const agencyID = action.payload.agencyID
      ? action.payload.agencyID
      : makeSelectAgencyId();
    const { allCategoriesUsers } = action.payload;
    const data = {
      where: { agencyID },
      options: {
        attributes: ['id'],
      },
      clients: {
        where: { phoneNumber: { $not: null } },
        options: {
          attributes: ['phoneNumber'],
        },
      },
    };
    if (
      Array.isArray(allCategoriesUsers) &&
      allCategoriesUsers.length > 0
    )
      data.where = {
        ...data.where,
        type: { $in: allCategoriesUsers },
      };
    const conversations = yield call(requestHandler, {
      method: 'POST',
      path: '/conversations/getAll',
      data,
    });
    if (conversations && Array.isArray(conversations))
      cellphones = yield all(
        conversations.reduce((accumulator, conversation) => {
          const phoneNumber = conversation.client.phoneNumber;
          if (phoneNumber && !accumulator.includes(phoneNumber)) {
            const tempPhoneNumber =
              phoneNumber.indexOf('52') === -1
                ? `52${phoneNumber}`
                : phoneNumber;
            accumulator.push(tempPhoneNumber);
          }
          return accumulator;
        }, []),
      );
    const payload = {
      ...action.payload,
      phoneNumbers: cellphones,
    };
    yield call(sendNotificationSaga, { payload });
    const message = {
      title: 'Enviada!',
      desc: `La notificación se envió correctamente.`,
    };
    yield put(sendNotificationsToAllUsersSuccess({ message }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba enviar la notifiación.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(sendNotificationsToAllUsersFail({ message }));
    console.log('error in send notification saga', e);
  }
}

export function* closeNotificationModalsSaga(action) {
  const { history, redirect } = action.payload;
  try {
    if (redirect) yield call(history.push, redirect);
  } catch (e) {
    console.log('error: ', e);
  }
}
