import { Button, MenuItem, TextField } from '@material-ui/core';

import { Formik } from 'formik';
import { OverlayTrigger } from 'react-bootstrap';
import { Picker } from 'emoji-mart';
import React from 'react';
import { agencyProfileSchema } from '../../containers/ProfileAgency/schema';
import { capitalize } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  textField: {
    margin: theme.spacing(1),
    width: '97%',
  },
  emojiFace: {
    fontSize: '20px',
  },
  submitButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2, 2, 0, 2),
  },
}));

const GeneralConfig = ({
  agencyData,
  setData,
  usersCategories,
  handleClickOpen,
  isLoading,
}) => {
  const classes = useStyles();

  const renderEmojiPicker = (values, setFieldValue, valueKey) => (
    <span>
      <Picker
        title=""
        emoji=""
        enableFrequentEmojiSort={false}
        emojiSize={20}
        showSkinTones={false}
        interactive={true}
        i18n={{
          search: 'Buscar',
          clear: 'Limpiar', // Accessible label on "clear" button
          notfound: 'Emoji no encontrado',
          skintext: 'Choose your default skin tone',
          categories: {
            search: 'Resultados',
            recent: 'Usados frecuentemente',
            smileys: 'Sonrisas y expresiones',
            people: 'Personas',
            nature: 'Animales y naturaleza',
            foods: 'Comida',
            activity: 'Actividades',
            places: 'Lugares y viajes',
            objects: 'Objetos',
            symbols: 'Símbolos',
            flags: 'Banderas',
            custom: 'Custom',
          },
        }}
        onSelect={(emoji) =>
          setFieldValue(valueKey, values[valueKey] + emoji.native)
        }
      />
    </span>
  );
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          name: agencyData.name,
          phoneNumber: agencyData.phoneNumber,
          email: agencyData.email,
          menuMessage: agencyData.menuMessage,
          advisorTimeout: agencyData.advisorTimeout,
          scoreMessage: agencyData.scoreMessage,
          unavailableMessage: agencyData.unavailableMessage,
          inactivityMessage: agencyData.inactivityMessage,
          defaultType: agencyData.defaultType,
        }}
        validationSchema={agencyProfileSchema}
        onSubmit={(values, { setStatus, setSubmitting }) => {
          setData(values);
          handleClickOpen();
        }}
      >
        {({
          values,
          status,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form
            className={classes.container}
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
          >
            <TextField
              required
              name="name"
              type="text"
              label="Nombre de la agencia"
              variant="outlined"
              className={classes.textField}
              value={values.name}
              onBlur={handleBlur}
              onChange={handleChange}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
            <TextField
              required
              name="phoneNumber"
              type="text"
              label="Número telefónico de la agencia"
              variant="outlined"
              className={classes.textField}
              value={values.phoneNumber}
              onBlur={handleBlur}
              onChange={(e) => {
                if (/^[0-9]*$/.test(e.target.value)) {
                  handleChange(e);
                }
              }}
              error={Boolean(
                touched.phoneNumber && errors.phoneNumber,
              )}
              helperText={touched.phoneNumber && errors.phoneNumber}
            />
            <TextField
              required
              name="email"
              type="email"
              label="Correo electrónico de la agencia"
              variant="outlined"
              className={classes.textField}
              value={values.email}
              onBlur={handleBlur}
              onChange={handleChange}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
            <TextField
              required
              select
              name="defaultType"
              type="text"
              label="Categoría de re-dirección de conversaciones sin interacción"
              variant="outlined"
              className={classes.textField}
              value={values.defaultType}
              onBlur={handleBlur}
              onChange={handleChange}
              error={Boolean(
                touched.defaultType && errors.defaultType,
              )}
              helperText={touched.defaultType && errors.defaultType}
            >
              {Array.isArray(usersCategories) &&
                usersCategories.map((option) => (
                  <MenuItem key={option} value={option}>
                    {capitalize(option)}
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              required
              multiline
              rows="2"
              name="menuMessage"
              type="text"
              label="Mensaje de bienvenida"
              variant="outlined"
              className={classes.textField}
              value={values.menuMessage}
              onBlur={handleBlur}
              onChange={handleChange}
              error={Boolean(
                touched.menuMessage && errors.menuMessage,
              )}
              helperText={touched.menuMessage && errors.menuMessage}
              InputProps={{
                endAdornment: (
                  <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    overlay={renderEmojiPicker(
                      values,
                      setFieldValue,
                      'menuMessage',
                    )}
                  >
                    <Button size="small">
                      <span
                        role="img"
                        aria-label=""
                        className={classes.emojiFace}
                      >
                        😁
                      </span>
                    </Button>
                  </OverlayTrigger>
                ),
              }}
            />
            <TextField
              required
              multiline
              rows="2"
              name="inactivityMessage"
              type="text"
              label="Mensaje al cliente cuando un asesor tarda en responder al cliente"
              variant="outlined"
              className={classes.textField}
              value={values.inactivityMessage}
              onBlur={handleBlur}
              onChange={handleChange}
              error={Boolean(
                touched.inactivityMessage && errors.inactivityMessage,
              )}
              helperText={
                touched.inactivityMessage && errors.inactivityMessage
              }
              InputProps={{
                endAdornment: (
                  <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    overlay={renderEmojiPicker(
                      values,
                      setFieldValue,
                      'inactivityMessage',
                    )}
                  >
                    <Button size="small">
                      <span
                        role="img"
                        aria-label=""
                        className={classes.emojiFace}
                      >
                        😁
                      </span>
                    </Button>
                  </OverlayTrigger>
                ),
              }}
            />
            <TextField
              required
              multiline
              rows="2"
              name="unavailableMessage"
              type="text"
              label="Mensaje para el cliente cuando no hay asesores disponibles"
              variant="outlined"
              className={classes.textField}
              value={values.unavailableMessage}
              onBlur={handleBlur}
              onChange={handleChange}
              error={Boolean(
                touched.unavailableMessage &&
                  errors.unavailableMessage,
              )}
              helperText={
                touched.unavailableMessage &&
                errors.unavailableMessage
              }
              InputProps={{
                endAdornment: (
                  <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    overlay={renderEmojiPicker(
                      values,
                      setFieldValue,
                      'unavailableMessage',
                    )}
                  >
                    <Button size="small">
                      <span
                        role="img"
                        aria-label=""
                        className={classes.emojiFace}
                      >
                        😁
                      </span>
                    </Button>
                  </OverlayTrigger>
                ),
              }}
            />
            <TextField
              required
              multiline
              rows="2"
              name="scoreMessage"
              type="text"
              label="Mensaje de calificación"
              variant="outlined"
              className={classes.textField}
              value={values.scoreMessage}
              onBlur={handleBlur}
              onChange={handleChange}
              error={Boolean(
                touched.scoreMessage && errors.scoreMessage,
              )}
              helperText={touched.scoreMessage && errors.scoreMessage}
              InputProps={{
                endAdornment: (
                  <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    overlay={renderEmojiPicker(
                      values,
                      setFieldValue,
                      'scoreMessage',
                    )}
                  >
                    <Button size="small">
                      <span
                        role="img"
                        aria-label=""
                        className={classes.emojiFace}
                      >
                        😁
                      </span>
                    </Button>
                  </OverlayTrigger>
                ),
              }}
            />
            <TextField
              required
              name="advisorTimeout"
              type="text"
              label="Límite de tiempo para que el asesor responda al cliente"
              variant="outlined"
              className={classes.textField}
              value={values.advisorTimeout}
              onBlur={handleBlur}
              onChange={handleChange}
              error={Boolean(
                touched.advisorTimeout && errors.advisorTimeout,
              )}
              helperText={
                touched.advisorTimeout && errors.advisorTimeout
              }
            />
            <div className={classes.submitButtonContainer}>
              <Button
                disabled={isLoading}
                type="submit"
                color="primary"
              >
                Actualizar
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default GeneralConfig;
