import { createAction, createSlice } from '@reduxjs/toolkit';

import { initialState } from './initialState';

const cleanStore = createAction('auth/logout');
const changeAgency = createAction('auth/changeAgency');

const campaignSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    getAllCampaigns: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getAllCampaignsSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.fromdb = action.payload.campaigns.rows;
        state.tablePages = action.payload.campaigns.pages;
        state.campaignDetails = null;
      },
    },
    getAllCampaignsFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.error = action.payload.error;
      },
    },
    getAllCampaignsForDownloadSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.toDownload = action.payload.campaigns;
      },
    },
    getAllCampaignsForDownloadFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.error = action.payload.error;
      },
    },
    getCampaignDetails: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getCampaignDetailsSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.campaignDetails = action.payload.campaign;
      },
    },
    getCampaignDetailsFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.error = action.payload.error;
      },
    },
    createCampaign: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    createCampaignSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    createCampaignFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    closeModals: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = initialState.successModal;
        state.errorModal = initialState.errorModal;
        state.deletedModal = false;
      },
    },
    resetStore: {
      reducer(state, action) {
        return initialState;
      },
    },
  },
  extraReducers: {
    [cleanStore]: (state, action) => {
      return initialState;
    },
    [changeAgency]: (state, action) => {
      return initialState;
    },
  },
});

export const {
  getAllCampaigns,
  getAllCampaignsSuccess,
  getAllCampaignsFail,
  getAllCampaignsForDownloadSuccess,
  getAllCampaignsForDownloadFail,
  getCampaignDetails,
  getCampaignDetailsSuccess,
  getCampaignDetailsFail,
  createCampaign,
  createCampaignSuccess,
  createCampaignFail,
  closeModals,
  resetStore,
} = campaignSlice.actions;

export default campaignSlice.reducer;
