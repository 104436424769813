import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Switch,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { cleanEmptyKeys, getNested } from '../../../../utils';
import {
  closeModals,
  getAgencyDetail,
  updateAgency,
  updateOption,
  updloadLogo,
} from '../../actions';

import Modal from '../../../../components/Modal';
import SplashScreen from '../../../../partials/layout/SplashScreen';
import { connect } from 'react-redux';
import { green } from '@material-ui/core/colors';
import { injectIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  divider: {
    width: '100%',
  },
  title: {
    textAlign: 'left',
    color: '#1c1c1c',
    position: 'relative',
  },
  content: {
    textAlign: 'left',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  menu: {
    height: '100%',
    width: '100%',
  },
  margin: {
    margin: theme.spacing(1),
  },
  iconRight: {
    margin: theme.spacing(0),
    padding: theme.spacing(1),
    position: 'absolute',
    top: '40%',
    right: '5%',
    marginTop: -12,
  },
  media: {
    height: 100,
    width: '100%',
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  image: {
    height: '100%',
    width: 'auto',
  },
  mediaIcon: {
    height: 60,
    width: '100%',
    position: 'relative',
  },
  imageIcon: {
    height: '100%',
    width: 'auto',
    position: 'absolute',
    top: '10%',
    left: '45%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function DetailAgency(props) {
  const classes = useStyles();
  const [agencyData, setAgency] = React.useState({});
  const [edit, setEdit] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [showButton, setButton] = React.useState(true);
  const [values, setValues] = React.useState({
    name: '',
    logo: '',
    phoneNumber: '',
    phoneID: '',
  });
  const [origianlValues, setOriginalValues] = React.useState({});

  useEffect(() => {
    props.getAgencyDetail({ agencyID: props.match.params.id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.agencies.agencyDetails) {
      const agencyData = props.agencies.agencyDetails;
      const cleanAgency = {
        id: agencyData.id,
        name: agencyData.name ? agencyData.name : '-',
        email: agencyData.email ? agencyData.email : '-',
        phoneNumber: agencyData.phoneNumber
          ? agencyData.phoneNumber
          : '-',
        phoneID: agencyData.phoneID ? agencyData.phoneID : '-',
        menuMessage: getNested('-', agencyData, 'menuMessage'),
        maytToken: getNested('-', agencyData, 'maytToken'),
        maytProductID: getNested('-', agencyData, 'maytProductID'),
        fbID: getNested('-', agencyData, 'fbID'),
        fbApiToken: getNested('-', agencyData, 'fbApiToken'),
        fbPageName: getNested('-', agencyData, 'fbPageNames'),
        whatsAppOriginWidget: getNested(
          false,
          agencyData,
          'whatsAppOriginWidget',
        ),
        replyOnAdmin: getNested(false, agencyData, 'replyOnAdmin'),
        customerLost: getNested(false, agencyData, 'customerLost'),
      };
      setAgency(cleanAgency);

      const editAgency = {
        id: agencyData.id,
        name: agencyData.name ? agencyData.name : '-',
        phoneNumber: agencyData.phoneNumber
          ? agencyData.phoneNumber
          : '-',
        phoneID: agencyData.phoneID ? agencyData.phoneID : '-',
        menuMessage: getNested('', agencyData, 'menuMessage'),
        maytToken: getNested('', agencyData, 'maytToken'),
        maytProductID: getNested('', agencyData, 'maytProductID'),
        fbID: getNested('', agencyData, 'fbID'),
        fbApiToken: getNested('', agencyData, 'fbApiToken'),
        fbPageName: getNested('', agencyData, 'fbPageName'),
      };
      setValues(editAgency);
      setOriginalValues(editAgency);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.agencies.agencyDetails]);

  useEffect(() => {
    if (props.agencies.logoUploaded) {
      setButton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.agencies.logoUploaded]);

  const handleUpdateOption = (option, value) => {
    const optionTemp = {
      id: agencyData.id,
    };
    optionTemp[option] = value;
    props.updateOption(optionTemp);
  };

  const handleChange = (name) => (event) => {
    if (name.includes('.')) {
      const splitedObjKey = name.split('.');
      setValues({
        ...values,
        [splitedObjKey[0]]: {
          ...values[splitedObjKey[0]],
          [splitedObjKey[1]]: event.target.value,
        },
      });
    } else setValues({ ...values, [name]: event.target.value });
    setButton(false);
  };

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  const handleSubmit = () => {
    let toSendData = props.agencies.logoUploaded
      ? {
          ...values,
          logo: props.agencies.logoURL,
        }
      : { ...values };
    toSendData = cleanEmptyKeys(toSendData, origianlValues);
    props.updateAgency(toSendData);
    handleClose();
  };

  function handleCloseModals() {
    props.closeModals();
  }

  const handleEdit = () => {
    setEdit(!edit);
  };

  const renderAgencyDetails = () => {
    return (
      <List component="nav" aria-label="Main mailbox folders">
        <ListItem button>
          <ListItemText
            primary="Nombre de agencia"
            secondary={agencyData.name}
          />
        </ListItem>
        <ListItem button>
          <ListItemText
            primary="Correo"
            secondary={agencyData.email}
          />
        </ListItem>
        <ListItem button>
          <ListItemText
            primary="Teléfono"
            secondary={agencyData.phoneNumber}
          />
        </ListItem>
        <ListItem button>
          <ListItemText
            primary="Phone Id"
            secondary={agencyData.phoneID}
          />
        </ListItem>
        <ListItem button>
          <ListItemText
            primary="maytProductID"
            secondary={agencyData.maytProductID}
          />
        </ListItem>
        <ListItem button>
          <ListItemText
            primary="maytToken"
            secondary={agencyData.maytToken}
          />
        </ListItem>
        <ListItem button>
          <ListItemText primary="fbID" secondary={agencyData.fbID} />
        </ListItem>
        <ListItem button>
          <ListItemText
            primary="fbApiToken"
            secondary={agencyData.fbApiToken}
          />
        </ListItem>
        <ListItem button>
          <ListItemText
            primary="fbPageName"
            secondary={agencyData.fbPageName}
          />
        </ListItem>
      </List>
    );
  };

  const renderAgencyEdit = () => {
    return (
      <form
        className={classes.container}
        noValidate
        autoComplete="off"
      >
        <TextField
          label="Nombre"
          className={classes.textField}
          value={values.name}
          onChange={handleChange('name')}
          margin="normal"
          variant="outlined"
        />
        <TextField
          label="Teléfono"
          className={classes.textField}
          value={values.phoneNumber}
          onChange={handleChange('phoneNumber')}
          margin="normal"
          variant="outlined"
        />
        <TextField
          label="Phone ID"
          className={classes.textField}
          value={values.phoneID}
          onChange={handleChange('phoneID')}
          margin="normal"
          variant="outlined"
        />
        <TextField
          label="maytProductID"
          className={classes.textField}
          value={values.maytProductID}
          onChange={handleChange('maytProductID')}
          margin="normal"
          variant="outlined"
        />
        <TextField
          label="maytToken"
          className={classes.textField}
          value={values.maytToken}
          onChange={handleChange('maytToken')}
          margin="normal"
          variant="outlined"
        />
        <TextField
          label="fbID"
          className={classes.textField}
          value={values.fbID}
          onChange={handleChange('fbID')}
          margin="normal"
          variant="outlined"
        />
        <TextField
          label="fbApiToken"
          className={classes.textField}
          value={values.fbApiToken}
          onChange={handleChange('fbApiToken')}
          margin="normal"
          variant="outlined"
        />
        <TextField
          label="fbPageName"
          className={classes.textField}
          value={values.fbPageName}
          onChange={handleChange('fbPageName')}
          margin="normal"
          variant="outlined"
        />
        {/* <div className={classes.buttonGroup}>
          <input
            accept="image/*"
            hidden
            id="raised-button-file"
            type="file"
            onChange={e => handleFile(e.target.files)}
          />
          <label
            htmlFor="raised-button-file"
            className={classes.wrapper}
          >
            <Button
              variant="contained"
              component="span"
              color="primary"
              className={buttonClassname}
              disabled={props.agencies.isLoading}
            >
              {props.agencies.logoUploaded
                ? 'Logo Cargado'
                : 'Cambiar logo'}
              {props.agencies.logoUploaded ? (
                <Check className={classes.rightIcon} />
              ) : (
                <CloudUploadIcon className={classes.rightIcon} />
              )}
            </Button>
            {props.agencies.isLoading && (
              <CircularProgress
                size={24}
                className={classes.buttonProgress}
              />
            )}
          </label>
        </div> */}
        <Button
          disabled={showButton}
          variant="contained"
          size="medium"
          color="primary"
          className={classes.margin}
          onClick={handleClickOpen}
        >
          Guardar
        </Button>
      </form>
    );
  };

  const renderConfirmation = () => {
    return (
      <div>
        <Dialog
          fullWidth={true}
          maxWidth="xs"
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            ¿Seguro que deseas editar la agencia?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Se actualizará la agencia con la nueva información que
              proporciones
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleSubmit} color="primary">
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = props.agencies;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message } = props.agencies[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={handleCloseModals}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={handleCloseModals}
          />
        );
      }
      return null;
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  return isEmpty(agencyData) ? (
    <SplashScreen></SplashScreen>
  ) : (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          <Paper className={classes.paper}>
            <DialogTitle className={classes.title}>
              {edit ? 'Agencia - (editar)' : 'Agencia'}
              <IconButton
                aria-label="Delete"
                className={classes.iconRight}
                onClick={handleEdit}
              >
                <i className="far fa-edit"></i>
              </IconButton>
            </DialogTitle>
            <Divider className={classes.divider} />
            <DialogContent className={classes.content}>
              {edit ? renderAgencyEdit() : renderAgencyDetails()}
              {renderConfirmation()}
              {renderFeedBackModal()}
            </DialogContent>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper className={classes.paper}>
            <DialogTitle className={classes.title}>
              Configuraciones
            </DialogTitle>
            <Divider className={classes.divider} />
            <DialogContent>
              <Grid item xs={12} className={classes.client}>
                <Typography variant="h6" className={classes.top}>
                  Widget
                </Typography>
              </Grid>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Switch
                      className={classes.switch}
                      checked={agencyData.whatsAppOriginWidget}
                      onChange={() =>
                        handleUpdateOption(
                          'whatsAppOriginWidget',
                          !agencyData.whatsAppOriginWidget,
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Origen Whatsapp"
                />
              </FormGroup>
              <Grid item xs={12} className={classes.client}>
                <Typography variant="h6" className={classes.top}>
                  Comunicación
                </Typography>
              </Grid>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Switch
                      className={classes.switch}
                      checked={agencyData.replyOnAdmin}
                      onChange={() =>
                        handleUpdateOption(
                          'replyOnAdmin',
                          !agencyData.replyOnAdmin,
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Comunicación centralizada"
                />
              </FormGroup>
              <Grid item xs={12} className={classes.client}>
                <Typography variant="h6" className={classes.top}>
                  Conversaciones
                </Typography>
              </Grid>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Switch
                      className={classes.switch}
                      checked={agencyData.customerLost}
                      onChange={() =>
                        handleUpdateOption(
                          'customerLost',
                          !agencyData.customerLost,
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Cliente perdido"
                />
              </FormGroup>
            </DialogContent>
          </Paper>
        </Grid>

        {/* <Grid item xs={12} sm={4}>
          <Paper className={classes.paper}>
            <DialogTitle className={classes.title}>Logo</DialogTitle>
            <Divider className={classes.divider} />
            <DialogContent>
              {agencyData.logo === '-' ? (
                <Typography variant="h4" gutterBottom>
                  No hay logo de agencia
                </Typography>
              ) : (
                <CardMedia
                  className={classes.media}
                  title="Logo de agencia"
                >
                  <img
                    src={agencyData.logo}
                    alt="logo"
                    className={classes.image}
                  />
                </CardMedia>
              )}
            </DialogContent>
          </Paper>
        </Grid> */}
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  agencies: state.agencies,
});

const mapDispatchToProps = {
  getAgencyDetail,
  updateAgency,
  updateOption,
  updloadLogo,
  closeModals,
};

export default withRouter(
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(DetailAgency),
  ),
);
