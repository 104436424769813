import { MenuItem, TextField, makeStyles } from '@material-ui/core';
import {
  Portlet,
  PortletBody,
} from '../../../../partials/content/Portlet';
import React, { useEffect, useState } from 'react';
import {
  cleanData,
  getData,
} from '../../../../features/dashboard/dashboardSlice';
import {
  getAgencies,
  getAgencyDetail,
} from '../../../agencies/actions';
import { getMainRoute, getNested } from '../../../../utils';
import {
  makeSelectAgenciesFromdb,
  makeSelectAgencyDetailsFromState,
} from '../../../agencies/selectors';
import {
  makeSelectCampaignsFromDashboard,
  makeSelectDashboardIsLoading,
  makeSelectEventsFromDashboard,
  makeSelectconversationsFromDashboard,
} from '../../../../features/dashboard/selectors';
import {
  makeSelectLoggedAgencyFromState,
  makeSelectUserRoleFromState,
} from '../../../../features/auth/selectors';

import GridContainer from 'app/components/Grid/GridContainer';
import GridItem from 'app/components/Grid/GridItem';
import QuickAdvisorsStats from 'app/widgets/QuickAdvisorsStats';
import QuickCampaignsSource from 'app/widgets/QuickCampaignsSource';
import QuickCategoryStatus from 'app/widgets/QuickCategoryStatus';
import QuickConversationsSources from 'app/widgets/QuickConversationsSources';
import QuickStatusCategories from 'app/widgets/QuickStatusCategories';
import QuickStatusClients from 'app/widgets/QuickStatusClients';
import { concat } from 'lodash';
import { connect } from 'react-redux';
import { get } from 'react-hook-form';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  agenciesSelect: {
    minWidth: theme.spacing(14),
  },
}));

function Dashboard(props) {
  const classes = useStyles();
  const {
    agencyID,
    conversations,
    agenciesFromdb,
    agencyDetails,
    getData,
    getAgencies,
    setActionButton,
    role,
    isLoading,
    cleanData,
    history,
    campaigns,
  } = props;

  const [selectedAgency, setSelectedAgency] = useState('');
  const [agencies, setAgencies] = useState([]);
  const [agencyCategories, setAgencyCategories] = useState([]);
  const [advisorName, setAdvisorName] = useState('');
  const [categoryStartDate, setcategoryStartDate] = useState(
    moment(),
  );
  const [categoryEndDate, setcategoryEndDate] = useState(moment());
  const [
    advisorsFilterStartDate,
    setAdvisorsFilterStartDate,
  ] = useState(moment().subtract(7, 'days'));
  const [advisorsFilterEndDate, setAdvisorsFilterEndDate] = useState(
    moment(),
  );
  const [
    conversationsSourceStartDate,
    setConversationsSourceStartDate,
  ] = useState(moment().subtract(7, 'days'));
  const [
    consversationsSourceEndDate,
    setConversationsSourceEndDate,
  ] = useState(moment());
  const [whatsAppOriginWidget, setWhatsAppOriginWidget] = useState(
    false,
  );
  const [
    campaignsSourceStartDate,
    setCampaignsSourceStartDate,
  ] = useState(moment().subtract(7, 'days'));
  const [
    campaignsSourceEndDate,
    setCampaignsSourceEndDate,
  ] = useState(moment());

  useEffect(() => {
    if (role === 'Super Admin') {
      if (selectedAgency) {
        const agencyFromDB = agenciesFromdb.find(
          (agency) => agency.id === selectedAgency,
        );
        setWhatsAppOriginWidget(
          get(agencyFromDB, 'whatsAppOriginWidget'),
        );
        handleGetCategoryByAgency();
        getData({
          agencyId: selectedAgency,
          categoryStartDate,
          categoryEndDate,
          advisorsFilterStartDate,
          advisorsFilterEndDate,
          advisorName,
          conversationsSourceStartDate,
          consversationsSourceEndDate,
          campaignsSourceStartDate,
          campaignsSourceEndDate,
        });
      }
    } else if (role === 'Agency Admin') {
      setWhatsAppOriginWidget(
        get(agencyDetails, 'whatsAppOriginWidget'),
      );
      handleGetAgencyCategories(agencyDetails);
      getData({
        agencyId: agencyID,
        categoryStartDate,
        categoryEndDate,
        advisorsFilterStartDate,
        advisorsFilterEndDate,
        advisorName,
        conversationsSourceStartDate,
        consversationsSourceEndDate,
        campaignsSourceStartDate,
        campaignsSourceEndDate,
      });
    } else {
      history.push(getMainRoute(role));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    role,
    selectedAgency,
    categoryStartDate,
    categoryEndDate,
    advisorsFilterStartDate,
    advisorsFilterEndDate,
    advisorName,
    agencyDetails,
    conversationsSourceStartDate,
    consversationsSourceEndDate,
    campaignsSourceStartDate,
    campaignsSourceEndDate,
  ]);

  useEffect(() => {
    if (role === 'Super Admin') {
      getAgencies();
    } else if (role === 'Agency Admin') {
      getAgencyDetail();
    }
    cleanData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let noOption = { value: '', label: '' };
    if (Array.isArray(agenciesFromdb)) {
      const cleanAgencies = concat(
        noOption,
        agenciesFromdb.map((agency) => {
          return {
            value: agency.id,
            label: agency.name,
          };
        }),
      );

      setAgencies(cleanAgencies);
    }
  }, [agenciesFromdb]);

  useEffect(() => {
    if (role === 'Super Admin')
      setActionButton(
        <>
          <TextField
            required
            select
            fullWidth
            className={classes.agenciesSelect}
            name="agencyID"
            label="Agencia"
            type="number"
            variant="outlined"
            size="small"
            value={selectedAgency}
            onChange={({ target: { value } }) =>
              setSelectedAgency(value)
            }
          >
            {agencies.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </>,
      );
    return () => {
      setActionButton(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agencies, selectedAgency, setActionButton, role]);

  const handleGetAgencyCategories = (agency) => {
    if (agency) {
      const agencyCategories = agency.customFlows.map(
        (customFlow) => customFlow.assessorType,
      );
      setAgencyCategories(agencyCategories);
    }
  };

  const handleGetCategoryByAgency = () => {
    if (selectedAgency) {
      let agency;
      for (let agencyElement of agenciesFromdb) {
        if (agencyElement.id === selectedAgency)
          agency = agencyElement;
      }
      handleGetAgencyCategories(agency);
    }
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={4} md={4} lg={4} xl={3}>
          <Portlet className="kt-portlet--border-bottom-brand">
            <PortletBody>
              <QuickStatusCategories
                value={
                  getNested(
                    0,
                    conversations,
                    'converStartedWidthAdvisorType',
                    'ventas',
                  ) +
                  getNested(
                    0,
                    conversations,
                    'converStartedWidthAdvisorType',
                    'servicio',
                  ) +
                  getNested(
                    0,
                    conversations,
                    'converStartedWidthAdvisorType',
                    'recepcion',
                  ) +
                  getNested(
                    0,
                    conversations,
                    'converStartedWidthAdvisorType',
                    'refacciones',
                  ) +
                  getNested(
                    0,
                    conversations,
                    'converStartedWidthAdvisorType',
                    'seminuevos',
                  ) +
                  getNested(
                    0,
                    conversations,
                    'converStartedWidthAdvisorType',
                    'financiamiento',
                  )
                }
                desc="Clientes atendidos"
                agencyCategories={agencyCategories}
                data={getNested(
                  0,
                  conversations,
                  'converStartedWidthAdvisorType',
                )}
                helpText="Esta casilla muestra los clientes que están recibiendo atención por parte de los asesores."
              />
            </PortletBody>
          </Portlet>
        </GridItem>
        <GridItem xs={12} sm={4} md={4} lg={4} xl={3}>
          <Portlet className="kt-portlet--border-bottom-brand">
            <PortletBody fluid={true}>
              <QuickStatusClients
                desc="Pendientes"
                value={getNested([], conversations, 'pending').length}
                conversations={getNested(
                  [],
                  conversations,
                  'pending',
                )}
                helpText="Esta casilla muestra los clientes que intentaron pedir informes cuando no había asesores disponibles."
              />
            </PortletBody>
          </Portlet>
        </GridItem>
        <GridItem xs={12} sm={4} md={4} lg={4} xl={3}>
          <Portlet className="kt-portlet--border-bottom-brand">
            <PortletBody>
              <QuickStatusClients
                desc="Sin interacción"
                value={
                  getNested([], conversations, 'noInteraction').length
                }
                conversations={getNested(
                  [],
                  conversations,
                  'noInteraction',
                )}
                helpText="Esta casilla muestra los clientes que no escogieron categoría al comunicarse con el bot."
              />
            </PortletBody>
          </Portlet>
        </GridItem>
      </GridContainer>
      {whatsAppOriginWidget && (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12} xl={9}>
            <Portlet className="kt-portlet--border-bottom-brand">
              <PortletBody fluid={true}>
                <QuickConversationsSources
                  desc="Origen Whatsapp"
                  conversations={conversations}
                  conversationsSourceStartDate={
                    conversationsSourceStartDate
                  }
                  consversationsSourceEndDate={
                    consversationsSourceEndDate
                  }
                  handleConversationSourceStartDate={
                    setConversationsSourceStartDate
                  }
                  handleConversationSourceEndDate={
                    setConversationsSourceEndDate
                  }
                />
              </PortletBody>
            </Portlet>
          </GridItem>
        </GridContainer>
      )}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12} xl={9}>
          <Portlet className="kt-portlet--border-bottom-brand">
            <PortletBody fluid={true}>
              <QuickCampaignsSource
                desc="Campañas"
                campaigns={campaigns}
                campaignsSourceStartDate={campaignsSourceStartDate}
                campaignsSourceEndDate={campaignsSourceEndDate}
                handleCampaignsSourceStartDate={
                  setCampaignsSourceStartDate
                }
                handleCampaignsSourceEndDate={
                  setCampaignsSourceEndDate
                }
              />
            </PortletBody>
          </Portlet>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12} xl={9}>
          <Portlet className="kt-portlet--border-bottom-brand">
            <PortletBody fluid={true}>
              <QuickCategoryStatus
                desc="Tiempo promedio de espera por categoría"
                conversations={conversations}
                categoryStartDate={categoryStartDate}
                categoryEndDate={categoryEndDate}
                handleCategoryStartDate={setcategoryStartDate}
                handleCategoryEndDate={setcategoryEndDate}
                agencyCategories={agencyCategories}
              />
            </PortletBody>
          </Portlet>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12} xl={9}>
          <Portlet className="kt-portlet--border-bottom-brand">
            <PortletBody fluid={true}>
              <QuickAdvisorsStats
                desc="Asesores"
                advisorsFiltered={getNested(
                  [],
                  conversations,
                  'advisorsFilterd',
                )}
                advisorsFilterStartDate={advisorsFilterStartDate}
                setAdvisorsFilterStartDate={
                  setAdvisorsFilterStartDate
                }
                advisorsFilterEndDate={advisorsFilterEndDate}
                setAdvisorsFilterEndDate={setAdvisorsFilterEndDate}
                advisorName={advisorName}
                setAdvisorName={setAdvisorName}
                isLoading={isLoading}
              />
            </PortletBody>
          </Portlet>
        </GridItem>
      </GridContainer>
    </>
  );
}

const mapStateToProps = (state) => ({
  conversations: makeSelectconversationsFromDashboard(state),
  campaigns: makeSelectCampaignsFromDashboard(state),
  role: makeSelectUserRoleFromState(state),
  agenciesFromdb: makeSelectAgenciesFromdb(state),
  isLoading: makeSelectDashboardIsLoading(state),
  events: makeSelectEventsFromDashboard(state),
  agencyID: makeSelectLoggedAgencyFromState(state),
  agencyDetails: makeSelectAgencyDetailsFromState(state),
});

const mapDispatchToProps = {
  getData,
  getAgencies,
  getAgencyDetail,
  cleanData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dashboard);
