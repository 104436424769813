import {
  Button,
  Card,
  CardContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import {
  assignConversationFromClient,
  attendConversationFromClient,
  closeModals,
  getClient,
  updateClient,
  updateConversationFromClient,
} from '../../clientsSlice';
import {
  makeSelectClientsDetails,
  selectClients,
} from '../../selectors';
import {
  makeSelectUsersFromState,
  makeSelectUsersIsLoading,
} from '../../../users/selectors';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import CancelIcon from '@material-ui/icons/Cancel';
import CategoryBadge from 'app/components/CategoryBadge';
import ConversationStatusBadge from 'app/components/ConversationStatusBadge';
import EditIcon from '@material-ui/icons/Edit';
import { MenuOption } from 'app/components/MenuOption';
import Modal from '../../../../components/Modal';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import RecentActivities from '../../../../components/RecentActivities';
import ShouldItRender from '../../../../components/ShouldItRender';
import SplashScreen from '../../../../partials/layout/SplashScreen';
import { TabPanel } from 'app/components/TabPanel';
import { Table } from 'react-bootstrap';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { getAgencies } from 'app/features/agencies/actions';
import { getNested } from '../../../../utils';
import { getUsers } from '../../../users/userSlice';
import { injectIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import { makeSelectAgenciesFromdb } from 'app/features/agencies/selectors';
import { makeSelectClientsLogs } from '../../../logs/selectors';
import { makeSelectUserRoleFromState } from 'app/features/auth/selectors';
import { withRouter } from 'react-router';

const useStyles = makeStyles((theme) => ({
  paper: {
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  paperTarjeta: {
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  title: {
    textAlign: 'left',
    color: '#1c1c1c',
  },
  textField: {
    margin: theme.spacing(1, 0, 1, 0),
  },
  rightContainer: {
    flexDirection: 'column',
  },
  tableTitle: {
    fontWeight: 401,
  },
  tables: {
    width: '100%',
  },
  clientTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  editClientButton: {
    margin: theme.spacing(1),
  },
  tabsMinimalWidth: {
    minWidth: '72px',
  },
  cardRoot: {
    padding: '0px 7px 5px 7px',
  },
  cardContent: {
    padding: '5px 7px 5px 7px',
    paddingBottom: '0px !important',
  },
  submiNotetbutton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  centerText: {
    textAlign: 'center',
  },
  conversationsCardRoot: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  conversationsCardContainer: {
    paddingBottom: '8px !important',
  },
  cardCointainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

function ClientDetail(props) {
  const classes = useStyles();
  const {
    clients,
    clientDetails,
    agenciesFromdb,
    clientLogs,
    getClient,
    setLeftSideComponent,
    setActionButton,
    advisors,
    role,
    updateConversationFromClient,
    assignConversationFromClient,
    attendConversationFromClient,
    getUsers,
    updateClient,
    closeModals,
    location,
    match,
  } = props;
  const [
    showConfirmAttendModal,
    setShowConfirmAttendModal,
  ] = useState(false);
  const [showEditClientModal, setShowEditClientModal] = useState(
    false,
  );
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [showProspectModal, setShowProspectModal] = useState(false);
  const [showProspectLostModal, setShowProspectLostModal] = useState(
    false,
  );
  const [tab, setTab] = React.useState(0);
  const [
    selectedConversation,
    setSelectedConversation,
  ] = React.useState(null);

  const {
    handleSubmit,
    register,
    errors,
    control,
    watch,
    reset,
  } = useForm();

  const assessorType = watch('assessorType');
  const agencyID = watch('agencyID');

  useEffect(() => {
    if (role === 'Super Admin') getAgencies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLeftSideComponent(
      <div className="kt-subheader__main">
        <div>
          <h3 className="kt-subheader__title">Detalle lead</h3>
        </div>
      </div>,
    );
    const data = {
      id: parseInt(props.match.params.clientID),
    };
    getClient(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setActionButton(
      <>
        <MenuOption
          Button={
            <Button className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10">
              Acciones
              <ArrowDropDownIcon className="kt-margin-l-10" />
            </Button>
          }
          options={[
            {
              name: (
                <>
                  <EditIcon />
                  &nbsp;&nbsp;&nbsp;Editar información del cliente
                </>
              ),
              onClick: togleClientModal,
            },
          ]}
        />
      </>,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientDetails]);

  useEffect(() => {
    if (clientDetails) {
      const phoneNumber = getNested(
        '',
        clientDetails,
        'phoneNumber',
      ).replace('52', '');
      reset({
        name: getNested('', clientDetails, 'name'),
        phoneNumber,
        note: getNested('', clientDetails, 'note'),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientDetails]);

  useEffect(() => {
    if (assessorType && agencyID)
      getUsers({ type: assessorType, agencyID });
    else if (assessorType) getUsers({ type: assessorType });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessorType, agencyID]);

  const togleAssignModal = () => setShowAssignModal(!showAssignModal);

  const toogleConfirmAttendModal = () => {
    setShowConfirmAttendModal(!showConfirmAttendModal);
  };

  const toogleProspectModal = () => {
    setShowProspectModal(!showProspectModal);
  };

  const toogleProspectLostModal = () => {
    setShowProspectLostModal(!showProspectLostModal);
  };

  const togleClientModal = () =>
    setShowEditClientModal(!showEditClientModal);

  const handleCloseModals = () => {
    closeModals({});
    setShowConfirmAttendModal(false);
  };

  const handleAssignConversation = (formData) => {
    const data = {
      clientID: parseInt(props.match.params.clientID),
      agencyUserID: formData.agencyUserID,
      conversationID: selectedConversation.id,
    };
    assignConversationFromClient(data);
    togleAssignModal();
  };

  const handleAttendConversation = () => {
    setShowConfirmAttendModal(false);
    attendConversationFromClient({
      clientID: parseInt(props.match.params.clientID),
      conversationID: selectedConversation.id,
    });
  };

  const handeProspectConversation = () => {
    setShowProspectModal(false);
    updateConversationFromClient({
      clientID: parseInt(props.match.params.clientID),
      conversationID: selectedConversation.id,
      status: 'Prospecto',
      title: 'Cliente prospecto',
    });
  };

  const handeProspectLost = () => {
    setShowProspectLostModal(false);
    updateConversationFromClient({
      clientID: parseInt(props.match.params.clientID),
      conversationID: selectedConversation.id,
      status: 'Prospecto perdido',
      title: 'Prospecto perdido',
    });
  };

  const handleUpdateClient = (formData) => {
    const { phoneNumber, ...rest } = formData;
    const data = {
      id: match.params.clientID,
      ...rest,
      phoneNumber: '52' + phoneNumber,
    };
    updateClient(data);
    setShowEditClientModal(false);
  };

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const renderAgencyOptions = () => {
    if (Array.isArray(agenciesFromdb))
      return agenciesFromdb.map((agency) => {
        return (
          <MenuItem key={`agency-${agency.id}`} value={agency.id}>
            {agency.name}
          </MenuItem>
        );
      });
    return null;
  };

  const renderAdvisorsOptions = () => {
    const advisorsOptions = [
      <MenuItem key="none" disabled value="">
        Selecciona una opción
      </MenuItem>,
    ];
    if (Array.isArray(advisors) && advisors.length > 0) {
      advisors.forEach((advisor) => {
        advisorsOptions.push(
          <MenuItem key={advisor.id} value={advisor.id}>
            {advisor.user.name}
          </MenuItem>,
        );
      });
    }
    return advisorsOptions;
  };

  const renderAttendClientDialog = () => {
    return (
      <Modal
        open={showConfirmAttendModal}
        closeModal={toogleConfirmAttendModal}
        dialogTitle="Atendiendo cliente"
        dialogText={`Está a punto de atender esta conversación, desea continuar?`}
        actionButtonText="Atender"
        onClick={handleAttendConversation}
      />
    );
  };

  const renderProspectDialog = () => {
    return (
      <Modal
        open={showProspectModal}
        closeModal={toogleProspectModal}
        dialogTitle="Cambiando a cliente prospecto"
        dialogText={`Está a punto de cambiar el cliente a un posible prospecto, desea continuar?`}
        actionButtonText="Cambiar a prospecto"
        onClick={handeProspectConversation}
      />
    );
  };

  const renderProspectLostDialog = () => {
    return (
      <Modal
        open={showProspectLostModal}
        closeModal={toogleProspectLostModal}
        dialogTitle="Cambiando a prospecto perdido"
        dialogText={`Está a punto de cambiar el prospecto a prospecto perdido, desea continuar?`}
        actionButtonText="Cambiar a prospecto perdido"
        onClick={handeProspectLost}
      />
    );
  };

  const renderAssaignConversationModal = () => {
    return (
      <Modal
        open={showAssignModal}
        closeModal={togleAssignModal}
        dialogTitle="Asignar conversación a asesor"
        actionButtonText="Asignar conversación"
        dialogChildren={
          <form
            id="update-form"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit(handleAssignConversation)}
          >
            <ShouldItRender
              locationPage={`/${location.pathname.split('/')[1]}`}
              action="selectAgency"
            >
              <FormControl
                variant="outlined"
                className={clsx(classes.textField)}
                fullWidth
              >
                <InputLabel id="label-agency">Agencia</InputLabel>
                <Controller
                  name="agencyID"
                  control={control}
                  defaultValue=""
                  as={
                    <Select
                      required
                      labelId="label-agency"
                      label="Agencia"
                      error={Boolean(errors.agencyID)}
                    >
                      <MenuItem disabled value="">
                        Selecciona una opción
                      </MenuItem>
                      {renderAgencyOptions()}
                    </Select>
                  }
                ></Controller>
              </FormControl>
            </ShouldItRender>
            <FormControl
              variant="outlined"
              className={clsx(classes.textField, classes.select)}
              fullWidth
            >
              <InputLabel id="label-adviser-type">
                Tipo de asesor
              </InputLabel>
              <Controller
                name="assessorType"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                as={
                  <Select
                    labelId="label-adviser-type"
                    label="Tipo de asesor"
                    error={Boolean(errors.assessorType)}
                  >
                    <MenuItem disabled value="">
                      Selecciona una opción
                    </MenuItem>
                    <MenuItem value={'ventas'}>Ventas</MenuItem>
                    <MenuItem value={'servicio'}>Servicio</MenuItem>
                    <MenuItem value={'recepcion'}>Recepción</MenuItem>
                    <MenuItem value={'refacciones'}>
                      Refacciones
                    </MenuItem>
                    <MenuItem value={'seminuevos'}>
                      Seminuevos
                    </MenuItem>
                  </Select>
                }
              />
            </FormControl>
            <FormControl
              variant="outlined"
              className={clsx(classes.textField, classes.select)}
              fullWidth
            >
              <InputLabel id="label-adviser-type">
                Seleccione el asesor
              </InputLabel>
              <Controller
                name="agencyUserID"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                as={
                  <Select
                    labelId="label-adviser-type"
                    label="Seleccione el asesor"
                    error={Boolean(errors.agencyUserID)}
                    disabled={!Boolean(assessorType)}
                  >
                    {renderAdvisorsOptions()}
                  </Select>
                }
              />
            </FormControl>
          </form>
        }
        form={'update-form'}
      />
    );
  };

  const renderUpdateClientInformationModal = () => {
    return (
      <Modal
        open={showEditClientModal}
        closeModal={togleClientModal}
        dialogTitle="Actaulizar información del cliente"
        actionButtonText="Actualizar información"
        dialogChildren={
          <form
            id="update-client-info-form"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit(handleUpdateClient)}
          >
            <TextField
              inputRef={register({
                required: true,
              })}
              name="name"
              required
              fullWidth
              type="text"
              label="Nombre del cliente"
              variant="outlined"
              className={classes.textField}
              error={Boolean(errors.name)}
            />
            <TextField
              inputRef={register({
                required: true,
                maxLength: 10,
              })}
              name="phoneNumber"
              required
              fullWidth
              type="text"
              label="Teléfono del cliente"
              variant="outlined"
              className={classes.textField}
              error={Boolean(errors.phoneNumber)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    +52
                  </InputAdornment>
                ),
              }}
            />
          </form>
        }
        form={'update-client-info-form'}
      />
    );
  };

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = clients;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message, redirect } = clients[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={() => handleCloseModals(redirect)}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={() => handleCloseModals(redirect)}
          />
        );
      }
      return null;
    } catch (e) {
      console.log('Modal error: ', e);
      handleCloseModals();
      return null;
    }
  };

  const conversationInfo = () => {
    return (
      <Paper className={classes.paperTarjeta}>
        <DialogTitle className={classes.title}>Lead</DialogTitle>
        <Table bordered className={classes.tables}>
          <tbody>
            <tr>
              <td className={classes.tableTitle}>NOMBRE</td>
              <td>{getNested('-', clientDetails, 'name')}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>TELÉFONO</td>
              <td>{getNested('-', clientDetails, 'phoneNumber')}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>INTERÉS</td>
              <td>
                {getNested(
                  '-',
                  clientDetails,
                  'conversations',
                  [clientDetails.conversations.length - 1],
                  'type',
                )}
              </td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>CORREO</td>
              <td>{getNested('-', clientDetails, 'email')}</td>
            </tr>
          </tbody>
        </Table>
      </Paper>
    );
  };

  return isEmpty(clientDetails) ? (
    <SplashScreen></SplashScreen>
  ) : (
    <div>
      {renderAttendClientDialog()}
      {renderProspectDialog()}
      {renderProspectLostDialog()}
      {renderFeedBackModal()}
      {renderAssaignConversationModal()}
      {renderUpdateClientInformationModal()}
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          {conversationInfo()}
        </Grid>
        <Grid item xs={12} md={8} className={classes.rightContainer}>
          <Card className={classes.cardRoot}>
            <CardContent className={classes.cardContent}>
              <Tabs
                value={tab}
                onChange={handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab
                  className={classes.tabsMinimalWidth}
                  label="Conversaciones"
                />
                <Tab
                  className={classes.tabsMinimalWidth}
                  label="Actividad"
                />
                <Tab
                  className={classes.tabsMinimalWidth}
                  label="Notas"
                />
              </Tabs>
              <TabPanel value={tab} index={0} padding={0}>
                {getNested([], clientDetails, 'conversations').map(
                  (conversation) => {
                    return (
                      <Card
                        key={`${conversation.id}`}
                        className={classes.conversationsCardRoot}
                      >
                        <CardContent
                          className={
                            classes.conversationsCardContainer
                          }
                        >
                          <div className={classes.cardCointainer}>
                            {getNested(
                              'n/a',
                              conversation,
                              'agencyUser',
                              'user',
                              'name',
                            )}
                            <CategoryBadge
                              categoryType={getNested(
                                'n/a',
                                conversation,
                                'agencyUser',
                                'type',
                              )}
                            />

                            <ConversationStatusBadge
                              status={conversation.status}
                            />
                            <MenuOption
                              Button={
                                <IconButton size="small">
                                  <MoreVertIcon />
                                </IconButton>
                              }
                              options={[
                                {
                                  name: (
                                    <>
                                      <AssignmentIndIcon />
                                      &nbsp;&nbsp;Asignar conversación
                                    </>
                                  ),
                                  onClick: () => {
                                    togleAssignModal();
                                    setSelectedConversation(
                                      conversation,
                                    );
                                  },
                                  shouldItRender: {
                                    should:
                                      conversation.status ===
                                        'SIN INTERACCION' ||
                                      conversation.status ===
                                        'Conversacion con Asesor iniciada' ||
                                      role === 'Super Admin',
                                  },
                                },
                                {
                                  name: (
                                    <>
                                      <CancelIcon />
                                      &nbsp;&nbsp;Prospecto perdido
                                    </>
                                  ),
                                  onClick: () => {
                                    toogleProspectLostModal();
                                    setSelectedConversation(
                                      conversation,
                                    );
                                  },
                                  shouldItRender: {
                                    should:
                                      conversation.status ===
                                      'Prospecto',
                                  },
                                },
                                {
                                  name: (
                                    <>
                                      <TransferWithinAStationIcon />
                                      &nbsp;&nbsp;Cliente prospecto
                                    </>
                                  ),
                                  onClick: () => {
                                    toogleProspectModal();
                                    setSelectedConversation(
                                      conversation,
                                    );
                                  },
                                  shouldItRender: {
                                    should:
                                      conversation.status ===
                                      'Conversacion con Asesor iniciada',
                                  },
                                },
                                {
                                  name: (
                                    <>
                                      <PersonAddIcon />
                                      &nbsp;&nbsp;Cliente atendido
                                    </>
                                  ),
                                  onClick: () => {
                                    toogleConfirmAttendModal();
                                    setSelectedConversation(
                                      conversation,
                                    );
                                  },
                                  shouldItRender: {
                                    should:
                                      conversation.status ===
                                      'Conversacion con Asesor iniciada',
                                  },
                                },
                              ]}
                            />
                          </div>
                        </CardContent>
                      </Card>
                    );
                  },
                )}
              </TabPanel>
              <TabPanel value={tab} index={1} padding={1}>
                <RecentActivities logs={clientLogs} noHeader />
              </TabPanel>
              <TabPanel value={tab} index={2} padding={1}>
                <div>
                  <form
                    id="update-client-info-form"
                    autoComplete="off"
                    noValidate
                    onSubmit={handleSubmit(handleUpdateClient)}
                  >
                    <TextField
                      multiline
                      rows={2}
                      inputRef={register()}
                      name="note"
                      required
                      fullWidth
                      type="text"
                      label="Notas del cliente"
                      variant="outlined"
                      className={classes.textField}
                      error={Boolean(errors.note)}
                    />
                    <div className={classes.submiNotetbutton}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Actualizar nota
                      </Button>
                    </div>
                  </form>
                </div>
              </TabPanel>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  clients: selectClients(state),
  clientDetails: makeSelectClientsDetails(state),
  clientLogs: makeSelectClientsLogs(state),
  advisors: makeSelectUsersFromState(state),
  isLoadingUsers: makeSelectUsersIsLoading(state),
  role: makeSelectUserRoleFromState(state),
  agenciesFromdb: makeSelectAgenciesFromdb(state),
});

const mapDispatchToProps = {
  getClient,
  updateClient,
  getUsers,
  assignConversationFromClient,
  attendConversationFromClient,
  updateConversationFromClient,

  closeModals,
};

export default withRouter(
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(ClientDetail),
  ),
);
