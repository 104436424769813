import { createAction, createSlice } from '@reduxjs/toolkit';

import { initialState } from './initialState';

const cleanStore = createAction('auth/logout');
const changeAgency = createAction('auth/changeAgency');

const conversationsSlice = createSlice({
  name: 'conversations',
  initialState,
  reducers: {
    getAllConversations: {
      reducer(state) {
        state.isLoading = true;
        state.conversationDetails = null;
      },
    },
    getAllConversationsSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.fromdb = action.payload.conversations.rows;
        state.tablePages = action.payload.conversations.pages;
      },
    },
    getAllConversationsFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.error = action.payload.error;
      },
    },
    getAllConversationsForDownloadSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.toDownload = action.payload.conversations;
      },
    },
    getAllConversationsForDownloadFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.error = action.payload.error;
      },
    },
    getConversation: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getConversationSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.conversationDetails = action.payload.conversation;
      },
    },
    getConversationFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.error = action.payload.error;
      },
    },
    getUserDetail: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    getUserDetailSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.userDetails = action.payload.details;
      },
    },
    getUserDetailFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.error = action.payload.error;
      },
    },
    attendConversation: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    attendConversationSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
        state.conversationDetails = {
          ...state.conversationDetails,
          ...action.payload.conversation,
        };
      },
    },
    attendConversationFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    updateConversation: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    updateConversationSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
        state.conversationDetails = {
          ...state.conversationDetails,
          ...action.payload.conversation,
        };
      },
    },
    updateConversationFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    updateConversationClient: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    assignConversation: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    updateMultipleConversations: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    updateMultipleConversationsSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    updateMultipleConversationsFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    assignConversationSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    assignConversationFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    addMessageReceived: {
      reducer(state, action) {
        state.conversationDetails.messages = [
          ...state.conversationDetails.messages,
          action.payload.message,
        ];
      },
    },
    uploadFilesAndSendThem: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    uploadFilesAndSendThemSuccess: {
      reducer(state, action) {
        state.isLoading = false;
      },
    },
    uploadFilesAndSendThemFail: {
      reducer(state, action) {
        state.isLoading = false;
      },
    },
    closeModals: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = initialState.successModal;
        state.errorModal = initialState.errorModal;
        state.deletedModal = false;
      },
    },
    resetStore: {
      reducer(state, action) {
        return initialState;
      },
    },
  },
  extraReducers: {
    [cleanStore]: (state, action) => {
      return initialState;
    },
    [changeAgency]: (state, action) => {
      return initialState;
    },
  },
});

export const {
  getAllConversations,
  getAllConversationsSuccess,
  getAllConversationsFail,
  getAllConversationsForDownload,
  getAllConversationsForDownloadSuccess,
  getAllConversationsForDownloadFail,
  getConversation,
  getConversationSuccess,
  getConversationFail,
  attendConversation,
  attendConversationSuccess,
  attendConversationFail,
  updateConversation,
  updateConversationSuccess,
  updateConversationFail,
  updateConversationClient,
  updateMultipleConversations,
  updateMultipleConversationsSuccess,
  updateMultipleConversationsFail,
  assignConversation,
  assignConversationSuccess,
  assignConversationFail,
  addMessageReceived,
  uploadFilesAndSendThem,
  uploadFilesAndSendThemSuccess,
  uploadFilesAndSendThemFail,
  closeModals,
  resetStore,
} = conversationsSlice.actions;

export default conversationsSlice.reducer;
