import { saveToken } from '../../app/features/auth/authSlice';

export function removeCSSClass(ele, cls) {
  const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
  ele.className = ele.className.replace(reg, ' ');
}

export function addCSSClass(ele, cls) {
  ele.classList.add(cls);
}

export const toAbsoluteUrl = (pathname) =>
  process.env.PUBLIC_URL + pathname;

export function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken },
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = authToken;
      }
      return config;
    },
    (err) => Promise.reject(err),
  );

  axios.interceptors.response.use(
    function (response) {
      const {
        auth: { authToken },
      } = store.getState();
      if (
        response.headers.authorization &&
        authToken !== response.headers.authorization
      )
        store.dispatch(
          saveToken({ token: response.headers.authorization }),
        );
      return response;
    },
    function (error) {
      console.log(error);
      return Promise.reject(error);
    },
  );
}

export function getDMSName(dms) {
  const formated = {
    magik: 'Magik',
    businesspro: 'Bussiness PRO',
    clearmechanics: 'Clear Mechanics',
    dynamics: 'Dynamics',
    'solomon(camcar)': 'Solomon (Camcar)',
    'solomon(camcar) leon': 'Solomon (Camcar) León',
    GDS: 'GDS',
  };
  return formated[dms];
}

export function getLoginErrors(code) {
  const error = {
    404: 'El correo electrónico con que está proporcionando no existe en nuestra base de datos. por favor inténtelo con otro correo.',
    460: 'Por favor, verifique que el correo y la contraseña que ha ingresado sean correctos e inténtalo de nuevo.',
    500: 'Ocurrió un error inesperado, por favor inténtelo de nuevo más tarde.',
  };
  return error[code];
}
