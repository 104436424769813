import { createAction, createSlice } from '@reduxjs/toolkit';

import { initialState } from './initialState';

const cleanStore = createAction('auth/logout');
const changeAgency = createAction('auth/changeAgency');

const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    getAllEvents: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getAllEventsSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.fromdb = action.payload.events.rows;
        state.tablePages = action.payload.events.pages;
      },
    },
    getAllEventsFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.error = action.payload.error;
      },
    },
    getEvent: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getEventSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.eventDetails = action.payload.event;
      },
    },
    getEventFail: {
      reducer(state, action) {
        state.isLoading = false;
      },
    },
    createEvent: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    createEventSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    createEventFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    updateEvent: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    updateEventSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    updateEventFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    createEventShipment: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    createEventShipmentSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    createEventShipmentFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    closeModals: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = initialState.successModal;
        state.errorModal = initialState.errorModal;
        state.deletedModal = false;
      },
    },
  },
  extraReducers: {
    [cleanStore]: (state, action) => {
      return initialState;
    },
    [changeAgency]: (state, action) => {
      return initialState;
    },
  },
});

export const {
  getAllEvents,
  getAllEventsSuccess,
  getAllEventsFail,
  getEvent,
  getEventSuccess,
  getEventFail,
  createEvent,
  createEventSuccess,
  createEventFail,
  createEventShipment,
  createEventShipmentSuccess,
  createEventShipmentFail,
  updateEvent,
  updateEventSuccess,
  updateEventFail,
  closeModals,
} = eventsSlice.actions;

export default eventsSlice.reducer;
