import * as yup from 'yup';

// const stringNotRequired = yup.string().nullable();
const stringValid = yup.string().required('Este campo es requerido');
// const numberValid = yup
//   .number()
//   .required("Este campo es requerido")
//   .positive("El valor no puede ser negativo");

export const agencySchema = yup.object().shape({
  name: stringValid,
  phoneNumber: stringValid,
  email: stringValid,
  phoneID: stringValid,
});
