import { call, put } from 'redux-saga/effects';
import {
  createCategoryFail,
  createCategorySuccess,
  deleteCategoryFail,
  deleteCategorySuccess,
  deleteTimeTableFail,
  deleteTimeTableSuccess,
  patchServiceHoursFail,
  patchServiceHoursSuccess,
  patchTimeTableFail,
  patchTimeTableSuccess,
  updateCategoryFail,
  updateCategorySuccess,
} from 'app/features/setup/setupSlice';

import { getAgencyDetail } from 'app/features/agencies/actions';
import { getNested } from 'app/utils';
import { requestHandler } from '../../services/requestHandler';

export function* createCategorySaga(action) {
  try {
    const data = {
      ...action.payload,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/customFlows/create',
      data,
    });
    const message = {
      title: 'Categoría creada',
      desc: 'La categoría ha sido creada correctamente!',
    };
    yield put(createCategorySuccess({ message }));
    yield put(getAgencyDetail());
  } catch (e) {
    yield put(createCategoryFail({}));
    console.log('error in patch timetable saga: ', e);
  }
}

export function* updateCategorySaga(action) {
  try {
    const data = {
      ...action.payload,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/customFlows/update',
      data,
    });
    const message = {
      title: 'Categoría actualizada',
      desc: 'La categoría ha sido actualizada correctamente!',
    };
    yield put(updateCategorySuccess({ message }));
    yield put(getAgencyDetail());
  } catch (e) {
    yield put(updateCategoryFail({}));
    console.log('error in patch timetable saga: ', e);
  }
}

export function* deleteCategorySaga(action) {
  try {
    const data = {
      ...action.payload,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/customFlows/remove',
      data,
    });
    const message = {
      title: 'Categoría eliminada',
      desc: 'La categoría ha sido eliminada correctamente!',
    };
    yield put(deleteCategorySuccess({ message }));
    yield put(getAgencyDetail());
  } catch (e) {
    yield put(deleteCategoryFail({}));
    console.log('error in patch timetable saga: ', e);
  }
}

export function* patchTimeTablesSaga(action) {
  try {
    let message;
    const data = {
      ...action.payload,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/timeTablePreset/patch',
      data,
    });
    if (getNested(null, data, 'name'))
      message = {
        title: 'Horario creado',
        desc: 'El horario se ha creado correctamente!',
      };
    else
      message = {
        title: 'Horario eliminado',
        desc: 'El horario ha sido eliminado correctamente!',
      };
    yield put(patchTimeTableSuccess({ message }));
    yield put(getAgencyDetail());
  } catch (e) {
    yield put(patchTimeTableFail({}));
    console.log('error in patch timetable saga: ', e);
  }
}

export function* deleteTimeTablesSaga(action) {
  try {
    const data = {
      ...action.payload,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/timeTablePreset/remove',
      data,
    });
    const message = {
      title: 'Horario eliminado',
      desc: '¡El horario ha sido eliminado correctamente!',
    };
    yield put(deleteTimeTableSuccess({ message }));
    yield put(getAgencyDetail());
  } catch (e) {
    yield put(deleteTimeTableFail({}));
    console.log('error in delete timetable saga: ', e);
  }
}

export function* patchServiceHoursSaga(action) {
  try {
    const data = {
      ...action.payload,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/agency/update',
      data,
    });
    const message = {
      title: 'Horario de servicio actualizado',
      desc:
        '¡El horario de servicio se ha sido actualizado correctamente!',
    };
    yield put(patchServiceHoursSuccess({ message }));
    yield put(getAgencyDetail());
  } catch (e) {
    const message = {
      title: 'Error',
      desc:
        'Ha ocurrido un error al tratar de actualizar los horarios de servicio, si el problema persiste intente más tarde',
    };
    yield put(patchServiceHoursFail({ message }));
    console.log('error in patch timetable saga: ', e);
  }
}

export function* closeModalsAndRedirectSetupSaga(action) {
  try {
    const { history, redirect } = action.payload;
    if (redirect) yield call(history.push, redirect);
  } catch (e) {
    console.log('error: ', e);
  }
}
