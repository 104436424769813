import { createAction, createSlice } from '@reduxjs/toolkit';

import { closeModals } from '../modals/modalsSlice';
import { initialState } from './initialState';

const cleanStore = createAction('auth/logout');

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    getData: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getDataSuccess: {
      reducer(state) {
        state.isLoading = false;
      },
    },
    getDataFail: {
      reducer(state) {
        state.isLoading = false;
      },
    },
    getCampaignsForDashboardSuccess: {
      reducer(state, action) {
        state.campaigns = {
          ...state.campaigns,
          ...action.payload,
        };
      },
    },
    getCampaignsForDashboardFail: {
      reducer(state, action) {
        state.error = action.payload.error;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    getConversationsForDashboardSuccess: {
      reducer(state, action) {
        state.conversations = {
          ...state.conversations,
          ...action.payload,
        };
      },
    },
    getConversationsForDashboardFail: {
      reducer(state, action) {
        state.error = action.payload.error;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    getAdvisorsForDashboardSuccess: {
      reducer(state, action) {
        state.conversations = {
          ...state.conversations,
          ...action.payload,
        };
      },
    },
    getAdvisorsForDashboardFail: {
      reducer(state, action) {
        state.error = action.payload.error;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    getEventsForDashboardSuccess: {
      reducer(state, action) {
        state.events = action.payload.events;
      },
    },
    getEventsForDashboardFail: {
      reducer(state, action) {
        state.error = action.payload.error;
      },
    },
    cleanData: {
      reducer(state) {
        return initialState;
      },
    },
  },
  extraReducers: {
    [cleanStore]: (state, action) => {
      return initialState;
    },
    [closeModals]: (state, action) => {
      state.isLoading = false;
      state.successModal = initialState.successModal;
      state.errorModal = initialState.errorModal;
    },
  },
});

export const {
  getData,
  getDataSuccess,
  getDataFail,
  getConversationsForDashboardSuccess,
  getConversationsForDashboardFail,
  getAdvisorsForDashboardSuccess,
  getAdvisorsForDashboardFail,
  getEventsForDashboardSuccess,
  getEventsForDashboardFail,
  cleanData,
  getCampaignsForDashboardSuccess,
  getCampaignsForDashboardFail,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
