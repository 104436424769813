import { IntlProvider, addLocaleData } from 'react-intl';

import React from 'react';
import enMessages from './messages/en.json';
import esMessages from './messages/es.json';
import locale_en from 'react-intl/locale-data/en';
import locale_es from 'react-intl/locale-data/es';
import { useSelector } from 'react-redux';

const allMessages = {
  en: enMessages,
  es: esMessages,
};

addLocaleData([...locale_en, ...locale_es]);

export default function I18nProvider({ children }) {
  const locale = useSelector(({ i18n }) => i18n.lang);
  const messages = allMessages[locale];

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}
