import { createAction, createSlice } from '@reduxjs/toolkit';

import { initialState } from './initialState';

const cleanStore = createAction('auth/logout');
const changeAgency = createAction('auth/changeAgency');

const setupSlice = createSlice({
  name: 'setup',
  initialState,
  reducers: {
    createCategory: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    createCategorySuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    createCategoryFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    updateCategory: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    updateCategorySuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    updateCategoryFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    deleteCategory: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    deleteCategorySuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    deleteCategoryFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    patchTimeTable: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    patchTimeTableSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    patchTimeTableFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    deleteTimeTable: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    deleteTimeTableSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    deleteTimeTableFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    patchServiceHours: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    patchServiceHoursSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    patchServiceHoursFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    closeSetupModals: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = initialState.successModal;
        state.errorModal = initialState.errorModal;
        state.deletedModal = false;
      },
    },
  },
  extraReducers: {
    [cleanStore]: (state, action) => {
      return initialState;
    },
    [changeAgency]: (state, action) => {
      return initialState;
    },
  },
});

export const {
  createCategory,
  createCategorySuccess,
  createCategoryFail,
  updateCategory,
  updateCategorySuccess,
  updateCategoryFail,
  deleteCategory,
  deleteCategorySuccess,
  deleteCategoryFail,
  patchTimeTable,
  patchTimeTableSuccess,
  patchTimeTableFail,
  deleteTimeTable,
  deleteTimeTableSuccess,
  deleteTimeTableFail,
  patchServiceHours,
  patchServiceHoursSuccess,
  patchServiceHoursFail,
  closeSetupModals,
} = setupSlice.actions;

export default setupSlice.reducer;
