import 'rc-time-picker/assets/index.css';

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { advisorTypes, emailRegex } from '../../../../utils';

import { CheckBoxRHFMUI } from 'app/components/CheckBoxRHFMUI';
import { Controller } from 'react-hook-form';
import { DayCards } from 'app/features/users/components/DayCards';
import { GreenRedSwitch } from 'app/components/GreenRedSwitch';
import React from 'react';
import ShouldItRender from '../../../../components/ShouldItRender';
import clsx from 'clsx';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  textField: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  subtitle: {
    marginBottom: theme.spacing(4.5),
  },
  checkBoxSubtitle: {
    marginBottom: theme.spacing(3),
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  submitbutton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
  },
  hourInputsContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  margiTop: {
    marginTop: theme.spacing(2),
  },
}));

const CreateOrEditAdvisorForm = (props) => {
  const classes = useStyles();
  const {
    control,
    handleSubmit,
    errors,
    register,
    setValue,
    renderAgencyOptions,
    role,
    handleUpdateOrCreate,
    activated,
    days,
    setDays,
    activeDays,
    setActiveDays,
    renderUpdateOrCreateText,
    categories,
  } = props;

  return (
    <>
      <Grid
        xs={12}
        item
        className={clsx(classes.subtitle, classes.margiTop)}
      >
        <Typography variant="h6">Información del asesor</Typography>
      </Grid>
      <form
        className={classes.container}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(handleUpdateOrCreate)}
      >
        <ShouldItRender
          locationPage={'/edit-advisor'}
          action="selectRole"
        >
          <Grid container spacing={2}>
            <Grid item xs>
              <FormControl
                variant="outlined"
                className={clsx(classes.textField)}
                fullWidth
              >
                <InputLabel id="label-role">Rol</InputLabel>
                <Controller
                  name="role"
                  control={control}
                  as={
                    <Select
                      required
                      labelId="label-role"
                      label="Rol"
                      error={Boolean(errors.role)}
                    >
                      <MenuItem disabled value="">
                        Selecciona una opción
                      </MenuItem>
                      <MenuItem value={'Agency Admin'}>
                        Administrador de agencia
                      </MenuItem>
                      <MenuItem value={'Advisor'}>Asesor</MenuItem>
                    </Select>
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </ShouldItRender>
        <ShouldItRender
          locationPage={'/edit-advisor'}
          action="selectAgency"
        >
          <Grid container spacing={2}>
            <Grid item xs>
              <FormControl
                variant="outlined"
                className={clsx(classes.textField)}
                fullWidth
              >
                <InputLabel id="label-agency">Agencia</InputLabel>
                <Controller
                  name="agencyID"
                  control={control}
                  defaultValue=""
                  as={
                    <Select
                      required
                      labelId="label-agency"
                      label="Agencia"
                      error={Boolean(errors.agencyID)}
                    >
                      <MenuItem disabled value="">
                        Selecciona una opción
                      </MenuItem>
                      {renderAgencyOptions()}
                    </Select>
                  }
                ></Controller>
              </FormControl>
            </Grid>
          </Grid>
        </ShouldItRender>
        <Grid container spacing={2}>
          <Grid item xs>
            <Controller
              name="name"
              control={control}
              as={
                <TextField
                  required
                  fullWidth
                  type="text"
                  label="Nombre completo"
                  variant="outlined"
                  className={classes.textField}
                  error={Boolean(errors.assessorName)}
                />
              }
            />
          </Grid>
          {role === 'Advisor' && (
            <Grid item xs>
              <FormGroup row className={classes.justifyContentCenter}>
                <FormControlLabel
                  name="activated"
                  inputRef={register}
                  control={
                    <GreenRedSwitch
                      checked={activated}
                      onChange={({ target }) =>
                        setValue('activated', target.checked)
                      }
                    />
                  }
                  label="Estatus"
                  labelPlacement="start"
                />
              </FormGroup>
            </Grid>
          )}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs>
            <Controller
              name="email"
              control={control}
              rules={{
                required: true,
                pattern: emailRegex(),
              }}
              as={
                <TextField
                  required
                  fullWidth
                  type="email"
                  label="Email"
                  variant="outlined"
                  className={classes.textField}
                  error={Boolean(errors.email)}
                />
              }
            />
          </Grid>
          {role === 'Advisor' && (
            <Grid item xs>
              <Controller
                name="phoneNumber"
                control={control}
                rules={{
                  required: true,
                  maxLength: 10,
                }}
                as={
                  <TextField
                    required
                    name="phoneNumber"
                    fullWidth
                    type="text"
                    label="Teléfono"
                    variant="outlined"
                    className={classes.textField}
                    error={Boolean(errors.phoneNumber)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          +52
                        </InputAdornment>
                      ),
                    }}
                  />
                }
              />
            </Grid>
          )}
          {role !== 'Advisor' && (
            <Grid item xs>
              <FormControl
                variant="outlined"
                fullWidth
                className={classes.formControl}
              >
                <InputLabel id="agency-label">Categorías</InputLabel>
                <Controller
                  control={control}
                  name="categories"
                  defaultValue={[]}
                  as={
                    <Select
                      id="demo-mutiple-name"
                      multiple
                      value={categories}
                      onChange={({ target: { value } }) =>
                        setValue('categories', value)
                      }
                      renderValue={(selected) => selected.join(', ')}
                      input={
                        <OutlinedInput
                          labelId="agency-label"
                          label="Categorías"
                        />
                      }
                      MenuProps={MenuProps}
                    >
                      {Object.keys(advisorTypes).map((key) => (
                        <MenuItem key={key} value={key}>
                          <Checkbox
                            checked={categories.indexOf(key) > -1}
                          />
                          {advisorTypes[key]}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                />
              </FormControl>
            </Grid>
          )}
        </Grid>
        {role === 'Advisor' && (
          <>
            <Grid container spacing={2}>
              <Grid item xs>
                <Controller
                  name="priority"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  as={
                    <TextField
                      required
                      fullWidth
                      type="text"
                      label="Prioridad"
                      variant="outlined"
                      className={classes.textField}
                      error={Boolean(errors.priority)}
                    />
                  }
                />
              </Grid>
              <Grid item xs>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  fullWidth
                >
                  <InputLabel id="label-adviser-type">
                    Tipo de asesor
                  </InputLabel>
                  <Controller
                    name="assessorType"
                    control={control}
                    rules={{ required: true }}
                    defaultValue=""
                    as={
                      <Select
                        labelId="label-adviser-type"
                        label="Tipo de asesor"
                        error={Boolean(errors.assessorType)}
                      >
                        <MenuItem disabled value="">
                          Selecciona una opción
                        </MenuItem>
                        {Object.keys(advisorTypes).map((key) => (
                          <MenuItem key={key} value={key}>
                            {advisorTypes[key]}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                  ></Controller>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              xs={12}
              item
              className={clsx(
                classes.checkBoxSubtitle,
                classes.margiTop,
              )}
            >
              <Typography variant="h6">
                Configuraciones del asesor
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <CheckBoxRHFMUI
                name="whatsConversations"
                label="Asesor de WhatsApp"
                control={control}
              />
            </Grid>
            <Grid item xs={4}>
              <CheckBoxRHFMUI
                name="fbConversations"
                label="Asesor de Facebook"
                control={control}
              />
            </Grid>
            <Grid
              xs={12}
              item
              className={clsx(classes.subtitle, classes.margiTop)}
            >
              <Typography variant="h6">
                Horarios del asesor
              </Typography>
            </Grid>
            <Grid container spacing={2}>
              <DayCards
                days={days}
                setDays={setDays}
                activeDays={activeDays}
                setActiveDays={setActiveDays}
              />
            </Grid>
          </>
        )}
        <div className={classes.submitbutton}>
          <Button type="submit" variant="contained" color="primary">
            {renderUpdateOrCreateText()}
          </Button>
        </div>
      </form>
    </>
  );
};

export default CreateOrEditAdvisorForm;
