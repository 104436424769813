import { closeModals } from '../modals/modalsSlice';
import { createSlice } from '@reduxjs/toolkit';
import { getNested } from '../../utils';
import { initialAuthState } from './initialState';

const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    login: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    loginSuccess: {
      reducer(state, action) {
        const agencyUsers = getNested(
          null,
          action,
          'payload',
          'agencyUsers',
        );
        state.isLoading = false;
        state.user = action.payload.user;
        state.agencyUsers = agencyUsers;
      },
    },
    loginFailure: {
      reducer(state, action) {
        state.isLoading = false;
        state.error = action.payload.error;
      },
    },
    loginAgencyUser: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    getAgencyUsers: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    getAgencyUsersSuccess: {
      reducer(state, action) {
        const agencyUsers = getNested(
          null,
          action,
          'payload',
          'agencyUsers',
        );
        state.isLoading = false;
        state.agencyUsers = agencyUsers;
      },
    },
    getAgencyUsersFailure: {
      reducer(state, action) {
        state.isLoading = false;
        state.error = action.payload.error;
      },
    },
    requestPasswordReset: {
      reducer(state, action) {},
    },
    requestPasswordResetSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: getNested('', action, 'payload', 'message'),
        };
      },
    },
    requestPasswordResetFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: getNested('', action, 'payload', 'message'),
        };
      },
    },
    passwordReset: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    passwordResetSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: getNested('', action, 'payload', 'message'),
        };
      },
    },
    passwordResetFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: getNested('', action, 'payload', 'message'),
        };
      },
    },
    confirmEmail: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    confirmEmailSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: getNested('', action, 'payload', 'message'),
        };
      },
    },
    confirmEmailFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: getNested('', action, 'payload', 'message'),
        };
      },
    },
    saveToken: {
      reducer(state, action) {
        state.authToken = action.payload.token;
      },
    },
    logout: {
      reducer(state, action) {
        return initialAuthState;
      },
    },
    changeAgency: {
      reducer(state, action) {},
    },
  },
  extraReducers: {
    [closeModals]: (state, action) => {
      state.isLoading = false;
      state.successModal = initialAuthState.successModal;
      state.errorModal = initialAuthState.errorModal;
    },
  },
});

export const {
  login,
  loginAgencyUser,
  loginSuccess,
  loginFailure,
  getAgencyUsers,
  getAgencyUsersSuccess,
  getAgencyUsersFailure,
  requestPasswordReset,
  requestPasswordResetSuccess,
  requestPasswordResetFail,
  passwordReset,
  passwordResetSuccess,
  passwordResetFail,
  confirmEmail,
  confirmEmailSuccess,
  confirmEmailFail,
  saveToken,
  logout,
  changeAgency,
} = authSlice.actions;

export default authSlice.reducer;
