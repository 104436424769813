export const initialState = {
  conversations: {
    fromdb: null,
    pending: null,
    noInteraction: null,
    assignedAdvisor: null,
    ventasResponseTime: 0,
    ventasCount: 0,
    servicioResponseTime: 0,
    servicioCount: 0,
    recepcionResponseTime: 0,
    recepcionCount: 0,
    refaccionesResponseTime: 0,
    refaccionesCount: 0,
    seminuevosResponseTime: 0,
    seminuevosCount: 0,
    financiamientoResponseTime: 0,
    financiamientoCount: 0,
    advisorsFilterd: null,
    converStartedWidthAdvisorType: {
      ventas: 0,
      servicio: 0,
      recepcion: 0,
      refacciones: 0,
      seminuevos: 0,
    },
    conversationsSources: {
      Micrositio: [],
      'Redes Sociales': [],
    },
  },
  campaigns: {
    fromdb: null,
  },
  events: null,
  successModal: {
    show: false,
    message: null,
  },
  errorModal: {
    show: false,
    message: null,
  },
  isLoading: false,
  error: null,
};
