import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { advisorTypes, traduceMinutesTime } from 'app/utils';

import { Badge } from 'react-bootstrap';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { IconButton } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  content: {
    display: 'flex',
    paddingBottom: '1rem',
    flexGrow: 1,
    flexDirection: 'column',
    minHeight: '95%',
  },
  desc: {
    fontWeight: 600,
    fontSize: '1.2rem',
    alignSelf: 'center',
    color: 'black',
    // color: kt-base-color(label, 2);
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  responseTimers: {
    margin: theme.spacing(5, 0, 1, 0),
    display: 'flex',
    justifyContent: 'space-around',
  },
  responseTimeByCategories: {
    fontWeight: 600,
    fontSize: '1.7rem',
    color: 'black',
  },
  responseTimeByClockIcon: {
    fontSize: '1.5rem',
    marginRight: theme.spacing(1.5),
  },
  responseTimeContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  responseTime: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  categoryPillcontainer: {
    alignSelf: 'center',
    marginTop: theme.spacing(1.5),
  },
  categoryPill: {
    fontSize: '.956em',
    fontWeight: 600,
    borderRadius: '7px',
  },
  ventasPill: {
    backgroundColor: '#effee1',
    color: '#249c66',
  },
  servicioPill: {
    backgroundColor: '#f6d8bc',
    color: '#814d49',
  },
  refaccionesPill: {
    backgroundColor: '#e9e7ff',
    color: '#345bc8',
  },
  seminuevosPill: {
    backgroundColor: '#e2f6f7',
    color: '#2b919c',
  },
  recepcionPill: {
    backgroundColor: '#effee1',
    color: '#2b919c',
  },
  datePicker: {
    width: '110px',
  },
  datePickerRight: {
    marginLeft: theme.spacing(1),
  },
  datePickerLeft: {
    marginRight: theme.spacing(1),
  },
  calendarIcon: {
    marginRight: '5px',
  },
  noData: {
    fontSize: '1.7em',
    fontWeight: 600,
    color: 'black',
  },
  totalConversations: {
    marginTop: theme.spacing(0.5),
  },
  datePickersContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default function QuickCategoryStatus({
  desc,
  conversations,
  categoryStartDate,
  categoryEndDate,
  handleCategoryStartDate,
  handleCategoryEndDate,
  agencyCategories,
}) {
  const classes = useStyles();

  const renderCategoryDetails = () => {
    if (agencyCategories.length > 0)
      return agencyCategories
        .filter(function (item, pos) {
          return agencyCategories.indexOf(item) === pos;
        })
        .map((key) => {
          let responseTime;
          if (conversations[`${key}ResponseTime`])
            responseTime = traduceMinutesTime(
              Math.round(
                conversations[`${key}ResponseTime`] /
                  conversations[`${key}Count`],
              ),
            );
          return (
            <div
              key={`response-time-${key}`}
              className={classes.responseTimeContainer}
            >
              <div className={classes.responseTime}>
                <i
                  className={clsx(
                    'far fa-clock',
                    classes.responseTimeByClockIcon,
                  )}
                ></i>
                <span className={classes.responseTimeByCategories}>
                  {conversations[`${key}ResponseTime`]
                    ? responseTime
                    : '-'}
                </span>
              </div>
              <div className={classes.totalConversations}>
                <span>
                  Conversaciones totales:&nbsp;
                  {conversations[`${key}Count`]}
                </span>
              </div>
              <div className={classes.categoryPillcontainer}>
                <Badge
                  className={clsx(
                    classes.categoryPill,
                    classes[`${key}Pill`],
                  )}
                >
                  {advisorTypes[key]}
                </Badge>
              </div>
            </div>
          );
        });
    else
      return (
        <div>
          <span className={classes.noData}>Sin Datos</span>
        </div>
      );
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.header}>
          <span className={classes.desc}>{desc}</span>
          <div className={classes.datePickersContainer}>
            <MuiPickersUtilsProvider
              utils={MomentUtils}
              locale={'es'}
            >
              <DatePicker
                autoOk
                disableToolbar
                disableFuture
                size="small"
                inputVariant="outlined"
                format="DD MMM"
                className={clsx(
                  classes.datePicker,
                  classes.datePickerLeft,
                )}
                value={categoryStartDate}
                onChange={handleCategoryStartDate}
                InputProps={{
                  startAdornment: (
                    <IconButton
                      aria-label="Select day"
                      size="small"
                      className={classes.calendarIcon}
                    >
                      <CalendarTodayIcon />
                    </IconButton>
                  ),
                }}
              />
              -
              <DatePicker
                autoOk
                disableToolbar
                disableFuture
                size="small"
                inputVariant="outlined"
                format="DD MMM"
                className={clsx(
                  classes.datePicker,
                  classes.datePickerRight,
                )}
                value={categoryEndDate}
                onChange={handleCategoryEndDate}
                InputProps={{
                  startAdornment: (
                    <IconButton
                      aria-label="Select day"
                      size="small"
                      className={classes.calendarIcon}
                    >
                      <CalendarTodayIcon />
                    </IconButton>
                  ),
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>

        <div className={classes.responseTimers}>
          {renderCategoryDetails()}
        </div>
      </div>
    </div>
  );
}
