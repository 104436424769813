import { createSelector } from 'reselect';
import { initialState } from './initialState';

export const selectLogs = (state) => state.logs || initialState;

export const makeSelectLogsIsLoading = createSelector(
  selectLogs,
  (logsState) => logsState.isLoading,
);

export const makeSelectConversationsLogs = createSelector(
  selectLogs,
  (logsState) => logsState.conversations,
);

export const makeSelectClientsLogs = createSelector(
  selectLogs,
  (logsState) => logsState.clients,
);

export const makeSelectEventsLogs = createSelector(
  selectLogs,
  (logsState) => logsState.clients,
);
