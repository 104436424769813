const api = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'development':
      return 'http://localhost:8000/api';
    case 'staging':
      return 'https://karbot-staging.karlo.io/api';
    case 'production':
      return 'https://karbot.karlo.io/api';
    default:
      return 'https://karbot.karlo.io/api';
    // return 'http://localhost:8000/api';
    // return 'https://karbot-staging.karlo.io/api';
  }
};

export default api;
