import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { Chart } from 'chart.js';
import { IconButton } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { metronic } from '../../_metronic';
import moment from 'moment';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  content: {
    display: 'flex',
    paddingBottom: '1rem',
    flexGrow: 1,
    flexDirection: 'column',
    minHeight: '95%',
  },
  desc: {
    fontWeight: 600,
    fontSize: '1.2rem',
    alignSelf: 'center',
    color: 'black',
    // color: kt-base-color(label, 2);
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  datePicker: {
    width: '110px',
  },
  datePickerRight: {
    marginLeft: theme.spacing(1),
  },
  datePickerLeft: {
    marginRight: theme.spacing(1),
  },
  calendarIcon: {
    marginRight: '5px',
  },
  noData: {
    fontSize: '1.7em',
    fontWeight: 600,
    color: 'black',
  },
  totalConversations: {
    marginTop: theme.spacing(0.5),
  },
  datePickersContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default function QuickConversationsSources({
  desc,
  conversations = [],
  conversationsSourceStartDate,
  consversationsSourceEndDate,
  handleConversationSourceStartDate,
  handleConversationSourceEndDate,
}) {
  const classes = useStyles();
  const canvasRef = React.useRef();
  const [days, setDays] = React.useState([]);
  const [labelDays, setLabelDays] = React.useState([]);
  const [socialNetworks, setsocialNetworks] = React.useState([]);
  const [microsite, setMicrosite] = React.useState([]);
  const [max, setMax] = React.useState(10);
  const [perDayRedes, setPerDayRedes] = React.useState(0);
  const [perDayMicrositio, setPerDayMicrositio] = React.useState(0);
  const [totalConversations, setTotalConversations] = React.useState(
    [],
  );

  React.useEffect(() => {
    if (conversationsSourceStartDate && consversationsSourceEndDate) {
      const { conversationsSources } = conversations;
      const tempDays = [];
      const tempLabelDays = [];
      const tempMicrosite = [];
      const tempNetworks = [];
      let conversationsMicrositio;
      let conversationsRedes;
      let tempMax = 0;
      let i = 0;
      let tempStartDate = moment(conversationsSourceStartDate);

      while (
        !tempStartDate.isAfter(consversationsSourceEndDate, 'day')
      ) {
        tempLabelDays.push(tempStartDate.format('DD MMMM'));
        tempDays.push(moment(tempStartDate));
        tempMicrosite[i] = 0;
        tempNetworks[i] = 0;
        tempStartDate = tempStartDate.add(1, 'days');
        i++;
      }
      setDays(tempDays);
      setLabelDays(tempLabelDays);
      if (
        conversationsSources &&
        Array.isArray(conversationsSources['Micrositio']) &&
        conversationsSources['Micrositio'].length > 0
      ) {
        conversationsMicrositio =
          conversationsSources['Micrositio'].length;

        tempDays.forEach((day, idx) => {
          conversationsSources['Micrositio'].forEach(
            (conversationStartDate) => {
              if (moment(conversationStartDate).isSame(day, 'day')) {
                tempMicrosite[idx]++;
                if (tempMax < tempMicrosite[idx])
                  tempMax = tempMicrosite[idx];
              }
            },
          );
        });
      }
      if (
        conversationsSources &&
        Array.isArray(conversationsSources['Redes Sociales']) &&
        conversationsSources['Redes Sociales'].length > 0
      ) {
        conversationsRedes =
          conversationsSources['Redes Sociales'].length;

        tempDays.forEach((day, idx) => {
          conversationsSources['Redes Sociales'].forEach(
            (conversationStartDate) => {
              if (moment(conversationStartDate).isSame(day, 'day')) {
                tempNetworks[idx]++;
                if (tempMax < tempNetworks[idx])
                  tempMax = tempNetworks[idx];
              }
            },
          );
        });
      }
      setTotalConversations(
        conversationsRedes + conversationsMicrositio,
      );
      setPerDayRedes(conversationsSources['Redes Sociales'].length);
      setPerDayMicrositio(conversationsSources['Micrositio'].length);
      setsocialNetworks(tempNetworks);
      setMicrosite(tempMicrosite);
      setMax(tempMax);
    }
    return () => {
      setDays([]);
      setLabelDays([]);
      setsocialNetworks([]);
      setMicrosite([]);
      setMax(0);
    };
  }, [
    conversationsSourceStartDate,
    consversationsSourceEndDate,
    conversations,
  ]);

  const {
    brandColor,
    shape2Color,
    shape3Color,
    infoColor,
  } = useSelector((state) => ({
    infoColor: metronic.builder.selectors.getConfig(
      state,
      'colors.state.info',
    ),
    brandColor: metronic.builder.selectors.getConfig(
      state,
      'colors.state.warning',
    ),
    shape2Color: metronic.builder.selectors.getConfig(
      state,
      'colors.base.shape.2',
    ),
    shape3Color: metronic.builder.selectors.getConfig(
      state,
      'colors.base.shape.3',
    ),
  }));

  const chartOptions = {
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 20,
        bottom: 0,
      },
    },
    legend: {
      display: true,
      position: 'right',
    },
    tooltips: {
      intersect: false,
      mode: 'nearest',
      xPadding: 10,
      yPadding: 10,
      caretPadding: 10,
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      animateRotate: false,
      animateScale: true,
    },
    scales: {
      xAxes: [
        {
          display: true,
          scaleLabel: {
            display: false,
            labelString: 'Días',
          },
          gridLines: false,
          ticks: {
            display: true,
            beginAtZero: true,
            fontColor: shape3Color,
            fontSize: 13,
            padding: 10,
          },
        },
      ],
      yAxes: [
        {
          categoryPercentage: 0.35,
          barPercentage: 0.7,
          display: true,
          scaleLabel: {
            display: false,
            labelString: 'Value',
          },
          gridLines: {
            color: shape2Color,
            offsetGridLines: false,
            drawTicks: false,
            borderDash: [3, 4],
            zeroLineWidth: 1,
            zeroLineColor: shape2Color,
            zeroLineBorderDash: [3, 4],
          },
          ticks: {
            max,
            stepSize: 3,
            display: true,
            beginAtZero: true,
            fontColor: shape3Color,
            fontSize: 13,
            padding: 10,
          },
        },
      ],
    },
  };

  const data = React.useMemo(
    () => ({
      labels: labelDays,
      datasets: [
        {
          label: `Redes : ${perDayRedes}`,
          fill: true,
          // borderWidth: 0,
          backgroundColor: Chart.helpers
            .color(brandColor)
            .alpha(0.76)
            .rgbString(),

          borderColor: Chart.helpers
            .color(brandColor)
            .alpha(0)
            .rgbString(),

          pointHoverRadius: 4,
          pointHoverBorderWidth: 12,
          pointBackgroundColor: Chart.helpers
            .color('#000000')
            .alpha(0)
            .rgbString(),
          pointBorderColor: Chart.helpers
            .color('#000000')
            .alpha(0)
            .rgbString(),
          pointHoverBackgroundColor: brandColor,
          pointHoverBorderColor: Chart.helpers
            .color('#000000')
            .alpha(0.1)
            .rgbString(),

          data: socialNetworks,
        },
        {
          label: `Micrositio : ${perDayMicrositio}`,
          fill: true,
          // borderWidth: 0,
          backgroundColor: Chart.helpers
            .color(infoColor)
            .alpha(0.76)
            .rgbString(),

          borderColor: Chart.helpers
            .color(infoColor)
            .alpha(0)
            .rgbString(),

          pointHoverRadius: 4,
          pointHoverBorderWidth: 12,
          pointBackgroundColor: Chart.helpers
            .color('#000000')
            .alpha(0)
            .rgbString(),
          pointBorderColor: Chart.helpers
            .color('#000000')
            .alpha(0)
            .rgbString(),
          pointHoverBackgroundColor: infoColor,
          pointHoverBorderColor: Chart.helpers
            .color('#000000')
            .alpha(0.1)
            .rgbString(),

          data: microsite,
        },
        {
          label: `Total : ${totalConversations}`,
        },
      ],
    }),
    [brandColor, days, infoColor, totalConversations],
  );

  React.useEffect(() => {
    const config = {
      type: 'line',
      data,
      options: chartOptions,
    };

    const chart = new Chart(canvasRef.current, config);

    return () => {
      chart.destroy();
    };
  }, [chartOptions, conversations]);

  const renderConversationSourcesChart = () => {
    return (
      <div className="kt-widget12__chart" style={{ height: '250px' }}>
        <canvas
          ref={canvasRef}
          width={683}
          height={312}
          id="kt_chart_order_statistics"
        />
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.header}>
          <span className={classes.desc}>{desc}</span>
          <div className={classes.datePickersContainer}>
            <MuiPickersUtilsProvider
              utils={MomentUtils}
              locale={'es'}
            >
              <DatePicker
                autoOk
                disableToolbar
                disableFuture
                size="small"
                inputVariant="outlined"
                format="DD MMM"
                className={clsx(
                  classes.datePicker,
                  classes.datePickerLeft,
                )}
                value={conversationsSourceStartDate}
                onChange={handleConversationSourceStartDate}
                InputProps={{
                  startAdornment: (
                    <IconButton
                      aria-label="Select day"
                      size="small"
                      className={classes.calendarIcon}
                    >
                      <CalendarTodayIcon />
                    </IconButton>
                  ),
                }}
              />
              -
              <DatePicker
                autoOk
                disableToolbar
                disableFuture
                size="small"
                inputVariant="outlined"
                format="DD MMM"
                className={clsx(
                  classes.datePicker,
                  classes.datePickerRight,
                )}
                value={consversationsSourceEndDate}
                onChange={handleConversationSourceEndDate}
                InputProps={{
                  startAdornment: (
                    <IconButton
                      aria-label="Select day"
                      size="small"
                      className={classes.calendarIcon}
                    >
                      <CalendarTodayIcon />
                    </IconButton>
                  ),
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>

        <div className={classes.responseTimers}>
          {renderConversationSourcesChart()}
        </div>
      </div>
    </div>
  );
}
