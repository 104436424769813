import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { toAbsoluteUrl } from '../../../_metronic';

class SplashScreen extends React.Component {
  render() {
    return (
      <>
        <div className="kt-splash-screen text-center">
          <div className="spinner-karlo-container">
            <img
              src={toAbsoluteUrl(
                '/media/logos/Logo-karbot-white.png',
              )}
              alt="Karlo logo"
              height="auto"
              width="200px"
            />
            <CircularProgress className="kt-splash-screen__spinner" />
          </div>
        </div>
      </>
    );
  }
}

export default SplashScreen;
