import { createSelector } from 'reselect';
import { initialState } from './initialState';

export const selectDashboard = (state) =>
  state.dashboard || initialState;

export const makeSelectconversationsFromDashboard = createSelector(
  selectDashboard,
  (dashboardState) => dashboardState.conversations,
);

export const makeSelectCampaignsFromDashboard = createSelector(
  selectDashboard,
  (dashboardState) => dashboardState?.campaigns?.fromdb,
);

export const makeSelectEventsFromDashboard = createSelector(
  selectDashboard,
  (dashboardState) => dashboardState.events,
);

export const makeSelectconversationsfromdb = () =>
  createSelector(
    selectDashboard,
    (dashboardState) => dashboardState.conversations.fromdb,
  );

export const makeSelectDashboardIsLoading = createSelector(
  selectDashboard,
  (dashboardState) => dashboardState.isLoading,
);
