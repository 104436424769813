import { createSelector } from 'reselect';
import { initialState } from './initialState';

export const selectSetup = (state) => state.setup || initialState;

export const makeSelectTimeTablesPresetFromState = createSelector(
  selectSetup,
  (setupState) => setupState.timeTables.fromdb,
);

export const makeSelectSetupIsLoading = createSelector(
  selectSetup,
  (setupState) => setupState.isLoading,
);
