import 'emoji-mart/css/emoji-mart.css';

import * as XLSX from 'xlsx';

import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Col, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import React, { useState } from 'react';
import {
  closeNotificationModals,
  notificationSend,
  sendNotificationsToAllUsers,
} from '../../notificationsSlice';
import { getNested, menuOptionsMapped } from '../../../../utils';

import ChipInput from 'material-ui-chip-input';
import Modal from '../../../../components/Modal';
import { Picker } from 'emoji-mart';
import PublishIcon from '@material-ui/icons/Publish';
import ShouldItRender from '../../../../components/ShouldItRender';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { getAgencies } from '../../../agencies/actions';
import { injectIntl } from 'react-intl';
import { makeSelectAgencies } from '../../../agencies/selectors';
import { makeSelectUserRoleFromState } from '../../../auth/selectors';

const conversationTypes = [
  'Cita de servicio',
  'Cotizar Mazda',
  'Prueba de manejo',
  'Ya estoy en tramite con un asesor de ventas o servicio',
];

const useStyles = makeStyles((theme) => ({
  container: {
    flexWrap: 'wrap',
  },
  paper: {
    padding: theme.spacing(7, 10),
  },
  margin: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  client: {
    padding: theme.spacing(0),
    marginBottom: theme.spacing(2),
  },
  textField: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  media: {
    height: 60,
    width: '100%',
    position: 'relative',
  },
  image: {
    height: '100%',
    width: 'auto',
    position: 'absolute',
    top: '10%',
    left: '45%',
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  subtitle: {
    marginBottom: theme.spacing(4.5),
  },
  sendButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  editor: {
    border: '1px solid #F1F1F1',
  },
  emoticonButton: {
    padding: '0px 10px',
  },
  emojiFace: {
    fontSize: '20px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonImportExcel: {
    marginBottom: theme.spacing(2),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function SendNotification(props) {
  const classes = useStyles();
  const {
    agencies,
    notifications,
    role,
    notificationSend,
    sendNotificationsToAllUsers,
    closeNotificationModals,
    getAgencies,
    history,
  } = props;
  const [emojiPickerState, SetEmojiPicker] = useState(false);
  const [excelFile, setExcelFile] = React.useState(null);
  const [excelNumbers, setExcelNumbers] = React.useState([]);
  const [showExcelFileModal, setShowExcelFileModal] = React.useState(
    false,
  );
  const [
    showSuccessUploadModal,
    setShowSuccessUploadModal,
  ] = React.useState(false);
  const {
    handleSubmit,
    register,
    errors,
    watch,
    setValue,
    control,
  } = useForm({
    defaultValues: {
      sendTo: 'allUsers',
      message: '',
      phoneNumbers: [],
      allCategoriesUsers: [],
    },
  });
  const phoneNumbers = watch('phoneNumbers');
  const sendTo = watch('sendTo');
  const allCategoriesUsers = watch('allCategoriesUsers');
  const message = watch('message');
  const agencyID = watch('agencyID');

  const handleCloseModals = (redirect) => {
    closeNotificationModals({ history, redirect });
  };

  React.useEffect(() => {
    getAgencies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = notifications;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message, redirect } = notifications[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={() => handleCloseModals(redirect)}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={() => handleCloseModals(redirect)}
          />
        );
      }
      return null;
    } catch (e) {
      console.log('Modal error: ', e);
      handleCloseModals({});
      return null;
    }
  };

  const toggleExcelModal = () =>
    setShowExcelFileModal(!showExcelFileModal);

  const handleFile = (selectedFiles, e) => {
    e.preventDefault();
    if (!selectedFiles || selectedFiles.length === 0) return;

    if (selectedFiles[0] !== null) {
      if (selectedFiles[0].size !== 0) {
        setExcelFile(selectedFiles[0]);
        setShowExcelFileModal(true);
        e.target.value = null;
        return;
      }
    }
    return;
  };

  const handleReadExcel = () => {
    const reader = new FileReader();
    reader.onload = (evt) => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });

      setExcelNumbers(dataParse);
    };
    reader.readAsBinaryString(excelFile);
    setShowExcelFileModal(false);
    setShowSuccessUploadModal(true);
  };

  const renderEmojiPicker = (values, setFieldValue) => {
    if (emojiPickerState) {
      return (
        <Picker
          title=""
          emoji=""
          enableFrequentEmojiSort={false}
          emojiSize={20}
          showSkinTones={false}
          i18n={{
            search: 'Buscar',
            clear: 'Limpiar', // Accessible label on "clear" button
            notfound: 'Emoji no encontrado',
            skintext: 'Choose your default skin tone',
            categories: {
              search: 'Resultados',
              recent: 'Usados frecuentemente',
              smileys: 'Sonrisas y expresiones',
              people: 'Personas',
              nature: 'Animales y naturaleza',
              foods: 'Comida',
              activity: 'Actividades',
              places: 'Lugares y viajes',
              objects: 'Objetos',
              symbols: 'Símbolos',
              flags: 'Banderas',
              custom: 'Custom',
            },
          }}
          onSelect={(emoji) =>
            setValue('message', message + emoji.native)
          }
        />
      );
    }
  };

  const renderAgencyOptions = () => {
    if (Array.isArray(agencies.fromdb))
      return agencies.fromdb.map((agency) => {
        return (
          <MenuItem key={`agency-${agency.id}`} value={agency.id}>
            {agency.name}
          </MenuItem>
        );
      });
    return null;
  };

  const handleAddPhoneNumber = (phoneNumbersArray, phoneNumber) => {
    const newphoneNumbers = [...phoneNumbersArray];
    newphoneNumbers.push(phoneNumber);
    return newphoneNumbers;
  };

  const handleDeletePhoneNumber = (phoneNumbersArray, index) => {
    const newphoneNumbers = [...phoneNumbersArray];
    newphoneNumbers.splice(index, 1);
    return newphoneNumbers;
  };

  const handleSendNotifications = (values) => {
    switch (sendTo) {
      case 'typeNumbers':
        delete values.sendTo;
        values.phoneNumbers = values.phoneNumbers.map((phoneNumber) =>
          phoneNumber.indexOf('52') === -1
            ? `52${phoneNumber}`
            : phoneNumber,
        );
        notificationSend(values);
        break;
      case 'allUsers':
        delete values.sendTo;
        sendNotificationsToAllUsers(values);
        break;
      case 'excelNumbers':
        delete values.sendTo;
        values.phoneNumbers = excelNumbers.map((phoneNumber) =>
          phoneNumber.indexOf('52') === -1
            ? `52${phoneNumber}`
            : phoneNumber,
        );
        notificationSend(values);
        break;
      case 'allCategoriesUsers':
        delete values.sendTo;
        sendNotificationsToAllUsers(values);
        break;

      default:
        console.log('values: ', values);
        break;
    }
  };

  const renderCategoryOptions = () => {
    let categories = [];
    if (role === 'Super Admin' && agencyID) {
      const agency = agencies.fromdb.filter(
        (agency) => agency.id === agencyID,
      );
      categories = agency[0].menuOptions;
    } else
      categories = getNested(
        [],
        agencies,
        'agencyDetails',
        'menuOptions',
      );
    if (categories.length > 0)
      return categories.map((category) => {
        return (
          <MenuItem key={`agency-${category}`} value={category}>
            {menuOptionsMapped[category]}
          </MenuItem>
        );
      });
    return null;
  };

  const renderUploadExcelFileDialog = () => {
    return (
      <Modal
        open={showExcelFileModal}
        closeModal={() => {
          toggleExcelModal();
          setExcelFile(null);
        }}
        dialogTitle="Importar órdenes"
        dialogText={`Está a punto de importar los números telefónicos desde el archivo ${
          excelFile ? excelFile.name : ''
        }\n desea continuar?`}
        actionButtonText="Importar"
        onClick={() => handleReadExcel()}
      />
    );
  };

  const renderUploadSuccessDialog = () => {
    return (
      <Modal
        open={showSuccessUploadModal}
        type="successModal"
        closeModal={() => {
          setShowSuccessUploadModal(false);
        }}
        dialogTitle="Importación completada"
        dialogText={`Se han cargado ${excelNumbers.length}\n números de celular`}
        actionButtonText="Aceptar"
        onClick={() => setShowSuccessUploadModal(false)}
      />
    );
  };

  const renderIngressNumbers = () => {
    switch (sendTo) {
      case 'typeNumbers':
        return (
          <Controller
            as={
              <ChipInput
                fullWidth
                className={classes.formControl}
                name="phoneNumbers"
                label="Destinatarios"
                placeholder="335663636, 3636363636"
                value={phoneNumbers}
                variant="outlined"
                newChipKeyCodes={[13, 32, 188]}
                onAdd={(receiver) => {
                  setValue(
                    'phoneNumbers',
                    handleAddPhoneNumber(phoneNumbers, receiver),
                  );
                }}
                onDelete={(_, index) => {
                  setValue(
                    'phoneNumbers',
                    handleDeletePhoneNumber(phoneNumbers, index),
                  );
                }}
              />
            }
            control={control}
            name="phoneNumbers"
          />
        );
      case 'excelNumbers':
        return (
          <>
            <input
              accept=".xlsx, .xls, .csv"
              hidden
              id="raised-button-file"
              type="file"
              onChange={(e) => handleFile(e.target.files, e)}
            />
            <label
              htmlFor="raised-button-file"
              className={classes.wrapper}
            >
              <Button
                variant="contained"
                className={clsx(
                  'btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10',
                  classes.buttonImportExcel,
                )}
                component="span"
              >
                Importar números de celular
              </Button>
            </label>
          </>
        );
      case 'allCategoriesUsers':
        return (
          <FormControl
            variant="outlined"
            fullWidth
            className={classes.formControl}
          >
            <InputLabel id="agency-label">Categorías</InputLabel>
            <Controller
              as={
                <Select
                  id="demo-mutiple-name"
                  multiple
                  value={allCategoriesUsers}
                  onChange={({ target: { value } }) =>
                    setValue('allCategoriesUsers', value)
                  }
                  input={
                    <OutlinedInput
                      labelId="agency-label"
                      label="Categorías"
                    />
                  }
                  MenuProps={MenuProps}
                >
                  {renderCategoryOptions()}
                </Select>
              }
              control={control}
              name="allCategoriesUsers"
            />
          </FormControl>
        );
      default:
        break;
    }
  };
  return (
    <>
      <Row>
        <Col>
          <Paper className={classes.paper}>
            <form
              className={classes.container}
              onSubmit={handleSubmit(handleSendNotifications)}
              noValidate
              autoComplete="off"
            >
              <div className="input-container">
                <Grid item xs={12} className={classes.subtitle}>
                  <Typography variant="h6" className={classes.top}>
                    Enviar mensaje de WhatsApp
                  </Typography>
                </Grid>
                <ShouldItRender
                  locationPage={'/notifications'}
                  action="selectAgency"
                >
                  <FormControl
                    variant="outlined"
                    className={clsx(classes.textField)}
                    fullWidth
                  >
                    <InputLabel id="label-agency">Agencia</InputLabel>
                    <Controller
                      as={
                        <Select
                          labelId="label-agency"
                          id="demo-mutiple-name"
                          label="Agencia"
                          error={Boolean(errors.agencyID)}
                        >
                          <MenuItem disabled value="">
                            Selecciona una opción
                          </MenuItem>
                          {renderAgencyOptions()}
                        </Select>
                      }
                      control={control}
                      name="agencyID"
                    />
                  </FormControl>
                </ShouldItRender>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Enviar a</FormLabel>
                  <Controller
                    as={
                      <RadioGroup
                        aria-label="Enviar a"
                        value={sendTo}
                        onChange={({ target }) =>
                          setValue('sendTo', target.value)
                        }
                      >
                        <FormControlLabel
                          value="allUsers"
                          control={<Radio />}
                          label={`Todos los usuarios que han interactuado con el bot.`}
                        />
                        <FormControlLabel
                          value="allCategoriesUsers"
                          control={<Radio />}
                          label="Segmentar por categoría."
                        />
                        <FormControlLabel
                          value="excelNumbers"
                          control={<Radio />}
                          label="Importar base de datos de excel (el listado de números telefónicos deberá ser en una sola columna y deberá iniciar en la celda A1)."
                        />
                        <FormControlLabel
                          value="typeNumbers"
                          control={<Radio />}
                          label="Lista personalizada."
                        />
                      </RadioGroup>
                    }
                    control={control}
                    rules={{ required: true }}
                    name="sendTo"
                  />
                </FormControl>
                <Grid container>
                  <Grid item xs={12}>
                    {renderIngressNumbers()}
                  </Grid>
                </Grid>

                <TextField
                  required
                  fullWidth
                  multiline
                  rowsMax="4"
                  name="message"
                  type="text"
                  label="Mensaje"
                  placeholder="Escribir mensaje aquí 😄"
                  variant="outlined"
                  className={classes.textField}
                  inputRef={register}
                  error={Boolean(errors.message && errors.message)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Button
                          size="small"
                          onClick={() =>
                            SetEmojiPicker(!emojiPickerState)
                          }
                        >
                          <span
                            role="img"
                            aria-label=""
                            className={classes.emojiFace}
                          >
                            😁
                          </span>
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  {renderEmojiPicker()}
                </Grid>
                <Grid item xs={4}>
                  <div className={classes.sendButton}>
                    <Button
                      disabled={notifications.isLoading}
                      type="submit"
                      color="primary"
                    >
                      Enviar
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Col>
      </Row>
      {renderFeedBackModal()}
      {renderUploadExcelFileDialog()}
      {renderUploadSuccessDialog()}
    </>
  );
}

const mapStateToProps = (state) => ({
  notifications: state.notifications,
  agencies: makeSelectAgencies(state),
  role: makeSelectUserRoleFromState(state),
});

const mapDispatchToProps = {
  closeNotificationModals,
  notificationSend,
  sendNotificationsToAllUsers,
  getAgencies,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(SendNotification),
);
