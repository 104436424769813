import { Checkbox, FormControlLabel } from '@material-ui/core';

import { Controller } from 'react-hook-form';
import React from 'react';

export const CheckBoxRHFMUI = ({ name, label, control }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={(props) => (
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => props.onChange(e.target.checked)}
              checked={props.value}
            />
          }
          label={label}
        />
      )}
    />
  );
};
