import { call, put } from 'redux-saga/effects';
import {
  createEventFail,
  createEventShipmentFail,
  createEventShipmentSuccess,
  createEventSuccess,
  getAllEventsFail,
  getAllEventsSuccess,
  getEventFail,
  getEventSuccess,
  updateEventFail,
  updateEventSuccess,
} from 'app/features/events/eventsSlice';

import { formatDatesForFilter } from 'app/utils';
import { getLogs } from 'app/features/logs/logsSlice';
import { requestHandler } from '../../services/requestHandler';

export function* getAllEventsSaga(action) {
  try {
    const { page, pageSize, filtered } = action.payload;
    const data = {
      where: {},
      agencies: {
        options: {
          attributes: ['name'],
        },
      },
      options: {
        // attributes: ['name', 'phoneNumber'],
        count: true,
        distinct: true,
      },
      pagination: {
        page,
        perPage: pageSize,
        order: [['createdAt', 'DESC']],
      },
    };
    if (filtered.length) {
      filtered.forEach((filter) => {
        if (filter.id === 'clientID')
          data.where[filter.id] = {
            $in: [parseInt(filter.value)],
          };
        else if (filter.id === 'client.phoneNumber')
          data.clients.where['phoneNumber'] = {
            $ilike: `%${filter.value}%`,
          };
        else if (filter.id === 'agencyUser.type') {
          data.agencyUsers = {
            ...data.agencyUsers,
            where: {
              type: {
                $ilike: `%${filter.value}%`,
              },
            },
          };
        } else if (filter.id === 'agencyUser.user.name') {
          data.agencyUsers.where = {
            ...data.agencyUsers.where,
            id: { $not: null },
          };
          data.agencyUsers.users = {
            where: {
              ...data.agencyUsers.users.where,
              name: {
                $ilike: `%${filter.value}%`,
              },
            },
          };
        } else if (filter.id === 'createdAt')
          data.where[filter.id] = formatDatesForFilter(filter.value);
        else data.where[filter.id] = { $ilike: `%${filter.value}%` };
      });
    }
    const events = yield call(requestHandler, {
      method: 'POST',
      path: '/events/getAll',
      data,
    });
    events.pages = yield call(Math.ceil, events.count / pageSize);
    yield put(getAllEventsSuccess({ events }));
  } catch (e) {
    yield put(getAllEventsFail({}));
    console.log('error getting all events: ', e);
  }
}

export function* getEventSaga(action) {
  try {
    const { id } = action.payload;
    const data = {
      where: {
        id,
      },
      eventShipments: true,
    };
    const event = yield call(requestHandler, {
      method: 'POST',
      path: '/events/getOne',
      data,
    });
    yield put(
      getLogs({
        object: 'events',
        objectID: id,
      }),
    );
    yield put(getEventSuccess({ event }));
  } catch (e) {
    yield put(getEventFail({}));
    console.log('error in getting client: ', e);
  }
}

export function* createEventSaga(action) {
  try {
    const { reFetchData, ...rest } = action.payload;
    const data = {
      ...rest,
    };
    const client = yield call(requestHandler, {
      method: 'POST',
      path: '/events/create',
      data,
    });
    const message = {
      title: 'Evento creado',
      desc: `El evento ha sido creado correctamente`,
    };
    yield put(createEventSuccess({ message, client }));
    yield call(reFetchData, {});
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Error al tratar de crear el evento, por favor intenta más tarde`,
    };
    yield put(createEventFail({ message }));
    console.log('error updating client: ', e);
  }
}

export function* createEventShipmentSaga(action) {
  try {
    const { id, ...rest } = action.payload;
    const data = {
      ...rest,
      eventID: id,
    };
    const client = yield call(requestHandler, {
      method: 'POST',
      path: '/eventShipments/create',
      data,
    });
    const message = {
      title: 'Envío creado',
      desc: `El envío ha sido creado y configurador correctamente`,
    };
    yield put(createEventShipmentSuccess({ message, client }));
    yield call(getEventSaga, { payload: { id } });
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Error al tratar de crear el envío, por favor intenta más tarde`,
    };
    yield put(createEventShipmentFail({ message }));
    console.log('error creating eventShipment: ', e);
  }
}

export function* updateEventSaga(action) {
  try {
    let message;
    const { reFetchData, status, ...rest } = action.payload;
    const data = {
      ...rest,
      status,
    };
    const client = yield call(requestHandler, {
      method: 'POST',
      path: '/events/update',
      data,
    });
    if (status === 'pendiente')
      message = {
        title: 'Evento enviado a revisión',
        desc: `El evento ha sido ha sido enviado a revisión`,
      };
    else if (status === 'validado')
      message = {
        title: 'Evento validado',
        desc: `El evento ha sido ha sido validado correctamente`,
      };
    yield put(updateEventSuccess({ message, client }));
    if (reFetchData) yield call(reFetchData, {});
    else yield call(getEventSaga, { payload: { id: rest.id } });
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Error al tratar de actualizar el evento, por favor intenta más tarde`,
    };
    yield put(updateEventFail({ message }));
    console.log('error updating client: ', e);
  }
}
