import { Badge } from 'react-bootstrap';
import React from 'react';
import { capitalize } from 'lodash';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  categoryPillcontainer: {
    alignSelf: 'center',
    marginTop: theme.spacing(1.5),
  },
  categoryPill: {
    fontSize: '.956em',
    fontWeight: 600,
    borderRadius: '7px',
  },
  ventasPill: {
    backgroundColor: '#effee1',
    color: '#249c66',
  },
  servicioPill: {
    backgroundColor: '#f6d8bc',
    color: '#814d49',
  },
  refaccionesPill: {
    backgroundColor: '#e9e7ff',
    color: '#345bc8',
  },
  seminuevosPill: {
    backgroundColor: '#e2f6f7',
    color: '#2b919c',
  },
  recepcionPill: {
    backgroundColor: '#effee1',
    color: '#2b919c',
  },
  financiamientoPill: {
    backgroundColor: '#EEE8AA',
    color: '#FF8C00',
  },
  'SIN INTERACCIONPill': {
    backgroundColor: '#e2f6f7',
    color: '#FF8C00',
  },
}));

export default function CategoryBadge({ categoryType }) {
  const classes = useStyles();

  return (
    <Badge
      className={clsx(
        classes.categoryPill,
        classes[`${categoryType}Pill`],
      )}
    >
      {capitalize(categoryType)}
    </Badge>
  );
}
