import { Badge, Table } from 'react-bootstrap';
import {
  Button,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import {
  addMessageReceived,
  assignConversation,
  attendConversation,
  closeModals,
  getConversation,
  updateConversation,
  updateConversationClient,
  uploadFilesAndSendThem,
} from '../../conversationsSlice';
import {
  advisorTypes,
  getBadgeColor,
  getNested,
  getResponseTime,
} from '../../../../utils';
import { capitalize, get, isEmpty } from 'lodash';
import { connect, useDispatch } from 'react-redux';
import {
  makeSelectAuthTokenFromState,
  makeSelectUserCategoriesFromState,
  makeSelectUserRoleFromState,
} from 'app/features/auth/selectors';
import {
  makeSelectConversationDetails,
  makeSelectConversations,
} from '../../selectors';
import { makeSelectAgencyDetailsFromState } from '../../../agencies/selectors';
import NoteRoundedIcon from '@material-ui/icons/NoteRounded';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import Modal from '../../../../components/Modal';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import RecentActivities from '../../../../components/RecentActivities';
import ShouldItRender from '../../../../components/ShouldItRender';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import { WhatsAppChat } from 'app/components/WhatsAppChat';
import api from 'app/config/api';
import { closeModals as closeClientModals } from '../../../clients/clientsSlice';
import clsx from 'clsx';
import { getAgencies } from 'app/features/agencies/actions';
import { getUsers } from '../../../users/userSlice';
import { injectIntl } from 'react-intl';
import io from 'socket.io-client';
import { makeSelectAgenciesFromdb } from 'app/features/agencies/selectors';
import { makeSelectConversationsLogs } from '../../../logs/selectors';
import { makeSelectUsersFromState } from '../../../users/selectors';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { selectClients } from 'app/features/clients/selectors';
import { withRouter } from 'react-router';
import { InfoDetails } from 'app/components/InfoDetails';

const useStyles = makeStyles((theme) => ({
  paper: {
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  title: {
    textAlign: 'left',
    color: '#1c1c1c',
  },
  textField: {
    margin: theme.spacing(1, 0, 1, 0),
  },
  rightContainer: {
    flexDirection: 'column',
  },
  tableTitle: {
    fontWeight: 401,
  },
  tables: {
    width: '100%',
  },
  clientTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  editClientButton: {
    margin: theme.spacing(1),
  },
}));

function ConversationDetail(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    clients,
    agenciesFromdb,
    conversations,
    conversationDetail,
    getConversation,
    setLeftSideComponent,
    setActionButton,
    conversationLogs,
    advisors,
    role,
    attendConversation,
    updateConversation,
    updateConversationClient,
    getUsers,
    assignConversation,
    categories,
    closeModals,
    closeClientModals,
    location,
    match,
    token,
    agencyDetails,
  } = props;
  const conversationID = match.params.conversationID;
  const [
    showConfirmAttendModal,
    setShowConfirmAttendModal,
  ] = useState(false);
  const [showProspectModal, setShowProspectModal] = useState(false);
  const [showProspectLostModal, setShowProspectLostModal] = useState(
    false,
  );
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [showEditClientModal, setShowEditClientModal] = useState(
    false,
  );
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [showCustomerLostModal, setShowCustomerLostModal] = useState(
    false,
  );
  const API_URL = api();
  const socketRef = React.useRef(null);

  const {
    handleSubmit,
    register,
    errors,
    control,
    watch,
    reset,
  } = useForm();

  const assessorType = watch('assessorType');
  const agencyID = watch('agencyID');

  useEffect(() => {
    if (role === 'Super Admin') getAgencies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLeftSideComponent(
      <div className="kt-subheader__main">
        <div>
          <h3 className="kt-subheader__title">
            Detalle de conversación
          </h3>
        </div>
      </div>,
    );
    const data = {
      id: parseInt(conversationID),
    };
    getConversation(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (socketRef.current) {
      socketRef.current.on(
        'conversations:messageReceived',
        (message) => {
          dispatch(addMessageReceived({ message: message.message }));
        },
      );

      socketRef.current.on('disconnect', (reason) => {
        dispatch(getConversation({ id: parseInt(conversationID) }));
      });
    }
    return () => {
      if (socketRef.current) socketRef.current.disconnect();
    };
  }, [socketRef.current, conversationID]);

  useEffect(() => {
    const conversationStatus = getNested(
      null,
      conversationDetail,
      'status',
    );
    const { customerLost } =
      agencyDetails === null ? false : agencyDetails;

    setActionButton(
      <>
        <ShouldItRender
          should={conversationStatus !== 'Venta facturada'}
        >
          <Button
            variant="contained"
            className="btn btn-label-info btn-bold btn-sm btn-icon-h kt-margin-l-10"
            onClick={toggleNotesModal}
          >
            Notas
            <NoteRoundedIcon className="kt-margin-l-10" />
          </Button>
        </ShouldItRender>
        <ShouldItRender
          should={
            conversationStatus === 'SIN INTERACCION' ||
            conversationStatus !== 'Venta facturada' ||
            conversationStatus ===
              'Conversacion con Asesor iniciada' ||
            role === 'Super Admin' ||
            role === 'Agency Admin'
          }
        >
          <Button
            variant="contained"
            className="btn btn-label-warning btn-bold btn-sm btn-icon-h kt-margin-l-10"
            onClick={togleAssignModal}
          >
            Asignar conversación
            <AssignmentIndIcon className="kt-margin-l-10" />
          </Button>
        </ShouldItRender>
        <ShouldItRender
          should={
            conversationStatus === 'Prospecto' ||
            conversationStatus !== 'Venta facturada'
          }
        >
          <Button
            variant="contained"
            className="btn btn-label-danger btn-bold btn-sm btn-icon-h kt-margin-l-10"
            onClick={toogleProspectLostModal}
          >
            Prospecto perdido
            <CancelIcon className="kt-margin-l-10" />
          </Button>
        </ShouldItRender>
        <ShouldItRender should={conversationStatus === 'Prospecto'}>
          <Button
            variant="contained"
            className="btn btn-bold btn-sm btn-icon-h kt-margin-l-10"
            style={{
              color: '#0b9494',
              backgroundColor: 'rgba(1, 255, 210, 0.1)',
            }}
            onClick={toggleInvoiceModal}
          >
            Venta Facturada
            <AssignmentIndIcon className="kt-margin-l-10" />
          </Button>
        </ShouldItRender>
        <ShouldItRender
          should={
            conversationStatus ===
              'Conversacion con Asesor iniciada' &&
            conversationStatus !== 'Venta facturada'
          }
        >
          <Button
            variant="contained"
            className="btn btn-label-info btn-bold btn-sm btn-icon-h kt-margin-l-10"
            onClick={toogleProspectModal}
          >
            Cliente prospecto
            <TransferWithinAStationIcon className="kt-margin-l-10" />
          </Button>
        </ShouldItRender>
        <ShouldItRender
          should={
            conversationStatus === 'Pendiente' ||
            conversationStatus === 'Ignorado'
          }
        >
          <Button
            variant="contained"
            className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
            onClick={toogleConfirmAttendModal}
          >
            Cliente atendido
            <PersonAddIcon className="kt-margin-l-10" />
          </Button>
        </ShouldItRender>
        <ShouldItRender should={customerLost}>
          <Button
            variant="contained"
            className="btn btn-label-danger btn-bold btn-sm btn-icon-h kt-margin-l-10"
            onClick={toggleCustomerLostModal}
          >
            Cliente perdido
            <CancelIcon className="kt-margin-l-10" />
          </Button>
        </ShouldItRender>
      </>,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationDetail]);

  useEffect(() => {
    if (conversationDetail) {
      const phoneNumber = getNested(
        '',
        conversationDetail,
        'client',
        'phoneNumber',
      ).replace('52', '');
      const notes = getNested('', conversationDetail, 'notes');
      reset({
        name: getNested('', conversationDetail, 'client', 'name'),
        phoneNumber,
        notes,
        email: getNested('', conversationDetail, 'client', 'email'),
        interest: getNested(
          '',
          conversationDetail,
          'client',
          'interest',
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationDetail]);

  useEffect(() => {
    if (
      conversationDetail &&
      conversationDetail.onAdminConversation
    ) {
      handleConnectSocket();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationDetail, socketRef]);

  useEffect(() => {
    if (assessorType && agencyID)
      getUsers({ type: assessorType, agencyID });
    else if (assessorType) getUsers({ type: assessorType });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessorType, agencyID]);

  const togleAssignModal = () => setShowAssignModal(!showAssignModal);

  const togleClientModal = () =>
    setShowEditClientModal(!showEditClientModal);

  const toggleNotesModal = () => setShowNotesModal(!showNotesModal);

  const toogleConfirmAttendModal = () => {
    setShowConfirmAttendModal(!showConfirmAttendModal);
  };

  const toogleProspectModal = () => {
    setShowProspectModal(!showProspectModal);
  };

  const toogleProspectLostModal = () => {
    setShowProspectLostModal(!showProspectLostModal);
  };

  const toggleInvoiceModal = () => {
    setShowInvoiceModal(!showInvoiceModal);
  };

  const toggleCustomerLostModal = () => {
    setShowCustomerLostModal(!showCustomerLostModal);
  };

  const handleUploadFiles = (selectedFiles) => {
    if (!selectedFiles || selectedFiles.length === 0) return;

    if (selectedFiles[0] !== null) {
      if (selectedFiles[0].size !== 0) {
        return dispatch(
          uploadFilesAndSendThem({
            selectedFiles,
            handleEmitMessage,
          }),
        );
      }
    }
    return;
  };

  const renderAgencyOptions = () => {
    if (Array.isArray(agenciesFromdb))
      return agenciesFromdb.map((agency) => {
        return (
          <MenuItem key={`agency-${agency.id}`} value={agency.id}>
            {agency.name}
          </MenuItem>
        );
      });
    return null;
  };

  const handleConnectSocket = () => {
    if (!socketRef.current && !isEmpty(conversationDetail))
      socketRef.current = io(
        API_URL.replace('/api', '') +
          `/conversations/${get(conversationDetail, 'id')}`,
        {
          auth: { token: token.replace('Bearer ', '') },
        },
      );
    setTimeout(() => {
      if (socketRef.current.disconnected) socketRef.current.connect();
    }, 300);
  };

  const handleAttendConversation = () => {
    setShowConfirmAttendModal(false);
    attendConversation({
      conversationID: parseInt(props.match.params.conversationID),
    });
  };

  const handeProspectConversation = () => {
    setShowProspectModal(false);
    updateConversation({
      conversationID: parseInt(props.match.params.conversationID),
      status: 'Prospecto',
      title: 'Cliente prospecto',
    });
  };

  const handeProspectLost = () => {
    setShowProspectLostModal(false);
    updateConversation({
      conversationID: parseInt(props.match.params.conversationID),
      status: 'Prospecto perdido',
      title: 'Prospecto perdido',
    });
  };

  const handleInvoice = () => {
    setShowProspectLostModal(false);
    updateConversation({
      conversationID: parseInt(props.match.params.conversationID),
      status: 'Venta facturada',
      title: 'Venta facturada',
    });
  };

  const handleCustomerLost = () => {
    setShowProspectLostModal(false);
    updateConversation({
      conversationID: parseInt(props.match.params.conversationID),
      status: 'Cliente perdido',
      title: 'Cliente perdido',
    });
  };

  const handleCloseModals = () => {
    closeModals({});
    closeClientModals({});
    setShowConfirmAttendModal(false);
  };

  const handleAssignConversation = (formData) => {
    const data = {
      agencyUserID: formData.agencyUserID,
      conversationID: parseInt(props.match.params.conversationID),
    };
    assignConversation(data);
    togleAssignModal();
  };

  const handleUpdateClient = (formData) => {
    const { phoneNumber, ...rest } = formData;
    const data = {
      conversationID: parseInt(props.match.params.conversationID),
      id: getNested(null, conversationDetail, 'clientID'),
      ...rest,
      phoneNumber: '52' + phoneNumber,
    };
    updateConversationClient(data);
    togleClientModal();
  };

  const handleUpdateNotes = (formData) => {
    const { notes } = formData;
    const data = {
      conversationID: parseInt(props.match.params.conversationID),
      notes,
    };

    updateConversation(data);
    toggleNotesModal();
  };
  const handleEmitMessage = (message, type) => {
    if (message)
      socketRef.current.emit('conversations:message', {
        message,
        type,
      });
  };

  const handleActivateChatForConversation = () => {
    const data = {
      onAdminConversation: true,
      conversationID: parseInt(props.match.params.conversationID),
    };
    updateConversation(data);
  };

  const handleEndChatForConversation = () => {
    const data = {
      onAdminConversation: false,
      conversationID: parseInt(props.match.params.conversationID),
    };
    updateConversation(data);
  };

  const renderAttendClientDialog = () => {
    return (
      <Modal
        open={showConfirmAttendModal}
        closeModal={toogleConfirmAttendModal}
        dialogTitle="Atendiendo cliente"
        dialogText={`Está a punto de atender esta conversación, ¿Desea continuar?`}
        actionButtonText="Atender"
        onClick={handleAttendConversation}
      />
    );
  };

  const renderProspectDialog = () => {
    return (
      <Modal
        open={showProspectModal}
        closeModal={toogleProspectModal}
        dialogTitle="Cambiando a cliente prospecto"
        dialogText={`Está a punto de cambiar el cliente a un posible prospecto, ¿Desea continuar?`}
        actionButtonText="Cambiar a prospecto"
        onClick={handeProspectConversation}
      />
    );
  };

  const renderProspectLostDialog = () => {
    return (
      <Modal
        open={showProspectLostModal}
        closeModal={toogleProspectLostModal}
        dialogTitle="Cambiando a prospecto perdido"
        dialogText={`Está a punto de cambiar el prospecto a prospecto perdido, ¿Desea continuar?`}
        actionButtonText="Cambiar a prospecto perdido"
        onClick={handeProspectLost}
      />
    );
  };

  const renderInvoiceDialog = () => {
    return (
      <Modal
        open={showInvoiceModal}
        closeModal={toggleInvoiceModal}
        dialogTitle="Cambiando a venta facturada"
        dialogText={`Está a punto de cambiar el prospecto a venta facturada, ¿Desea continuar?`}
        actionButtonText="Cambiar a venta facturada"
        onClick={handleInvoice}
      />
    );
  };

  const renderCustomerLostDialog = () => {
    return (
      <Modal
        open={showCustomerLostModal}
        closeModal={toggleCustomerLostModal}
        dialogTitle="Cambiando a cliente perdido"
        dialogText={`Está a punto de cambiar el prospecto a cliente perdido, ¿Desea continuar?`}
        actionButtonText="Cambiar a cliente perdido"
        onClick={handleCustomerLost}
      />
    );
  };

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = conversations;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message, redirect } = conversations[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={() => handleCloseModals(redirect)}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={() => handleCloseModals(redirect)}
          />
        );
      }
      return null;
    } catch (e) {
      console.log('Modal error: ', e);
      handleCloseModals();
      return null;
    }
  };

  const renderClientFeedBackModal = () => {
    try {
      const { successModal, errorModal } = clients;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message, redirect } = clients[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={() => handleCloseModals(redirect)}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={() => handleCloseModals(redirect)}
          />
        );
      }
      return null;
    } catch (e) {
      console.log('Modal error: ', e);
      handleCloseModals();
      return null;
    }
  };

  const renderAdvisorsOptions = () => {
    const advisorsOptions = [
      <MenuItem key="none" disabled value="">
        Selecciona una opción
      </MenuItem>,
    ];
    if (Array.isArray(advisors) && advisors.length > 0) {
      advisors.forEach((advisor) => {
        advisorsOptions.push(
          <MenuItem key={advisor.id} value={advisor.id}>
            {advisor.user.name}
            <Badge
              className={classes.badges}
              variant={advisor.activated ? 'success' : 'danger'}
              style={{
                marginLeft: '15px',
              }}
            >
              {advisor.activated ? 'Activo' : 'Inactivo'}
            </Badge>
          </MenuItem>,
        );
      });
    }
    return advisorsOptions;
  };

  const renderAssaignConversationModal = () => {
    return (
      <Modal
        open={showAssignModal}
        closeModal={togleAssignModal}
        dialogTitle="Asignar conversación a asesor"
        actionButtonText="Asignar conversación"
        dialogChildren={
          <form
            id="update-form"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit(handleAssignConversation)}
          >
            <ShouldItRender
              locationPage={`/${location.pathname.split('/')[1]}`}
              action="selectAgency"
            >
              <FormControl
                variant="outlined"
                className={clsx(classes.textField)}
                fullWidth
              >
                <InputLabel id="label-agency">Agencia</InputLabel>
                <Controller
                  name="agencyID"
                  control={control}
                  defaultValue=""
                  as={
                    <Select
                      required
                      labelId="label-agency"
                      label="Agencia"
                      error={Boolean(errors.agencyID)}
                    >
                      <MenuItem disabled value="">
                        Selecciona una opción
                      </MenuItem>
                      {renderAgencyOptions()}
                    </Select>
                  }
                ></Controller>
              </FormControl>
            </ShouldItRender>
            <FormControl
              variant="outlined"
              className={clsx(classes.textField, classes.select)}
              fullWidth
            >
              <InputLabel id="label-adviser-type">
                Tipo de asesor
              </InputLabel>
              <Controller
                name="assessorType"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                as={
                  <Select
                    labelId="label-adviser-type"
                    label="Tipo de asesor"
                    error={Boolean(errors.assessorType)}
                  >
                    <MenuItem disabled value="">
                      Selecciona una opción
                    </MenuItem>
                    {Array.isArray(categories) &&
                    categories.length > 0
                      ? categories.map((category) => (
                          <MenuItem key={category} value={category}>
                            {category}
                          </MenuItem>
                        ))
                      : Object.keys(advisorTypes).map((key) => (
                          <MenuItem key={key} value={key}>
                            {advisorTypes[key]}
                          </MenuItem>
                        ))}
                  </Select>
                }
              />
            </FormControl>
            <FormControl
              variant="outlined"
              className={clsx(classes.textField, classes.select)}
              fullWidth
            >
              <InputLabel id="label-adviser-type">
                Seleccione el asesor
              </InputLabel>
              <Controller
                name="agencyUserID"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                as={
                  <Select
                    labelId="label-adviser-type"
                    label="Seleccione el asesor"
                    error={Boolean(errors.agencyUserID)}
                    disabled={!Boolean(assessorType)}
                  >
                    {renderAdvisorsOptions()}
                  </Select>
                }
              />
            </FormControl>
          </form>
        }
        form={'update-form'}
      />
    );
  };

  const renderUpdateClientInformationModal = () => {
    return (
      <Modal
        open={showEditClientModal}
        closeModal={togleClientModal}
        dialogTitle="Actualizar información del cliente"
        actionButtonText="Actualizar información"
        dialogChildren={
          <form
            id="update-client-info-form"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit(handleUpdateClient)}
          >
            <TextField
              inputRef={register({
                required: true,
              })}
              name="name"
              required
              fullWidth
              type="text"
              label="Nombre del cliente"
              variant="outlined"
              className={classes.textField}
              error={Boolean(errors.name)}
            />
            <TextField
              inputRef={register({
                required: true,
                maxLength: 10,
              })}
              name="phoneNumber"
              required
              fullWidth
              type="text"
              label="Teléfono del cliente"
              variant="outlined"
              className={classes.textField}
              error={Boolean(errors.phoneNumber)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    +52
                  </InputAdornment>
                ),
              }}
            />
          </form>
        }
        form={'update-client-info-form'}
      />
    );
  };

  const conversationInfo = () => {
    let origin = get(conversationDetail, 'origin', '') || 'Whatsapp';
    const source = get(conversationDetail, 'source', '');
    if (source) origin = `${origin} (${source})`;
    return (
      <Paper className={classes.paper}>
        <DialogTitle className={classes.title}>
          Conversación
        </DialogTitle>
        <Table bordered className={classes.tables}>
          <tbody>
            <tr>
              <td className={classes.tableTitle}>ESTATUS</td>
              <td>
                {
                  <Badge
                    style={{
                      backgroundColor: getBadgeColor(
                        get(conversationDetail, 'status'),
                      ),
                      color: '#fff',
                    }}
                  >
                    {get(conversationDetail, 'status')}
                    {/* {getStatusName(conversationDetail.status)} */}
                  </Badge>
                }
              </td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>FECHA</td>
              <td>
                {moment(get(conversationDetail, 'createdAt')).format(
                  'DD/MM/YYYY HH:mm',
                )}
              </td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>CATEGORÍA</td>
              <td>
                {capitalize(
                  getNested(
                    'n/a',
                    conversationDetail,
                    'customFlow',
                    'assessorType',
                  ),
                )}
              </td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>AGENCIA</td>
              <td>
                {getNested(
                  'n/a',
                  conversationDetail,
                  'agency',
                  'name',
                )}
              </td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>ORIGEN</td>
              <td>{origin}</td>
            </tr>
            {
              <tr>
                <td className={classes.tableTitle}>
                  TIEMPO DE RESPUESTA
                </td>
                <td>
                  {getResponseTime(
                    conversationDetail?.repliedAt,
                    conversationDetail?.createdAt,
                    conversationDetail?.assignedAt,
                    conversationDetail?.manuallyAssigned,
                  )}
                </td>
              </tr>
            }
            {/* {getNested(null, conversationDetail, 'score') && (
              <tr>
                <td className={classes.tableTitle}>CALIFICACIÓN</td>
                <td>
                  <Box component="fieldset" borderColor="transparent">
                    <Rating
                      name="read-only"
                      value={conversationDetail.score}
                      readOnly
                    />
                  </Box>
                </td>
              </tr>
            )} */}
          </tbody>
        </Table>
      </Paper>
    );
  };

  const advisorInfo = () => {
    return (
      <Paper className={classes.paper}>
        <DialogTitle className={classes.title}>Asesor</DialogTitle>
        <Table bordered className={classes.tables}>
          <tbody>
            <tr>
              <td className={classes.tableTitle}>NOMBRE</td>
              <td>
                {getNested(
                  'n/a',
                  conversationDetail,
                  'agencyUser',
                  'user',
                  'name',
                )}
              </td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>CATEGORÍA</td>
              <td>
                {getNested(
                  'n/a',
                  conversationDetail,
                  'agencyUser',
                  'type',
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </Paper>
    );
  };

  const notesModal = () => {
    return (
      <Modal
        open={showNotesModal}
        closeModal={toggleNotesModal}
        dialogTitle="Actualizar notas de la conversación"
        actionButtonText="Actualizar notas"
        dialogChildren={
          <form
            id="update-notes-info-form"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit(handleUpdateNotes)}
          >
            <TextField
              inputRef={register({
                required: true,
              })}
              name="notes"
              required
              fullWidth
              multiline
              rows={5}
              type="text"
              label="Notas"
              variant="outlined"
              className={classes.textField}
              error={Boolean(errors.notes)}
            />
          </form>
        }
        form={'update-notes-info-form'}
      />
    );
  };

  const renderNotes = () => {
    const notes = getNested('', conversationDetail, 'notes');
    return (
      <Paper className={classes.paper}>
        <div className={classes.clientTitleContainer}>
          <DialogTitle className={classes.title}>Notas</DialogTitle>
          <IconButton
            onClick={toggleNotesModal}
            className={classes.editClientButton}
          >
            <EditIcon />
          </IconButton>
        </div>
        <Table bordered className={classes.tables}>
          <tbody>
            <tr>
              <td style={{ whiteSpace: 'pre-wrap' }}>{notes}</td>
            </tr>
          </tbody>
        </Table>
      </Paper>
    );
  };
  const clientInfo = () => {
    const { info } = conversationDetail || {};

    return (
      <Paper className={classes.paper}>
        <div className={classes.clientTitleContainer}>
          <DialogTitle className={classes.title}>Cliente</DialogTitle>
          <IconButton
            onClick={togleClientModal}
            className={classes.editClientButton}
          >
            <EditIcon />
          </IconButton>
        </div>

        <Table bordered className={classes.tables}>
          <tbody>
            <tr>
              <td className={classes.tableTitle}>NOMBRE</td>
              <td>{get(conversationDetail, 'client.name')}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>TELÉFONO</td>
              <td>{get(conversationDetail, 'client.phoneNumber')}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>EMAIL</td>
              <td>{get(conversationDetail, 'client.email')}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>INTERÉS</td>
              <td>{get(conversationDetail, 'client.interest')}</td>
            </tr>
          </tbody>
        </Table>
      </Paper>
    );
  };

  return (
    <div>
      {renderInvoiceDialog()}
      {renderAttendClientDialog()}
      {renderProspectDialog()}
      {renderProspectLostDialog()}
      {renderCustomerLostDialog()}
      {renderFeedBackModal()}
      {renderClientFeedBackModal()}
      {renderAssaignConversationModal()}
      {renderUpdateClientInformationModal()}
      {notesModal()}
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          {conversationInfo()}
          {clientInfo()}
          {advisorInfo()}
          {renderNotes()}
        </Grid>
        <Grid item xs={12} md={8} className={classes.rightContainer}>
          {get(conversationDetail, 'messages', []).length > 0 && (
            <WhatsAppChat
              name={get(conversationDetail, 'client.name')}
              messages={get(conversationDetail, 'messages', [])}
              socketConnect={handleConnectSocket}
              activateConversation={handleActivateChatForConversation}
              endConversation={handleEndChatForConversation}
              onAdminConversation={get(
                conversationDetail,
                'onAdminConversation',
                false,
              )}
              sendMessage={handleEmitMessage}
              replyOnAdmin={get(
                conversationDetail,
                'agency.replyOnAdmin',
                false,
              )}
              handleUploadFiles={handleUploadFiles}
            />
          )}
          <Grid item>
            <RecentActivities logs={conversationLogs} />
          </Grid>
          {conversationDetail?.info && conversationDetail?.source && (
            <Grid item>
              <InfoDetails
                info={conversationDetail?.info}
                source={conversationDetail?.source}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  clients: selectClients(state),
  conversations: makeSelectConversations(state),
  conversationDetail: makeSelectConversationDetails(state),
  conversationLogs: makeSelectConversationsLogs(state),
  advisors: makeSelectUsersFromState(state),
  role: makeSelectUserRoleFromState(state),
  agenciesFromdb: makeSelectAgenciesFromdb(state),
  categories: makeSelectUserCategoriesFromState(state),
  token: makeSelectAuthTokenFromState(state),
  agencyDetails: makeSelectAgencyDetailsFromState(state),
});

const mapDispatchToProps = {
  getConversation,
  attendConversation,
  updateConversation,
  updateConversationClient,
  getUsers,
  assignConversation,
  closeModals,
  closeClientModals: closeClientModals,
};

export default withRouter(
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(ConversationDetail),
  ),
);
