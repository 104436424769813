import { createAction, createSlice } from '@reduxjs/toolkit';

import { initialState } from './initialState';

const cleanStore = createAction('auth/logout');
const changeAgency = createAction('auth/changeAgency');

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    getUsers: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getUsersSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.fromdb = action.payload.users;
        state.userDetails = null;
      },
    },
    getUsersFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.error = action.payload.error;
      },
    },
    getUsersCategories: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getUsersCategoriesSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.usersCategories = action.payload.categories;
      },
    },
    getUsersCategoriesFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.error = action.payload.error;
      },
    },
    getInvitations: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getInvitationsSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.invitations = action.payload.invitations;
      },
    },
    getInvitationsFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.error = action.payload.error;
      },
    },
    createAdvisor: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    createAdvisorSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    createAdvisorFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    updateAdvisor: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    updateAdvisorSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    updateAdvisorFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    updateMultipleAdvisors: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    updateMultipleAdvisorsSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    updateMultipleAdvisorsFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    assignAgencyUser: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    assignAgencyUserSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    assignAgencyUserFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.error = action.payload.error;
      },
    },
    updateUser: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    updateUserSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    updateUserFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.error = action.payload.error;
      },
    },
    deleteUser: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    deleteUserSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    deleteUserFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    deleteAgencyUser: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    deleteAgencyUserSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    deleteAgencyUserFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    resendInvitation: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    resendInvitationSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    resendInvitationFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    inviteAdvisor: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    inviteAdvisorSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    inviteAdvisorFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    emailUserExists: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    emailUserExistsSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    emailUserExistsFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    acceptInvitation: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    acceptInvitationSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    acceptInvitationFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    getUserDetail: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    getUserDetailSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.userDetails = action.payload.user;
      },
    },
    getUserDetailFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.error = action.payload.error;
      },
    },
    cleanUserDetail: {
      reducer(state, action) {
        state.userDetails = null;
      },
    },
    closeModals: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = initialState.successModal;
        state.errorModal = initialState.errorModal;
        state.deletedModal = false;
      },
    },
    resetStoreUsers: {
      reducer(state, action) {
        return initialState;
      },
    },
  },
  extraReducers: {
    [cleanStore]: (state, action) => {
      return initialState;
    },
    [changeAgency]: (state, action) => {
      return initialState;
    },
  },
});

export const {
  getUsers,
  getUsersSuccess,
  getUsersFail,
  getUsersCategories,
  getUsersCategoriesSuccess,
  getUsersCategoriesFail,
  getInvitations,
  getInvitationsSuccess,
  getInvitationsFail,
  updateUser,
  updateUserSuccess,
  updateUserFail,
  deleteUser,
  deleteUserSuccess,
  deleteUserFail,
  deleteAgencyUser,
  deleteAgencyUserSuccess,
  deleteAgencyUserFail,
  resendInvitation,
  resendInvitationSuccess,
  resendInvitationFail,
  emailUserExists,
  emailUserExistsSuccess,
  emailUserExistsFail,
  inviteAdvisor,
  inviteAdvisorSuccess,
  inviteAdvisorFail,
  acceptInvitation,
  acceptInvitationSuccess,
  acceptInvitationFail,
  getUserDetail,
  getUserDetailSuccess,
  getUserDetailFail,
  cleanUserDetail,
  closeModals,
  resetStoreUsers,
  createAdvisor,
  createAdvisorSuccess,
  createAdvisorFail,
  updateAdvisor,
  updateAdvisorSuccess,
  updateAdvisorFail,
  updateMultipleAdvisors,
  updateMultipleAdvisorsSuccess,
  updateMultipleAdvisorsFail,
  assignAgencyUser,
  assignAgencyUserSuccess,
  assignAgencyUserFail,
} = userSlice.actions;

export default userSlice.reducer;
