import 'moment/locale/es';

/* eslint-disable react/display-name */
import {
  Button,
  IconButton,
  Paper,
  makeStyles,
} from '@material-ui/core';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React, { useEffect, useRef, useState } from 'react';
import {
  filterCaseInsensitive,
  formatDate,
  getNested,
  menuOptionsMapped,
} from '../../../../utils';
import {
  makeSelectCampaignToDownload,
  makeSelectCampaignsFromdb,
  selectCampaigns,
} from '../../selectors';

import Add from '@material-ui/icons/Add';
import { CSVLink } from 'react-csv';
import Close from '@material-ui/icons/Close';
import FileCopyTwoTone from '@material-ui/icons/FileCopyTwoTone';
import { Link } from 'react-router-dom';
import MUIDatePickerFilter from 'app/components/MUIDatePickerFilter';
import MomentUtils from '@date-io/moment';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { getAllCampaigns } from '../../campaignSlice';
import { injectIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import { makeSelectUserFromState } from '../../../auth/selectors';
import moment from 'moment';
import { withRouter } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    widht: '100%',
    marginBottom: theme.spacing(2),
  },
  range: {
    paddingTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  datePickerTextfield: {
    padding: '5px 5px 5px !important',
    height: '20px',
  },
  datePicker: {
    width: 60,
  },
}));

const CampaignsTable = (props) => {
  const classes = useStyles();
  const {
    campaigns,
    user,
    getAllCampaigns,
    setActionButton,
    campaignsFromdb,
    campaignsToDownload,
    intl,
  } = props;
  const [firstDate, setFirstDate] = useState(null);
  const [secondDate, setSecondDate] = useState(null);
  const [campaignsToExport, setCampaignsToExport] = useState([]);
  const reactTable = useRef(null);

  const handleFetchData = debounce(
    (state, instance) => {
      const { page, pageSize, filtered } = state;
      getAllCampaigns({
        firstDate,
        secondDate,
        page,
        pageSize,
        filtered,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
    300,
    { leading: false, trailing: true },
  );

  useEffect(() => {
    renderButtons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignsToExport, firstDate, secondDate]);

  useEffect(() => {
    if (firstDate && secondDate)
      reactTable.current && reactTable.current.fireFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstDate, secondDate]);

  useEffect(() => {
    reactTable.current && reactTable.current.fireFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    let campaignsToExport = [];
    if (
      Array.isArray(campaignsToDownload) &&
      campaignsToDownload.length > 0
    ) {
      campaignsToExport = campaignsToDownload.map((campaign) => {
        let initializedConversations = 0;
        campaign.campaignClicks.forEach((click) => {
          const conversationStatus = getNested(
            null,
            click,
            'conversation',
            'status',
          );
          if (
            conversationStatus === 'Conversacion con Asesor iniciada'
          )
            initializedConversations++;
        });
        return {
          'Nombre de campaña': getNested('n/a', campaign, 'name'),
          Categoría: getNested('n/a', campaign, 'customFlow', 'name'),
          'Número de clicks': getNested(
            [],
            campaign,
            'campaignClicks',
          ).length,
          'Conversaciones iniciadas': initializedConversations,
          'Fecha de creación': moment(campaign.createdAt).format(
            'DD/MM/YYYY HH:mm',
          ),
        };
      });
      setCampaignsToExport(campaignsToExport);
    }
    return () => {
      setCampaignsToExport([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignsToDownload]);

  const renderButtons = () => {
    setActionButton(
      <>
        <MuiPickersUtilsProvider utils={MomentUtils} locale={'es'}>
          <DatePicker
            disableToolbar
            variant="inline"
            label="Inicial"
            format="DD/MM"
            className={classes.datePicker}
            value={firstDate}
            onChange={handleFirstDate}
            InputProps={{
              endAdornment: firstDate ? (
                <IconButton
                  aria-label="Select locale"
                  size="small"
                  onClick={() => handleFirstDate(null)}
                >
                  <Close />
                </IconButton>
              ) : null,
            }}
          />
          <span className={classes.range}>&nbsp;A&nbsp;</span>
          <DatePicker
            disableToolbar
            variant="inline"
            label="Final"
            format="DD/MM"
            className={classes.datePicker}
            value={secondDate}
            onChange={handleSecondDate}
            InputProps={{
              endAdornment: secondDate ? (
                <IconButton
                  aria-label="Select locale"
                  size="small"
                  onClick={() => handleSecondDate(null)}
                >
                  <Close />
                </IconButton>
              ) : null,
            }}
          />
        </MuiPickersUtilsProvider>
        <CSVLink
          data={campaignsToExport}
          filename="tabla_de_conversaciones.csv"
          onClick={(event) => {
            return campaignsToExport.length > 0;
          }}
        >
          <Button
            variant="contained"
            className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
            disabled={Boolean(campaignsToExport.length <= 0)}
          >
            Exportar
            <FileCopyTwoTone className="kt-margin-l-10" />
          </Button>
        </CSVLink>
        <Link to={'/create-campaign'}>
          <Button
            variant="contained"
            className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
          >
            Crear campaña
            <Add className="kt-margin-l-10" />
          </Button>
        </Link>
      </>,
    );
  };

  const handleFirstDate = (date) => {
    setFirstDate(date);
  };

  const handleSecondDate = (date) => {
    setSecondDate(date);
  };

  const colums = [
    {
      Header: () => <strong>Nombre de campaña</strong>,
      accessor: 'name',
      Cell: (row) => (
        <Link to={`/detail-campaign/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>Categoría</strong>,
      accessor: 'customFlow.name',
      Cell: (row) => (
        <Link to={`/detail-campaign/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value
              ? row.value
              : menuOptionsMapped[row.original.type]}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>Num. de clicks</strong>,
      accessor: 'campaignClicks',
      Cell: (row) => (
        <Link to={`/detail-campaign/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {!isEmpty(row.value) ? row.value.length : 0}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Fecha de creación</strong>,
      accessor: 'createdAt',
      Cell: (row) => (
        <Link to={`/detail-campaign/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {formatDate(row.value)}
          </div>
        </Link>
      ),
      Filter: (cell) => {
        return <MUIDatePickerFilter cell={cell} />;
      },
    },
  ];

  const handleGetCampaigns = () => {
    if (Array.isArray(campaignsFromdb)) return campaignsFromdb;
    return [];
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <ReactTable
          NoDataComponent={() => (
            <div className="rt-noData">Sin resultados</div>
          )}
          ref={reactTable}
          manual
          onFetchData={handleFetchData}
          data={handleGetCampaigns()}
          columns={colums}
          pages={campaigns.tablePages}
          filterable
          defaultFilterMethod={filterCaseInsensitive}
          className="-striped -highlight"
          previousText="Anterior"
          nextText="Siguiente"
          loading={campaigns.isLoading}
          loadingText="Cargando datos..."
        />
      </Paper>
    </div>
  );
};

const mapStateToProps = (state) => ({
  campaigns: selectCampaigns(state),
  campaignsFromdb: makeSelectCampaignsFromdb(state),
  campaignsToDownload: makeSelectCampaignToDownload(state),
  user: makeSelectUserFromState(state),
});

const mapDispatchToProps = {
  getAllCampaigns,
};

export default withRouter(
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(CampaignsTable),
  ),
);
