import { Switch } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/styles';

export const GreenRedSwitch = withStyles({
  switchBase: {
    color: '#fd397a',
  },
  checked: {
    '&$checked': {
      color: '#0abb87',
    },
    '&$checked + $track': {
      backgroundColor: green[500],
    },
  },
  track: {},
})(Switch);
