import {
  Button,
  Card,
  CardContent,
  DialogTitle,
  Grid,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import {
  closeModals,
  createEventShipment,
  getEvent,
  updateEvent,
} from 'app/features/events/eventsSlice';
import {
  makeSelectEventsDetails,
  selectEvents,
} from 'app/features/events/selectors';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ButtonImportPhoneNumbers from 'app/components/ButtonImportPhoneNumbers';
import ChipInput from 'material-ui-chip-input';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { MenuOption } from 'app/components/MenuOption';
import Modal from '../../../../components/Modal';
import MomentUtils from '@date-io/moment';
import RecentActivities from '../../../../components/RecentActivities';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SendIcon from '@material-ui/icons/Send';
import SplashScreen from '../../../../partials/layout/SplashScreen';
import { TabPanel } from 'app/components/TabPanel';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getNested } from '../../../../utils';
import { getUsers } from '../../../users/userSlice';
import { injectIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import { makeSelectAgenciesFromdb } from 'app/features/agencies/selectors';
import { makeSelectEventsLogs } from '../../../logs/selectors';
import { makeSelectUserRoleFromState } from 'app/features/auth/selectors';
import moment from 'moment';
import { withRouter } from 'react-router';

const useStyles = makeStyles((theme) => ({
  paper: {
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  paperTarjeta: {
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  title: {
    textAlign: 'left',
    color: '#1c1c1c',
  },
  textField: {
    margin: theme.spacing(1, 0, 1, 0),
  },
  rightContainer: {
    flexDirection: 'column',
  },
  tableTitle: {
    fontWeight: 401,
  },
  tables: {
    width: '100%',
  },
  tabsMinimalWidth: {
    minWidth: '72px',
  },
  cardRoot: {
    padding: '0px 7px 5px 7px',
  },
  cardContent: {
    padding: '5px 7px 5px 7px',
    paddingBottom: '0px !important',
  },
  buttonImportExcel: {
    marginBottom: theme.spacing(2),
  },
}));

function EventDetail(props) {
  const classes = useStyles();
  const {
    events,
    eventDetails,
    clientLogs,
    getEvent,
    setLeftSideComponent,
    setActionButton,
    updateEvent,
    getUsers,
    createEventShipment,
    role,
    closeModals,
  } = props;
  const [
    showScheduleShipmentModal,
    setShowScheduleShipmentModal,
  ] = useState(false);
  const [tab, setTab] = React.useState(0);
  const [
    showSendToValidateModal,
    setShowSendToValidateModal,
  ] = useState(false);
  const [
    showValidateEventModal,
    setShowValidateEventModal,
  ] = useState(false);
  const eventID = parseInt(props.match.params.eventID);

  const { handleSubmit, control, watch, setValue } = useForm({
    defaultValues: { launchDate: moment() },
  });

  const assessorType = watch('assessorType');
  const agencyID = watch('agencyID');
  const launchDate = watch('launchDate');
  const values = watch();

  useEffect(() => {
    setLeftSideComponent(
      <div className="kt-subheader__main">
        <div>
          <h3 className="kt-subheader__title">Detalle del evento</h3>
        </div>
      </div>,
    );
    const data = {
      id: eventID,
    };
    getEvent(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setActionButton(
      <>
        <MenuOption
          Button={
            <Button className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10">
              Acciones
              <ArrowDropDownIcon className="kt-margin-l-10" />
            </Button>
          }
          options={[
            {
              name: (
                <>
                  <SendIcon />
                  &nbsp;&nbsp;&nbsp;ENVIAR A REVISIÓN
                </>
              ),
              onClick: toggleSendToValidateEventModal,
              props: {
                disabled: eventDetails?.status === 'pendiente',
              },
              shouldItRender: {
                should:
                  eventDetails?.status !== 'validado' &&
                  role !== 'Super Admin',
              },
            },
            {
              name: (
                <>
                  <DoneAllIcon />
                  &nbsp;&nbsp;&nbsp;VALIDAR EVENTO
                </>
              ),
              onClick: toggleValidateEventModal,
              // props: {
              //   disabled: eventDetails?.status !== 'pendiente',
              // },
              shouldItRender: {
                should: role === 'Super Admin',
              },
            },
            {
              name: (
                <>
                  <ScheduleIcon />
                  &nbsp;&nbsp;&nbsp;PROGRAMAR ENVÍO
                </>
              ),
              onClick: toggleScheduleShipmentModal,
              props: {
                disabled: eventDetails?.status !== 'validado',
              },
            },
          ]}
        />
      </>,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventDetails]);

  useEffect(() => {
    if (assessorType && agencyID)
      getUsers({ type: assessorType, agencyID });
    else if (assessorType) getUsers({ type: assessorType });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessorType, agencyID]);

  const toggleScheduleShipmentModal = () =>
    setShowScheduleShipmentModal(!showScheduleShipmentModal);

  const toggleSendToValidateEventModal = () =>
    setShowSendToValidateModal(!showSendToValidateModal);

  const toggleValidateEventModal = () =>
    setShowValidateEventModal(!showValidateEventModal);

  const handleCloseModals = () => {
    closeModals({});
  };

  const handleCreateEventShiptment = (formData) => {
    const { phoneNumbers, ...rest } = formData;
    const data = {
      id: eventID,
      ...rest,
      phoneList: phoneNumbers,
      status: 'pendiente',
    };
    createEventShipment(data);
    setShowScheduleShipmentModal(false);
  };

  const handleSendToValidateEvent = () => {
    const data = {
      id: eventID,
      status: 'pendiente',
    };
    updateEvent(data);
    setShowSendToValidateModal(false);
  };

  const handleValidateEvent = () => {
    const data = {
      id: eventID,
      status: 'validado',
    };
    updateEvent(data);
    setShowValidateEventModal(false);
  };

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const handleAddPhoneNumber = (phoneNumbersArray, phoneNumber) => {
    const newphoneNumbers = [...phoneNumbersArray];
    newphoneNumbers.push(phoneNumber);
    return newphoneNumbers;
  };

  const handleDeletePhoneNumber = (phoneNumbersArray, index) => {
    const newphoneNumbers = [...phoneNumbersArray];
    newphoneNumbers.splice(index, 1);
    return newphoneNumbers;
  };

  const renderSendToValidateEvent = () => {
    return (
      <Modal
        open={showSendToValidateModal}
        closeModal={toggleSendToValidateEventModal}
        dialogTitle="Enviar evento a revisión"
        dialogText="El evento será enviado para su revisón para ser validado"
        actionButtonText="Enviar evento"
        onClick={handleSendToValidateEvent}
      />
    );
  };

  const renderViewEventModal = () => {
    return (
      <Modal
        open={showValidateEventModal}
        closeModal={toggleValidateEventModal}
        onClick={handleValidateEvent}
        dialogTitle="Validar evento"
        dialogText="¿Desea validar el evento?"
        actionButtonText="Validar evento"
      />
    );
  };

  const renderUpdateClientInformationModal = () => {
    return (
      <Modal
        open={showScheduleShipmentModal}
        closeModal={toggleScheduleShipmentModal}
        dialogTitle="Programar envío"
        actionButtonText="Programar envío"
        dialogChildren={
          <form
            id="update-client-info-form"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit(handleCreateEventShiptment)}
          >
            <ButtonImportPhoneNumbers
              buttonName="Cargar números de celular desde excel"
              setExcelNumbers={(parsedPhoneNumbers) =>
                setValue('phoneNumbers', parsedPhoneNumbers)
              }
              classNameButton={classes.buttonImportExcel}
            />
            <Controller
              as={
                <ChipInput
                  fullWidth
                  className={classes.textField}
                  name="phoneNumbers"
                  label="Destinatarios"
                  placeholder="335663636, 3636363636"
                  value={values.phoneNumbers}
                  variant="outlined"
                  newChipKeyCodes={[13, 32, 188]}
                  onAdd={(receiver) => {
                    setValue(
                      'phoneNumbers',
                      handleAddPhoneNumber(
                        values.phoneNumbers,
                        receiver,
                      ),
                    );
                  }}
                  onDelete={(_, index) => {
                    setValue(
                      'phoneNumbers',
                      handleDeletePhoneNumber(
                        values.phoneNumbers,
                        index,
                      ),
                    );
                  }}
                />
              }
              control={control}
              name="phoneNumbers"
            />
            <Controller
              control={control}
              name="launchDate"
              defaultValue={null}
              rules={{ required: true }}
              as={
                <MuiPickersUtilsProvider
                  utils={MomentUtils}
                  locale={'es'}
                >
                  <KeyboardDateTimePicker
                    autoOk
                    fullWidth
                    disablePast
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    className={classes.textField}
                    label="Fecha de envío"
                    value={launchDate}
                    onChange={(e) => setValue('launchDate', e, false)}
                    format="DD/MM/yyyy HH:mm"
                    ampm={false}
                    invalidDateMessage="Fecha inválida"
                    invalidLabel="Fecha inválida"
                    helperText="Ingresa la hora en formato de 24hrs"
                  />
                </MuiPickersUtilsProvider>
              }
            />
          </form>
        }
        form={'update-client-info-form'}
      />
    );
  };

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = events;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message, redirect } = events[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={() => handleCloseModals(redirect)}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={() => handleCloseModals(redirect)}
          />
        );
      }
      return null;
    } catch (e) {
      console.log('Modal error: ', e);
      handleCloseModals();
      return null;
    }
  };

  const conversationInfo = () => {
    return (
      <Paper className={classes.paperTarjeta}>
        <DialogTitle className={classes.title}>Evento</DialogTitle>
        <Table bordered className={classes.tables}>
          <tbody>
            <tr>
              <td className={classes.tableTitle}>NOMBRE</td>
              <td>{getNested('-', eventDetails, 'name')}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>STATUS</td>
              <td>{getNested('-', eventDetails, 'status')}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>MENSAJE</td>
              <td>{getNested('-', eventDetails, 'message')}</td>
            </tr>
          </tbody>
        </Table>
      </Paper>
    );
  };

  const handleRenderEventShipments = () => {
    const eventShipmnents = getNested(
      null,
      eventDetails,
      'eventShipments',
    );
    if (Array.isArray(eventShipmnents) && eventShipmnents.length > 0)
      return (
        <Table bordered className={classes.tables}>
          <thead>
            <tr>
              <th>ID</th>
              <th># DESTINATARIOS</th>
              <th>FECHA DE ENVÍO</th>
              <th>STATUS</th>
            </tr>
          </thead>
          <tbody>
            {eventShipmnents.map((eventShipment) => {
              const phoneList = getNested(
                [],
                eventShipment,
                'phoneList',
              );
              const phoneNumbers = phoneList.join(', ');
              return (
                <tr key={`event-shipment${eventShipment.id}`}>
                  <td>{eventShipment.id}</td>
                  <td>
                    <Tooltip title={phoneNumbers}>
                      <Typography variant="body1">
                        {phoneList.length}
                      </Typography>
                    </Tooltip>
                  </td>
                  <td>
                    {moment(eventShipment.launchDate).format(
                      'DD/MM/YYYY HH:mm',
                    )}
                  </td>
                  <td>{eventShipment.status}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      );
    else
      return (
        <Typography variant="h6" gutterBottom align="center">
          No existen envíos para este evento
        </Typography>
      );
  };

  return isEmpty(eventDetails) ? (
    <SplashScreen />
  ) : (
    <>
      {renderViewEventModal()}
      {renderUpdateClientInformationModal()}
      {renderSendToValidateEvent()}
      {renderFeedBackModal()}
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          {conversationInfo()}
        </Grid>
        <Grid item xs={12} md={8} className={classes.rightContainer}>
          <Card className={classes.cardRoot}>
            <CardContent className={classes.cardContent}>
              <Tabs
                value={tab}
                onChange={handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab
                  className={classes.tabsMinimalWidth}
                  label="Envíos"
                />
                <Tab
                  className={classes.tabsMinimalWidth}
                  label="Actividad"
                />
              </Tabs>
              <TabPanel value={tab} index={0} padding={1}>
                {handleRenderEventShipments()}
              </TabPanel>
              <TabPanel value={tab} index={1} padding={1}>
                <RecentActivities logs={clientLogs} noHeader />
              </TabPanel>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({
  events: selectEvents(state),
  eventDetails: makeSelectEventsDetails(state),
  eventsLogs: makeSelectEventsLogs(state),
  role: makeSelectUserRoleFromState(state),
  agenciesFromdb: makeSelectAgenciesFromdb(state),
});

const mapDispatchToProps = {
  getEvent,
  updateEvent,
  createEventShipment,
  getUsers,
  closeModals,
};

export default withRouter(
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(EventDetail),
  ),
);
