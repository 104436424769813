import React, { useEffect } from 'react';
import {
  Redirect,
  Route,
  Switch,
  withRouter,
} from 'react-router-dom';
import { getMainRoute, getNested } from './utils';
import { shallowEqual, useSelector } from 'react-redux';

import AuthPage from './features/auth/container/AuthPage';
import ErrorsPage from './pages/errors/ErrorsPage';
import { LayoutContextProvider } from '../_metronic';
import LogoutPage from './features/auth/components/Logout';
import RoutesControllers from './RoutesControllers';
import { tawkToService } from './services/tawkToService';

export const Routes = withRouter(({ Layout, history, location }) => {
  const { isAuthorized, menuConfig, role, user } = useSelector(
    ({ auth, builder: { menuConfig } }) => ({
      menuConfig,
      isAuthorized: auth.user != null,
      role: auth.user && auth.user.role,
      user: auth.user,
    }),
    shallowEqual,
  );

  const [actionButton, setActionButton] = React.useState(null);
  const [leftSideComponent, setLeftSideComponent] = React.useState(
    null,
  );

  const cleanSubheaderComponents = () => {
    setActionButton(null);
    setLeftSideComponent(null);
  };

  useEffect(() => {
    cleanSubheaderComponents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    tawkToService(
      '5e1e11b627773e0d832d8a97/1duioo7qv',
      `${getNested('Visitante', user, 'name')} - Karbot`,
      getNested(null, user, 'email'),
      () => {},
    );
  }, [user]);

  const handleRoutes = () => {
    return Object.keys(RoutesControllers).map((route) => {
      const Component = RoutesControllers[route];
      // const routes = getRouteByRoles(role);
      // return Object.keys(routes).map(route => {
      //   const Component = routes[route];
      return (
        <Route
          exact
          key={route}
          path={route}
          render={(routeProps) => (
            <Layout
              actionButton={actionButton}
              leftSideComponent={leftSideComponent}
            >
              <Component
                setActionButton={setActionButton}
                setLeftSideComponent={setLeftSideComponent}
                {...routeProps}
              ></Component>
            </Layout>
          )}
        />
      );
    });
  };

  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <LayoutContextProvider history={history} menuConfig={menuConfig}>
      <Switch>
        {!isAuthorized ||
        location.pathname.includes('auth/confirm-email') ? (
          /* Render auth page when user at `/auth` and not authorized. */
          <Route path="/auth" component={AuthPage} />
        ) : (
          /* Otherwise redirect to root page (`/`) */
          <Redirect from="/auth" to={getMainRoute(role)} />
        )}

        <Route path="/error" component={ErrorsPage} />
        <Route path="/logout" component={LogoutPage} />

        {!isAuthorized ||
        location.pathname.includes('auth/confirm-email') ? (
          /* Redirect to `/auth` when user is not authorized */
          <Redirect to="/auth" />
        ) : (
          handleRoutes()
        )}
        <Redirect to="/error/error-v1" />
      </Switch>
    </LayoutContextProvider>
  );
});
