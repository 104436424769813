import { agenciesActionTypes } from './actionTypes';

export function getAgencies() {
  return {
    type: agenciesActionTypes.GetAgencies,
  };
}
export function getAgenciesSuccess(payload) {
  return {
    type: agenciesActionTypes.GetAgenciesSuccess,
    payload,
  };
}
export function getAgenciesFail(payload) {
  return {
    type: agenciesActionTypes.GetAgenciesFailure,
    payload,
  };
}

export function createAgency(payload) {
  return {
    type: agenciesActionTypes.CreateAgency,
    payload,
  };
}

export function createAgencySuccess(payload) {
  return {
    type: agenciesActionTypes.CreateAgencySuccess,
    payload,
  };
}

export function createAgencyFail(payload) {
  return {
    type: agenciesActionTypes.CreateAgencyFail,
    payload,
  };
}

export function updateAgency(payload) {
  return {
    type: agenciesActionTypes.UpdateAgency,
    payload,
  };
}

export function updateAgencySuccess(payload) {
  return {
    type: agenciesActionTypes.UpdateAgencySuccess,
    payload,
  };
}

export function updateAgencyFail(payload) {
  return {
    type: agenciesActionTypes.UpdateAgencyFailure,
    payload,
  };
}

export function updateOption(payload) {
  return {
    type: agenciesActionTypes.UpdateOptionAgency,
    payload,
  };
}

export function updateOptionSuccess(payload) {
  return {
    type: agenciesActionTypes.UpdateOptionAgencySuccess,
    payload,
  };
}

export function updateOptionFail(payload) {
  return {
    type: agenciesActionTypes.UpdateOptionAgencyFail,
    payload,
  };
}

export function getAgencyDetail(payload) {
  return {
    type: agenciesActionTypes.GetAgencyDetail,
    payload,
  };
}

export function getAgencyDetailSuccess(payload) {
  return {
    type: agenciesActionTypes.GetAgencyDetailSuccess,
    payload,
  };
}

export function getAgencyDetailFail(payload) {
  return {
    type: agenciesActionTypes.GetAgencyDetailFailure,
    payload,
  };
}

export function updloadLogo(logo) {
  return {
    type: agenciesActionTypes.UploadAgencyLogo,
    payload: { logo },
  };
}

export function updloadLogoSuccess(payload) {
  return {
    type: agenciesActionTypes.UploadAgencyLogoSuccess,
    payload,
  };
}

export function updloadLogoFail(payload) {
  return {
    type: agenciesActionTypes.UploadAgencyLogoFailure,
    payload,
  };
}

export function closeModals() {
  return {
    type: agenciesActionTypes.CloseModals,
  };
}

export function resetStoreAgencies() {
  return {
    type: agenciesActionTypes.ResetStore,
  };
}
