import { agenciesActionTypes } from './actionTypes';
import createReducer from '../../utils/createReducer';
import { initialState } from './initialState';

export const agencyReducer = createReducer(initialState, {
  [agenciesActionTypes.GetAgencies](state, action) {
    return { ...state, isLoading: true, error: null };
  },
  [agenciesActionTypes.GetAgenciesSuccess](state, action) {
    return {
      ...state,
      isLoading: false,
      fromdb: action.payload.agencies,
    };
  },
  [agenciesActionTypes.GetAgenciesFailure](state, action) {
    return {
      ...state,
      isLoading: false,
      error: action.payload.error,
    };
  },
  [agenciesActionTypes.CreateAgency](state, action) {
    return { ...state, isLoading: true, error: null };
  },
  [agenciesActionTypes.CreateAgencySuccess](state, action) {
    return {
      ...state,
      isLoading: false,
      successModal: {
        show: true,
        message: action.payload.message,
      },
    };
  },
  [agenciesActionTypes.CreateAgencyFail](state, action) {
    return {
      ...state,
      isLoading: false,
      errorModal: {
        show: true,
        message: action.payload.message,
      },
    };
  },
  [agenciesActionTypes.UpdateAgency](state, action) {
    return { ...state, isLoading: true, error: null };
  },
  [agenciesActionTypes.UpdateAgencySuccess](state, action) {
    return {
      ...state,
      isLoading: false,
      agencyDetails: {
        ...state.agencyDetails,
        ...action.payload.agency,
      },
      successModal: {
        show: true,
        message: action.payload.message,
      },
    };
  },
  [agenciesActionTypes.UpdateAgencyFailure](state, action) {
    return {
      ...state,
      isLoading: false,
      errorModal: {
        show: true,
        message: action.payload.message,
      },
    };
  },
  [agenciesActionTypes.GetAgencyDetail](state, action) {
    return { ...state, isLoading: true };
  },
  [agenciesActionTypes.GetAgencyDetailSuccess](state, action) {
    return {
      ...state,
      isLoading: false,
      agencyDetails: action.payload.agency,
    };
  },
  [agenciesActionTypes.GetAgencyDetailFailure](state, action) {
    return {
      ...state,
      isLoading: false,
      errorModal: {
        show: true,
        message: action.payload.message,
      },
    };
  },
  [agenciesActionTypes.UpdateOptionAgencySuccess](state, action) {
    return {
      ...state,
      isLoading: false,
      agencyDetails: action.payload.agency,
      successModal: {
        show: true,
        message: action.payload.message,
      },
    };
  },
  [agenciesActionTypes.UpdateOptionAgencyFail](state, action) {
    return {
      ...state,
      isLoading: false,
      errorModal: {
        show: true,
        message: action.payload.message,
      },
    };
  },
  [agenciesActionTypes.UploadAgencyLogo](state, action) {
    return { ...state, isLoading: true };
  },
  [agenciesActionTypes.UploadAgencyLogoSuccess](state, action) {
    return {
      ...state,
      isLoading: false,
      logoUploaded: true,
      logoURL: action.payload.url,
    };
  },
  [agenciesActionTypes.UploadAgencyLogoFailure](state, action) {
    return {
      ...state,
      isLoading: false,
      logoUploaded: false,
      errorModal: {
        show: true,
        message: action.payload.message,
      },
    };
  },
  [agenciesActionTypes.CloseModals](state, action) {
    return {
      ...state,
      successModal: initialState.successModal,
      errorModal: initialState.errorModal,
      logoUploaded: false,
      logoURL: null,
    };
  },
  [agenciesActionTypes.ResetStore](state, action) {
    return {
      ...initialState,
    };
  },
  'auth/logout'(state, action) {
    return {
      ...initialState,
    };
  },
  'auth/changeAgency'(state, action) {
    return {
      ...initialState,
    };
  },
});
