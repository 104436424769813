export const agenciesActionTypes = {
  GetAgencies: 'GET_AGENCIES',
  GetAgenciesSuccess: 'GET_AGENCIES_SUCCESS',
  GetAgenciesFailure: 'GET_AGENCIES_FAILURE',
  CreateAgency: 'CREATE_AGENCY',
  CreateAgencySuccess: 'CREATE_AGENCY_SUCCESS',
  CreateAgencyFail: 'CREATE_AGENCY_FAILURE',
  GetAgencyDetail: 'GET_AGENCY_DETAIL',
  GetAgencyDetailSuccess: 'GET_AGENCY_DETAIL_SUCCESS',
  GetAgencyDetailFailure: 'GET_AGENCY_DETAIL_FAILURE',
  UpdateAgency: 'UPDATE_AGENCY',
  UpdateAgencySuccess: 'UPDATE_AGENCY_SUCCESS',
  UpdateAgencyFailure: 'UPDATE_AGENCY_FAILURE',
  UpdateOptionAgency: 'UPDATE_OPTION_AGENCY',
  UpdateOptionAgencySuccess: 'UPDATE_OPTION_AGENCY_SUCCESS',
  UpdateOptionAgencyFail: 'UPDATE_OPTION_AGENCY_FAILURE',
  UploadAgencyLogo: 'UPLOAD_AGENCY_LOGO',
  UploadAgencyLogoSuccess: 'UPLOAD_AGENCY_LOGO_SUCCESS',
  UploadAgencyLogoFailure: 'UPLOAD_AGENCY_LOGO_FAILURE',
  CloseModals: 'CLOSE_MODALS',
  ResetStore: 'RESET_STORE_AGENCIES',
};
