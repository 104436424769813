import React, { useEffect, useState } from 'react';

import { Autocomplete } from '@material-ui/lab';
import { Controller } from 'react-hook-form';
import { TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import { getAgencies } from 'app/features/agencies/actions';
import { makeSelectAgenciesFromdb } from 'app/features/agencies/selectors';

function MUIRHFAutocompleteAgencies(props) {
  const { agenciesFromdb, getAgencies, className, ...rest } = props;

  const [agenciesOptions, setAgenciesOptions] = useState([]);

  useEffect(() => {
    getAgencies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Array.isArray(agenciesFromdb)) {
      const cleanAgencies = agenciesFromdb.map((agency) => {
        return {
          id: agency.id,
          label: agency.name,
        };
      });
      setAgenciesOptions(cleanAgencies);
    }
  }, [agenciesFromdb]);

  const getOpObj = (option) => {
    if (!option.id)
      option = agenciesOptions.find((op) => op.id === option);
    return option;
  };

  return (
    <Controller
      {...rest}
      render={(props) => (
        <Autocomplete
          {...props}
          className={className}
          getOptionLabel={(option) => getOpObj(option).label}
          getOptionSelected={(option, value) =>
            option.id === value.id
          }
          options={agenciesOptions}
          renderInput={(params) => (
            <TextField
              {...params}
              label={'Agencia'}
              variant="outlined"
            />
          )}
          onChange={(_, data) => {
            if (data) props.onChange(data.id);
            else props.onChange('');
          }}
        />
      )}
    />
  );
}

const mapStateToProps = (state) => ({
  agenciesFromdb: makeSelectAgenciesFromdb(state),
});

const mapDispatchToProps = {
  getAgencies: getAgencies,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MUIRHFAutocompleteAgencies);
