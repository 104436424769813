import { Col, Row, Table } from 'react-bootstrap';
import {
  Grid,
  IconButton,
  Paper,
  TextField,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { closeModals, getCampaignDetails } from '../../campaignSlice';
import {
  makeSelectCampaignDetails,
  selectCampaigns,
} from '../../selectors';

import { CopyToClipboard } from '../../../../components/CopyToClipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Modal from '../../../../components/Modal';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import facebookLogo from '../../../../assets/img/logos/facebook.png';
import { getNested } from '../../../../utils';
import { injectIntl } from 'react-intl';
import instagramLogo from '../../../../assets/img/logos/instagram.png';
import twitterLogo from '../../../../assets/img/logos/twitter.png';

const mapLogos = {
  fb: facebookLogo,
  instagram: instagramLogo,
  twitter: twitterLogo,
};

const useStyles = makeStyles((theme) => ({
  container: {
    flexWrap: 'wrap',
  },
  paper: {
    padding: theme.spacing(7, 10, 1, 10),
  },
  textField: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  subtitle: {
    marginBottom: theme.spacing(4.5),
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  card: {
    width: '85%',
    margin: theme.spacing(1, 1, 1, 1),
  },
  CardContent: {
    padding: '10px !important',
  },
  margiTop: {
    marginTop: theme.spacing(2),
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  alignByColumnMedia: {
    flexFlow: 'column',
    alignItems: 'center',
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
}));

function DetailCampaign(props) {
  const classes = useStyles();
  const {
    campaigns,
    campaignDetails,
    getCampaignDetails,
    closeModals,
    history,
    match,
    intl,
  } = props;

  useEffect(() => {
    props.setLeftSideComponent(
      <div className="kt-subheader__main">
        <div>
          <h3 className="kt-subheader__title">Detalle de campaña</h3>
        </div>
      </div>,
    );
    const campaignID = match.params.campaignID;
    if (campaignID) getCampaignDetails({ campaignID });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseModals = (redirect = null) => {
    closeModals({ history, redirect });
  };

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = campaigns;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message, redirect } = campaigns[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={() => handleCloseModals(redirect)}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={() => handleCloseModals(redirect)}
          />
        );
      }
      return null;
    } catch (e) {
      console.log('Modal error: ', e);
      handleCloseModals();
      return null;
    }
  };

  const renderTableLinksDetails = () => {
    const medias = getNested([], campaignDetails, 'media');
    return medias.map((media) => {
      return (
        <tr key={media}>
          <td>
            {media in mapLogos ? (
              <img
                src={mapLogos[media]}
                width="100"
                alt="facebook"
              ></img>
            ) : (
              <b>
                {intl.formatMessage({
                  id: `CAMPAIGN.MEDIA.${media}`,
                })}
              </b>
            )}
          </td>
          <td>
            <Row>
              <Col xs sm>
                <span>
                  {`${getNested(
                    '-',
                    campaignDetails,
                    'link',
                  )}&media=${media}`}
                </span>
              </Col>
              <Col xs={2} sm={2}>
                <CopyToClipboard
                  textToCopy={`${getNested(
                    '-',
                    campaignDetails,
                    'link',
                  )}&media=${media}`}
                >
                  <IconButton
                    color="primary"
                    aria-label="copiar url"
                    component="span"
                    size="medium"
                    className={classes.marginLeft}
                  >
                    <FileCopyIcon />
                  </IconButton>
                </CopyToClipboard>
              </Col>
            </Row>
          </td>
          <td>
            <Row>
              <Col xs sm>
                <span>
                  {`${getNested(
                    '-',
                    campaignDetails,
                    'fbLink',
                  )}&media=${media}`}
                </span>
              </Col>
              <Col xs={2} sm={2}>
                <CopyToClipboard
                  textToCopy={`${getNested(
                    '-',
                    campaignDetails,
                    'fbLink',
                  )}&media=${media}`}
                >
                  <IconButton
                    color="primary"
                    aria-label="copiar url"
                    component="span"
                    size="medium"
                    className={classes.marginLeft}
                  >
                    <FileCopyIcon />
                  </IconButton>
                </CopyToClipboard>
              </Col>
            </Row>
          </td>
        </tr>
      );
    });
  };

  const renderTableDetails = () => {
    const conversationStatuses = [
      'Conversacion con Asesor iniciada',
      'Prospecto',
    ];
    const medias = getNested([], campaignDetails, 'media');
    const campaignClicks = getNested(
      [],
      campaignDetails,
      'campaignClicks',
    );
    let mapClicks = {};
    medias.forEach((media) => {
      mapClicks[media] = {
        clicks: 0,
        'Conversacion con Asesor iniciada': 0,
        Prospecto: 0,
      };
    });
    mapClicks = campaignClicks
      .filter((campaign) => campaign.media)
      .reduce((acc, click) => {
        const conversationStatus = getNested(
          null,
          click,
          'conversation',
          'status',
        );
        if (conversationStatuses.includes(conversationStatus)) {
          acc[click.media][conversationStatus] =
            acc[click.media][conversationStatus] + 1;
        }
        if (click.media in acc)
          acc[click.media]['clicks'] = acc[click.media]['clicks'] + 1;
        return acc;
      }, mapClicks);
    return Object.keys(mapClicks).map((media) => {
      return (
        <tr key={media}>
          <td>
            <b>
              {intl.formatMessage({
                id: `CAMPAIGN.MEDIA.${media}`,
              })}
            </b>
          </td>
          <td>{mapClicks[media]['clicks']}</td>
          <td>
            {mapClicks[media]['Conversacion con Asesor iniciada']}
          </td>
          <td>{mapClicks[media]['Prospecto']}</td>
        </tr>
      );
    });
  };

  return (
    <Paper className={classes.paper}>
      {renderFeedBackModal()}
      <div className="input-container">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              disabled
              name="name"
              fullWidth
              type="text"
              label="Nombre de campaña"
              variant="outlined"
              value={getNested('-', campaignDetails, 'name')}
              className={classes.textField}
            />
            <TextField
              disabled
              name="name"
              fullWidth
              type="text"
              label="Categoría"
              variant="outlined"
              value={getNested(
                getNested('n/a', campaignDetails, 'type'),
                campaignDetails,
                'customFlow',
                'name',
              )}
              className={classes.textField}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              multiline
              disabled
              rows="6"
              type="text"
              label="Descripción"
              variant="outlined"
              value={getNested('-', campaignDetails, 'description')}
              className={classes.textField}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs>
            <TextField
              fullWidth
              multiline
              disabled
              name="initialMessage"
              rows="5"
              type="text"
              label="Mensaje inicial"
              variant="outlined"
              value={getNested(
                '-',
                campaignDetails,
                'initialMessage',
              )}
              className={classes.textField}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          className={clsx(
            classes.alignByColumnMedia,
            classes.marginBottom,
          )}
        >
          <Table bordered className={classes.tables} responsive>
            <thead>
              <tr>
                <th></th>
                <th>WHATSAPP URL</th>
                <th>FACEBOOK URL</th>
              </tr>
            </thead>
            <tbody>{renderTableLinksDetails()}</tbody>
          </Table>
          {/* {getNested([], campaignDetails, 'media').includes('fb') && (
            <Card className={classes.card}>
              <CardContent className={classes.CardContent}>
                <Grid
                  container
                  spacing={2}
                  className={classes.justifyContentCenter}
                >
                  <Grid item>
                    <img
                      src={facebookLogo}
                      width="100"
                      alt="facebook"
                    ></img>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {`${getNested(
                      '-',
                      campaignDetails,
                      'link',
                    )}&media=fb`}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
          {getNested([], campaignDetails, 'media').includes(
            'twitter',
          ) && (
            <Card className={classes.card}>
              <CardContent className={classes.CardContent}>
                <Grid
                  container
                  spacing={2}
                  className={classes.justifyContentCenter}
                >
                  <Grid item>
                    <img
                      src={twitterLogo}
                      width="100"
                      alt="twitter"
                    ></img>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {`${getNested(
                      '-',
                      campaignDetails,
                      'link',
                    )}&media=twitter`}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
          {getNested([], campaignDetails, 'media').includes(
            'instagram',
          ) && (
            <Card className={classes.card}>
              <CardContent className={classes.CardContent}>
                <Grid
                  container
                  spacing={2}
                  className={classes.justifyContentCenter}
                >
                  <Grid item>
                    <img
                      src={instagramLogo}
                      width="100"
                      alt="Instagram"
                    ></img>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {`${getNested(
                      '-',
                      campaignDetails,
                      'link',
                    )}&media=instagram`}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
          {getNested([], campaignDetails, 'media').includes(
            'web',
          ) && (
            <Card className={classes.card}>
              <CardContent className={classes.CardContent}>
                <Grid
                  container
                  spacing={2}
                  className={classes.justifyContentCenter}
                >
                  <Grid item>
                    <b>Web</b> &nbsp;&nbsp;
                    {`${getNested(
                      '-',
                      campaignDetails,
                      'link',
                    )}&media=web`}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
          {getNested([], campaignDetails, 'media').includes(
            'mailing',
          ) && (
            <Card className={classes.card}>
              <CardContent className={classes.CardContent}>
                <Grid
                  container
                  spacing={2}
                  className={classes.justifyContentCenter}
                >
                  <Grid item>
                    <b>Mailing</b> &nbsp;&nbsp;
                    {`${getNested(
                      '-',
                      campaignDetails,
                      'link',
                    )}&media=mailing`}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
          {getNested([], campaignDetails, 'media').includes(
            'other',
          ) && (
            <Card className={classes.card}>
              <CardContent className={classes.CardContent}>
                <Grid
                  container
                  spacing={2}
                  className={classes.justifyContentCenter}
                >
                  <Grid item>
                    <b>Otro </b>&nbsp;&nbsp;
                    {`${getNested(
                      '-',
                      campaignDetails,
                      'link',
                    )}&media=other`}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
        </Grid>
        <Grid item xs={12} className={classes.subtitle}>
          <Typography variant="h6" className={classes.top}>
            Conversaciones por Facebook Messenger
          </Typography>
        </Grid>
        <Grid
          container
          spacing={2}
          className={clsx(
            classes.alignByColumnMedia,
            classes.marginBottom,
          )}
        >
          {getNested([], campaignDetails, 'media').includes('fb') && (
            <Card className={classes.card}>
              <CardContent className={classes.CardContent}>
                <Grid
                  container
                  spacing={2}
                  className={classes.justifyContentCenter}
                >
                  <Grid item>
                    <img
                      src={facebookLogo}
                      width="100"
                      alt="facebook"
                    ></img>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {`${getNested(
                      '-',
                      campaignDetails,
                      'fbLink',
                    )}&media=fb`}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )} */}
        </Grid>
        {getNested([], campaignDetails, 'media').length > 0 && (
          <Grid container spacing={2}>
            <Table bordered className={classes.tables}>
              <thead>
                <tr>
                  <th></th>
                  <th>CLICKS</th>
                  <th>CONVERSACIONES CON ASESOR INCIADAS</th>
                  <th>NÚMERO DE PROSPECTOS</th>
                </tr>
              </thead>
              <tbody>{renderTableDetails()}</tbody>
            </Table>
          </Grid>
        )}
      </div>
    </Paper>
  );
}

DetailCampaign.propTypes = {
  users: PropTypes.object,
  agencies: PropTypes.object,
  createAdvisor: PropTypes.func,
  getUserDetail: PropTypes.func,
  closeModals: PropTypes.func,
  history: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

const mapStateToProps = (state) => ({
  campaigns: selectCampaigns(state),
  campaignDetails: makeSelectCampaignDetails(state),
});

const mapDispatchToProps = {
  getCampaignDetails,
  closeModals,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(DetailCampaign),
);
