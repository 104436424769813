import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import {
  TimePicker as MaterialTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { cloneObjectLevels, getNested } from '../../../../utils';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import MomentUtils from '@date-io/moment';
import React from 'react';
import clsx from 'clsx';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  container: {
    flexWrap: 'wrap',
  },
  paper: {
    padding: theme.spacing(7, 10, 1, 10),
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  fromToInputs: {
    width: '50%',
    margin: theme.spacing(0, 1, 1, 0),
  },
  card: {
    width: '100%',
    marginBottom: '10px',
  },
  hourInputsContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  CardContent: {
    padding: '10px !important',
  },
  addDeleteButtonsSeparation: {
    margin: theme.spacing(0, 1, 0, 1),
  },
}));

const mapDayNames = {
  1: 'Lunes',
  2: 'Martes',
  3: 'Miércoles',
  4: 'Jueves',
  5: 'Viernes',
  6: 'Sábado',
  7: 'Domingo',
};

export const DayCards = ({
  days,
  setDays,
  activeDays,
  setActiveDays,
  optionsHours = true,
  copySchedule = true,
  daysCheckBox = true,
}) => {
  const classes = useStyles();

  // const [dayHovered, setDayHovered] = useState(null);

  const handleCopyToAllDaysHour = (selectedDay) => {
    const activeDayToCopy = getNested(false, activeDays, selectedDay);
    const dayToCopy = days[selectedDay].map((timeTables) => {
      delete timeTables.id;
      return timeTables;
    });
    const tempDays = { ...days };
    const tempActiveDays = { ...activeDays };
    Object.keys(tempDays)
      .filter((key) => selectedDay !== key)
      .forEach((key) => {
        tempDays[key] = cloneObjectLevels(dayToCopy);
      });
    Object.keys(tempActiveDays)
      .filter((key) => selectedDay !== key)
      .forEach((key) => {
        tempActiveDays[key] = activeDayToCopy;
      });
    setDays(tempDays);
    setActiveDays(tempActiveDays);
  };

  const handleSetHourToDayRange = (value, day, index, rangeType) => {
    const tempDays = { ...days };
    tempDays[day][index][rangeType] = value;
    setDays(tempDays);
  };

  const handleAddRageToDay = (day) => {
    const tempDays = { ...days };
    tempDays[day].push({ from: '', to: '' });
    setDays(tempDays);
  };

  const handleDeleteHour = (hourIndex, day) => {
    let tempDays = { ...days };
    if (tempDays[day].length === 1)
      tempDays[day] = [{ from: '', to: '' }];
    else tempDays[day].splice(hourIndex, 1);
    setDays(tempDays);
  };

  const renderButtonAddHours = (hours, hourIndex, day) => {
    if (!optionsHours) return null;
    if (hourIndex === 0) {
      return (
        <>
          <IconButton
            className={classes.addDeleteButtonsSeparation}
            size="small"
            aria-label="delete"
            onClick={() => handleDeleteHour(hourIndex, day)}
          >
            <DeleteIcon fontSize="inherit" />
          </IconButton>
          <IconButton
            className={classes.addDeleteButtonsSeparation}
            size="small"
            aria-label="add"
            onClick={() => handleAddRageToDay(day)}
          >
            <AddIcon fontSize="inherit" />
          </IconButton>
        </>
      );
    } else if (hours.id && hourIndex !== 0) {
      return (
        <IconButton
          className={classes.addDeleteButtonsSeparation}
          size="small"
          aria-label="delete"
          onClick={() => handleDeleteHour(hourIndex, day)}
        >
          <DeleteIcon fontSize="inherit" />
        </IconButton>
      );
    } else if (hourIndex !== 0) {
      return (
        <IconButton
          className={classes.addDeleteButtonsSeparation}
          size="small"
          aria-label="delete"
          onClick={() => handleDeleteHour(hourIndex, day)}
        >
          <DeleteIcon fontSize="inherit" />
        </IconButton>
      );
    }
  };

  const handleDisplayCheckboxDays = (day) => {
    if (daysCheckBox)
      return (
        <FormControlLabel
          control={
            <Checkbox
              checked={getNested(false, activeDays, day)}
              onChange={({ target: { checked } }) => {
                const tempActiveDays = { ...activeDays };
                tempActiveDays[day] = checked;
                setActiveDays(tempActiveDays);
              }}
            />
          }
          label={mapDayNames[day]}
        />
      );
    else return mapDayNames[day];
  };

  return Object.keys(days).map((day) => (
    <Card
      key={`day-${day}`}
      className={classes.card}
      // onMouseEnter={() => setDayHovered(day)}
      // onMouseLeave={() => setDayHovered(null)}
    >
      <CardContent className={classes.CardContent}>
        <Grid container spacing={2}>
          <Grid item xs={4} className={classes.flex}>
            {handleDisplayCheckboxDays(day)}
          </Grid>
          <Grid item xs={6} className={classes.flex}>
            <div
              className={clsx(
                classes.flex,
                classes.hourInputsContainer,
              )}
            >
              {days[day].map((hours, hourIndex) => (
                <div
                  key={`range-hours-${hourIndex}`}
                  className={clsx(classes.flex)}
                >
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <MaterialTimePicker
                      autoOk
                      clearable
                      ampm={false}
                      size="small"
                      className={classes.fromToInputs}
                      variant="dialog"
                      label="Desde"
                      cancelLabel="Cancelar"
                      clearLabel="Limpiar"
                      orientation="portrait"
                      value={
                        hours.from ? moment(hours.from, 'H:mm') : null
                      }
                      onChange={(value) => {
                        const checkedValue = value
                          ? value.format('H:mm')
                          : '';
                        handleSetHourToDayRange(
                          checkedValue,
                          day,
                          hourIndex,
                          'from',
                        );
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <MaterialTimePicker
                      autoOk
                      clearable
                      ampm={false}
                      size="small"
                      className={classes.fromToInputs}
                      variant="dialog"
                      label="Hasta"
                      cancelLabel="Cancelar"
                      clearLabel="Limpiar"
                      orientation="portrait"
                      value={
                        hours.to ? moment(hours.to, 'H:mm') : null
                      }
                      onChange={(value) => {
                        const checkedValue = value
                          ? value.format('H:mm')
                          : '';
                        handleSetHourToDayRange(
                          checkedValue,
                          day,
                          hourIndex,
                          'to',
                        );
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  {renderButtonAddHours(hours, hourIndex, day)}
                </div>
              ))}
            </div>
          </Grid>
          <Grid item xs={2} className={classes.flex}>
            {day === '1' && copySchedule && (
              <Button
                size="small"
                color="primary"
                onClick={() => handleCopyToAllDaysHour(day)}
              >
                Copiar horario a todos
              </Button>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  ));
};
