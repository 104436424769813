import { agencyReducer } from '../features/agencies/reducer';
import authSlice from '../features/auth/authSlice';
import campaignSlice from '../features/campaign/campaignSlice';
import clientsSlice from '../features/clients/clientsSlice';
import { combineReducers } from '@reduxjs/toolkit';
import conversationsSlice from '../features/conversations/conversationsSlice';
import dashboardSlice from '../features/dashboard/dashboardSlice';
import eventsSlice from '../features/events/eventsSlice';
import logsSlice from '../features/logs/logsSlice';
import { metronic } from '../../_metronic';
import notificationsSlice from '../features/notifications/notificationsSlice';
import setupSlice from '../features/setup/setupSlice';
import userSlice from '../features/users/userSlice';

export const rootReducer = combineReducers({
  auth: authSlice,
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer,
  users: userSlice,
  agencies: agencyReducer,
  conversations: conversationsSlice,
  logs: logsSlice,
  notifications: notificationsSlice,
  campaigns: campaignSlice,
  dashboard: dashboardSlice,
  setup: setupSlice,
  clients: clientsSlice,
  events: eventsSlice,
});
