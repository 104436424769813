import { createAction, createSlice } from '@reduxjs/toolkit';

import { initialState } from './initialState';

const cleanStore = createAction('auth/logout');
const changeAgency = createAction('auth/changeAgency');

const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    getAllClients: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getAllClientsSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.fromdb = action.payload.clients.rows;
        state.tablePages = action.payload.clients.pages;
      },
    },
    getAllClientsFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.error = action.payload.error;
      },
    },
    getClient: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getClientSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.clientDetails = action.payload.client;
      },
    },
    getClientFail: {
      reducer(state, action) {
        state.isLoading = false;
      },
    },
    updateClient: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    updateClientSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
        state.clientDetails = {
          ...state.clientDetails,
          ...action.payload.client,
        };
      },
    },
    updateClientFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    updateConversationFromClient: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    assignConversationFromClient: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    attendConversationFromClient: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    closeModals: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = initialState.successModal;
        state.errorModal = initialState.errorModal;
        state.deletedModal = false;
      },
    },
  },
  extraReducers: {
    [cleanStore]: (state, action) => {
      return initialState;
    },
    [changeAgency]: (state, action) => {
      return initialState;
    },
  },
});

export const {
  getAllClients,
  getAllClientsSuccess,
  getAllClientsFail,
  getClient,
  getClientSuccess,
  getClientFail,
  updateClient,
  updateClientSuccess,
  updateClientFail,
  updateConversationFromClient,
  assignConversationFromClient,
  attendConversationFromClient,
  closeModals,
} = clientsSlice.actions;

export default clientsSlice.reducer;
