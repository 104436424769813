import { createSelector } from 'reselect';
import { initialState } from './initialState';

export const selectUsers = (state) => state.users || initialState;

export const makeSelectUsersIsLoading = createSelector(
  selectUsers,
  (usersState) => usersState.isLoading,
);

export const makeSelectUsersFromState = createSelector(
  selectUsers,
  (usersState) => usersState.fromdb,
);

export const makeSelectUserDetailsFromState = createSelector(
  selectUsers,
  (usersState) => usersState.userDetails,
);

export const makeSelectUserInvitationsFromState = createSelector(
  selectUsers,
  (usersState) => usersState.invitations,
);
export const makeSelectUsersCategoriesFromState = createSelector(
  selectUsers,
  (usersState) => usersState.usersCategories,
);

export const makeSelectUsersSuccessModal = () =>
  createSelector(
    selectUsers,
    (usersState) => usersState.successModal,
  );

export const makeSelectUsersErrorModal = () =>
  createSelector(selectUsers, (usersState) => usersState.errorModal);
