import { Col, Row } from 'react-bootstrap';
import { MenuItem, Paper, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
  cleanUserDetail,
  closeModals,
  createAdvisor,
  emailUserExists,
  getUserDetail,
  inviteAdvisor,
  updateAdvisor,
} from '../../userSlice';
import {
  cloneObjectLevels,
  compareDates,
  formatTime,
  getNested,
  withOutAccent,
} from '../../../../utils';

import CreateOrEditAdvisorForm from '../../components/CreateOrDeteleAdvisorForm';
import InviteAdvisorForm from '../../components/InviteAdvisorForm';
import Modal from '../../../../components/Modal';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { makeSelectAgencies } from '../../../agencies/selectors';
import { makeSelectUserDetailsFromState } from '../../selectors';
import { makeSelectUserRoleFromState } from '../../../auth/selectors';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  container: {
    flexWrap: 'wrap',
  },
  paper: {
    padding: theme.spacing(7, 10, 1, 10),
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  fromToInputs: {
    width: '50%',
    margin: theme.spacing(0, 1, 1, 0),
  },
  card: {
    width: '100%',
    marginBottom: '10px',
  },
  hourInputsContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  CardContent: {
    padding: '10px !important',
  },
}));

const mapDayNames = {
  1: 'Lunes',
  2: 'Martes',
  3: 'Miércoles',
  4: 'Jueves',
  5: 'Viernes',
  6: 'Sábado',
  7: 'Domingo',
};

const mapNamesDay = {
  lunes: 1,
  martes: 2,
  miercoles: 3,
  jueves: 4,
  viernes: 5,
  sabado: 6,
  domingo: 7,
};

const cleanActiveDays = {
  1: false,
  2: false,
  3: false,
  4: false,
  5: false,
  6: false,
  7: false,
};

const cleanDays = {
  1: [{ from: '', to: '' }],
  2: [{ from: '', to: '' }],
  3: [{ from: '', to: '' }],
  4: [{ from: '', to: '' }],
  5: [{ from: '', to: '' }],
  6: [{ from: '', to: '' }],
  7: [{ from: '', to: '' }],
};

const cleanOriginalDays = {
  1: [],
  2: [],
  3: [],
  4: [],
  5: [],
  6: [],
  7: [],
};

function CreateAdvisor(props) {
  const classes = useStyles();
  const {
    agencies,
    users,
    userDetails,
    userRole,
    setLeftSideComponent,
    createAdvisor,
    updateAdvisor,
    getUserDetail,
    emailUserExists,
    cleanUserDetail,
    inviteAdvisor,
    closeModals,
    location,
    history,
    match,
  } = props;
  const [showBadTimeModal, setShowBadTimeModal] = useState(false);
  const [pageName, setPageName] = useState('');
  const [originalDays, setOriginalDays] = useState(
    cloneObjectLevels(cleanOriginalDays),
  );
  const [days, setDays] = useState(cloneObjectLevels(cleanDays));
  const [activeDays, setActiveDays] = useState(
    cloneObjectLevels(cleanActiveDays),
  );

  const {
    handleSubmit,
    register,
    errors,
    control,
    setValue,
    watch,
    reset,
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      phoneNumber: '',
      priority: '',
      activated: false,
      assessorType: '',
      agencyID: '',
      role: '',
      activeDays: {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
      },
      whatsConversations: false,
      fbConversations: false,
      categories: [],
    },
  });

  const email = watch('email');
  const activated = watch('activated');
  const role = watch('role');
  const categories = watch('categories');

  useEffect(() => {
    const userID = match.params.id;
    let pageTitleName = '';
    const pageName = location.pathname.split('/')[1];
    if (match.params.id) {
      getUserDetail({ userID });
      pageTitleName = 'Actualizar asesor';
    } else if (pageName === 'create-advisor') {
      cleanUserDetail();
      pageTitleName = 'Crear nuevo asesor';
    } else if (pageName === 'invite-advisor') {
      cleanUserDetail();
      pageTitleName = 'Invitar asesor';
    }
    setLeftSideComponent(
      <div className="kt-subheader__main">
        <div>
          <h3 className="kt-subheader__title">{pageTitleName}</h3>
        </div>
      </div>,
    );
    setPageName(pageName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(userDetails) && match.params.id) {
      const tempOriginalDays = cloneObjectLevels(cleanOriginalDays);
      const tempDays = cloneObjectLevels(cleanDays);
      const tempActiveDays = cloneObjectLevels(cleanActiveDays);
      getNested([], userDetails, 'timeTables').forEach(
        (timeTable) => {
          if (timeTable.activated) {
            tempActiveDays[mapNamesDay[timeTable.day]] = true;
          }
          const tempDay = {
            id: timeTable.id,
            activated: timeTable.activated,
            from: `${formatTime(timeTable.hourA)}:${formatTime(
              timeTable.minutesA,
            )}`,
            to: `${formatTime(timeTable.hourB)}:${formatTime(
              timeTable.minutesB,
            )}`,
          };
          if (
            tempDays[mapNamesDay[timeTable.day]].length > 0 &&
            !tempDays[mapNamesDay[timeTable.day]][0].from &&
            !tempDays[mapNamesDay[timeTable.day]][0].to
          )
            tempDays[mapNamesDay[timeTable.day]][0] = {
              ...tempDay,
            };
          else
            tempDays[mapNamesDay[timeTable.day]].push({
              ...tempDay,
            });
          tempOriginalDays[mapNamesDay[timeTable.day]].push({
            ...tempDay,
          });
        },
      );
      reset({
        name: getNested('', userDetails, 'user', 'name'),
        email: getNested('', userDetails, 'user', 'email'),
        phoneNumber: getNested('', userDetails, 'phoneNumber'),
        priority: getNested('', userDetails, 'priority'),
        activated: getNested(false, userDetails, 'activated'),
        assessorType: getNested('', userDetails, 'type'),
        agencyID: getNested('', userDetails, 'agencyID'),
        role: getNested('', userDetails, 'user', 'role'),
        whatsConversations: getNested(
          false,
          userDetails,
          'whatsConversations',
        ),
        fbConversations: getNested(
          false,
          userDetails,
          'fbConversations',
        ),
        categories: getNested([], userDetails, 'user', 'categories'),
      });
      setDays(tempDays);
      setOriginalDays(tempOriginalDays);
      setActiveDays(tempActiveDays);
    }
    return () => {
      setDays(cloneObjectLevels(cleanDays));
      setOriginalDays(cloneObjectLevels(cleanOriginalDays));
      setActiveDays(cleanActiveDays);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  useEffect(() => {
    if (
      userRole === 'Agency Admin' &&
      location.pathname.split('/')[1] === 'create-advisor'
    ) {
      reset({
        name: '',
        email: '',
        phoneNumber: '',
        priority: '',
        activated: false,
        assessorType: '',
        agencyID: '',
        role: 'Advisor',
        activeDays: {
          1: false,
          2: false,
          3: false,
          4: false,
          5: false,
          6: false,
          7: false,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails, userRole]);

  const toggleBadTimeModal = () => {
    setShowBadTimeModal(!showBadTimeModal);
  };

  const handleCloseModals = (redirect = null) => {
    closeModals({ history, redirect });
  };

  const createTimeTables = (data) => {
    try {
      Object.keys(days).forEach((dayKey, index) => {
        days[dayKey].forEach((dayTime) => {
          if (dayTime.from && dayTime.to) {
            const day = withOutAccent(
              mapDayNames[dayKey].toLowerCase(),
            );
            if (compareDates(dayTime.from, dayTime.to))
              throw new Error('Bad time range');
            const hourASplited = dayTime.from.split(':');
            const hourBSplited = dayTime.to.split(':');
            const hourA = parseInt(hourASplited[0]);
            const minutesA = parseInt(hourASplited[1]);
            const hourB = parseInt(hourBSplited[0]);
            const minutesB = parseInt(hourBSplited[1]);
            const activated = activeDays[dayKey];
            data.timeTables.push({
              day,
              hourA,
              minutesA,
              hourB,
              minutesB,
              activated,
            });
          }
        });
      });
      return data;
    } catch (e) {
      console.log(e);
      if (e.message === 'Bad time range') setShowBadTimeModal(true);
    }
  };

  const onSubmitCreate = (values) => {
    const { activeDays, assessorType, role, ...restValues } = values;
    let data = {
      ...restValues,
      type: assessorType,
      role: role ? role : 'Advisor',
      timeTables: role ? (role === 'Advisor' ? [] : undefined) : [],
    };

    data = createTimeTables(data);
    createAdvisor(data);
  };

  const onSubmitInvite = (values) => {
    const { activeDays, assessorType, ...restValues } = values;
    const data = {
      ...restValues,
      type: assessorType,
      timeTables: role ? (role === 'Advisor' ? [] : undefined) : [],
    };

    createTimeTables(data);
    inviteAdvisor(data);
  };

  const contrastHours = () => {
    const copyOriginalDays = cloneObjectLevels(originalDays);
    const timeTables = [];
    Object.keys(copyOriginalDays).forEach((originalDayKey) => {
      copyOriginalDays[originalDayKey].forEach(
        (originalHours, index) => {
          let notFound = true;
          let changed = false;
          days[originalDayKey].forEach((modifiedHour) => {
            if (originalHours.id === modifiedHour.id) {
              notFound = false;
              const tempActiveDays = { ...activeDays };
              if (
                originalHours.from !== modifiedHour.from ||
                originalHours.to !== modifiedHour.to
              ) {
                originalHours.from = modifiedHour.from;
                originalHours.to = modifiedHour.to;
                changed = true;
              }
              if (
                originalHours.activated !==
                tempActiveDays[parseInt(originalDayKey)]
              ) {
                changed = true;
              }
            }
          });
          if (originalHours.id && notFound) {
            originalHours.remove = true;
          } else if (!changed && originalHours.id) {
            copyOriginalDays[originalDayKey].splice(index, 1);
          }
        },
      );
      days[originalDayKey].forEach((modifiedHour) => {
        if (
          !modifiedHour.id &&
          modifiedHour.from &&
          modifiedHour.to
        ) {
          copyOriginalDays[originalDayKey].push(modifiedHour);
        }
      });
    });

    Object.keys(copyOriginalDays).forEach((originalDayKey) => {
      copyOriginalDays[originalDayKey].forEach(
        (originalHours, index) => {
          if (compareDates(originalHours.from, originalHours.to))
            throw new Error('Bad time range');
          const day = withOutAccent(
            mapDayNames[originalDayKey].toLowerCase(),
          );
          const hourASplited = originalHours.from.split(':');
          const hourBSplited = originalHours.to.split(':');
          const hourA = parseInt(hourASplited[0]);
          const minutesA = parseInt(hourASplited[1]);
          const hourB = parseInt(hourBSplited[0]);
          const minutesB = parseInt(hourBSplited[1]);
          const activated = activeDays[originalDayKey];
          timeTables.push({
            id: originalHours.id ? originalHours.id : undefined,
            remove: originalHours.remove ? true : undefined,
            day,
            hourA,
            minutesA,
            hourB,
            minutesB,
            activated,
          });
        },
      );
    });
    return timeTables;
  };

  const onSubmitUpdate = (values) => {
    const { activeDays, assessorType, ...restValues } = values;
    const data = {
      ...restValues,
      id: match.params.id,
      type: assessorType,
      userID: userDetails.userID,
    };
    try {
      const timeTables = contrastHours();
      if (Array.isArray(timeTables) && timeTables.length > 0)
        data.timeTables = timeTables;
      updateAdvisor(data);
    } catch (e) {
      if (e.message === 'Bad time range') setShowBadTimeModal(true);
    }
  };

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = users;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message, redirect } = props.users[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={() => handleCloseModals(redirect)}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={() => handleCloseModals(redirect)}
          />
        );
      }
      return null;
    } catch (e) {
      console.log('Modal error: ', e);
      handleCloseModals();
      return null;
    }
  };

  const renderBadDateRange = () => {
    return (
      <Modal
        open={showBadTimeModal}
        type={'errorModal'}
        closeModal={toggleBadTimeModal}
        onClick={toggleBadTimeModal}
        dialogTitle="Horario incorrecto"
        dialogText="Has ingresado un horario erróneo, la hora del campo *desde* debe ser mayor a la del campo *hasta*"
        actionButtonText="Aceptar"
      />
    );
  };

  const renderAgencyOptions = () => {
    if (Array.isArray(agencies.fromdb))
      return agencies.fromdb.map((agency) => {
        return (
          <MenuItem key={`agency-${agency.id}`} value={agency.id}>
            {agency.name}
          </MenuItem>
        );
      });
    return null;
  };

  const renderUpdateOrCreateText = () => {
    if (match.params.id) {
      return 'Actualizar asesor';
    } else if (pageName === 'create-advisor') {
      return 'Crear nuevo asesor';
    } else if (pageName === 'invite-advisor') {
      return 'Invitar asesor';
    }
  };

  const handleUpdateOrCreate = (values) => {
    if (match.params.id) {
      onSubmitUpdate(values);
    } else if (pageName === 'create-advisor') {
      onSubmitCreate(values);
    } else if (pageName === 'invite-advisor') {
      onSubmitInvite(values);
    }
    return;
  };

  return (
    <Row>
      <Col lg="12" md="12">
        {renderFeedBackModal()}
        {renderBadDateRange()}
        <Paper className={classes.paper}>
          {pageName === 'invite-advisor' ? (
            <InviteAdvisorForm
              control={control}
              handleSubmit={handleSubmit}
              errors={errors}
              register={register}
              setValue={setValue}
              renderAgencyOptions={renderAgencyOptions}
              handleUpdateOrCreate={handleUpdateOrCreate}
              activated={activated}
              renderUpdateOrCreateText={renderUpdateOrCreateText}
              email={email}
              emailUserExists={emailUserExists}
              days={days}
              setDays={setDays}
              activeDays={activeDays}
              setActiveDays={setActiveDays}
            />
          ) : (
            <CreateOrEditAdvisorForm
              control={control}
              handleSubmit={handleSubmit}
              errors={errors}
              register={register}
              setValue={setValue}
              renderAgencyOptions={renderAgencyOptions}
              role={role}
              handleUpdateOrCreate={handleUpdateOrCreate}
              activated={activated}
              renderUpdateOrCreateText={renderUpdateOrCreateText}
              days={days}
              setDays={setDays}
              activeDays={activeDays}
              setActiveDays={setActiveDays}
              categories={categories}
            />
          )}
        </Paper>
      </Col>
    </Row>
  );
}

const mapStateToProps = (state) => ({
  users: state.users,
  userDetails: makeSelectUserDetailsFromState(state),
  agencies: makeSelectAgencies(state),
  userRole: makeSelectUserRoleFromState(state),
});

const mapDispatchToProps = {
  createAdvisor,
  updateAdvisor,
  closeModals,
  getUserDetail,
  cleanUserDetail,
  inviteAdvisor,
  emailUserExists,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateAdvisor);
