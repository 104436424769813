import {
  Button,
  IconButton,
  Paper,
  Tooltip,
} from '@material-ui/core';

import AttachFileIcon from '@material-ui/icons/AttachFile';
import DescriptionIcon from '@material-ui/icons/Description';
import { InsertEmoticon } from '@material-ui/icons';
import { OverlayTrigger } from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Picker } from 'emoji-mart';
import React from 'react';
import SendIcon from '@material-ui/icons/Send';
import ShouldItRender from 'app/components/ShouldItRender';
import clsx from 'clsx';
import { formatDate } from 'app/utils';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  chat: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '15px',
    backgroundColor: '#f6f6f6',
  },
  chatHeader: {
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid lightgray',
  },
  chatHeaderInfo: {
    flex: 1,
    paddingLeft: '20px',
  },
  chatHeaderInfoH: {
    marginBottom: '3px',
    fontWeight: 500,
  },
  chatHeaderRight: {
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: '100px',
  },
  chatBody: {
    flex: 1,
    backgroundRepeat: 'repeat',
    backgroundPosition: 'center',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '2px',
    paddingBottom: '2px',
    backgroundImage:
      'url("https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png")',
  },
  chatMessage: {
    position: 'relative',
    fontSize: '13px',
    padding: '10px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    width: 'fit-content',
    maxWidth: '500px',
    marginBottom: '15px',
  },
  chatTimestamp: {
    marginLeft: '10px',
    fontSize: 'xx-small',
  },
  chatReceiver: {
    marginLeft: 'auto',
    backgroundColor: '#dcf8c6',
  },
  chatFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '62px',
    borderTop: '1px solid lightgray',
  },
  chatFooterForm: {
    display: 'flex',
    flex: 1,
    marginLeft: theme.spacing(1),
  },
  chatFooterFormInput: {
    flex: 1,
    borderRadius: '20px',
    padding: '10px',
    border: 'none',
    outlineWidth: 0,
  },
  chatFooterFormButton: {
    display: 'none',
  },
  chatFooterIcons: {
    padding: '10px',
    color: 'gray',
  },
  label: {
    margin: 0,
  },
  fileIcon: {
    fontSize: '4em',
  },
}));

export const WhatsAppChat = ({
  name,
  messages = [],
  activateConversation,
  socketConnect,
  endConversation,
  onAdminConversation,
  sendMessage,
  replyOnAdmin,
  handleUploadFiles,
}) => {
  const classes = useStyles();
  const scroller = React.useRef(null);
  const [localMessages, setLocalMessages] = React.useState([]);
  const [message, setMessage] = React.useState('');

  React.useEffect(() => {
    if (Array.isArray(messages) && messages.length > 0) {
      let tempMessages = [...messages];
      tempMessages.sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      );
      setLocalMessages(tempMessages);
    }
    setTimeout(() => {
      if (scroller && scroller.current)
        scroller.current.scrollTop = scroller.current.scrollHeight;
    }, 300);
    return () => {
      setLocalMessages([]);
    };
  }, [messages, scroller]);

  const renderEmojiPicker = () => (
    <span style={{ zIndex: 1300 }}>
      <Picker
        title=""
        emoji=""
        enableFrequentEmojiSort={false}
        emojiSize={20}
        showSkinTones={false}
        interactive={true}
        i18n={{
          search: 'Buscar',
          clear: 'Limpiar', // Accessible label on "clear" button
          notfound: 'Emoji no encontrado',
          skintext: 'Choose your default skin tone',
          categories: {
            search: 'Resultados',
            recent: 'Usados frecuentemente',
            smileys: 'Sonrisas y expresiones',
            people: 'Personas',
            nature: 'Animales y naturaleza',
            foods: 'Comida',
            activity: 'Actividades',
            places: 'Lugares y viajes',
            objects: 'Objetos',
            symbols: 'Símbolos',
            flags: 'Banderas',
            custom: 'Custom',
          },
        }}
        onSelect={(emoji) => setMessage(`${message}${emoji.native}`)}
      />
    </span>
  );

  return (
    <Paper className={classes.chat}>
      <div className={classes.chatHeader}>
        <div className={classes.chatHeaderInfo}>
          <h5 className={classes.chatHeaderInfoH}>{name}</h5>
        </div>
        <div className={classes.chatHeaderRight}>
          <ShouldItRender should={replyOnAdmin}>
            {!onAdminConversation && (
              <Button
                variant="contained"
                className="btn btn-label-info btn-bold btn-sm btn-icon-h kt-margin-l-10"
                onClick={() => {
                  activateConversation();
                  setTimeout(() => {
                    socketConnect();
                  }, 1000);
                }}
              >
                Iniciar conversación
              </Button>
            )}
            {onAdminConversation && (
              <Button
                variant="contained"
                className="btn btn-label-danger btn-bold btn-sm btn-icon-h kt-margin-l-10"
                onClick={endConversation}
              >
                Finalizar conversación
              </Button>
            )}
          </ShouldItRender>
        </div>
      </div>
      <div className={classes.chatBody}>
        <PerfectScrollbar
          options={{
            wheelSpeed: 1,
            wheelPropagation: false,
          }}
          style={{ maxHeight: '40vh', position: 'relative' }}
          containerRef={(el) => (scroller.current = el)}
        >
          {localMessages.map((message) => {
            if (message.type === 'text' || !message.type)
              return (
                <p
                  key={`${message.createdAt}-${message.id}`}
                  className={clsx(
                    classes.chatMessage,
                    message.from !== 'user'
                      ? classes.chatReceiver
                      : {},
                  )}
                >
                  {message.text}
                  <Tooltip title={formatDate(message.createdAt)}>
                    <span className={classes.chatTimestamp}>
                      {moment(message.createdAt).format('hh:mm a')}
                    </span>
                  </Tooltip>
                </p>
              );
            else {
              if (
                message.text.indexOf('.jpg') > -1 ||
                message.text.indexOf('.jpeg') > -1 ||
                message.text.indexOf('.png') > -1
              )
                return (
                  <p
                    key={`${message.createdAt}-${message.id}`}
                    className={clsx(
                      classes.chatMessage,
                      message.from !== 'user'
                        ? classes.chatReceiver
                        : {},
                    )}
                  >
                    <>
                      <a href={message.text}>
                        <img src={message.text} alt="" width={100} />
                      </a>
                      <Tooltip title={formatDate(message.createdAt)}>
                        <span className={classes.chatTimestamp}>
                          {moment(message.createdAt).format(
                            'hh:mm a',
                          )}
                        </span>
                      </Tooltip>
                    </>
                  </p>
                );
              else {
                return (
                  <p
                    key={`${message.createdAt}-${message.id}`}
                    className={clsx(
                      classes.chatMessage,
                      message.from !== 'user'
                        ? classes.chatReceiver
                        : {},
                    )}
                  >
                    <>
                      <a href={message.text}>
                        <DescriptionIcon
                          className={classes.fileIcon}
                          fontSize="large"
                          color="primary"
                        />
                        <br />
                        Archivo&nbsp;
                        {message.text.substring(
                          message.text.length - 3,
                          message.text.length,
                        )}
                      </a>
                      <Tooltip title={formatDate(message.createdAt)}>
                        <span className={classes.chatTimestamp}>
                          {moment(message.createdAt).format(
                            'hh:mm a',
                          )}
                        </span>
                      </Tooltip>
                    </>
                  </p>
                );
              }
            }
          })}
        </PerfectScrollbar>
      </div>
      <div className={classes.chatFooter}>
        <div className={classes.root}>
          <input
            accept="image/*,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
            hidden
            id="icon-button-file"
            type="file"
            onChange={(e) => handleUploadFiles(e.target.files)}
            multiple
          />
          <label htmlFor="icon-button-file" className={classes.label}>
            <IconButton
              disabled={!onAdminConversation}
              className={classes.chatFooterIcons}
              component="span"
            >
              <AttachFileIcon />
            </IconButton>
          </label>
        </div>
        <OverlayTrigger
          trigger="click"
          placement="top"
          overlay={renderEmojiPicker()}
        >
          <IconButton
            disabled={!onAdminConversation}
            className={classes.chatFooterIcons}
          >
            <InsertEmoticon />
          </IconButton>
        </OverlayTrigger>

        <form
          className={classes.chatFooterForm}
          onSubmit={(e) => {
            e.preventDefault();
            sendMessage(message, 'text');
            setMessage('');
          }}
        >
          <input
            disabled={!onAdminConversation}
            className={classes.chatFooterFormInput}
            type="text"
            placeholder="Escribe un mensaje"
            value={message}
            onChange={({ target: { value } }) => setMessage(value)}
          />
          <button
            disabled={!onAdminConversation}
            type="submit"
            className={classes.chatFooterFormButton}
          >
            Send a message
          </button>
          <div>
            <IconButton
              disabled={!onAdminConversation}
              className={classes.chatFooterIcons}
              type="submit"
            >
              <SendIcon />
            </IconButton>
          </div>
        </form>
      </div>
    </Paper>
  );
};
