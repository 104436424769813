import { call, put, select } from 'redux-saga/effects';
import {
  changeAgency,
  confirmEmailFail,
  confirmEmailSuccess,
  getAgencyUsersFailure,
  getAgencyUsersSuccess,
  loginFailure,
  loginSuccess,
  passwordResetFail,
  passwordResetSuccess,
  requestPasswordResetFail,
  requestPasswordResetSuccess,
} from './authSlice.js';
import {
  getAgencyDetail,
  resetStoreAgencies,
} from '../agencies/actions.js';

import { closeModals } from '../modals/modalsSlice.js';
import { makeSelectUserID } from './selectors.js';
import { requestHandler } from '../../services/requestHandler';

export function* loginUser(action) {
  try {
    let data = {
      ...action.payload,
    };
    let user = yield call(requestHandler, {
      method: 'POST',
      path: '/user/login',
      data,
    });
    let responseAgencyUsers = [];
    if (user.role !== 'Super Admin') {
      data = {
        where: {
          userID: user.id,
        },
        options: {
          attributes: [
            'id',
            'userID',
            'agencyID',
            'priority',
            'type',
            'ignored',
            'phoneNumber',
            'totalConversations',
            'lastConversation',
          ],
        },
        agencies: {
          options: {
            attributes: ['name'],
          },
        },
      };
      responseAgencyUsers = yield call(requestHandler, {
        method: 'POST',
        path: '/agencyUsers/getAll',
        data,
      });
      if (
        Array.isArray(responseAgencyUsers) &&
        responseAgencyUsers.length > 0
      ) {
        data = {
          agencyUserID:
            responseAgencyUsers[responseAgencyUsers.length - 1].id,
        };
        user = yield call(requestHandler, {
          method: 'POST',
          path: '/user/pickAgencyUser',
          data,
        });
      }
    }
    user.session = undefined;
    yield put(
      loginSuccess({ user, agencyUsers: responseAgencyUsers }),
    );
    yield put(closeModals());
    if (user.agencyID && user.role !== 'Super Admin') {
      yield put(getAgencyDetail({ agencyID: user.agencyID }));
    }
  } catch (e) {
    const message = {
      title: '',
      desc: `Error al tratar de iniciar sesión, por favor verifica tu correo y contraseña`,
    };
    yield put(loginFailure({ message }));
    console.log('error in login user saga', e);
  }
}

export function* loginAgencyUserSaga(action) {
  try {
    const { userID, agencyUserID } = action.payload;
    let data = {
      where: {
        userID,
      },
      options: {
        attributes: [
          'id',
          'userID',
          'agencyID',
          'priority',
          'type',
          'ignored',
          'phoneNumber',
          'totalConversations',
          'lastConversation',
        ],
      },
      agencies: {
        options: {
          attributes: ['name'],
        },
      },
    };
    const responseAgencyUsers = yield call(requestHandler, {
      method: 'POST',
      path: '/agencyUsers/getAll',
      data,
    });
    data = {
      agencyUserID,
    };
    let user = yield call(requestHandler, {
      method: 'POST',
      path: '/user/pickAgencyUser',
      data,
    });
    user.session = undefined;
    yield put(
      loginSuccess({ user, agencyUsers: responseAgencyUsers }),
    );
    yield put(changeAgency());
    yield put(closeModals());
    if (user.agencyID && user.role !== 'Super Admin') {
      yield put(getAgencyDetail({ agencyID: user.agencyID }));
    }
  } catch (e) {
    const message = {
      title: '',
      desc: `Error al tratar de iniciar sesión, por favor verifica tu correo y contraseña`,
    };
    yield put(loginFailure({ message }));
    console.log('error in login user saga', e);
  }
}

export function* getAgencyUsersSaga(action) {
  try {
    const userID = yield select(makeSelectUserID());
    let data = {
      where: {
        userID,
      },
      options: {
        attributes: [
          'id',
          'userID',
          'agencyID',
          'priority',
          'type',
          'ignored',
          'phoneNumber',
          'totalConversations',
          'lastConversation',
        ],
      },
      agencies: {
        options: {
          attributes: ['name'],
        },
      },
    };
    const responseAgencyUsers = yield call(requestHandler, {
      method: 'POST',
      path: '/agencyUsers/getAll',
      data,
    });
    yield put(
      getAgencyUsersSuccess({ agencyUsers: responseAgencyUsers }),
    );
  } catch (e) {
    yield put(getAgencyUsersFailure('Error getting agencyUsers'));
    console.log('Error getting agency Users', e);
  }
}

export function* requestPasswordResetSaga(action) {
  try {
    const { email, history } = action.payload;
    const data = {
      email,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/user/passwordReset/request',
      data,
    });
    const message = {
      title: '',
      desc: `Revise la bandeja de entrada de su correo electrónico. Usted debe haber recibido un correo con instrucciones.`,
    };
    yield put(
      requestPasswordResetSuccess({
        message,
      }),
    );
    yield call(history.push, '/auth/login');
  } catch (e) {
    const message = {
      title: '',
      desc: `Ocurrió un error al tratar de validar su nueva contraseña, por favor inténtelo de nuevo más tarde.`,
    };
    yield put(requestPasswordResetFail({ message }));
    console.log('error in request password reset saga', e);
  }
}

export function* passwordResetSaga(action) {
  try {
    const { token, password, history } = action.payload;
    const data = {
      token,
      newPassword: password,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/user/passwordReset/update',
      data,
    });
    const message = {
      title: '',
      desc: `La contraseña ha sido cambiada exitosamente.`,
    };
    yield put(passwordResetSuccess({ message }));
    yield call(history.push, '/auth/login');
  } catch (e) {
    const message = {
      title: '',
      desc: `Ocurrió un error al tratar de validar su nueva contraseña, por favor inténtelo de nuevo más tarde.`,
    };
    yield put(passwordResetFail({ message }));
    console.log('error in password reset saga', e);
  }
}

export function* confirmEmailSaga(action) {
  const { history, ...rest } = action.payload;
  try {
    const data = {
      ...rest,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/user/confirmEmail',
      data,
    });
    const message = {
      title: '',
      desc: `La cuenta ha sido creada exitosamente.`,
    };
    yield put(confirmEmailSuccess({ message }));
    yield call(history.push, '/auth/login');
  } catch (e) {
    const message = {
      title: '',
      desc: `Ocurrió un error al tratar de validar su cuenta, por favor inténtelo de nuevo más tarde.`,
    };
    yield put(confirmEmailFail({ message }));
    console.log('error in confirm email saga', e);
  }
}

export function* cleanStoreSaga(action) {
  try {
    yield put(resetStoreAgencies());
  } catch (e) {
    console.log('Clean storage error: ', e);
  }
}
