/* eslint-disable jsx-a11y/no-onchange */
import 'rc-time-picker/assets/index.css';

import { Alert, Badge } from 'react-bootstrap';
/* eslint-disable react/display-name */
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import {
  advisorTypes,
  cloneObjectLevels,
  compareDates,
  filterCaseInsensitive,
  formatTime,
  getNested,
  isNumeric,
  withOutAccent,
} from '../../../../utils';
import {
  assignAgencyUser,
  closeModals,
  deleteAgencyUser,
  deleteUser,
  getUsers,
  resendInvitation,
  updateMultipleAdvisors,
  updateUser,
} from '../../userSlice';
import {
  getAgencies,
  getAgencyDetail,
} from '../../../agencies/actions';
import {
  makeSelectAgenciesFromdb,
  makeSelectAgencyDetailsFromState,
} from '../../../agencies/selectors';
import {
  makeSelectOwnedAgenciesFromState,
  makeSelectUserCategoriesFromState,
  makeSelectUserFromState,
  makeSelectUserRoleFromState,
} from '../../../auth/selectors';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { DayCards } from '../../components/DayCards';
import { GreenRedSwitch } from 'app/components/GreenRedSwitch';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { Link } from 'react-router-dom';
import { MenuOption } from '../../../../components/MenuOption';
import Modal from '../../../../components/Modal';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import ReactTable from 'react-table';
import ShouldItRender from '../../../../components/ShouldItRender';
import { concat } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { makeSelectUsersFromState } from '../../../users/selectors';
import { mapNamesDay } from 'app/utils';
import { withRouter } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    widht: '100%',
    marginBottom: theme.spacing(2),
  },
  paperAdd: {
    widht: '100%',
  },
  margin: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  media: {
    height: 60,
    width: '100%',
    position: 'relative',
  },
  image: {
    height: '100%',
    width: 'auto',
    position: 'absolute',
    top: '10%',
    left: '45%',
    marginTop: -12,
    marginLeft: -12,
  },
  bold: {
    fontWeight: 'bold',
  },
  editableCell: {
    backgroundColor: '#fafafa',
    textAlign: 'center',
  },
  editIcon: {
    minWidth: '0px !important',
    width: '30px !important',
  },
  marginIcon: {
    margin: theme.spacing(0, 1, 0, 1),
  },
  checkbox: {
    padding: '0px',
  },
  switchExtraUsers: {
    marginRight: theme.spacing(1.5),
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
}));

const cleanDays = {
  1: [{ from: '', to: '' }],
  2: [{ from: '', to: '' }],
  3: [{ from: '', to: '' }],
  4: [{ from: '', to: '' }],
  5: [{ from: '', to: '' }],
  6: [{ from: '', to: '' }],
  7: [{ from: '', to: '' }],
};

const cleanActiveDays = {
  1: false,
  2: false,
  3: false,
  4: false,
  5: false,
  6: false,
  7: false,
};

const mapDayNames = {
  1: 'Lunes',
  2: 'Martes',
  3: 'Miércoles',
  4: 'Jueves',
  5: 'Viernes',
  6: 'Sábado',
  7: 'Domingo',
};

function UsersTable(props) {
  const classes = useStyles();
  const {
    usersFromdb,
    agenciesFromdb,
    role,
    user,
    getAgencies,
    assignAgencyUser,
    getUsers,
    deleteAgencyUser,
    updateMultipleAdvisors,
    ownedAgencies,
    agencyDetails,
    getAgencyDetail,
    categories,
  } = props;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showAssignAgencyModal, setShowAssignAgencyModal] = useState(
    false,
  );
  const [
    showMultipleScheduleModal,
    setShowMultipleScheduleModal,
  ] = useState(false);
  const [agencies, setAgencies] = useState([]);
  const [usersFromState, setUsersFromState] = useState([]);
  const [showBadTimeAlert, setShowBadTimeAlert] = useState(false);
  const [days, setDays] = useState(cloneObjectLevels(cleanDays));
  const [showExternalUsers, setShowExternalUsers] = useState(false);
  const [selectedTimeTable, setSelectedTimeTable] = useState('');
  const [activeDays, setActiveDays] = useState(
    cloneObjectLevels(cleanActiveDays),
  );

  const { handleSubmit, errors, control } = useForm();

  useEffect(() => {
    if (Array.isArray(usersFromdb) && usersFromdb.length > 0)
      setUsersFromState([...usersFromdb]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersFromdb]);

  useEffect(() => {
    const legthSelectedRows = getSelectedRows({ length: true });
    props.setActionButton(
      <>
        <ShouldItRender
          should={
            Array.isArray(ownedAgencies) && ownedAgencies.length > 0
          }
        >
          <FormControlLabel
            control={
              <GreenRedSwitch
                checked={showExternalUsers}
                onChange={({ target }) => {
                  setShowExternalUsers(target.checked);
                }}
              />
            }
            label="Usuarios externos"
            labelPlacement="start"
            className={classes.switchExtraUsers}
          />
        </ShouldItRender>
        <MenuOption
          Button={
            <Button
              className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
              disabled={legthSelectedRows === 0}
            >
              Acciones
              <ArrowDropDownIcon className="kt-margin-l-10" />
            </Button>
          }
          options={[
            {
              name: (
                <>
                  <QueryBuilderIcon />
                  &nbsp;&nbsp;&nbsp;Ajustar horario
                </>
              ),
              onClick: () => toggleMultipleScheduleModal(),
              props: {
                disabled: !isSameUserRoleAsUsersSelected('Advisor'),
              },
            },
          ]}
        />
        <ShouldItRender
          locationPage={props.history.location.pathname}
          action="addAdvisor"
        >
          <Link to={'/create-advisor'}>
            <Button
              variant="contained"
              className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
            >
              Crear asesor
              <PersonAddIcon className="kt-margin-l-10" />
            </Button>
          </Link>
        </ShouldItRender>
        <ShouldItRender
          locationPage={props.history.location.pathname}
          action="inviteAdvisor"
        >
          <Link to={'/invite-advisor'}>
            <Button
              variant="contained"
              className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
            >
              Invitar asesor
              <GroupAddIcon className="kt-margin-l-10" />
            </Button>
          </Link>
        </ShouldItRender>
      </>,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, usersFromState, showExternalUsers]);

  useEffect(() => {
    if (role !== 'Super Admin') getAgencyDetail();
    getUsers({ showExternalUsers });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, showExternalUsers]);

  useEffect(() => {
    if (role === 'Super Admin') getAgencies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let noOption = { value: '', label: '' };
    if (Array.isArray(agenciesFromdb)) {
      const cleanAgencies = concat(
        noOption,
        agenciesFromdb.map((agency) => {
          return {
            value: agency.id,
            label: agency.name,
          };
        }),
      );

      setAgencies(cleanAgencies);
    }
  }, [agenciesFromdb]);

  const isSameUserRoleAsUsersSelected = (role) => {
    const selectedUsers = getSelectedRows();
    if (Array.isArray(selectedUsers) && selectedUsers.length > 0) {
      for (let selectedUser of selectedUsers) {
        if (selectedUser.user.role !== role) {
          return false;
        }
      }
    }
    return true;
  };

  const getSelectedRows = (options = {}) => {
    const usersSelected = usersFromState.filter(
      (user) => user.selected,
    );
    if (options.length) return usersSelected.length;
    else return [...usersSelected];
  };

  const handleSelectRowFromTable = (index) => {
    const tempUsersFromState = [...usersFromState];
    if ('selected' in tempUsersFromState[index])
      tempUsersFromState[index] = {
        ...tempUsersFromState[index],
        selected: !tempUsersFromState[index].selected,
      };
    else
      tempUsersFromState[index] = {
        ...tempUsersFromState[index],
        selected: true,
      };
    setUsersFromState(tempUsersFromState);
  };

  const getActiveDays = (timeTables) => {
    let activeDays = [];
    if (Array.isArray(timeTables) && timeTables.length > 0) {
      activeDays = timeTables.reduce((accumulator, timeTable) => {
        if (
          !accumulator.includes(timeTable.day) &&
          timeTable.activated
        )
          accumulator.push(timeTable.day);
        return accumulator;
      }, []);
    }
    return activeDays;
  };

  const handleCloseModals = () => {
    props.closeModals({});
    setShowDeleteModal(false);
  };

  const handleDeleteUser = () => {
    const data = {};
    const agencyUsers = getNested(
      null,
      selectedUser,
      'user',
      'agencyUsers',
    );
    data.agencyUserID = selectedUser.id;
    if (Array.isArray(agencyUsers) && agencyUsers.length === 1) {
      data.userID = selectedUser.userID;
    }
    data.showExternalUsers = showExternalUsers;
    deleteAgencyUser(data);
    toggleDeleteModal();
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const toggleAssignAgencyModal = () => {
    setShowAssignAgencyModal(!showAssignAgencyModal);
  };

  const toggleMultipleScheduleModal = () => {
    setDays(cloneObjectLevels(cleanDays));
    setActiveDays(cloneObjectLevels(cleanActiveDays));
    setSelectedTimeTable('');
    setShowMultipleScheduleModal(!showMultipleScheduleModal);
  };

  const loadTimeTablePreset = (idx) => {
    const tempDays = cloneObjectLevels(cleanDays);
    const tempActiveDays = cloneObjectLevels(cleanActiveDays);
    getNested([], agencyDetails, 'timeTablePresets', idx).forEach(
      (timeTable) => {
        if (timeTable.activated) {
          tempActiveDays[mapNamesDay[timeTable.day]] = true;
        }
        const tempDay = {
          id: timeTable.id,
          activated: timeTable.activated,
          from: `${formatTime(timeTable.hourA)}:${formatTime(
            timeTable.minutesA,
          )}`,
          to: `${formatTime(timeTable.hourB)}:${formatTime(
            timeTable.minutesB,
          )}`,
        };
        if (
          tempDays[mapNamesDay[timeTable.day]].length > 0 &&
          !tempDays[mapNamesDay[timeTable.day]][0].from &&
          !tempDays[mapNamesDay[timeTable.day]][0].to
        )
          tempDays[mapNamesDay[timeTable.day]][0] = {
            ...tempDay,
          };
        else
          tempDays[mapNamesDay[timeTable.day]].push({
            ...tempDay,
          });
      },
    );
    setActiveDays(tempActiveDays);
    setDays(tempDays);
  };

  const updateSchedule = () => {
    const timeTables = [];
    const rawSelectedUsers = getSelectedRows();
    let data = {
      users: [],
    };

    try {
      Object.keys(days).forEach((dayKey, index) => {
        days[dayKey].forEach((dayTime) => {
          if (dayTime.from && dayTime.to) {
            const day = withOutAccent(
              mapDayNames[dayKey].toLowerCase(),
            );
            if (compareDates(dayTime.from, dayTime.to))
              throw new Error('Bad time range');
            const hourASplited = dayTime.from.split(':');
            const hourBSplited = dayTime.to.split(':');
            const hourA = parseInt(hourASplited[0]);
            const minutesA = parseInt(hourASplited[1]);
            const hourB = parseInt(hourBSplited[0]);
            const minutesB = parseInt(hourBSplited[1]);
            const activated = activeDays[dayKey];
            timeTables.push({
              day,
              hourA,
              minutesA,
              hourB,
              minutesB,
              activated,
            });
          }
        });
      });
    } catch (e) {
      console.log(e);
      if (e.message === 'Bad time range')
        return setShowBadTimeAlert(true);
    }
    setShowMultipleScheduleModal(false);

    rawSelectedUsers.forEach((rawUser) => {
      const tempUser = {
        id: rawUser.id,
        timeTables: [],
      };
      if (
        Array.isArray(rawUser.timeTables) &&
        rawUser.timeTables.length > 0
      )
        rawUser.timeTables.forEach((timeTable) =>
          tempUser.timeTables.push({ ...timeTable, remove: true }),
        );

      tempUser.timeTables = tempUser.timeTables.concat(timeTables);
      data.users.push(tempUser);
      data.showExternalUsers = showExternalUsers;
    });
    updateMultipleAdvisors(data);
  };

  const handleAssignAgency = (values) => {
    const data = {
      ...values,
      userID: selectedUser.userID,
      userName: selectedUser.user.name,
    };
    assignAgencyUser(data);
    setShowAssignAgencyModal(false);
  };

  const handleEditedValue = (value, index) => {
    return (
      value &&
      isNumeric(value) &&
      parseInt(value) !== props.users.fromdb[index].priority
    );
  };

  const handleUpdateUserInDB = (value, cellInfo) => {
    if (value !== '' && handleEditedValue(value, cellInfo.index)) {
      props.updateUser({
        id: cellInfo.original.id,
        priority: value,
      });
    }
  };

  const inputToBeChanged = (row) => {
    if (getNested(null, row.original, 'user', 'role') === 'Advisor') {
      const priority =
        usersFromdb && row.index in usersFromdb
          ? usersFromdb[row.index].priority
          : '';
      return (
        <div
          className={classes.editableCell}
          contentEditable
          suppressContentEditableWarning
          onBlur={({ target }) => {
            handleUpdateUserInDB(target.innerHTML, row);
          }}
          dangerouslySetInnerHTML={{
            __html: priority,
          }}
        />
      );
    } else
      return (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          n/a
        </div>
      );
  };

  const renderConfirmationDelete = () => {
    return (
      <Modal
        open={showDeleteModal}
        closeModal={toggleDeleteModal}
        dialogTitle="¿Seguro que deseas eliminar el usuario?"
        dialogText="Si continúas no habrá manera de recuperar la información
            que haya sido borrada."
        actionButtonText="Aceptar"
        onClick={handleDeleteUser}
      />
    );
  };

  const renderAssingAgencyModal = () => {
    const agenciesAlreadyLinked = getNested(
      [],
      selectedUser,
      'user',
      'agencyUsers',
    ).map((agencyUser) => agencyUser.agencyID);
    return (
      <Modal
        open={showAssignAgencyModal}
        closeModal={toggleAssignAgencyModal}
        dialogTitle="Asignar agencia a Administrador"
        actionButtonText="Asignar agencia"
        dialogChildren={
          <form
            id="assign-agency-form"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit(handleAssignAgency)}
          >
            <FormControl
              variant="outlined"
              className={classes.select}
              fullWidth
            >
              <InputLabel id="label-agency">
                Seleccione la agencia
              </InputLabel>
              <Controller
                name="agencyID"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                as={
                  <Select
                    labelId="label-agency"
                    label="Seleccione la agencia"
                    error={Boolean(errors.agencyID)}
                  >
                    {agencies
                      .filter(
                        (option) =>
                          !agenciesAlreadyLinked.includes(
                            option.value,
                          ),
                      )
                      .map((option) => {
                        return (
                          <MenuItem
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        );
                      })}
                  </Select>
                }
              />
            </FormControl>
          </form>
        }
        form={'assign-agency-form'}
      />
    );
  };

  const renderError = () => {
    return (
      <Alert
        show={showBadTimeAlert}
        variant="danger"
        onClose={() => setShowBadTimeAlert(false)}
        dismissible
      >
        <Alert.Heading>Horario incorrecto</Alert.Heading>
        <p className={classes.alert}>
          Has ingresado un horario erróneo, la hora del campo *desde*
          debe ser mayor a la del campo *hasta*
        </p>
      </Alert>
    );
  };

  const renderMultipleUpdateHours = () => {
    return (
      <Modal
        open={showMultipleScheduleModal}
        closeModal={toggleMultipleScheduleModal}
        maxWidth="sm"
        fullWidth
        dialogTitle="Actualizar horarios de asesores seleccionados"
        actionButtonText="Actaulizar horarios"
        dialogChildren={
          <>
            {renderError()}
            <FormControl
              variant="outlined"
              className={classes.textField}
              fullWidth
            >
              <InputLabel id="label-adviser-type">
                Formato de horario
              </InputLabel>
              <Select
                labelId="label-adviser-type"
                label="Formato de horario"
                error={Boolean(errors.assessorType)}
                value={selectedTimeTable}
                onChange={({ target: { value } }) => {
                  setSelectedTimeTable(value);
                  loadTimeTablePreset(value);
                }}
              >
                <MenuItem disabled value="">
                  Selecciona un horario
                </MenuItem>
                {agencyDetails &&
                  Array.isArray(agencyDetails.timeTablePresets) &&
                  agencyDetails.timeTablePresets.length > 0 &&
                  agencyDetails.timeTablePresets.map(
                    (timeTable, idx) => {
                      return (
                        <MenuItem key={idx} value={idx}>
                          {getNested('-', timeTable, 0, 'name')}
                        </MenuItem>
                      );
                    },
                  )}
              </Select>
            </FormControl>
            <DayCards
              days={days}
              setDays={setDays}
              activeDays={activeDays}
              setActiveDays={setActiveDays}
            />
          </>
        }
        onClick={updateSchedule}
      />
    );
  };

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = props.users;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message, redirect } = props.users[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={() => handleCloseModals(redirect)}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={() => handleCloseModals(redirect)}
          />
        );
      }
      return null;
    } catch (e) {
      console.log('Modal error: ', e);
      handleCloseModals();
      return null;
    }
  };

  const colums = [
    {
      accessor: 'selected',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <Checkbox
            className={classes.checkbox}
            checked={row.value}
            onChange={() => handleSelectRowFromTable(row.index)}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </div>
      ),
      filterable: false,
      width: 60,
    },
    {
      Header: <strong>Agencia</strong>,
      accessor: 'agency.name',
      Cell: (row) => (
        <Link to={`/edit-advisor/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
    },
    {
      Header: <strong>Nombre</strong>,
      accessor: 'user.name',
      Cell: (row) => (
        <Link to={`/edit-advisor/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>Correo</strong>,
      accessor: 'user.email',
      Cell: (row) => (
        <Link to={`/edit-advisor/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>Teléfono</strong>,
      accessor: 'phoneNumber',
      Cell: (row) => (
        <Link to={`/edit-advisor/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>Tipo</strong>,
      accessor: 'type',
      Cell: (row) => (
        <Link to={`/edit-advisor/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {getNested(null, row.original, 'user', 'role') ===
            'Advisor'
              ? row.value
              : 'n/a'}
          </div>
        </Link>
      ),
      Filter: (cell) => {
        return (
          <select
            onChange={(e) => cell.onChange(e.target.value)}
            value={
              cell.filter && cell.filter.value
                ? cell.filter.value
                : ''
            }
          >
            <option value="">Todos</option>
            {Array.isArray(categories) && categories.length > 0
              ? categories.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))
              : Object.keys(advisorTypes).map((key) => (
                  <option key={key} value={key}>
                    {advisorTypes[key]}
                  </option>
                ))}
            <option value="SIN INTERACCION">SIN INTERACCION</option>
          </select>
        );
      },
    },
    {
      Header: () => <strong>Días activo</strong>,
      accessor: 'timeTables',
      Cell: (row) => {
        const activeDays = getActiveDays(row.value);
        return (
          <Link to={`/edit-advisor/${row.original.id}`}>
            <div
              style={{
                textAlign: 'center',
              }}
            >
              {getNested(null, row.original, 'user', 'role') ===
              'Advisor' ? (
                <>
                  <span
                    className={
                      activeDays.includes('lunes')
                        ? classes.bold
                        : null
                    }
                  >
                    L
                  </span>
                  &nbsp;
                  <span
                    className={
                      activeDays.includes('martes')
                        ? classes.bold
                        : null
                    }
                  >
                    M
                  </span>
                  &nbsp;
                  <span
                    className={
                      activeDays.includes('miercoles')
                        ? classes.bold
                        : null
                    }
                  >
                    M
                  </span>
                  &nbsp;
                  <span
                    className={
                      activeDays.includes('jueves')
                        ? classes.bold
                        : null
                    }
                  >
                    J
                  </span>
                  &nbsp;
                  <span
                    className={
                      activeDays.includes('viernes')
                        ? classes.bold
                        : null
                    }
                  >
                    V
                  </span>
                  &nbsp;
                  <span
                    className={
                      activeDays.includes('sabado')
                        ? classes.bold
                        : null
                    }
                  >
                    S
                  </span>
                  &nbsp;
                  <span
                    className={
                      activeDays.includes('domingo')
                        ? classes.bold
                        : null
                    }
                  >
                    D
                  </span>
                </>
              ) : (
                'n/a'
              )}
            </div>
          </Link>
        );
      },
      filterable: false,
    },
    {
      Header: () => <strong>Activo</strong>,
      accessor: 'activated',
      Cell: (row) => (
        <Link to={`/edit-advisor/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {getNested(null, row.original, 'user', 'role') ===
            'Advisor' ? (
              <Badge
                className={classes.badges}
                variant={row.value ? 'success' : 'danger'}
              >
                {row.value ? 'Activo' : 'No activo'}
              </Badge>
            ) : (
              'n/a'
            )}
          </div>
        </Link>
      ),
      Filter: (cell) => {
        return (
          <select
            onChange={(e) => cell.onChange(e.target.value)}
            value={
              cell.filter && cell.filter.value
                ? cell.filter.value
                : ''
            }
          >
            <option value="">Todos</option>
            <option value="false">No activo</option>
            <option value="true">Activo</option>
          </select>
        );
      },
    },
    {
      Header: () => <strong>Prioridad</strong>,
      accessor: 'priority',
      Cell: inputToBeChanged,
    },
    {
      Header: '',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {getNested(null, row.original, 'user', 'role') ===
            'Agency Admin' && role === 'Super Admin' ? (
            <Tooltip title="Asignar agencia a usario">
              <IconButton
                size="small"
                onClick={() => {
                  toggleAssignAgencyModal();
                  setSelectedUser(row.original);
                }}
                className={classes.marginIcon}
              >
                <i className="fas fa-plus" />
              </IconButton>
            </Tooltip>
          ) : null}
          <Tooltip title="BORRAR">
            <IconButton
              size="small"
              onClick={() => {
                toggleDeleteModal();
                setSelectedUser(row.original);
              }}
              className={classes.marginIcon}
            >
              <i className="far fa-trash-alt"></i>
            </IconButton>
          </Tooltip>
        </div>
      ),
      filterable: false,
      // show: props.role === 'Super Admin',
    },
  ];

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {renderConfirmationDelete()}
        {renderAssingAgencyModal()}
        {renderFeedBackModal()}
        {renderMultipleUpdateHours()}
        <ReactTable
          NoDataComponent={() => (
            <div className="rt-noData">Sin resultados</div>
          )}
          data={usersFromState}
          columns={colums}
          filterable
          defaultFilterMethod={filterCaseInsensitive}
          className="-striped -highlight"
          previousText="Anterior"
          nextText="Siguiente"
          loading={props.users.isLoading}
          loadingText="Cargando datos..."
        />
      </Paper>
    </div>
  );
}

const mapStateToProps = (state) => ({
  users: state.users,
  usersFromdb: makeSelectUsersFromState(state),
  agenciesFromdb: makeSelectAgenciesFromdb(state),
  user: makeSelectUserFromState(state),
  role: makeSelectUserRoleFromState(state),
  ownedAgencies: makeSelectOwnedAgenciesFromState(state),
  agencyDetails: makeSelectAgencyDetailsFromState(state),
  categories: makeSelectUserCategoriesFromState(state),
});

const mapDispatchToProps = {
  getUsers,
  assignAgencyUser,
  deleteUser,
  deleteAgencyUser,
  closeModals,
  getAgencies,
  resendInvitation,
  updateUser,
  updateMultipleAdvisors,
  getAgencyDetail,
};

export default withRouter(
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(UsersTable),
  ),
);
