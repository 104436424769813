import { createSelector } from 'reselect';
import { initialState } from './initialState';

export const selectAgencies = (state) =>
  state.agencies || initialState;

export const makeSelectAgencies = createSelector(
  selectAgencies,
  (agenciesState) => agenciesState,
);

export const makeSelectAgenciesFromdb = createSelector(
  selectAgencies,
  (agenciesState) => agenciesState.fromdb,
);

export const makeSelectAgencyDetailsFromState = createSelector(
  selectAgencies,
  (agenciesState) => agenciesState.agencyDetails,
);

export const makeSelectAgenciesIsloading = createSelector(
  selectAgencies,
  (agenciesState) => agenciesState.isLoading,
);
