/* eslint-disable react/display-name */
import {
  Button,
  IconButton,
  TextField,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
import {
  cloneObjectLevels,
  compareDates,
  formatTime,
  getNested,
  withOutAccent,
} from 'app/utils';

import { Alert } from 'react-bootstrap';
import { DayCards } from 'app/features/users/components/DayCards';
import Modal from 'app/components/Modal';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import ReactTable from 'react-table';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  commission: {
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: 'black',
  },
  tables: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  leyendsContainer: {
    padding: theme.spacing(1, 3, 4, 5),
  },
  items: {
    padding: theme.spacing(0),
  },
  createScheduleButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '8px',
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
}));

const mapDayNames = {
  1: 'Lunes',
  2: 'Martes',
  3: 'Miércoles',
  4: 'Jueves',
  5: 'Viernes',
  6: 'Sábado',
  7: 'Domingo',
};

const cleanActiveDays = {
  1: false,
  2: false,
  3: false,
  4: false,
  5: false,
  6: false,
  7: false,
};

const cleanDays = {
  1: [{ from: '', to: '' }],
  2: [{ from: '', to: '' }],
  3: [{ from: '', to: '' }],
  4: [{ from: '', to: '' }],
  5: [{ from: '', to: '' }],
  6: [{ from: '', to: '' }],
  7: [{ from: '', to: '' }],
};

const PredefinedSchedule = ({
  patchTimeTable,
  timeTablePresets,
  isLoadingAgencies,
}) => {
  const classes = useStyles();

  const [days, setDays] = useState(cloneObjectLevels(cleanDays));
  const [
    showCreatecheduleModal,
    setShowCreatecheduleModal,
  ] = useState(false);
  const [showBadTimeAlert, setShowBadTimeAlert] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedTimeTable, setSelectedTimeTable] = useState(null);
  const [activeDays, setActiveDays] = useState(
    cloneObjectLevels(cleanActiveDays),
  );

  const { handleSubmit, errors, register } = useForm();

  const toggleCreateScheduleModal = () => {
    setDays(cloneObjectLevels(cleanDays));
    setActiveDays(cloneObjectLevels(cleanActiveDays));
    setSelectedTimeTable('');
    setShowCreatecheduleModal(!showCreatecheduleModal);
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const handleDeleteTimeTablePreset = () => {
    const selectedTimeTables = selectedTimeTable.map((timeTable) => ({
      ...timeTable,
      remove: true,
    }));
    patchTimeTable({ timeTablePresets: selectedTimeTables });
    setShowDeleteModal(false);
  };

  const renderConfirmationDelete = () => {
    return (
      <Modal
        open={showDeleteModal}
        closeModal={toggleDeleteModal}
        dialogTitle="¿Seguro que deseas eliminar el horario predefinido?"
        dialogText="Si continúas no habrá manera de recuperar la información
            que haya sido borrada."
        actionButtonText="Aceptar"
        onClick={handleDeleteTimeTablePreset}
      />
    );
  };

  const createSchedule = (formData) => {
    const timeTables = [];
    try {
      Object.keys(days).forEach((dayKey, index) => {
        days[dayKey].forEach((dayTime) => {
          if (dayTime.from && dayTime.to) {
            const day = withOutAccent(
              mapDayNames[dayKey].toLowerCase(),
            );
            if (compareDates(dayTime.from, dayTime.to))
              throw new Error('Bad time range');
            const hourASplited = dayTime.from.split(':');
            const hourBSplited = dayTime.to.split(':');
            const hourA = parseInt(hourASplited[0]);
            const minutesA = parseInt(hourASplited[1]);
            const hourB = parseInt(hourBSplited[0]);
            const minutesB = parseInt(hourBSplited[1]);
            const activated = activeDays[dayKey];
            timeTables.push({
              day,
              hourA,
              minutesA,
              hourB,
              minutesB,
              activated,
            });
          }
        });
      });
    } catch (e) {
      console.log(e);
      if (e.message === 'Bad time range')
        return setShowBadTimeAlert(true);
    }
    setShowCreatecheduleModal(false);
    patchTimeTable({
      name: formData.name,
      timeTablePresets: timeTables,
    });
    // updateMultipleAdvisors(data);
  };

  const getHoursPerDay = (timetablesArray, day) => {
    if (
      Array.isArray(timetablesArray) &&
      timetablesArray.length > 0
    ) {
      const hours = timetablesArray
        .filter((timeTable) => day === timeTable.day)
        .map((timeTable) => {
          return (
            <span key={`${timeTable.id}`}>
              {`${formatTime(timeTable.hourA)}:${formatTime(
                timeTable.minutesA,
              )} - ${formatTime(timeTable.hourB)}:${formatTime(
                timeTable.minutesB,
              )}`}
              <br />
            </span>
          );
        });
      if (hours.length > 0) return hours;
      else return <span>-</span>;
    }
  };

  const columns = [
    {
      Header: () => <strong>Nombre</strong>,
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {getNested('-', row.original, 0, 'name')}
        </div>
      ),
    },
    {
      Header: () => <strong>Lunes</strong>,
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {getHoursPerDay(row.original, 'lunes')}
        </div>
      ),
    },
    {
      Header: () => <strong>Martes</strong>,
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {getHoursPerDay(row.original, 'martes')}
        </div>
      ),
    },
    {
      Header: () => <strong>Miércoles</strong>,
      Cell: (row) => {
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {getHoursPerDay(row.original, 'miercoles')}
          </div>
        );
      },
    },
    {
      Header: () => <strong>Jueves</strong>,
      Cell: (row) => {
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {getHoursPerDay(row.original, 'jueves')}
          </div>
        );
      },
    },
    {
      Header: () => <strong>Viernes</strong>,
      Cell: (row) => {
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {getHoursPerDay(row.original, 'viernes')}
          </div>
        );
      },
    },
    {
      Header: () => <strong>Sábado</strong>,
      Cell: (row) => {
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {getHoursPerDay(row.original, 'sabado')}
          </div>
        );
      },
    },
    {
      Header: () => <strong>Domigo</strong>,
      Cell: (row) => {
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {getHoursPerDay(row.original, 'domingo')}
          </div>
        );
      },
    },
    {
      Header: '',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <Tooltip title="BORRAR">
            <IconButton
              size="small"
              onClick={() => {
                toggleDeleteModal();
                setSelectedTimeTable(row.original);
              }}
              className={classes.marginIcon}
            >
              <i className="far fa-trash-alt"></i>
            </IconButton>
          </Tooltip>
        </div>
      ),
      filterable: false,
      // show: props.role === 'Super Admin',
    },
  ];

  const renderError = () => {
    return (
      <Alert
        show={showBadTimeAlert}
        variant="danger"
        onClose={() => setShowBadTimeAlert(false)}
        dismissible
      >
        <Alert.Heading>Horario incorrecto</Alert.Heading>
        <p className={classes.alert}>
          Has ingresado un horario erróneo, la hora del campo *desde*
          debe ser mayor a la del campo *hasta*
        </p>
      </Alert>
    );
  };

  const renderMultipleUpdateHours = () => {
    return (
      <Modal
        open={showCreatecheduleModal}
        closeModal={toggleCreateScheduleModal}
        maxWidth="sm"
        fullWidth
        dialogTitle="Crear formato de horario"
        actionButtonText="Crear formato"
        dialogChildren={
          <>
            {renderError()}
            <form
              id="create-timetable-form"
              autoComplete="off"
              noValidate
              onSubmit={handleSubmit(createSchedule)}
            >
              <TextField
                inputRef={register({
                  required: true,
                })}
                name="name"
                required
                fullWidth
                type="text"
                label="Nombre del horario"
                variant="outlined"
                className={classes.textField}
                error={Boolean(errors.name)}
              />
              <DayCards
                days={days}
                setDays={setDays}
                activeDays={activeDays}
                setActiveDays={setActiveDays}
              />
            </form>
          </>
        }
        form={'create-timetable-form'}
      />
    );
  };

  return (
    <>
      {renderMultipleUpdateHours()}
      {renderConfirmationDelete()}
      <div className={classes.createScheduleButton}>
        <Button
          variant="contained"
          className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
          onClick={toggleCreateScheduleModal}
        >
          Crear horario
          <QueryBuilderIcon className="kt-margin-l-10" />
        </Button>
      </div>
      <ReactTable
        defaultPageSize={10}
        minRows={5}
        NoDataComponent={() => (
          <div className="rt-noData">Sin resultados</div>
        )}
        className={classes.table}
        data={getNested([], timeTablePresets)}
        columns={columns}
        previousText="Anterior"
        nextText="Siguiente"
        loading={isLoadingAgencies}
        loadingText="Cargando datos..."
      />
    </>
  );
};

export default PredefinedSchedule;
