import 'moment/locale/es';

/* eslint-disable react/display-name */
import {
  Button,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import {
  closeModals,
  createEvent,
  getAllEvents,
  updateEvent,
} from 'app/features/events/eventsSlice';
import { formatDate, getNested } from '../../../../utils';
import {
  makeSelectEventsFromdb,
  selectEvents,
} from 'app/features/events/selectors';
import {
  makeSelectUserFromState,
  makeSelectUserRoleFromState,
} from '../../../auth/selectors';

import { Add } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import MUIDatePickerFilter from 'app/components/MUIDatePickerFilter';
import MUIRHFAutocompleteAgencies from 'app/components/MUIRHFAutocompleteAgencies';
import Modal from 'app/components/Modal';
import { OverlayTrigger } from 'react-bootstrap';
import { Picker } from 'emoji-mart';
import ReactTable from 'react-table';
import ShouldItRender from 'app/components/ShouldItRender';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    widht: '100%',
    marginBottom: theme.spacing(2),
  },
  textField: {
    margin: theme.spacing(0, 0, 2, 0),
  },
}));

const EventsTable = (props) => {
  const classes = useStyles();
  const {
    events,
    getAllEvents,
    createEvent,
    updateEvent,
    role,
    user,
    eventsFromdb,
    closeModals,
    setActionButton,
  } = props;
  const [showCreateEventModal, setShowCreateEventModal] = useState(
    false,
  );

  const [
    showValidateEventModal,
    setShowValidateEventModal,
  ] = useState(false);
  const [selectedEvent, setSeletedEvent] = useState(null);
  const reactTable = useRef(null);

  const {
    handleSubmit,
    register,
    errors,
    setValue,
    watch,
    control,
    reset,
  } = useForm({
    defaultValues: {},
  });

  const values = watch();

  useEffect(() => {
    reactTable.current && reactTable.current.fireFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    renderButtons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderButtons = () => {
    setActionButton(
      <>
        <Button
          variant="contained"
          className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
          onClick={() => {
            setShowCreateEventModal(true);
            reset({
              name: '',
              message: '',
              categoryType: '',
            });
          }}
        >
          Crear evento
          <Add className="kt-margin-l-10" />
        </Button>
      </>,
    );
  };

  const handleFetchData = debounce(
    (state, instance) => {
      const { page, pageSize, filtered } = state;
      getAllEvents({
        page,
        pageSize,
        filtered,
      });
    },
    1000,
    { leading: false, trailing: true },
  );

  const handleCreateOrEditEvent = (formData) => {
    if (selectedEvent) handleEditEvent(formData);
    else handleCreateEvent(formData);
  };

  const handleCreateEvent = (formData) => {
    const data = {
      status: 'creado',
      reFetchData: reactTable.current.fireFetchData,
      ...formData,
    };
    createEvent(data);
    setShowCreateEventModal(false);
  };

  const handleEditEvent = (formData) => {
    const data = {
      reFetchData: reactTable.current.fireFetchData,
      ...formData,
      id: selectedEvent.id,
    };
    updateEvent(data);
    setShowCreateEventModal(false);
  };

  const handleValidateEvent = () => {
    const data = {
      reFetchData: reactTable.current.fireFetchData,
      id: selectedEvent.id,
      status: 'validado',
    };
    updateEvent(data);
    setShowValidateEventModal(false);
  };

  const renderEmojiPicker = () => (
    <span style={{ zIndex: 1300 }}>
      <Picker
        title=""
        emoji=""
        enableFrequentEmojiSort={false}
        emojiSize={20}
        showSkinTones={false}
        interactive={true}
        i18n={{
          search: 'Buscar',
          clear: 'Limpiar', // Accessible label on "clear" button
          notfound: 'Emoji no encontrado',
          skintext: 'Choose your default skin tone',
          categories: {
            search: 'Resultados',
            recent: 'Usados frecuentemente',
            smileys: 'Sonrisas y expresiones',
            people: 'Personas',
            nature: 'Animales y naturaleza',
            foods: 'Comida',
            activity: 'Actividades',
            places: 'Lugares y viajes',
            objects: 'Objetos',
            symbols: 'Símbolos',
            flags: 'Banderas',
            custom: 'Custom',
          },
        }}
        onSelect={(emoji) =>
          setValue('message', values.message + emoji.native)
        }
      />
    </span>
  );

  const renderCreateEvent = () => {
    return (
      <Modal
        open={showCreateEventModal}
        closeModal={() => {
          setShowCreateEventModal(false);
          setTimeout(() => {
            setSeletedEvent(null);
          }, 500);
        }}
        dialogTitle={
          selectedEvent ? 'Actualizar evento' : 'Crear evento'
        }
        dialogText="Ingrese los siguientes datos del evento."
        actionButtonText={
          selectedEvent ? 'Actualizar evento' : 'Crear evento'
        }
        dialogChildren={
          <form
            id="create-event-form"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit(handleCreateOrEditEvent)}
          >
            <ShouldItRender
              should={role === 'Super Admin' && !selectedEvent}
            >
              <MUIRHFAutocompleteAgencies
                name="agencyID"
                control={control}
                fullWidth
                className={classes.textField}
              />
            </ShouldItRender>
            <TextField
              name="name"
              label="Nombre del evento"
              error={Boolean(errors.name)}
              className={classes.textField}
              inputRef={register({
                required: true,
              })}
              margin="normal"
              variant="outlined"
            />
            <TextField
              inputRef={register({
                required: true,
              })}
              required
              fullWidth
              multiline
              name="message"
              rows="4"
              type="text"
              label="Mensaje"
              placeholder="Ingresa el mensaje 😄"
              variant="outlined"
              className={classes.textField}
              error={Boolean(errors.initialMessage)}
              inputProps={{
                maxLength: 500,
              }}
              InputProps={{
                endAdornment: (
                  <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    overlay={renderEmojiPicker()}
                  >
                    <Button>
                      <span
                        role="img"
                        aria-label=""
                        className={classes.emojiFace}
                      >
                        😁
                      </span>
                    </Button>
                  </OverlayTrigger>
                ),
              }}
            />
          </form>
        }
        form={'create-event-form'}
      />
    );
  };

  const renderViewEventModal = () => {
    return (
      <Modal
        open={showValidateEventModal}
        closeModal={() => {
          setShowValidateEventModal(false);
          setSeletedEvent(null);
        }}
        onClick={handleValidateEvent}
        dialogTitle="Validar evento"
        actionButtonText="Validar evento"
        dialogChildren={
          <>
            <TextField
              name="name"
              label="Nombre del evento"
              className={classes.textField}
              inputRef={register({
                required: true,
              })}
              InputProps={{
                readOnly: true,
              }}
              margin="normal"
              variant="outlined"
            />
            <TextField
              inputRef={register({
                required: true,
              })}
              InputProps={{
                readOnly: true,
              }}
              required
              fullWidth
              multiline
              name="message"
              rows="4"
              type="text"
              label="Mensaje"
              variant="outlined"
              className={classes.textField}
            />
          </>
        }
      />
    );
  };

  const colums = [
    {
      Header: () => <strong>Agencia</strong>,
      accessor: 'agency.name',
      Cell: (row) => (
        <Link to={`/event-details/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      show: role === 'Super Admin',
    },
    {
      Header: () => <strong>Nombre</strong>,
      accessor: 'name',
      Cell: (row) => (
        <Link to={`/event-details/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>Fecha de creación</strong>,
      accessor: 'createdAt',
      Cell: (row) => (
        <Link to={`/event-details/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {formatDate(row.value)}
          </div>
        </Link>
      ),
      Filter: (cell) => {
        return <MUIDatePickerFilter cell={cell} />;
      },
    },
    {
      Header: () => <strong>Última fecha de uso</strong>,
      accessor: 'lastUsed',
      Cell: (row) => (
        <Link to={`/event-details/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value ? formatDate(row.value) : '-'}
          </div>
        </Link>
      ),
      Filter: (cell) => {
        return <MUIDatePickerFilter cell={cell} />;
      },
    },
    {
      Header: () => <strong>Status</strong>,
      accessor: 'status',
      Cell: (row) => (
        <Link to={`/event-details/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong></strong>,
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <>
            <Tooltip title="Editar evento">
              <IconButton
                className={classes.button}
                size="small"
                onClick={() => {
                  setShowCreateEventModal(true);
                  setSeletedEvent(row.original);
                  reset({
                    name: row.original.name,
                    message: row.original.message,
                    categoryType: row.original.categoryType,
                  });
                }}
              >
                <i className="fas fa-edit"></i>
              </IconButton>
            </Tooltip>
            <ShouldItRender
              should={
                role === 'Super Admin' &&
                row.original.status === 'pendiente'
              }
            >
              <Tooltip title="Validar evento">
                <IconButton
                  className={classes.button}
                  size="small"
                  onClick={() => {
                    setShowValidateEventModal(true);
                    setSeletedEvent(row.original);
                    reset({
                      name: row.original.name,
                      message: row.original.message,
                      categoryType: row.original.categoryType,
                    });
                  }}
                >
                  <i className="fas fa-check-circle"></i>
                </IconButton>
              </Tooltip>
            </ShouldItRender>
          </>
        </div>
      ),
      width: 70,
      filterable: false,
    },
  ];

  const handleCloseModals = () => {
    closeModals();
  };

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = events;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message, redirect } = events[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={() => handleCloseModals(redirect)}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={() => handleCloseModals(redirect)}
          />
        );
      }
      return null;
    } catch (e) {
      console.log('Modal error: ', e);
      handleCloseModals();
      return null;
    }
  };

  return (
    <div className={classes.root}>
      {renderCreateEvent()}
      {renderViewEventModal()}
      {renderFeedBackModal()}
      <Paper className={classes.paper}>
        <ReactTable
          NoDataComponent={() => (
            <div className="rt-noData">Sin resultados</div>
          )}
          ref={reactTable}
          manual
          onFetchData={handleFetchData}
          data={getNested([], eventsFromdb)}
          columns={colums}
          pages={events.tablePages}
          filterable
          className="-striped -highlight"
          previousText="Anterior"
          nextText="Siguiente"
          loading={events.isLoading}
          loadingText="Cargando datos..."
        />
      </Paper>
    </div>
  );
};

const mapStateToProps = (state) => ({
  eventsFromdb: makeSelectEventsFromdb(state),
  events: selectEvents(state),
  role: makeSelectUserRoleFromState(state),
  user: makeSelectUserFromState(state),
});

const mapDispatchToProps = {
  getAllEvents,
  createEvent,
  updateEvent,
  closeModals,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EventsTable),
);
