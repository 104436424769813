/* eslint-disable react/display-name */
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { IconButton, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { Badge } from 'react-bootstrap';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import MomentUtils from '@date-io/moment';
import ReactTable from 'react-table';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import matchSorter from 'match-sorter';
import { traduceMinutesTime } from 'app/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  content: {
    display: 'flex',
    paddingBottom: '1rem',
    flexGrow: 1,
    flexDirection: 'column',
    minHeight: '95%',
    color: 'black',
  },
  desc: {
    fontWeight: 600,
    fontSize: '1.2rem',
    alignSelf: 'center',
    color: 'black',
    // color: kt-base-color(label, 2);
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
  },
  categoryPillcontainer: {
    alignSelf: 'center',
    marginTop: theme.spacing(2),
  },
  categoryPill: {
    fontSize: '.956em',
    fontWeight: 600,
    borderRadius: '7px',
  },
  ventasPill: {
    backgroundColor: '#effee1',
    color: '#249c66',
  },
  servicioPill: {
    backgroundColor: '#f6d8bc',
    color: '#814d49',
  },
  refaccionesPill: {
    backgroundColor: '#e9e7ff',
    color: '#345bc8',
  },
  seminuevosPill: {
    backgroundColor: '#e2f6f7',
    color: '#2b919c',
  },
  recepcionPill: {
    backgroundColor: '#effee1',
    color: '#2b919c',
  },
  datePicker: {
    width: '110px',
  },
  datePickerFistDate: {
    marginRight: theme.spacing(1),
  },
  datePickerSecondDate: {
    marginLeft: theme.spacing(1),
  },
  calendarIcon: {
    marginRight: '5px',
  },
  table: {
    border: 'none',
    boxShadow: 'none',
  },
  datePickersContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  advisorInput: {
    marginRight: theme.spacing(1),
  },
}));

export default function QuickAdvisorsStats({
  desc,
  advisorsFiltered,
  advisorsFilterStartDate,
  setAdvisorsFilterStartDate,
  advisorsFilterEndDate,
  setAdvisorsFilterEndDate,
  advisorName,
  setAdvisorName,
  isLoading,
}) {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [term, setTerm] = useState('');

  useEffect(() => {
    setData(
      matchSorter(advisorsFiltered, term, {
        keys: [
          'name',
          'type',
          'open',
          'Pendiente',
          'Ignorado',
          'responseTime',
        ],
      }),
    );
  }, [advisorName, advisorsFiltered, term]);

  const columns = [
    {
      Header: () => <strong>Nombre</strong>,
      accessor: 'name',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value}
        </div>
      ),
    },
    {
      Header: () => <strong>Categoría</strong>,
      accessor: 'type',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <Badge
            className={clsx(
              classes.categoryPill,
              classes[`${row.value}Pill`],
            )}
          >
            {row.value}
          </Badge>
        </div>
      ),
    },
    {
      Header: () => <strong>Asignados</strong>,
      accessor: 'count',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value}
        </div>
      ),
    },
    {
      Header: () => <strong>Pendientes</strong>,
      accessor: 'Pendiente',
      Cell: (row) => {
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        );
      },
    },
    {
      Header: () => <strong>Abiertas</strong>,
      accessor: 'open',
      Cell: (row) => {
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        );
      },
    },
    {
      Header: () => <strong>Ignorados</strong>,
      accessor: 'Ignorado',
      Cell: (row) => {
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        );
      },
    },
    {
      Header: () => <strong>Sin Interacción</strong>,
      accessor: 'noInteraction',
      Cell: (row) => {
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        );
      },
    },
    {
      Header: () => <strong>Prom. respuesta</strong>,
      accessor: 'responseTime',
      Cell: (row) => {
        let responseTime = 0;
        if (row.value)
          responseTime = traduceMinutesTime(
            Math.round(row.value / row.original.countResponseTime),
          );
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {responseTime}
          </div>
        );
      },
    },
  ];

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.header}>
          <span className={classes.desc}>{desc}</span>
          <div className={classes.datePickersContainer}>
            <TextField
              required
              className={classes.advisorInput}
              label="Buscar"
              type="text"
              variant="outlined"
              size="small"
              value={term}
              onChange={({ target: { value } }) => setTerm(value)}
              InputProps={{
                startAdornment: (
                  <IconButton
                    aria-label="Search"
                    size="small"
                    className={classes.calendarIcon}
                  >
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
            <MuiPickersUtilsProvider
              utils={MomentUtils}
              locale={'es'}
            >
              <DatePicker
                autoOk
                disableToolbar
                disableFuture
                size="small"
                inputVariant="outlined"
                format="DD MMM"
                className={clsx(
                  classes.datePicker,
                  classes.datePickerFistDate,
                )}
                value={advisorsFilterStartDate}
                onChange={setAdvisorsFilterStartDate}
                InputProps={{
                  startAdornment: (
                    <IconButton
                      aria-label="Select day"
                      size="small"
                      className={classes.calendarIcon}
                    >
                      <CalendarTodayIcon />
                    </IconButton>
                  ),
                }}
              />
              -
              <DatePicker
                autoOk
                disableToolbar
                disableFuture
                size="small"
                inputVariant="outlined"
                format="DD MMM"
                className={clsx(
                  classes.datePicker,
                  classes.datePickerSecondDate,
                )}
                value={advisorsFilterEndDate}
                onChange={setAdvisorsFilterEndDate}
                InputProps={{
                  startAdornment: (
                    <IconButton
                      aria-label="Select day"
                      size="small"
                      className={classes.calendarIcon}
                    >
                      <CalendarTodayIcon />
                    </IconButton>
                  ),
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>

        <ReactTable
          defaultPageSize={10}
          minRows={5}
          NoDataComponent={() => (
            <div className="rt-noData">Sin resultados</div>
          )}
          className={classes.table}
          getTheadProps={() => ({
            style: { border: 'none' },
          })}
          getTdProps={() => ({
            style: { border: 'none' },
          })}
          data={data}
          columns={columns}
          previousText="Anterior"
          nextText="Siguiente"
          loading={isLoading}
          loadingText="Cargando datos..."
        />
      </div>
    </div>
  );
}
