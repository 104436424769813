import { IconButton, Tooltip } from '@material-ui/core';
import { getNested, traduceMinutesTime } from 'app/utils';

import { Badge } from 'react-bootstrap';
import HelpIcon from '@material-ui/icons/Help';
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  content: {
    display: 'flex',
    paddingBottom: '1rem',
    flexGrow: 1,
    flexDirection: 'column',
    minHeight: '95%',
  },
  number: {
    fontWeight: 600,
    fontSize: '1.3rem',
    padding: '5px',
  },
  desc: {
    fontWeight: 600,
    fontSize: '1.2rem',
    alignSelf: 'center',
    marginLeft: theme.spacing(1.5),
    color: 'black',
    // color: kt-base-color(label, 2);
  },
  headerContainner: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  nameAndWaitTime: {
    margin: theme.spacing(3, 0, 1, 0),
    display: 'flex',
    justifyContent: 'space-between',
  },
  headers: {
    fontWeight: 600,
    fontSize: '.9rem',
  },
  waitingTimeContainter: {
    margin: theme.spacing(1.5, 0, 1.5, 0),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0, 0, 1, 0),
    borderBottom: '1px solid #efeff4',
  },
  waitingTimeBadge: {
    fontWeight: 600,
    fontSize: '1rem',
    padding: '5px',
    backgroundColor: '#feeeee',
    color: '#e74e3c',
  },
  clientName: {
    fontSize: '1.1rem',
    color: 'black',
  },
}));

const perfectScrollbarOptions = {
  wheelSpeed: 1,
  wheelPropagation: false,
};

export default function QuickStatusClients({
  value,
  desc,
  conversations,
  helpText,
}) {
  const classes = useStyles();

  const handleRenderWatingTime = (waitingTime) => {
    if (waitingTime > 15)
      return (
        <Badge className={classes.waitingTimeBadge} pill>
          {traduceMinutesTime(waitingTime)}
        </Badge>
      );
    else return `${waitingTime} min`;
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.headerContainner}>
          <div>
            <Badge className={classes.number} variant="dark">
              {value}
            </Badge>
            <span className={classes.desc}>{desc}</span>
          </div>
          <Tooltip title={helpText} placement="top">
            <IconButton aria-label={helpText} size="small">
              <HelpIcon />
            </IconButton>
          </Tooltip>
        </div>

        <div className={classes.nameAndWaitTime}>
          <span className={classes.headers}>Nombre</span>
          <span className={classes.headers}>Tiempo de espera</span>
        </div>
        <div>
          <PerfectScrollbar
            options={perfectScrollbarOptions}
            style={{ maxHeight: '18vh', position: 'relative' }}
          >
            {Array.isArray(conversations) &&
              conversations.map((conversation) => {
                const waitingTime = getNested(
                  '',
                  conversation,
                  'waitingTime',
                );
                return (
                  <Link
                    key={`${conversation.id}`}
                    to={`/conversation-details/${conversation.id}`}
                  >
                    <div className={classes.waitingTimeContainter}>
                      <span className={classes.clientName}>
                        {getNested(
                          getNested(
                            '-',
                            conversation,
                            'client',
                            'phoneNumber',
                          ),
                          conversation,
                          'client',
                          'name',
                        )}
                      </span>
                      {handleRenderWatingTime(waitingTime)}
                    </div>
                  </Link>
                );
              })}
          </PerfectScrollbar>
        </div>
      </div>
    </div>
  );
}
