import {
  acceptInvitation,
  assignAgencyUser,
  closeModals,
  createAdvisor,
  deleteAgencyUser,
  deleteUser,
  emailUserExists,
  getInvitations,
  getUserDetail,
  getUsers,
  getUsersCategories,
  inviteAdvisor,
  resendInvitation,
  updateAdvisor,
  updateMultipleAdvisors,
  updateUser,
} from '../features/users/userSlice';
import {
  acceptInvitationSaga,
  assignAgencyUserSaga,
  closeModalsAndRedirectSaga,
  createAdvisorSaga,
  deleteAgencyUserSaga,
  deleteUserSaga,
  emailUserExistsSaga,
  getAgencyUsersInvitationsSaga,
  getUserDetailSaga,
  getUsersCategoriesSaga,
  getUsersSaga,
  inviteAdvisorSaga,
  resendInvitationSaga,
  updateAdvisorSaga,
  updateMultipleAdvisorsSaga,
  updateUserSaga,
} from '../features/users/sagas';
import { all, takeLatest } from 'redux-saga/effects';
import {
  assignConversation,
  attendConversation,
  getAllConversations,
  getConversation,
  updateConversation,
  updateConversationClient,
  uploadFilesAndSendThem,
  updateMultipleConversations,
} from '../features/conversations/conversationsSlice';
import {
  assignConversationFromClient,
  attendConversationFromClient,
  getAllClients,
  getClient,
  updateClient,
  updateConversationFromClient,
} from 'app/features/clients/clientsSlice';
import {
  assignConversationFromClientSaga,
  attendConversationFromClientSaga,
  getAllClientsSaga,
  getClientSaga,
  updateClientSaga,
  updateConversationFromClientSaga,
} from 'app/features/clients/sagas';
import {
  assignConversationSaga,
  attendConversationSaga,
  getAllConversationsSaga,
  getConversationSaga,
  updateConversationClientSaga,
  updateConversationSaga,
  updateMultipleConversationsSaga,
  uploadFilesAndSendThemSaga,
} from '../features/conversations/sagas';
import {
  closeModals as campaignCloseModals,
  createCampaign,
  getAllCampaigns,
  getCampaignDetails,
} from '../features/campaign/campaignSlice';
import {
  cleanStoreSaga,
  confirmEmailSaga,
  getAgencyUsersSaga,
  loginAgencyUserSaga,
  loginUser,
  passwordResetSaga,
  requestPasswordResetSaga,
} from '../features/auth/sagas';
import {
  closeModalsAndRedirectCampaignSaga,
  createCampaignSaga,
  getAllCampaignsSaga,
  getCampaignDetailsSaga,
} from '../features/campaign/sagas';
import {
  closeModalsAndRedirectSetupSaga,
  createCategorySaga,
  deleteCategorySaga,
  deleteTimeTablesSaga,
  patchServiceHoursSaga,
  patchTimeTablesSaga,
  updateCategorySaga,
} from 'app/features/setup/sagas';
import {
  closeNotificationModals,
  sendNotificationsToAllUsers,
} from '../features/notifications/notificationsSlice';
import {
  closeNotificationModalsSaga,
  sendNotificationSaga,
  sendNotificationToAllUsersSaga,
} from '../features/notifications/sagas';
import {
  closeSetupModals,
  createCategory,
  deleteCategory,
  deleteTimeTable,
  patchServiceHours,
  patchTimeTable,
  updateCategory,
} from 'app/features/setup/setupSlice';
import {
  confirmEmail,
  getAgencyUsers,
  login,
  loginAgencyUser,
  logout,
  passwordReset,
  requestPasswordReset,
} from '../features/auth/authSlice';
import {
  createAgencySaga,
  getAgenciesSaga,
  getAgencyDetailSaga,
  updateAgencySaga,
  updateOptionAgencySaga,
  uploadLogoSaga,
} from '../features/agencies/sagas';
import {
  createEvent,
  createEventShipment,
  getAllEvents,
  getEvent,
  updateEvent,
} from 'app/features/events/eventsSlice';
import {
  createEventSaga,
  createEventShipmentSaga,
  getAllEventsSaga,
  getEventSaga,
  updateEventSaga,
} from 'app/features/events/sagas';

import { agenciesActionTypes } from '../features/agencies/actionTypes';
import { getData } from 'app/features/dashboard/dashboardSlice';
import { getDataForDashboardSaga } from 'app/features/dashboard/sagas';
import { getLogs } from '../features/logs/logsSlice';
import { getLogsSaga } from '../features/logs/sagas';
import { notificationSend } from '../features/notifications/notificationsSlice';

export default function* root() {
  yield all([
    takeLatest(login, loginUser),
    takeLatest(loginAgencyUser, loginAgencyUserSaga),
    takeLatest(getAgencyUsers, getAgencyUsersSaga),
    takeLatest(logout, cleanStoreSaga),
    takeLatest(requestPasswordReset, requestPasswordResetSaga),
    takeLatest(passwordReset, passwordResetSaga),
    takeLatest(confirmEmail, confirmEmailSaga),
    takeLatest(getUsers, getUsersSaga),
    takeLatest(getUsersCategories, getUsersCategoriesSaga),
    takeLatest(getInvitations, getAgencyUsersInvitationsSaga),
    takeLatest(deleteUser, deleteUserSaga),
    takeLatest(deleteAgencyUser, deleteAgencyUserSaga),
    takeLatest(resendInvitation, resendInvitationSaga),
    takeLatest(inviteAdvisor, inviteAdvisorSaga),
    takeLatest(emailUserExists, emailUserExistsSaga),
    takeLatest(acceptInvitation, acceptInvitationSaga),
    takeLatest(getUserDetail, getUserDetailSaga),
    takeLatest(updateUser, updateUserSaga),
    takeLatest(createAdvisor, createAdvisorSaga),
    takeLatest(closeModals, closeModalsAndRedirectSaga),
    takeLatest(agenciesActionTypes.GetAgencies, getAgenciesSaga),
    takeLatest(agenciesActionTypes.CreateAgency, createAgencySaga),
    takeLatest(agenciesActionTypes.UpdateAgency, updateAgencySaga),
    takeLatest(
      agenciesActionTypes.UpdateOptionAgency,
      updateOptionAgencySaga,
    ),
    takeLatest(
      agenciesActionTypes.GetAgencyDetail,
      getAgencyDetailSaga,
    ),
    takeLatest(agenciesActionTypes.UploadAgencyLogo, uploadLogoSaga),
    takeLatest(getAllConversations, getAllConversationsSaga),
    takeLatest(getConversation, getConversationSaga),
    takeLatest(getLogs, getLogsSaga),
    takeLatest(attendConversation, attendConversationSaga),
    takeLatest(updateConversation, updateConversationSaga),
    takeLatest(
      updateMultipleConversations,
      updateMultipleConversationsSaga,
    ),
    takeLatest(
      updateConversationClient,
      updateConversationClientSaga,
    ),
    takeLatest(assignConversation, assignConversationSaga),
    takeLatest(uploadFilesAndSendThem, uploadFilesAndSendThemSaga),
    takeLatest(updateAdvisor, updateAdvisorSaga),
    takeLatest(updateMultipleAdvisors, updateMultipleAdvisorsSaga),
    takeLatest(assignAgencyUser, assignAgencyUserSaga),
    takeLatest(notificationSend, sendNotificationSaga),
    takeLatest(
      sendNotificationsToAllUsers,
      sendNotificationToAllUsersSaga,
    ),
    takeLatest(closeNotificationModals, closeNotificationModalsSaga),
    takeLatest(getAllCampaigns, getAllCampaignsSaga),
    takeLatest(createCampaign, createCampaignSaga),
    takeLatest(
      campaignCloseModals,
      closeModalsAndRedirectCampaignSaga,
    ),
    takeLatest(getCampaignDetails, getCampaignDetailsSaga),
    takeLatest(getData, getDataForDashboardSaga),
    takeLatest(patchTimeTable, patchTimeTablesSaga),
    takeLatest(deleteTimeTable, deleteTimeTablesSaga),
    takeLatest(createCategory, createCategorySaga),
    takeLatest(updateCategory, updateCategorySaga),
    takeLatest(deleteCategory, deleteCategorySaga),
    takeLatest(patchServiceHours, patchServiceHoursSaga),
    takeLatest(closeSetupModals, closeModalsAndRedirectSetupSaga),
    takeLatest(getAllClients, getAllClientsSaga),
    takeLatest(getClient, getClientSaga),
    takeLatest(updateClient, updateClientSaga),
    takeLatest(
      updateConversationFromClient,
      updateConversationFromClientSaga,
    ),
    takeLatest(
      assignConversationFromClient,
      assignConversationFromClientSaga,
    ),
    takeLatest(
      attendConversationFromClient,
      attendConversationFromClientSaga,
    ),
    takeLatest(getAllEvents, getAllEventsSaga),
    takeLatest(getEvent, getEventSaga),
    takeLatest(createEvent, createEventSaga),
    takeLatest(updateEvent, updateEventSaga),
    takeLatest(createEventShipment, createEventShipmentSaga),
  ]);
}
