import { createSelector } from 'reselect';
import { initialState } from './initialState';

export const selectConversations = (state) =>
  state.conversations || initialState;

export const makeSelectConversations = createSelector(
  selectConversations,
  (conversationsState) => conversationsState,
);

export const makeSelectConversatioToDownload = createSelector(
  selectConversations,
  (conversationsState) => conversationsState.toDownload,
);

export const makeSelectIsLoadingConversationsFromdb = createSelector(
  selectConversations,
  (conversationsState) => conversationsState.isLoading,
);

export const makeSelectConversationDetails = createSelector(
  selectConversations,
  (conversationsState) => conversationsState.conversationDetails,
);
