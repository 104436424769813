export const permissions = {
  'Super Admin': {
    '/users': ['addAdvisor', 'inviteAdvisor'],
    '/create-advisor': ['selectRole', 'selectAgency'],
    '/edit-advisor': ['selectRole', 'selectAgency'],
    '/notifications': ['selectAgency'],
    '/conversation-details': ['selectAgency'],
    '/client-details': ['selectAgency'],
  },
  'Agency Admin': {
    '/users': ['addAdvisor', 'view'],
    '/create-advisor': [],
    '/edit-advisor': [],
    '/notifications': [],
    '/conversation-details': [],
    '/client-details': [],
  },
  Advisor: {
    '/users': ['view'],
    '/create-advisor': [],
    '/edit-advisor': [],
    '/notifications': [],
    '/conversation-details': [],
    '/client-details': [],
  },
};
