import { call, put, select } from 'redux-saga/effects';
import {
  createCampaignFail,
  createCampaignSuccess,
  getAllCampaignsFail,
  getAllCampaignsForDownloadFail,
  getAllCampaignsForDownloadSuccess,
  getAllCampaignsSuccess,
  getCampaignDetailsFail,
  getCampaignDetailsSuccess,
} from './campaignSlice.js';
import {
  makeSelectUserID,
  makeSelectUserRole,
} from '../auth/selectors.js';

import { formatDatesForFilter } from 'app/utils/index.js';
import { getLogs } from '../logs/logsSlice.js';
import { requestHandler } from '../../services/requestHandler';

export function* getAllCampaignsSaga(action) {
  const role = yield select(makeSelectUserRole());
  const userID = yield select(makeSelectUserID());
  const {
    firstDate,
    secondDate,
    page,
    pageSize,
    filtered,
  } = action.payload;
  try {
    const data = {
      where: {},
      pagination: {
        page,
        perPage: pageSize,
        order: [['updatedAt', 'DESC']],
      },
      options: {
        count: true,
        distinct: true,
      },
      campaignClicks: {
        conversations: true,
      },
      customFlows: true,
    };
    if (filtered.length) {
      filtered.forEach((filter) => {
        if (filter.id === 'createdAt')
          data.where[filter.id] = formatDatesForFilter(filter.value);
        else data.where[filter.id] = { $ilike: `%${filter.value}%` };
      });
    }
    if (firstDate && secondDate) {
      data.where.createdAt = {
        $gt: firstDate.utcOffset(0).startOf('day'),
        $lt: secondDate.utcOffset(0).endOf('day'),
      };
      yield call(getAllCampaignsForDownloadSaga, {
        payload: { data },
      });
    }
    const campaigns = yield call(requestHandler, {
      method: 'POST',
      path: '/campaigns/getAll',
      data,
    });
    campaigns.pages = yield call(
      Math.ceil,
      campaigns.count / pageSize,
    );
    yield put(getAllCampaignsSuccess({ campaigns }));
  } catch (e) {
    yield put(getAllCampaignsFail('getUserBySuperAdmin'));
    console.log('error in getting campaigns', e);
  }
}

export function* getAllCampaignsForDownloadSaga(action) {
  try {
    const data = { ...action.payload.data };
    delete data.pagination;
    delete data.options;
    const campaigns = yield call(requestHandler, {
      method: 'POST',
      path: '/campaigns/getAll',
      data,
    });
    yield put(getAllCampaignsForDownloadSuccess({ campaigns }));
  } catch (e) {
    yield put(getAllCampaignsForDownloadFail('getUserBySuperAdmin'));
    console.log(
      'error in get usergetting campaigns for download saga',
      e,
    );
  }
}

export function* getCampaignDetailsSaga(action) {
  try {
    const { campaignID } = action.payload;
    const data = {
      where: { id: campaignID },
      campaignClicks: {
        conversations: true,
      },
      customFlows: true,
    };
    const campaign = yield call(requestHandler, {
      method: 'POST',
      path: '/campaigns/getOne',
      data,
    });
    yield put(getCampaignDetailsSuccess({ campaign }));
    // yield put(
    //   getLogs({
    //     object: 'conversations',
    //     objectID: id,
    //   }),
    // );
  } catch (e) {
    yield put(getCampaignDetailsFail({}));
    console.log('error in get user BySuperAdmin saga', e);
  }
}

export function* createCampaignSaga(action) {
  try {
    const data = {
      ...action.payload,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/campaigns/create',
      data,
    });
    const message = {
      title: 'Campaña creada',
      desc: `La campaña se ha creado correctamente`,
    };
    const redirect = '/campaigns';
    yield put(createCampaignSuccess({ message, redirect }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Error al tratar de crear la campaña, por favor intenta más tarde`,
    };
    yield put(createCampaignFail({ message }));
    console.log('error in get user BySuperAdmin saga', e);
  }
}

export function* closeModalsAndRedirectCampaignSaga(action) {
  const { history, redirect } = action.payload;
  try {
    if (redirect) yield call(history.push, redirect);
  } catch (e) {
    console.log('error: ', e);
  }
}

// export function* getAllConversationsForDownloadSaga(action) {
//   const { firstDate, secondDate } = action.payload;
//   const role = yield select(makeSelectUserRole());
//   const userID = yield select(makeSelectUserID());
//   try {
//     const data = {
//       where: {},
//       pagination: {
//         order: [['updatedAt', 'DESC']],
//       },
//       clients: true,
//       users: true,
//       agencies: true,
//     };
//     if (firstDate && secondDate)
//       data.where.createdAt = {
//         $gt: firstDate.format('YYYY-MM-DDT01:00:00.000Z'),
//         $lt: secondDate.format('YYYY-MM-DDT23:59:00.000Z'),
//       };
//     if (role === 'Advisor') {
//       data.where = { ...data.where, userID };
//     }
//     const conversations = yield call(requestHandler, {
//       method: 'POST',
//       path: '/conversations/getAll',
//       data,
//     });
//     yield put(
//       getAllConversationsForDownloadSuccess({ conversations }),
//     );
//   } catch (e) {
//     yield put(
//       getAllConversationsForDownloadFail('getUserBySuperAdmin'),
//     );
//     console.log('error in get user BySuperAdmin saga', e);
//   }
// }

// export function* prospectConversationSaga(action) {
//   try {
//     const { conversationID } = action.payload;
//     const data = {
//       id: conversationID,
//       status: 'Prospecto',
//     };
//     yield call(requestHandler, {
//       method: 'POST',
//       path: '/conversations/update',
//       data,
//     });
//     const message = {
//       title: 'Cliente prospecto',
//       desc: `La conversación se actualizó correctamente`,
//     };
//     yield put(attendConversationSuccess({ message }));
//     yield call(getConversationSaga, {
//       payload: { id: conversationID },
//     });
//   } catch (e) {
//     const message = {
//       title: 'Error',
//       desc: `Error al tratar de actualizar la conversación, por favor intenta más tarde`,
//     };
//     yield put(attendConversationFail({ message }));
//     console.log('error in prospect: ', e);
//   }
// }
