import { call, put, select } from 'redux-saga/effects';
import { dateIsBetween, getNested, getWatingTime } from '../../utils';
import {
  getAdvisorsForDashboardFail,
  getAdvisorsForDashboardSuccess,
  getCampaignsForDashboardSuccess,
  getConversationsForDashboardFail,
  getConversationsForDashboardSuccess,
  getDataSuccess,
  getEventsForDashboardFail,
  getEventsForDashboardSuccess,
} from './dashboardSlice';

import { makeSelectAgencyId } from '../auth/selectors.js';
import { makeSelectconversationsfromdb } from './selectors.js';
import moment from 'moment';
import { requestHandler } from '../../services/requestHandler';

export function* getDataForDashboardSaga(action) {
  const {
    agencyId,
    categoryStartDate,
    categoryEndDate,
    advisorsFilterStartDate,
    advisorsFilterEndDate,
    conversationsSourceStartDate,
    consversationsSourceEndDate,
    campaignsSourceStartDate,
    campaignsSourceEndDate,
  } = action.payload;
  yield call(getConversationsForDashboardSaga, {
    payload: {
      agencyId,
      categoryStartDate,
      categoryEndDate,
      conversationsSourceStartDate,
      consversationsSourceEndDate,
    },
  });
  yield call(getCampaignsForDasboardSaga, {
    payload: {
      agencyId,
      campaignsSourceStartDate,
      campaignsSourceEndDate,
    },
  });
  yield call(getAdvisorsForDashboardSaga, {
    payload: {
      agencyId,
      advisorsFilterStartDate,
      advisorsFilterEndDate,
    },
  });
  yield call(getEventsForDashboardSaga, {
    payload: {
      agencyId,
    },
  });
  yield put(getDataSuccess());
}

export function* getCampaignsForDasboardSaga(action) {
  try {
    const {
      campaignsSourceStartDate,
      campaignsSourceEndDate,
    } = action.payload;

    let agencyId = '';
    if (action && action.payload && action.payload.agencyId)
      agencyId = action.payload.agencyId;
    const data = {
      where: {},
      // options: {
      //   order: [['updatedAt', 'DESC']],
      // },
      campaignClicks: {
        conversations: true,
      },
    };
    // if (campaignsSourceStartDate) {
    //   data.where.createdAt = {
    //     $gt: campaignsSourceStartDate.utcOffset(0).startOf('day'),
    //     // $lt: secondDate.utcOffset(0).endOf('day'),
    //   };
    // }
    // console.log('DATA', data);
    const campaigns = yield call(requestHandler, {
      method: 'POST',
      path: '/campaigns/getAll',
      data,
    });
    const campaignAgency = campaigns.filter(
      (el) => el.agencyID === agencyId,
    );

    yield put(
      getCampaignsForDashboardSuccess({
        fromdb: campaignAgency,
        ...campaignAgency,
      }),
    );
  } catch (error) {
    console.log(error);
  }
}

export function* getConversationsForDashboardSaga(action) {
  try {
    const {
      categoryStartDate,
      categoryEndDate,
      conversationsSourceStartDate,
      consversationsSourceEndDate,
    } = action.payload;
    let agencyId = '';
    if (action && action.payload && action.payload.agencyId)
      agencyId = action.payload.agencyId;
    else agencyId = yield select(makeSelectAgencyId());
    const data = {
      where: {},
      clients: {
        options: {
          attributes: ['name', 'phoneNumber'],
        },
      },
      agencyUsers: {
        options: {
          attributes: ['type'],
        },
      },
      options: {
        order: [['updatedAt', 'DESC']],
      },
    };
    if (agencyId)
      data.agencies = {
        where: { id: agencyId },
        options: { attributes: ['id'] },
      };
    data.where.createdAt = {
      $gt: moment('2020-12-16').startOf('day'),
    };
    const conversationsFromdb = yield call(requestHandler, {
      method: 'POST',
      path: '/conversations/getAll',
      data,
    });

    let conversationsFiltered = conversationsFromdb
      .filter((conversation) => conversation.status !== 'Ignorado')
      .reduce(
        (acc, conversation) => {
          switch (conversation.status) {
            case 'SIN INTERACCION':
              conversation.waitingTime = getWatingTime(
                conversation.createdAt,
                conversation.repliedAt,
                conversation.assignedAt,
                conversation.manuallyAssigned,
                true,
              );
              acc.noInteraction.push(conversation);
              break;
            case 'Pendiente':
              conversation.waitingTime = getWatingTime(
                conversation.createdAt,
                conversation.repliedAt,
                conversation.assignedAt,
                conversation.manuallyAssigned,
                true,
              );
              if (conversation?.inactive)
                acc.noInteraction.push(conversation);
              else acc.pending.push(conversation);
              break;
            case 'Asesor Asignado':
              conversation.waitingTime = getWatingTime(
                conversation.createdAt,
                conversation.repliedAt,
                conversation.assignedAt,
                conversation.manuallyAssigned,
                true,
              );
              acc.assignedAdvisor.push(conversation);
              break;
            case 'Conversacion con Asesor iniciada':
              const type = getNested(
                null,
                conversation,
                'agencyUser',
                'type',
              );
              if (type) acc.converStartedWidthAdvisorType[type]++;
              break;
            default:
              break;
          }
          if (
            dateIsBetween(
              categoryStartDate,
              categoryEndDate,
              conversation.createdAt,
            ) &&
            conversation.repliedAt &&
            conversation.agencyUser &&
            conversation.agencyUser.type
          ) {
            const responseTime = getWatingTime(
              conversation.createdAt,
              conversation.repliedAt,
              conversation.assignedAt,
              conversation.manuallyAssigned,
              false,
            );
            acc[
              `${conversation.agencyUser.type}ResponseTime`
            ] += responseTime;
            acc[`${conversation.agencyUser.type}Count`]++;
          }
          if (
            dateIsBetween(
              categoryStartDate,
              categoryEndDate,
              conversation.createdAt,
            ) &&
            conversation.repliedAt &&
            conversation.agencyUser &&
            conversation.agencyUser.type
          ) {
            const responseTime = getWatingTime(
              conversation.createdAt,
              conversation.repliedAt,
              conversation.assignedAt,
              conversation.manuallyAssigned,
              false,
            );
            acc[
              `${conversation.agencyUser.type}ResponseTime`
            ] += responseTime;
            acc[`${conversation.agencyUser.type}Count`]++;
          }
          if (
            (conversation.source in acc.conversationsSources ||
              conversation.source === null) &&
            dateIsBetween(
              conversationsSourceStartDate,
              consversationsSourceEndDate,
              conversation.createdAt,
            )
          ) {
            if (conversation.source === null)
              acc.conversationsSources['Redes Sociales'].push(
                conversation.createdAt,
              );
            else
              acc.conversationsSources[conversation.source].push(
                conversation.createdAt,
              );
          }
          return acc;
        },
        {
          pending: [],
          noInteraction: [],
          assignedAdvisor: [],
          ventasResponseTime: 0,
          ventasCount: 0,
          servicioResponseTime: 0,
          servicioCount: 0,
          recepcionResponseTime: 0,
          recepcionCount: 0,
          refaccionesResponseTime: 0,
          refaccionesCount: 0,
          seminuevosResponseTime: 0,
          seminuevosCount: 0,
          financiamientoResponseTime: 0,
          financiamientoCount: 0,
          converStartedWidthAdvisorType: {
            ventas: 0,
            servicio: 0,
            recepcion: 0,
            refacciones: 0,
            seminuevos: 0,
            financiamiento: 0,
          },
          conversationsSources: {
            Micrositio: [],
            'Redes Sociales': [],
          },
        },
      );
    yield put(
      getConversationsForDashboardSuccess({
        fromdb: conversationsFromdb,
        ...conversationsFiltered,
      }),
    );
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba obtener las órdenes de pago.
      Intente de nuevo, si el error persiste intente más tarde.`,
    };
    yield put(getConversationsForDashboardFail({ message }));
    console.log('error in login user saga', e);
  }
}

export function* getAdvisorsForDashboardSaga(action) {
  try {
    const {
      advisorsFilterStartDate,
      advisorsFilterEndDate,
    } = action.payload;
    let agencyID = '';
    if (action && action.payload && action.payload.agencyId)
      agencyID = action.payload.agencyId;
    else agencyID = yield select(makeSelectAgencyId());
    const conversationsFromdb = yield select(
      makeSelectconversationsfromdb(),
    );
    let data = {
      where: {
        agencyID,
      },
      pagination: { order: [['createdAt', 'DESC']] },
      users: {
        where: {
          role: 'Advisor',
        },
        options: { attributes: ['name'] },
      },
    };
    const agencyUsers = yield call(requestHandler, {
      method: 'POST',
      path: '/agencyUsers/getAll',
      data,
    });
    data = {
      where: {
        agencyID,
        agencyUserID: { $not: null },
      },
      pagination: { order: [['createdAt', 'DESC']] },
    };

    const advisorsObject = agencyUsers.reduce((acc, agencyUser) => {
      acc[agencyUser.id] = {
        name: agencyUser.user.name,
        type: agencyUser.type,
        open: 0,
        Pendiente: 0,
        Ignorado: 0,
        noInteraction: 0,
        count: 0,
        responseTime: 0,
        countResponseTime: 0,
      };
      return acc;
    }, {});

    const advisorsFilterdObject = conversationsFromdb
      .filter((conversation) =>
        dateIsBetween(
          advisorsFilterStartDate,
          advisorsFilterEndDate,
          conversation.createdAt,
        ),
      )
      .reduce((acc, conversation) => {
        if (
          conversation.agencyUserID &&
          conversation.agencyUserID in acc
        ) {
          switch (conversation.status) {
            case 'Pendiente':
              if (conversation?.inactive) {
                acc[conversation.agencyUserID]['noInteraction'] += 1;
              } else {
                acc[conversation.agencyUserID][
                  conversation.status
                ] += 1;
              }
              acc[conversation.agencyUserID].count += 1;
              break;
            case 'Ignorado':
              acc[conversation.agencyUserID][
                conversation.status
              ] += 1;
              acc[conversation.agencyUserID].count += 1;
              break;
            case 'Conversacion con Asesor iniciada':
            case 'Prospecto':
              acc[conversation.agencyUserID]['open'] += 1;
              acc[conversation.agencyUserID].count += 1;
              break;
            default:
              break;
          }
          switch (conversation.status) {
            case 'Pendiente':
            case 'Conversacion con Asesor iniciada':
            case 'Prospecto':
              if (!conversation?.inactive) {
                acc[
                  conversation.agencyUserID
                ].responseTime += getWatingTime(
                  conversation.createdAt,
                  conversation.repliedAt,
                  conversation.assignedAt,
                  conversation.manuallyAssigned,
                  false,
                );
                acc[conversation.agencyUserID].countResponseTime += 1;
              }
              break;
            default:
              break;
          }
        }
        return acc;
      }, advisorsObject);

    const advisorsFilterd = Object.keys(advisorsFilterdObject).map(
      (key) => advisorsFilterdObject[key],
    );
    yield put(getAdvisorsForDashboardSuccess({ advisorsFilterd }));
  } catch (e) {
    yield put(getAdvisorsForDashboardFail('getUserBySuperAdmin'));
    console.log('error in get user BySuperAdmin saga', e);
  }
}

export function* getEventsForDashboardSaga(action) {
  try {
    let agencyID = '';
    if (action && action.payload && action.payload.agencyId)
      agencyID = action.payload.agencyId;
    else agencyID = yield select(makeSelectAgencyId());
    let data = {
      where: {
        agencyID,
      },
      pagination: { order: [['createdAt', 'DESC']] },
    };
    const events = yield call(requestHandler, {
      method: 'POST',
      path: '/events/getAll',
      data,
    });

    yield put(getEventsForDashboardSuccess({ events }));
  } catch (e) {
    yield put(getEventsForDashboardFail('getUserBySuperAdmin'));
    console.log('error in get user BySuperAdmin saga', e);
  }
}
