/* eslint-disable react/display-name */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Tab,
  Tabs,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import {
  closeModals,
  getAgencyDetail,
  updateAgency,
} from '../../../agencies/actions';
import {
  closeSetupModals,
  createCategory,
  deleteCategory,
  deleteTimeTable,
  patchServiceHours,
  patchTimeTable,
  updateCategory,
} from 'app/features/setup/setupSlice';

import Categories from '../../components/Categories';
import GeneralConfig from 'app/features/setup/components/GeneralConfig';
import Modal from '../../../../components/Modal';
import PredefinedSchedule from 'app/features/setup/components/PredefinedSchedule';
import ServiceSchedule from 'app/features/setup/components/ServiceSchedule';
import SplashScreen from '../../../../partials/layout/SplashScreen';
import { TabPanel } from 'app/components/TabPanel';
import { connect } from 'react-redux';
import { getNested } from '../../../../utils';
import { getUsersCategories } from 'app/features/users/userSlice';
import { injectIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import { makeSelectAgencyDetailsFromState } from '../../../agencies/selectors';
import { makeSelectUserFromState } from '../../../auth/selectors';
import { makeSelectUsersCategoriesFromState } from 'app/features/users/selectors';
import { makeStyles } from '@material-ui/core/styles';
import { selectSetup } from 'app/features/setup/selectors';
import { withRouter } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  paperInfo: {
    padding: theme.spacing(0),
    textAlign: 'left',
  },
  title: {
    marginTop: 25,
  },
  agency: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    margin: theme.spacing(0),
  },
  logo: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  tabsContainer: {
    marginLeft: theme.spacing(4),
  },
}));

function ProfileAgency(props) {
  const classes = useStyles();
  const {
    agencies,
    agencyDetails,
    getAgencyDetail,
    user,
    createCategory,
    updateCategory,
    deleteCategory,
    patchTimeTable,
    patchServiceHours,
    deleteTimeTable,
    setup,
    usersCategories,
    getUsersCategories,
    closeSetupModals,
    history,
  } = props;
  const [agencyData, setAgency] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [tab, setTab] = React.useState(0);
  const [data, setData] = React.useState({});

  useEffect(() => {
    getAgencyDetail();
    getUsersCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (agencyDetails) {
      const editAgency = {
        id: agencyDetails.id,
        name: agencyDetails.name ? agencyDetails.name : '',
        email: agencyDetails.email ? agencyDetails.email : '',
        phoneNumber: agencyDetails.phoneNumber
          ? agencyDetails.phoneNumber
          : '',
        nickname: agencyDetails.nickname
          ? agencyDetails.nickname
          : '',
        logo: agencyDetails.logo ? agencyDetails.logo : '',
        menuMessage: getNested('', agencyDetails, 'menuMessage'),
        advisorTimeout: getNested(
          '',
          agencyDetails,
          'advisorTimeout',
        ),
        scoreMessage: getNested('', agencyDetails, 'scoreMessage'),
        unavailableMessage: getNested(
          '',
          agencyDetails,
          'unavailableMessage',
        ),
        inactivityMessage: getNested(
          '',
          agencyDetails,
          'inactivityMessage',
        ),
        defaultType: getNested('', agencyDetails, 'defaultType'),
      };
      setAgency(editAgency);
    }
  }, [agencyDetails]);

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseModals = () => {
    handleClose();
    props.closeModals({ history });
  };

  const handleCloseSetupModals = () => {
    handleClose();
    closeSetupModals({ history });
  };

  const handleUpdate = () => {
    handleClose();
    props.updateAgency(data);
  };

  const renderConfirmation = () => {
    return (
      <div>
        <Dialog
          fullWidth={true}
          maxWidth="xs"
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            ¿Seguro que deseas editar la agencia?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Se actualizará la agencia con la nueva información que
              proporciones
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleUpdate} color="primary">
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = props.agencies;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message } = props.agencies[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={handleCloseModals}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={handleCloseModals}
          />
        );
      }
      return null;
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  const renderSetupFeedBackModal = () => {
    try {
      const { successModal, errorModal } = setup;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message } = setup[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={handleCloseSetupModals}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={handleCloseSetupModals}
          />
        );
      }
      return null;
    } catch (e) {
      console.log('e: ', e);
      handleCloseSetupModals();
      return null;
    }
  };

  return isEmpty(agencyData) ? (
    <SplashScreen />
  ) : (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <Paper className={classes.paperInfo}>
            <div className={classes.tabsContainer}>
              <Tabs
                value={tab}
                onChange={handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="Configuración general" />
                <Tab label="Categorías" />
                <Tab label="Horarios predefinidos" />
                <Tab label="Horarios de servicio" />
              </Tabs>
            </div>
            <TabPanel value={tab} index={0}>
              <GeneralConfig
                agencyData={agencyData}
                setData={setData}
                isLoading={agencies.isLoading}
                handleClickOpen={handleClickOpen}
                usersCategories={usersCategories}
              />
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <Categories
                customFlows={getNested(
                  [],
                  agencyDetails,
                  'customFlows',
                )}
                createCategory={createCategory}
                updateCategory={updateCategory}
                deleteCategory={deleteCategory}
              />
            </TabPanel>
            <TabPanel value={tab} index={2}>
              <PredefinedSchedule
                patchTimeTable={patchTimeTable}
                isLoadingAgencies={agencies.isLoading}
                deleteTimeTable={deleteTimeTable}
                timeTablePresets={getNested(
                  [],
                  agencyDetails,
                  'timeTablePresets',
                )}
              />
            </TabPanel>
            <TabPanel value={tab} index={3}>
              <ServiceSchedule
                patchServiceHours={patchServiceHours}
                isLoadingAgencies={agencies.isLoading}
                deleteTimeTable={deleteTimeTable}
                agencyTimeTables={getNested(
                  [],
                  agencyDetails,
                  'timeTables',
                )}
              />
            </TabPanel>
          </Paper>
        </Grid>
      </Grid>
      {renderFeedBackModal()}
      {renderSetupFeedBackModal()}
      {renderConfirmation()}
    </div>
  );
}

const mapStateToProps = (state) => ({
  setup: selectSetup(state),
  agencies: state.agencies,
  user: makeSelectUserFromState(state),
  agencyDetails: makeSelectAgencyDetailsFromState(state),
  usersCategories: makeSelectUsersCategoriesFromState(state),
});

const mapDispatchToProps = {
  getAgencyDetail,
  updateAgency,
  closeModals,
  createCategory,
  deleteCategory,
  patchTimeTable,
  deleteTimeTable,
  updateCategory,
  patchServiceHours,
  getUsersCategories,
  closeSetupModals: closeSetupModals,
};

export default withRouter(
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(ProfileAgency),
  ),
);
