import { call, put, select } from 'redux-saga/effects';
import {
  createAgencyFail,
  createAgencySuccess,
  getAgencies,
  getAgenciesFail,
  getAgenciesSuccess,
  getAgencyDetailFail,
  getAgencyDetailSuccess,
  updateAgencyFail,
  updateAgencySuccess,
  updateOptionFail,
  updateOptionSuccess,
  updloadLogoFail,
  updloadLogoSuccess,
} from './actions';
import {
  makeSelectAgencyId,
  makeSelectUserRole,
} from '../auth/selectors.js';

import { groupTimeTables } from 'app/utils';
import { requestHandler } from '../../services/requestHandler';

export function* getAgenciesSaga(action) {
  try {
    const data = {
      customFlows: true,
      options: {
        attributes: [
          'id',
          'name',
          'phoneNumber',
          'phoneID',
          'whatsAppOriginWidget',
        ],
        order: [['updatedAt', 'DESC']],
      },
    };
    const agencies = yield call(requestHandler, {
      method: 'POST',
      path: '/agency/getAll',
      data,
    });
    yield put(getAgenciesSuccess({ agencies }));
  } catch (e) {
    yield put(getAgenciesFail('getAgenciesSaga'));
    console.log('error in get agencies saga');
  }
}

export function* createAgencySaga(action) {
  try {
    const data = {
      ...action.payload,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/agency/create',
      data,
    });
    const message = {
      title: '¡Éxito!',
      desc: `La agencia se creó correctamente`,
    };
    yield put(createAgencySuccess({ message }));
    yield put(getAgencies());
  } catch (e) {
    let message = '';
    if (e.code === 463)
      message = {
        title: 'Error',
        desc: `Ya existe una agencia con ese nickname o nombre o email.
        Intenta de nuevo cambiando alguno de esos datos.`,
      };
    else
      message = {
        title: 'Error',
        desc: `Ocurrió un error cuando se trataba de crear la agencia.
        Por favor, inténtelo de nuevo más tarde.`,
      };
    yield put(createAgencyFail({ message }));
    console.log('error in create agency saga', e);
  }
}

export function* updateAgencySaga(action) {
  try {
    let agencyID;
    const agencyId = yield select(makeSelectAgencyId());
    const userRole = yield select(makeSelectUserRole());
    const { id, ...payload } = action.payload;
    if (userRole === 'Super Admin') agencyID = id;
    else agencyID = agencyId;
    const data = {
      ...payload,
      id: agencyID,
    };
    const agency = yield call(requestHandler, {
      method: 'POST',
      path: '/agency/update',
      data,
    });
    const message = {
      title: '¡Éxito!',
      desc: `La información de agencia se actualizó correctamente`,
    };
    yield put(updateAgencySuccess({ agency, message }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba actualizar la
      agencia. Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(updateAgencyFail({ message }));
    console.log('error in update agency saga', e);
  }
}

export function* updateOptionAgencySaga(action) {
  try {
    const data = {
      ...action.payload,
    };
    const agency = yield call(requestHandler, {
      method: 'POST',
      path: '/agency/update',
      data,
    });
    const message = {
      title: '¡Éxito!',
      desc: `La opción de la agencia se actualizó correctamente`,
    };
    yield put(updateOptionSuccess({ agency, message }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se trataba de actualizar la opción de la agencia.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(updateOptionFail({ message }));
    console.log('error in update agency saga', e);
  }
}

export function* getAgencyDetailSaga(action) {
  try {
    const agencyId = yield select(makeSelectAgencyId());
    const userRole = yield select(makeSelectUserRole());
    let tempagencyID;
    if (userRole === 'Super Admin')
      tempagencyID = action.payload.agencyID;
    else tempagencyID = agencyId;
    const data = {
      where: { id: tempagencyID },
      customFlows: true,
      timeTablePresets: true,
      timeTables: true,
    };
    const agency = yield call(requestHandler, {
      method: 'POST',
      path: '/agency/getOne',
      data,
    });
    if (
      Array.isArray(agency.timeTablePresets) &&
      agency.timeTablePresets.length > 0
    ) {
      agency.timeTablePresets = yield call(
        groupTimeTables,
        agency.timeTablePresets,
      );
    }
    yield put(getAgencyDetailSuccess({ agency }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se trataba obtener la información de la agencia.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(getAgencyDetailFail({ message }));
    console.log('error in get detail agency saga', e);
  }
}

export function* uploadLogoSaga(action) {
  try {
    const url = yield call(requestHandler, {
      method: 'POST',
      path: '/agency/uploadLogo',
      contenType: 'multipart/form-data',
      data: action.payload.logo,
    });
    yield put(updloadLogoSuccess({ url }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se trataba subir el logo.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(updloadLogoFail({ message }));
    console.log('error in upload logo saga', e);
  }
}
