import {
  Avatar,
  Button,
  Card,
  CardHeader,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { advisorTypes, getNested } from 'app/utils';

import CategoryBadge from 'app/components/CategoryBadge';
import ChipInput from 'material-ui-chip-input';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { MenuOption } from 'app/components/MenuOption';
import Modal from 'app/components/Modal';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { OverlayTrigger } from 'react-bootstrap';
import { Picker } from 'emoji-mart';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { orderBy } from 'lodash';

const useStyles = makeStyles((theme) => ({
  categoryTittle: {
    fontSize: '1em',
    textAlign: 'center',
  },
  createCategoryButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
  },
  inputMarginBottom: {
    marginBottom: theme.spacing(2),
  },
  categoriesContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  cardContainer: {
    margin: theme.spacing(2, 0, 2, 0),
  },
}));

const Categories = ({
  customFlows,
  createCategory,
  updateCategory,
  deleteCategory,
}) => {
  const classes = useStyles();

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isCategoryNumberUsed, setIsCategoryNumberUsed] = useState(
    false,
  );

  const {
    handleSubmit,
    errors,
    register,
    control,
    watch,
    setValue,
    reset,
  } = useForm();

  const name = watch('name');
  const keywords = watch('keywords');
  const number = watch('number');

  useEffect(() => {
    handleCheckNumberCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [number]);

  const handleCheckNumberCategory = () => {
    let tempIsCategoryNumberuser = false;
    if (
      number &&
      Array.isArray(customFlows) &&
      customFlows.length > 0
    )
      customFlows.forEach((customFlow) => {
        if (
          customFlow.number.toString() === number &&
          number !== selectedCategory.number.toString()
        )
          tempIsCategoryNumberuser = true;
      });
    setIsCategoryNumberUsed(tempIsCategoryNumberuser);
  };

  const toggleCreateModal = () => {
    if (!showCreateModal) {
      reset({
        number: getNested([], customFlows).length + 1,
        name: '',
        assessorType: '',
        keywords: [],
      });
      setSelectedCategory(null);
    }
    setShowCreateModal(!showCreateModal);
  };

  const toggleDeleteModal = () =>
    setShowDeleteModal(!showDeleteModal);

  const handleAddKeyword = (keywordsArray, keyword) => {
    const newKeywords = [...keywordsArray];
    newKeywords.push(keyword);
    return newKeywords;
  };

  const handleDeleteKeyword = (keywordsArray, index) => {
    const newKeywords = [...keywordsArray];
    newKeywords.splice(index, 1);
    return newKeywords;
  };

  const handleCreateOrUpdateCategory = (formData) => {
    if (selectedCategory) hadleUpdateCategory(formData);
    else handleCreateCategoy(formData);
  };

  const handleCreateCategoy = (formData) => {
    const { number, ...rest } = formData;
    createCategory({
      ...rest,
      number: number ? number : customFlows.length + 1,
    });
    setShowCreateModal(false);
  };

  const handleLoadCategory = (customFlow) => {
    reset({
      number: customFlow.number,
      name: customFlow.name,
      assessorType: customFlow.assessorType,
      keywords: customFlow.keywords,
    });
  };

  const hadleUpdateCategory = (formData) => {
    updateCategory({ ...formData, id: selectedCategory.id });
    setShowCreateModal(false);
    reset({
      number: '',
      name: '',
      assessorType: '',
      keywords: [],
    });
    setSelectedCategory(null);
  };

  const handleDeleteCategory = () => {
    deleteCategory({ id: selectedCategory.id });
    setShowDeleteModal(false);
  };

  const renderEmojiPicker = (value, setFieldValue, valueKey) => (
    <Picker
      style={{ zIndex: 1300 }}
      title=""
      emoji=""
      enableFrequentEmojiSort={false}
      emojiSize={20}
      showSkinTones={false}
      interactive={true}
      i18n={{
        search: 'Buscar',
        clear: 'Limpiar', // Accessible label on "clear" button
        notfound: 'Emoji no encontrado',
        skintext: 'Choose your default skin tone',
        categories: {
          search: 'Resultados',
          recent: 'Usados frecuentemente',
          smileys: 'Sonrisas y expresiones',
          people: 'Personas',
          nature: 'Animales y naturaleza',
          foods: 'Comida',
          activity: 'Actividades',
          places: 'Lugares y viajes',
          objects: 'Objetos',
          symbols: 'Símbolos',
          flags: 'Banderas',
          custom: 'Custom',
        },
      }}
      onSelect={(emoji) =>
        setFieldValue(valueKey, value + emoji.native)
      }
    />
  );

  const renderConfirmationDelete = () => {
    return (
      <Modal
        open={showDeleteModal}
        closeModal={toggleDeleteModal}
        dialogTitle="¿Seguro que deseas eliminar la categoría?"
        dialogText="Si continúas no habrá manera de recuperar la información
            que haya sido borrada."
        actionButtonText="Aceptar"
        onClick={handleDeleteCategory}
      />
    );
  };

  const renderCreateModal = () => {
    return (
      <Modal
        open={showCreateModal}
        closeModal={toggleCreateModal}
        maxWidth="sm"
        fullWidth
        dialogTitle={
          selectedCategory
            ? 'Editar categoría'
            : 'Crear nueva categoría'
        }
        actionButtonText={
          selectedCategory ? 'Editar categoría' : 'Crear categoría'
        }
        dialogChildren={
          <>
            <form
              id="create-timetable-form"
              autoComplete="off"
              noValidate
              onSubmit={handleSubmit(handleCreateOrUpdateCategory)}
            >
              <TextField
                inputRef={register({
                  required: true,
                  validate: {
                    isDifferentNumber: (value) =>
                      !isCategoryNumberUsed,
                  },
                })}
                name="number"
                required
                fullWidth
                type="text"
                label="Posición en el menú"
                variant="outlined"
                className={classes.inputMarginBottom}
                error={Boolean(errors.number) || isCategoryNumberUsed}
                helperText={
                  isCategoryNumberUsed
                    ? 'Este número de categoría ya está en uso, por favor elige otro o modifica los ya existentes.'
                    : null
                }
              />
              <TextField
                inputRef={register({
                  required: true,
                })}
                name="name"
                required
                fullWidth
                type="text"
                label="Nombre de la categoría"
                variant="outlined"
                className={classes.inputMarginBottom}
                error={Boolean(errors.name)}
                InputProps={{
                  endAdornment: (
                    <OverlayTrigger
                      trigger="click"
                      placement="bottom"
                      overlay={renderEmojiPicker(
                        name,
                        setValue,
                        'name',
                      )}
                    >
                      <Button>
                        <span
                          role="img"
                          aria-label=""
                          className={classes.emojiFace}
                        >
                          😁
                        </span>
                      </Button>
                    </OverlayTrigger>
                  ),
                }}
              />
              <FormControl
                variant="outlined"
                className={clsx(
                  classes.inputMarginBottom,
                  classes.select,
                )}
                fullWidth
              >
                <InputLabel id="label-adviser-type">
                  Tipo de asesores asignados a la categoría
                </InputLabel>
                <Controller
                  name="assessorType"
                  control={control}
                  rules={{ required: true }}
                  defaultValue=""
                  as={
                    <Select
                      labelId="label-adviser-type"
                      label="Tipo de asesores asignados a la categoría"
                      error={Boolean(errors.assessorType)}
                    >
                      <MenuItem disabled value="">
                        Selecciona una opción
                      </MenuItem>
                      {Object.keys(advisorTypes).map((key) => (
                        <MenuItem key={key} value={key}>
                          {advisorTypes[key]}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                />
              </FormControl>
              <Controller
                as={
                  <ChipInput
                    fullWidth
                    className={classes.inputMarginBottom}
                    label="Keywords"
                    placeholder="1, cotizar, nuevo, cotización, crédito, enganche ..."
                    value={keywords}
                    variant="outlined"
                    newChipKeyCodes={[13, 32, 188]}
                    onAdd={(receiver) => {
                      setValue(
                        'keywords',
                        handleAddKeyword(keywords, receiver),
                      );
                    }}
                    onDelete={(_, index) => {
                      setValue(
                        'keywords',
                        handleDeleteKeyword(keywords, index),
                      );
                    }}
                  />
                }
                control={control}
                name="keywords"
                rules={{ required: true }}
              />
            </form>
          </>
        }
        form={'create-timetable-form'}
      />
    );
  };

  const renderCommissionsByCards = () => (
    <>
      <Grid item xs={6} md={6}>
        {Array.isArray(customFlows) &&
          orderBy(customFlows, ['number'], ['asc']).map(
            (customFlow) => {
              return (
                <Card
                  key={`${customFlow.id}`}
                  className={classes.cardContainer}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        aria-label="recipe"
                        className={classes.avatar}
                      >
                        {customFlow.number}
                      </Avatar>
                    }
                    action={
                      <MenuOption
                        Button={
                          <IconButton>
                            <MoreVertIcon />
                          </IconButton>
                        }
                        options={[
                          {
                            name: <EditIcon />,
                            onClick: () => {
                              toggleCreateModal();
                              setSelectedCategory(customFlow);
                              handleLoadCategory(customFlow);
                            },
                          },
                          {
                            name: <DeleteIcon />,
                            onClick: () => {
                              toggleDeleteModal();
                              setSelectedCategory(customFlow);
                            },
                          },
                        ]}
                      />
                    }
                    titleTypographyProps={{
                      className: classes.categoryTittle,
                    }}
                    subheaderTypographyProps={{
                      className: classes.categoryTittle,
                    }}
                    title={customFlow.name}
                    subheader={
                      <CategoryBadge
                        categoryType={customFlow.assessorType}
                      />
                    }
                  />
                </Card>
              );
            },
          )}
      </Grid>
    </>
  );

  return (
    <>
      {renderCreateModal()}
      {renderConfirmationDelete()}
      <div className={classes.createCategoryButton}>
        <Button
          variant="contained"
          className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
          onClick={toggleCreateModal}
        >
          Crear categoría
          <QueryBuilderIcon className="kt-margin-l-10" />
        </Button>
      </div>
      <Grid className={classes.categoriesContainer} container>
        {renderCommissionsByCards()}
      </Grid>
    </>
  );
};

export default Categories;
