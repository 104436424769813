import { createSelector } from 'reselect';
import { getNested } from 'app/utils';
import { initialAuthState } from './initialState';

const selectAuth = (state) => state.auth || initialAuthState;

const makeSelectUserRole = () =>
  createSelector(selectAuth, (authState) => authState.user.role);

export const makeSelectUserID = () =>
  createSelector(selectAuth, (authState) => authState.user.id);

export const makeSelectAgencyUserID = () =>
  createSelector(
    selectAuth,
    (authState) => authState.user.agencyUserID,
  );

export const makeSelectAgencyID = () =>
  createSelector(
    selectAuth,
    (authState) => authState.user.agencyUserID,
  );

export const makeSelectUserFromState = createSelector(
  selectAuth,
  (authState) => authState.user,
);

export const makeSelectUserIDFromState = createSelector(
  selectAuth,
  (authState) => authState.user.id,
);

export const makeSelectUserRoleFromState = createSelector(
  selectAuth,
  (authState) => authState.user.role,
);

export const makeSelectAgencyUsersFromState = createSelector(
  selectAuth,
  (authState) => authState.agencyUsers,
);

export const makeSelectLoggedAgencyFromState = createSelector(
  selectAuth,
  (authState) => authState.user.agencyID,
);

export const makeSelectAuthTokenFromState = createSelector(
  selectAuth,
  (authState) => authState.authToken,
);

export const makeSelectOwnedAgenciesFromState = createSelector(
  selectAuth,
  (authState) => getNested(null, authState, 'user', 'ownedAgencies'),
);

export const makeSelectUserCategoriesFromState = createSelector(
  selectAuth,
  (authState) => getNested(null, authState, 'user', 'categories'),
);

export const makeSelectUserCategories = () =>
  createSelector(selectAuth, (authState) =>
    getNested(null, authState, 'user', 'categories'),
  );

const makeSelectAgencyId = () =>
  createSelector(selectAuth, (authState) => authState.user.agencyID);

export { selectAuth, makeSelectUserRole, makeSelectAgencyId };
