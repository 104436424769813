import { IconButton, Tooltip } from '@material-ui/core';

import { Badge } from 'react-bootstrap';
import CategoryBadge from 'app/components/CategoryBadge';
import HelpIcon from '@material-ui/icons/Help';
import PerfectScrollbar from 'react-perfect-scrollbar';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  content: {
    display: 'flex',
    paddingBottom: '1rem',
    flexGrow: 1,
    flexDirection: 'column',
    minHeight: '95%',
  },
  number: {
    fontWeight: 600,
    fontSize: '1.3rem',
    padding: '5px',
  },
  desc: {
    fontWeight: 600,
    fontSize: '1.2rem',
    alignSelf: 'center',
    marginLeft: theme.spacing(1.5),
    color: 'black',
    // color: kt-base-color(label, 2);
  },
  headerContainner: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  nameAndWaitTime: {
    margin: theme.spacing(3, 0, 1, 0),
    display: 'flex',
    justifyContent: 'space-between',
  },
  headers: {
    fontWeight: 600,
    fontSize: '.9rem',
  },
  waitingTimeContainter: {
    margin: theme.spacing(1.5, 0, 1.5, 0),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0, 0, 1, 0),
    borderBottom: '1px solid #efeff4',
  },
  waitingTimeBadge: {
    fontWeight: 600,
    fontSize: '1rem',
    padding: '5px',
    backgroundColor: '#feeeee',
    color: '#e74e3c',
  },
  clientName: {
    fontSize: '1.1rem',
    color: 'black',
  },
}));

const perfectScrollbarOptions = {
  wheelSpeed: 1,
  wheelPropagation: false,
};

export default function QuickStatusCategories({
  value,
  desc,
  agencyCategories,
  data,
  helpText,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.headerContainner}>
          <div>
            <Badge className={classes.number} variant="dark">
              {value}
            </Badge>
            <span className={classes.desc}>{desc}</span>
          </div>
          <Tooltip title={helpText} placement="top">
            <IconButton aria-label={helpText} size="small">
              <HelpIcon />
            </IconButton>
          </Tooltip>
        </div>

        <div className={classes.nameAndWaitTime}>
          <span className={classes.headers}>Categoría</span>
          <span className={classes.headers}>
            Conversaciones atentidas
          </span>
        </div>
        <div>
          <PerfectScrollbar
            options={perfectScrollbarOptions}
            style={{ maxHeight: '18vh', position: 'relative' }}
          >
            {agencyCategories &&
              agencyCategories
                .filter(function (item, pos) {
                  return agencyCategories.indexOf(item) === pos;
                })
                .map((key) => {
                  return (
                    <div
                      key={key}
                      className={classes.waitingTimeContainter}
                    >
                      <span className={classes.clientName}>
                        {<CategoryBadge categoryType={key} />}
                      </span>
                      <span className={classes.clientName}>
                        {data[key]}
                      </span>
                    </div>
                  );
                })}
          </PerfectScrollbar>
        </div>
      </div>
    </div>
  );
}
