import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React, { useEffect } from 'react';
import { closeModals, createCampaign } from '../../campaignSlice';

import Modal from '../../../../components/Modal';
import MomentUtils from '@date-io/moment';
import { OverlayTrigger } from 'react-bootstrap';
import { Picker } from 'emoji-mart';
import PropTypes from 'prop-types';
import ShouldItRender from '../../../../components/ShouldItRender';
import clsx from 'clsx';
import { connect } from 'react-redux';
import facebookLogo from '../../../../assets/img/logos/facebook.png';
import { getAgencies } from '../../../agencies/actions';
import { getNested } from 'app/utils';
import instagramLogo from '../../../../assets/img/logos/instagram.png';
import { makeSelectAgencies } from '../../../agencies/selectors';
import { makeSelectUserRoleFromState } from '../../../auth/selectors';
import twitterLogo from '../../../../assets/img/logos/twitter.png';

const useStyles = makeStyles((theme) => ({
  container: {
    flexWrap: 'wrap',
  },
  paper: {
    padding: theme.spacing(7, 10, 1, 10),
  },
  textField: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  subtitle: {
    marginBottom: theme.spacing(4.5),
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  card: {
    width: '30%',
    margin: theme.spacing(1, 1, 1, 1),
  },
  submitbutton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  },
  CardContent: {
    padding: '10px !important',
  },
  margiTop: {
    marginTop: theme.spacing(2),
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  emojiFace: {
    fontSize: '20px',
  },
}));

function CreateCampaign(props) {
  const classes = useStyles();
  const {
    agencies,
    campaigns,
    createCampaign,
    updateAdvisor,
    getAgencies,
    role,
    closeModals,
    history,
    match,
  } = props;

  const {
    handleSubmit,
    register,
    errors,
    control,
    setValue,
    watch,
  } = useForm();

  const agencyID = watch('agencyID');
  const startDate = watch('startDate');
  const endDate = watch('endDate');
  const initialMessage = watch('initialMessage');

  useEffect(() => {
    if (role === 'Super Admin') getAgencies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    props.setLeftSideComponent(
      <div className="kt-subheader__main">
        <div>
          <h3 className="kt-subheader__title">
            {renderUpdateOrCreateText()}
          </h3>
        </div>
      </div>,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseModals = (redirect = null) => {
    closeModals({ history, redirect });
  };

  const onSubmitCreate = (values) => {
    console.log('Values: ', values);
  };

  const onSubmitUpdate = (values) => {
    const { activeDays, assessorType, ...restValues } = values;
    const data = {
      ...restValues,
      id: match.params.id,
      type: assessorType,
    };
    updateAdvisor(data);
  };

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = campaigns;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message, redirect } = campaigns[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={() => handleCloseModals(redirect)}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={() => handleCloseModals(redirect)}
          />
        );
      }
      return null;
    } catch (e) {
      console.log('Modal error: ', e);
      handleCloseModals();
      return null;
    }
  };

  const renderAgencyOptions = () => {
    if (Array.isArray(agencies.fromdb))
      return agencies.fromdb.map((agency) => {
        return (
          <MenuItem key={`agency-${agency.id}`} value={agency.id}>
            {agency.name}
          </MenuItem>
        );
      });
    return null;
  };

  const renderCategoryOptions = () => {
    let customFlows = [];
    if (role === 'Super Admin' && agencyID) {
      const agency = agencies.fromdb.filter(
        (agency) => agency.id === agencyID,
      );
      customFlows = agency[0].customFlows;
    } else
      customFlows = getNested(
        [],
        agencies,
        'agencyDetails',
        'customFlows',
      );
    if (customFlows.length > 0)
      return customFlows.map((customFlow) => {
        return (
          <MenuItem
            key={`agency-${customFlow.id}`}
            value={customFlow.id}
          >
            {customFlow.name}
          </MenuItem>
        );
      });
    return null;
  };

  const renderUpdateOrCreateText = () => {
    if (match.params && match.params.id) {
      return 'Actualizar campaña';
    } else {
      return 'Crear nueva campaña';
    }
  };

  const handleUpdateOrCreate = (values) => {
    const data = {
      name: values.name,
      description: values.description,
      initialMessage: values.initialMessage,
      media: [],
      type: values.type,
      agencyID: values.agencyID,
      customFlowID: values.customFlowID,
    };
    values.fb && data.media.push('fb');
    values.instagram && data.media.push('instagram');
    values.twitter && data.media.push('twitter');
    values.web && data.media.push('web');
    values.mailing && data.media.push('mailing');
    values.other && data.media.push('other');
    createCampaign(data);
    return;
  };

  const renderEmojiPicker = (values, setFieldValue) => (
    <span>
      <Picker
        title=""
        emoji=""
        enableFrequentEmojiSort={false}
        emojiSize={20}
        showSkinTones={false}
        interactive={true}
        i18n={{
          search: 'Buscar',
          clear: 'Limpiar', // Accessible label on "clear" button
          notfound: 'Emoji no encontrado',
          skintext: 'Choose your default skin tone',
          categories: {
            search: 'Resultados',
            recent: 'Usados frecuentemente',
            smileys: 'Sonrisas y expresiones',
            people: 'Personas',
            nature: 'Animales y naturaleza',
            foods: 'Comida',
            activity: 'Actividades',
            places: 'Lugares y viajes',
            objects: 'Objetos',
            symbols: 'Símbolos',
            flags: 'Banderas',
            custom: 'Custom',
          },
        }}
        onSelect={(emoji) =>
          setValue('initialMessage', initialMessage + emoji.native)
        }
      />
    </span>
  );

  return (
    <Paper className={classes.paper}>
      {renderFeedBackModal()}
      <form
        className={classes.container}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(handleUpdateOrCreate)}
      >
        <div className="input-container">
          <ShouldItRender
            locationPage={'/edit-advisor'}
            action="selectAgency"
          >
            <Grid container spacing={2}>
              <Grid item xs>
                <FormControl
                  variant="outlined"
                  className={clsx(classes.textField)}
                  fullWidth
                >
                  <InputLabel id="label-agency">Agencia</InputLabel>
                  <Controller
                    name="agencyID"
                    control={control}
                    defaultValue=""
                    as={
                      <Select
                        required
                        labelId="label-agency"
                        label="Agencia"
                        error={Boolean(errors.agencyID)}
                      >
                        <MenuItem disabled value="">
                          Selecciona una opción
                        </MenuItem>
                        {renderAgencyOptions()}
                      </Select>
                    }
                  ></Controller>
                </FormControl>
              </Grid>
            </Grid>
          </ShouldItRender>
          <Grid container spacing={2}>
            <Grid item xs>
              <TextField
                inputRef={register}
                name="name"
                required
                fullWidth
                type="text"
                label="Nombre de campaña"
                variant="outlined"
                className={classes.textField}
                error={Boolean(errors.assessorName)}
              />
            </Grid>
            <Grid item xs>
              <FormControl
                variant="outlined"
                className={clsx(classes.textField)}
                fullWidth
              >
                <InputLabel id="label-category">Categoría</InputLabel>
                <Controller
                  name="customFlowID"
                  control={control}
                  defaultValue=""
                  as={
                    <Select
                      required
                      labelId="label-category"
                      label="Categoría"
                      error={Boolean(errors.agencyID)}
                    >
                      <MenuItem disabled value="">
                        Selecciona una opción
                      </MenuItem>
                      {renderCategoryOptions()}
                    </Select>
                  }
                ></Controller>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs>
              <Controller
                control={control}
                name="startDate"
                defaultValue={null}
                // rules={{ required: true }}
                as={
                  <MuiPickersUtilsProvider
                    utils={MomentUtils}
                    locale={'es'}
                  >
                    <DatePicker
                      autoOk
                      fullWidth
                      disableToolbar
                      error={Boolean(errors.date)}
                      className={classes.textField}
                      label="Fecha de inicio"
                      variant="inline"
                      inputVariant="outlined"
                      value={startDate}
                      onChange={(e) =>
                        setValue('startDate', e, false)
                      }
                    />
                  </MuiPickersUtilsProvider>
                }
              />
            </Grid>
            <Grid item xs>
              <Controller
                control={control}
                name="endDate"
                defaultValue={null}
                // rules={{ required: true }}
                as={
                  <MuiPickersUtilsProvider
                    utils={MomentUtils}
                    locale={'es'}
                  >
                    <DatePicker
                      autoOk
                      fullWidth
                      disableToolbar
                      error={Boolean(errors.date)}
                      className={classes.textField}
                      label="Fecha de fin"
                      variant="inline"
                      inputVariant="outlined"
                      value={endDate}
                      onChange={(e) => setValue('endDate', e, false)}
                    />
                  </MuiPickersUtilsProvider>
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs>
              <TextField
                inputRef={register}
                required
                fullWidth
                multiline
                name="description"
                rows="5"
                type="text"
                label="Descripción"
                variant="outlined"
                className={classes.textField}
                error={Boolean(errors.description)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs>
              <TextField
                inputRef={register({
                  required: true,
                })}
                required
                fullWidth
                multiline
                name="initialMessage"
                rows="4"
                type="text"
                label="Mensaje inicial"
                placeholder="Ingresa el mensaje inicial 😄"
                variant="outlined"
                className={classes.textField}
                error={Boolean(errors.initialMessage)}
                inputProps={{
                  maxLength: 255,
                }}
                InputProps={{
                  endAdornment: (
                    <OverlayTrigger
                      trigger="click"
                      placement="bottom"
                      overlay={renderEmojiPicker()}
                    >
                      <Button size="small">
                        <span
                          role="img"
                          aria-label=""
                          className={classes.emojiFace}
                        >
                          😁
                        </span>
                      </Button>
                    </OverlayTrigger>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            className={clsx(
              classes.justifyContentCenter,
              classes.marginBottom,
            )}
          >
            <Card className={classes.card}>
              <CardContent className={classes.CardContent}>
                <Grid
                  container
                  spacing={2}
                  className={classes.justifyContentCenter}
                >
                  <Grid item>
                    <FormControlLabel
                      name="fb"
                      inputRef={register}
                      control={<Checkbox />}
                      label={
                        <img
                          src={facebookLogo}
                          width="100"
                          alt="facebook"
                        ></img>
                      }
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card className={classes.card}>
              <CardContent className={classes.CardContent}>
                <Grid
                  container
                  spacing={2}
                  className={classes.justifyContentCenter}
                >
                  <Grid item>
                    <FormControlLabel
                      name="twitter"
                      inputRef={register}
                      control={<Checkbox />}
                      label={
                        <img
                          src={twitterLogo}
                          width="100"
                          alt="twitter"
                        ></img>
                      }
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card className={classes.card}>
              <CardContent className={classes.CardContent}>
                <Grid
                  container
                  spacing={2}
                  className={classes.justifyContentCenter}
                >
                  <Grid item>
                    <FormControlLabel
                      name="instagram"
                      inputRef={register}
                      control={<Checkbox />}
                      label={
                        <img
                          src={instagramLogo}
                          width="100"
                          alt="Instagram"
                        ></img>
                      }
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card className={classes.card}>
              <CardContent className={classes.CardContent}>
                <Grid
                  container
                  spacing={2}
                  className={classes.justifyContentCenter}
                >
                  <Grid item>
                    <FormControlLabel
                      name="web"
                      inputRef={register}
                      control={<Checkbox />}
                      label="web"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card className={classes.card}>
              <CardContent className={classes.CardContent}>
                <Grid
                  container
                  spacing={2}
                  className={classes.justifyContentCenter}
                >
                  <Grid item>
                    <FormControlLabel
                      name="mailing"
                      inputRef={register}
                      control={<Checkbox />}
                      label="Mailing"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card className={classes.card}>
              <CardContent className={classes.CardContent}>
                <Grid
                  container
                  spacing={2}
                  className={classes.justifyContentCenter}
                >
                  <Grid item>
                    <FormControlLabel
                      name="other"
                      inputRef={register}
                      control={<Checkbox />}
                      label="Otro"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <div className={classes.submitbutton}>
            <Button type="submit" variant="contained" color="primary">
              {renderUpdateOrCreateText()}
            </Button>
          </div>
        </div>
      </form>
    </Paper>
  );
}

CreateCampaign.propTypes = {
  users: PropTypes.object,
  agencies: PropTypes.object,
  createAdvisor: PropTypes.func,
  getUserDetail: PropTypes.func,
  closeModals: PropTypes.func,
  history: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

const mapStateToProps = (state) => ({
  campaigns: state.campaigns,
  agencies: makeSelectAgencies(state),
  role: makeSelectUserRoleFromState(state),
});

const mapDispatchToProps = {
  createCampaign,
  getAgencies,
  closeModals,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateCampaign);
