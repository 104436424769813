import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { Chart } from 'chart.js';
import { IconButton } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import React from 'react';
import clsx from 'clsx';
import { getActiveElement } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { metronic } from '../../_metronic';
import moment from 'moment';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  content: {
    display: 'flex',
    paddingBottom: '1rem',
    flexGrow: 1,
    flexDirection: 'column',
    minHeight: '95%',
  },
  desc: {
    fontWeight: 600,
    fontSize: '1.2rem',
    alignSelf: 'center',
    color: 'black',
    // color: kt-base-color(label, 2);
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  datePicker: {
    width: '110px',
  },
  datePickerRight: {
    marginLeft: theme.spacing(1),
  },
  datePickerLeft: {
    marginRight: theme.spacing(1),
  },
  calendarIcon: {
    marginRight: '5px',
  },
  noData: {
    fontSize: '1.7em',
    fontWeight: 600,
    color: 'black',
  },
  totalConversations: {
    marginTop: theme.spacing(0.5),
  },
  datePickersContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default function QuickCampaignsSource({
  campaignsSourceStartDate,
  campaignsSourceEndDate,
  handleCampaignsSourceStartDate,
  handleCampaignsSourceEndDate,
  desc,
  campaigns,
}) {
  const classes = useStyles();
  const canvasRef = React.useRef();
  const [labelDays, setLabelDays] = React.useState([]);
  const [graphs, setGraphs] = React.useState([]);
  const [dataGraph, setDataGraph] = React.useState([]);
  const [total, setTotal] = React.useState(null);

  React.useEffect(() => {
    setTotal(50);
    let dataSet = [];
    let totalData = [];
    if (campaignsSourceStartDate && campaignsSourceEndDate) {
      const tempDays = [];
      const tempLabelDays = [];
      let tempGraph = [];
      let i = 0;
      let tempDaysString = [];
      let tempStartDate = moment(campaignsSourceStartDate);
      while (!tempStartDate.isAfter(campaignsSourceEndDate, 'day')) {
        tempLabelDays.push(tempStartDate.format('yyyy-MM-DD'));
        tempDays.push(moment(tempStartDate));
        tempDaysString.push(tempStartDate.format('yyyy-MM-DD'));
        tempStartDate = tempStartDate.add(1, 'days');
        tempGraph[i] = 0;
        i++;
      }

      let count = 0;
      let arr = new Array();

      campaigns?.forEach((element) => {
        const register = element.campaignClicks;
        register?.forEach((element1) => {
          tempDaysString.forEach((element2) => {
            if (element1.createdAt.substring(0, 10) === element2) {
              count++;
            }
          });
          if (count > 0) {
            arr.push({
              name: element.name,
              clicks: count,
              date: element1.createdAt.substring(0, 10),
            });
          }
        });
        count = 0;
      });

      let prev = new Array();
      const countArr = (arr) => {
        tempDaysString.forEach((el) => {
          let count = arr.filter((result) => el === result.date);
          if (count.length > 0) {
            prev.push({
              name: count[0].name,
              clicks: count.length,
            });
          }
        });
      };
      countArr(arr);

      const newCampaigns = campaigns?.map((item, index) => {
        let numClicks = prev?.filter((x) => x.name === item.name);
        return {
          ...item,
          clicks: numClicks,
        };
      });

      // tempDaysString.forEach((day, idx) => {
      //   newCampaigns?.forEach((el) => {
      //     const date = el.campaignClicks;
      //     date?.forEach((e) => {
      //       if (e.createdAt.substring(0, 10) === day) {
      //         el?.clicks?.map((i) => i.clicks);
      //         tempGraph[idx]++;
      //       }
      //     });
      //   });
      // });
      setLabelDays(tempDaysString);

      let click = [];
      let mayorValue = 0;

      newCampaigns?.forEach((item) => {
        let sum = item?.clicks;
        sum?.map((el) => {
          click.push(el.clicks);
        });
        totalData = click.reduce((a, b) => a + b, 0);
        setTotal(totalData);
      });

      if (click.length > 0) {
        for (let i = 0; i < click.length; i++) {
          if (click[i] > mayorValue) {
            mayorValue = click[i];
            setTotal(mayorValue);
          }
        }
      }

      if (campaigns) {
        newCampaigns?.map((element) => {
          const clicks = element.clicks?.map((el) => el.clicks);
          let sumClicks = [];
          let perCampaign = [];

          const clicksPerCampaigns = element.clicks?.map((el) =>
            sumClicks.push(el.clicks),
          );
          perCampaign = sumClicks.reduce((a, b) => a + b, 0);
          dataSet.push({
            label: `${element.name} : ${perCampaign}`,
            fill: true,
            backgroundColor: Chart.helpers
              .color(brandColor)
              .alpha(0.76)
              .rgbString(),
            borderColor: Chart.helpers
              .color(brandColor)
              .alpha(0)
              .rgbString(),
            pointHoverRadius: 4,
            pointHoverBorderWidth: 12,
            pointBackgroundColor: Chart.helpers
              .color('#000000')
              .alpha(0)
              .rgbString(),
            pointBorderColor: Chart.helpers
              .color('#000000')
              .alpha(0)
              .rgbString(),
            pointHoverBackgroundColor: brandColor,
            pointHoverBorderColor: Chart.helpers
              .color('#000000')
              .alpha(0.1)
              .rgbString(),
            data: clicks,
          });
        });
      }
      dataSet.push({
        label: `Total : ${totalData}`,
        fill: true,
        backgroundColor: Chart.helpers
          .color(infoColor)
          .alpha(0.76)
          .rgbString(),
      });

      setGraphs(dataSet);
    }
    return () => {
      setLabelDays([]);
      setGraphs([]);
    };
  }, [campaigns, campaignsSourceStartDate, campaignsSourceEndDate]);

  const {
    brandColor,
    shape2Color,
    shape3Color,
    infoColor,
  } = useSelector((state) => ({
    infoColor: metronic.builder.selectors.getConfig(
      state,
      'colors.state.info',
    ),
    brandColor: metronic.builder.selectors.getConfig(
      state,
      'colors.state.warning',
    ),
    shape2Color: metronic.builder.selectors.getConfig(
      state,
      'colors.base.shape.2',
    ),
    shape3Color: metronic.builder.selectors.getConfig(
      state,
      'colors.base.shape.3',
    ),
  }));

  const chartOptions = {
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    legend: {
      display: true,
      position: 'right',
    },
    tooltips: {
      intersect: false,
      mode: 'nearest',
      xPadding: 10,
      yPadding: 10,
      caretPadding: 10,
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      animateRotate: false,
      animateScale: true,
    },
    scales: {
      xAxes: [
        {
          display: true,
          scaleLabel: {
            display: false,
            labelString: 'Días',
          },
          gridLines: false,
          ticks: {
            display: true,
            beginAtZero: true,
            fontColor: shape3Color,
            fontSize: 13,
            padding: 10,
          },
        },
      ],
      yAxes: [
        {
          categoryPercentage: 0.35,
          barPercentage: 0.7,
          display: true,
          scaleLabel: {
            display: false,
            labelString: 'Value',
          },
          gridLines: {
            color: shape2Color,
            offsetGridLines: false,
            drawTicks: false,
            borderDash: [3, 4],
            zeroLineWidth: 1,
            zeroLineColor: shape2Color,
            zeroLineBorderDash: [3, 4],
          },
          ticks: {
            max: total,
            stepSize: 2,
            display: true,
            beginAtZero: true,
            fontColor: shape3Color,
            fontSize: 13,
            padding: 10,
          },
        },
      ],
    },
  };

  const data = React.useMemo(
    () => ({
      labels: labelDays,
      datasets: graphs,
    }),
    [brandColor, infoColor, total, dataGraph, graphs],
  );

  React.useEffect(() => {
    const config = {
      type: 'line',
      data,
      options: chartOptions,
    };

    const chart = new Chart(canvasRef.current, config);

    return () => {
      chart.destroy();
    };
  }, [chartOptions, campaigns]);

  const renderConversationSourcesChart = () => {
    return (
      <div className="kt-widget12__chart" style={{ height: '250px' }}>
        <canvas
          ref={canvasRef}
          width={683}
          height={312}
          id="kt_chart_order_statistics"
        />
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.header}>
          <span className={classes.desc}>{desc}</span>
          <div className={classes.datePickersContainer}>
            <MuiPickersUtilsProvider
              utils={MomentUtils}
              locale={'es'}
            >
              <DatePicker
                autoOk
                disableToolbar
                disableFuture
                size="small"
                inputVariant="outlined"
                format="DD MMM"
                className={clsx(
                  classes.datePicker,
                  classes.datePickerLeft,
                )}
                value={campaignsSourceStartDate}
                onChange={handleCampaignsSourceStartDate}
                InputProps={{
                  startAdornment: (
                    <IconButton
                      aria-label="Select day"
                      size="small"
                      className={classes.calendarIcon}
                    >
                      <CalendarTodayIcon />
                    </IconButton>
                  ),
                }}
              />
              -
              <DatePicker
                autoOk
                disableToolbar
                disableFuture
                size="small"
                inputVariant="outlined"
                format="DD MMM"
                className={clsx(
                  classes.datePicker,
                  classes.datePickerRight,
                )}
                value={campaignsSourceEndDate}
                onChange={handleCampaignsSourceEndDate}
                InputProps={{
                  startAdornment: (
                    <IconButton
                      aria-label="Select day"
                      size="small"
                      className={classes.calendarIcon}
                    >
                      <CalendarTodayIcon />
                    </IconButton>
                  ),
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>

        <div className={classes.responseTimers}>
          {renderConversationSourcesChart()}
        </div>
      </div>
    </div>
  );
}
