/* eslint-disable jsx-a11y/no-onchange */
import 'moment/locale/es';

/* eslint-disable react/display-name */
import {
  Button,
  IconButton,
  Checkbox,
  Paper,
  Tab,
  Tabs,
  makeStyles,
} from '@material-ui/core';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React, { useEffect, useRef, useState } from 'react';
import {
  advisorTypes,
  filterCaseInsensitive,
  formatDate,
  getNested,
  getResponseTime,
} from '../../../../utils';
import Modal from '../../../../components/Modal';
import { debounce, get } from 'lodash';
import {
  getAllConversations,
  getAllConversationsForDownload,
  updateMultipleConversations,
} from '../../conversationsSlice';
import {
  makeSelectUserCategoriesFromState,
  makeSelectUserFromState,
  makeSelectUserRoleFromState,
} from '../../../auth/selectors';
import { makeSelectAgencyDetailsFromState } from '../../../agencies/selectors';
import ShouldItRender from '../../../../components/ShouldItRender';

import { CSVLink } from 'react-csv';
import Close from '@material-ui/icons/Close';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CancelIcon from '@material-ui/icons/Cancel';
import ConversationStatusBadge from 'app/components/ConversationStatusBadge';
import FileCopyTwoTone from '@material-ui/icons/FileCopyTwoTone';
import { Link } from 'react-router-dom';
import { MenuOption } from '../../../../components/MenuOption';
import MUIDatePickerFilter from 'app/components/MUIDatePickerFilter';
import MomentUtils from '@date-io/moment';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { makeSelectConversatioToDownload } from 'app/features/conversations/selectors';
import moment from 'moment';
import { withRouter } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    widht: '100%',
    marginBottom: theme.spacing(2),
  },
  range: {
    paddingTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  datePickerTextfield: {
    padding: '5px 5px 5px !important',
    height: '20px',
  },
  checkbox: {
    padding: '0px',
  },
  datePicker: {
    width: 60,
  },
  label: {
    width: '75%',
    marginLeft: 0,
    backgroundColor: 'white',
    [theme.breakpoints.between('md', 'lg')]: {
      width: '45%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '70%',
    },
    // paddingRight: 20,
  },
}));

const ConversationsTable = (props) => {
  const classes = useStyles();
  const {
    conversations,
    conversationsToDownload,
    getAllConversations,
    role,
    user,
    agencyDetails,
    categories,
    setActionButton,
    setLeftSideComponent,
    updateMultipleConversations,
  } = props;
  const [dataConversations, setDataConversations] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showChangeStatusModal, setShowChangeStatusModal] = useState(
    false,
  );
  const [changeStatus, setChangeStatus] = useState(null);
  const [indexConversation, setIndexConversation] = useState('');
  const [changeTitleStatus, setChangeTitleStatus] = useState(null);
  const [firstDate, setFirstDate] = useState(null);
  const [secondDate, setSecondDate] = useState(null);
  const [conversationsToExport, setConversationsToExport] = useState(
    [],
  );
  const [tabSelected, setTabSelected] = useState(0);
  const [columns, setColumns] = useState([
    {
      Header: () => <strong>Cliente id</strong>,
      accessor: 'clientID',
      Cell: (row) => (
        <Link to={`/conversation-details/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>Fuente</strong>,
      accessor: 'origin',
      Cell: (row) => {
        let origin = row.value;
        const source = get(row, 'original.source', '');

        if (origin === 'Mailing' && source === 'planta') {
          origin = 'Sitio web planta';
        } else if (
          (origin === 'Mailing' && source === 'web') ||
          (origin === 'Whatsapp' && source === 'Micrositio')
        ) {
          origin = 'Sitio web distribuidor';
        } else if (origin === 'Mailing' && source === 'messenger') {
          origin = 'Facebook';
        } else if (origin === 'Mailing' && source === 'facebook') {
          origin = 'Facebook';
        } else if (
          origin === 'Facebook' &&
          source === 'Facebook ADS'
        ) {
          origin = 'Facebook';
        } else if (origin === 'Facebook' && !source) {
          origin = 'Facebook';
        } else if (origin === 'Google Sheets') {
          origin = origin;
        } else {
          origin = 'Whatsapp';
        }

        return (
          <Link to={`/conversation-details/${row.original.id}`}>
            <div
              style={{
                textAlign: 'center',
              }}
            >
              {origin}
            </div>
          </Link>
        );
      },
      Filter: (cell) => {
        return (
          <select
            onChange={(e) => cell.onChange(e.target.value)}
            value={
              cell.filter && cell.filter.value
                ? cell.filter.value
                : ''
            }
          >
            <option value="">Todos</option>
            <option value="facebook">Facebook</option>
            <option value="Google Sheets">Google Sheets</option>
            <option value="Sitio web distribuidor">
              Sitio web distribuidor
            </option>
            <option value="Sitio web planta">Sitio web planta</option>
            <option value="Whatsapp">Whatsapp</option>
          </select>
        );
      },
      width: 138,
    },
    {
      Header: () => <strong>Medio</strong>,
      accessor: 'source',
      Cell: (row) => {
        let source = row.value;
        const origin = get(row, 'original.origin', '');

        if (
          (origin === 'Mailing' && source === 'web') ||
          (origin === 'Mailing' && source === 'planta')
        ) {
          source = 'Formulario';
        } else if (origin === 'Mailing' && source === 'messenger') {
          source = 'Messenger';
        } else if (
          origin === 'Facebook' &&
          source === 'Facebook ADS'
        ) {
          source = 'Facebook ADS';
        } else if (origin === 'Facebook' && !source) {
          source = 'Whatsapp';
        } else if (origin === 'Google Sheets' && !source) {
          source = 'Google Sheets';
        } else {
          source = 'Whatsapp';
        }
        return (
          <Link to={`/conversation-details/${row.original.id}`}>
            <div
              style={{
                textAlign: 'center',
              }}
            >
              {source}
            </div>
          </Link>
        );
      },
      Filter: (cell) => {
        return (
          <select
            onChange={(e) => cell.onChange(e.target.value)}
            value={
              cell.filter && cell.filter.value
                ? cell.filter.value
                : ''
            }
          >
            <option value="">Todos</option>
            <option value="Messenger">Messenger</option>
            <option value="Formulario">Formulario</option>
            <option value="Facebook ADS">Facebook ADS</option>
            <option value="Whatsapp">Whatsapp</option>
          </select>
        );
      },
      width: 138,
    },
    {
      Header: () => <strong>Campaña</strong>,
      accessor: 'campaignClick',
      Cell: (row) => (
        <Link to={`/conversation-details/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value
              ? getNested('-', row.value, 'campaign', 'name')
              : 'Contacto directo'}
          </div>
        </Link>
      ),
      width: 160,
    },
    {
      Header: () => <strong>Agencia</strong>,
      accessor: 'agency.name',
      Cell: (row) => (
        <Link to={`/conversation-details/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      show: role === 'Super Admin',
    },
    {
      Header: () => <strong>Asesor</strong>,
      accessor: 'agencyUser.user.name',
      Cell: (row) => (
        <Link to={`/conversation-details/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value
              ? row.value
              : row.original?.user?.name
              ? row.original.user.name
              : ''}
          </div>
        </Link>
      ),
      show: role === 'Super Admin' || role === 'Agency Admin',
    },
    {
      Header: () => <strong>Categoría asesor</strong>,
      accessor: 'agencyUser.type',
      Cell: (row) => (
        <Link to={`/conversation-details/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value
              ? row.value
              : row.original.user
              ? row.original.user.type
              : ''}
          </div>
        </Link>
      ),
      Filter: (cell) => {
        return (
          <select
            onChange={(e) => cell.onChange(e.target.value)}
            value={
              cell.filter && cell.filter.value
                ? cell.filter.value
                : ''
            }
          >
            <option value="">Todos</option>
            {Array.isArray(categories) && categories.length > 0
              ? categories.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))
              : Object.keys(advisorTypes).map((key) => (
                  <option key={key} value={key}>
                    {advisorTypes[key]}
                  </option>
                ))}
          </select>
        );
      },
      show: role === 'Super Admin' || role === 'Agency Admin',
    },
    {
      Header: () => <strong>Fecha</strong>,
      accessor: 'createdAt',
      Cell: (row) => (
        <Link to={`/conversation-details/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {formatDate(row.value)}
          </div>
        </Link>
      ),
      Filter: (cell) => {
        return <MUIDatePickerFilter cell={cell} />;
      },
    },
    {
      Header: () => <strong>Teléfono</strong>,
      accessor: 'client.phoneNumber',
      Cell: (row) => (
        <Link to={`/conversation-details/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>Tiempo de respuesta</strong>,
      accessor: 'repliedAt',
      Cell: (row) => (
        <Link to={`/conversation-details/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value && moment(row.original.createdAt)
              ? getResponseTime(
                  row.value,
                  row.original.createdAt,
                  row.original.assignedAt,
                  row.original.manuallyAssigned,
                )
              : '-'}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>Estatus</strong>,
      accessor: 'status',
      Cell: (row) => (
        <Link to={`/conversation-details/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <ConversationStatusBadge status={row.value} />
          </div>
        </Link>
      ),
      Filter: (cell) => {
        return (
          <select
            onChange={(e) => cell.onChange(e.target.value)}
            value={
              cell.filter && cell.filter.value
                ? cell.filter.value
                : ''
            }
          >
            <option value="">Todos</option>
            <option value="En curso">En curso</option>
            <option value="Asesor Asignado">Asesor asignado</option>
            <option value="Conversacion con Asesor iniciada">
              Conversacion con Asesor iniciada
            </option>
            <option value="Pendiente">Pendiente</option>
            <option value="Ignorado">Ignorado</option>
            <option value="Calificacion">Calificación</option>
            <option value="SIN INTERACCION">SIN INTERACCION</option>
            <option value="telefono pendiente">
              Teléfono pendiente
            </option>
            <option value="Información Incompleta">
              Información Incompleta
            </option>
          </select>
        );
      },
    },
    // {
    //   Header: () => <strong>Calificación</strong>,
    //   accessor: 'score',
    //   Cell: row => (
    //     <Link to={`/conversation-details/${row.original.id}`}>
    //       <div
    //         style={{
    //           textAlign: 'center',
    //         }}
    //       >
    //         {row.value ? (
    //           <Box component="fieldset" borderColor="transparent">
    //             <Rating name="read-only" value={row.value} readOnly />
    //           </Box>
    //         ) : (
    //           'n/a'
    //         )}
    //       </div>
    //     </Link>
    //   ),
    //   filterable: false,
    // },
  ]);
  const reactTable = useRef(null);

  const handleFetchData = debounce(
    (state, instance) => {
      const { page, pageSize, filtered } = state;
      getAllConversations({
        firstDate,
        secondDate,
        page,
        pageSize,
        filtered,
        prospects: tabSelected,
      });
    },
    1000,
    { leading: false, trailing: true },
  );

  useEffect(() => {
    if (
      conversations.hasOwnProperty('fromdb') &&
      conversations.fromdb &&
      conversations.fromdb.length > 0
    ) {
      setDataConversations([...conversations.fromdb]);
    } else {
      setDataConversations([]);
    }
  }, [conversations]);

  useEffect(() => {
    if (firstDate && secondDate) {
      reactTable.current && reactTable.current.fireFetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [firstDate, secondDate]);

  useEffect(() => {
    if (
      firstDate &&
      secondDate &&
      Array.isArray(conversationsToDownload) &&
      conversationsToDownload.length > 0
    ) {
      let tempConversationsToExport = [];
      tempConversationsToExport = conversationsToDownload.map(
        (conversation) => {
          let origin = conversation.origin || 'Whatsapp';
          const source = conversation.source;
          if (source) origin += ` (${source})`;
          else origin += '(Redes Sociales)';

          return {
            'Campaña/Contacto directo': getNested(
              null,
              conversation,
              'campaignClick',
            )
              ? getNested(
                  '-',
                  conversation,
                  'campaignClick',
                  'campaign',
                  'name',
                )
              : 'Contacto directo',
            'Cliente id': conversation.clientID,
            Origen: origin,
            'Nombre del cliente':
              'client' in conversation &&
              conversation.client &&
              conversation.client.name
                ? conversation.client.name
                : 'n/a',
            'Teléfono del cliente': getNested(
              'n/a',
              conversation,
              'client',
              'phoneNumber',
            ),
            'Categoría de la conversación': conversation.type,
            Estatus: conversation.status,
            Asesor: getNested(
              getNested(
                'n/a',
                conversation,
                'agencyUser',
                'user',
                'name',
              ),
              conversation,
              'user',
              'name',
            ),
            'Tipo asesor': getNested(
              getNested('n/a', conversation, 'agencyUser', 'type'),
              conversation,
              'user',
              'type',
            ),
            Fecha: moment(conversation.createdAt).format(
              'DD/MM/YYYY HH:mm',
            ),
            'Tiempo de respuesta':
              conversation.repliedAt && moment(conversation.repliedAt)
                ? getResponseTime(
                    conversation.repliedAt,
                    conversation.createdAt,
                    conversation.assignedAt,
                    conversation.manuallyAssigned,
                  )
                : '-',
          };
        },
      );
      setConversationsToExport(tempConversationsToExport);
    }
    return () => {
      setConversationsToExport([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstDate, secondDate, conversationsToDownload]);

  useEffect(() => {
    setLeftSideComponent(
      <>
        <Tabs
          value={tabSelected}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChangeTab}
          className={classes.label}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Leads" />
          <Tab label="Prospectos activos" />
          <Tab label="Prospectos perdidos" />
          {role === 'Super Admin' || (
            <Tab label="Clientes perdidos" />
          )}
        </Tabs>
      </>,
    );
    reactTable.current && reactTable.current.fireFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabSelected]);

  useEffect(() => {
    reactTable.current && reactTable.current.fireFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    setActionButton(
      <div style={{ display: 'inline' }}>
        <MuiPickersUtilsProvider utils={MomentUtils} locale={'es'}>
          <DatePicker
            autoOk
            disableToolbar
            variant="inline"
            label="Inicial"
            format="DD/MM"
            className={classes.datePicker}
            value={firstDate}
            onChange={handleFirstDate}
            InputProps={{
              endAdornment: firstDate ? (
                <IconButton
                  aria-label="Select locale"
                  size="small"
                  onClick={() => handleFirstDate(null)}
                >
                  <Close />
                </IconButton>
              ) : null,
            }}
          />
          <span className={classes.range}>&nbsp;A&nbsp;</span>
          <DatePicker
            autoOk
            disableToolbar
            variant="inline"
            label="Final"
            format="DD/MM"
            className={classes.datePicker}
            value={secondDate}
            onChange={handleSecondDate}
            InputProps={{
              endAdornment: secondDate ? (
                <IconButton
                  aria-label="Select locale"
                  size="small"
                  onClick={() => handleSecondDate(null)}
                >
                  <Close />
                </IconButton>
              ) : null,
            }}
          />
        </MuiPickersUtilsProvider>
        <CSVLink
          data={conversationsToExport}
          filename="tabla_de_conversaciones.csv"
          onClick={(event) => {
            return conversationsToExport.length > 0;
          }}
        >
          <Button
            variant="contained"
            className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
            disabled={Boolean(conversationsToExport.length <= 0)}
          >
            Exportar
            <FileCopyTwoTone className="kt-margin-l-10" />
          </Button>
        </CSVLink>
        <ShouldItRender>
          <MenuOption
            Button={
              <Button
                className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-20 kt-margin-r-20"
                disabled={false}
              >
                Acciones
                <ArrowDropDownIcon className="kt-margin-l-10" />
              </Button>
            }
            options={[
              {
                name: (
                  <>
                    <TransferWithinAStationIcon className="kt-margin-r-20" />
                    Clientes prospectos
                  </>
                ),
                onClick: () =>
                  toggleChangeStatusModal(
                    'Prospecto',
                    'Cliente prospecto',
                  ),
              },
              {
                name: (
                  <>
                    <CancelIcon className="kt-margin-r-20" />
                    Prospecto perdidos
                  </>
                ),
                onClick: () =>
                  toggleChangeStatusModal(
                    'Prospecto perdido',
                    'Prospecto Perdido',
                  ),
              },
            ]}
          />
        </ShouldItRender>
      </div>,
    );
  }, [firstDate, secondDate, conversations, conversationsToExport]);

  const toggleChangeStatusModal = (status, title) => {
    setShowChangeStatusModal(!showChangeStatusModal);
    setChangeStatus(status);
    setChangeTitleStatus(title);
  };

  useEffect(() => {
    if (role) {
      setColumns([
        {
          width: 50,
          accessor: 'selected',
          Cell: (row) => {
            // console.log('row', row);
            return (
              <div
                style={{
                  textAlign: 'center',
                }}
              >
                <Checkbox
                  className={classes.checkbox}
                  onChange={(e) => {
                    const { checked } = e.currentTarget;
                    setIndexConversation({
                      checked,
                      idx: row.index,
                    });
                  }}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
            );
          },
          sortable: false,
          filterable: false,
        },
        ...columns,
      ]);
    }
  }, [role]);

  const handleChangeTab = (event, value) => setTabSelected(value);

  const handleFirstDate = (date) => {
    setFirstDate(date);
    if (!date && !secondDate)
      reactTable.current && reactTable.current.fireFetchData();
  };

  const handleSecondDate = (date) => {
    setSecondDate(date);
    if (!date && !firstDate)
      reactTable.current && reactTable.current.fireFetchData();
  };

  const updateStatusConversations = async () => {
    const rowsToUpdate = getSelectedRows();
    await updateMultipleConversations({
      selectedRows: rowsToUpdate,
      status: changeStatus,
    });
    setShowChangeStatusModal(!showChangeStatusModal);
    await getAllConversations({
      firstDate,
      secondDate,
      page: 1,
      pageSize: 20,
      filtered: [],
      prospects: tabSelected,
    });
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const renderChangeStatusDialog = () => {
    return (
      <Modal
        open={showChangeStatusModal}
        closeModal={() => toggleChangeStatusModal()}
        dialogTitle={`Cambiando conversaciones a ${changeTitleStatus}`}
        dialogText={`Está a punto de cambiar las conversaciones a estatus "${changeTitleStatus}". ¿Desea continuar?`}
        actionButtonText={`Cambiar a ${changeTitleStatus}`}
        onClick={updateStatusConversations}
      />
    );
  };

  const getSelectedRows = () => {
    const conversationsSelected = selectedRows;
    return conversationsSelected;
  };

  useEffect(() => {
    const { idx } = indexConversation;
    if (typeof idx === 'number' && idx >= 0) {
      const { checked } = indexConversation;
      const tempConversations = [...conversations.fromdb];
      const conversationSelected = tempConversations[idx];

      const allConversationsSelected = [
        ...selectedRows,
        conversationSelected,
      ];

      const cleanConversations = allConversationsSelected.filter(
        (item) => {
          if (item.id === conversationSelected.id) {
            // If its checked add it, if not remove it.
            if (checked) return true;
            else return false;
          }
          // we always return true because its a new element.
          return true;
        },
      );
      setSelectedRows(cleanConversations);
    }
  }, [indexConversation]);

  return (
    <div className={classes.root}>
      {showChangeStatusModal && renderChangeStatusDialog()}
      <Paper className={classes.paper}>
        <ReactTable
          NoDataComponent={() => (
            <div className="rt-noData">Sin resultados</div>
          )}
          ref={reactTable}
          manual
          onFetchData={handleFetchData}
          data={dataConversations}
          columns={columns}
          pages={conversations.tablePages}
          filterable
          defaultFilterMethod={filterCaseInsensitive}
          className="-striped -highlight"
          previousText="Anterior"
          nextText="Siguiente"
          loading={conversations.isLoading}
          loadingText="Cargando datos..."
        />
      </Paper>
    </div>
  );
};

ConversationsTable.propTypes = {
  conversations: PropTypes.object,
  getAllConversations: PropTypes.func,
  getAllConversationsForDownload: PropTypes.func,
  setActionButton: PropTypes.func,
  intl: PropTypes.object,
};

const mapStateToProps = (state) => ({
  conversations: state.conversations,
  conversationsToDownload: makeSelectConversatioToDownload(state),
  role: makeSelectUserRoleFromState(state),
  user: makeSelectUserFromState(state),
  categories: makeSelectUserCategoriesFromState(state),
  agencyDetails: makeSelectAgencyDetailsFromState(state),
});

const mapDispatchToProps = {
  getAllConversations,
  getAllConversationsForDownload,
  updateMultipleConversations,
};

export default withRouter(
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(ConversationsTable),
  ),
);
