import 'rc-time-picker/assets/index.css';

import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useRef } from 'react';
import {
  advisorTypes,
  emailRegex,
  emailRegexString,
} from '../../../../utils';

import { CheckBoxRHFMUI } from 'app/components/CheckBoxRHFMUI';
import { Controller } from 'react-hook-form';
import { DayCards } from 'app/features/users/components/DayCards';
import ShouldItRender from '../../../../components/ShouldItRender';
import Switch from '@material-ui/core/Switch';
import clsx from 'clsx';
import { debounce } from 'lodash';
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';

const PurpleSwitch = withStyles({
  switchBase: {
    color: '#fd397a',
  },
  checked: {
    '&$checked': {
      color: '#0abb87',
    },
    '&$checked + $track': {
      backgroundColor: green[500],
    },
  },
  track: {},
})(Switch);

const useStyles = makeStyles((theme) => ({
  textField: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  subtitle: {
    marginBottom: theme.spacing(4.5),
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  checkBoxSubtitle: {
    marginBottom: theme.spacing(3),
  },
  submitbutton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
  },
  hourInputsContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  margiTop: {
    marginTop: theme.spacing(2),
  },
}));

const InviteAdvisorForm = (props) => {
  const classes = useStyles();
  const {
    control,
    handleSubmit,
    errors,
    register,
    setValue,
    renderAgencyOptions,
    handleUpdateOrCreate,
    activated,
    renderUpdateOrCreateText,
    email,
    emailUserExists,
    days,
    setDays,
    activeDays,
    setActiveDays,
  } = props;

  useEffect(() => {
    if (emailRegexString.test(email))
      handleSearchEmail.current(email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const handleSearchEmail = useRef(
    debounce(
      (value) => {
        emailUserExists({ email: value });
      },
      1500,
      { leading: false, trailing: true },
    ),
  );

  return (
    <>
      <Grid
        xs={12}
        item
        className={clsx(classes.subtitle, classes.margiTop)}
      >
        <Typography variant="h6">Información del asesor</Typography>
      </Grid>
      <form
        className={classes.container}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(handleUpdateOrCreate)}
      >
        <ShouldItRender
          locationPage={'/edit-advisor'}
          action="selectAgency"
        >
          <Grid container spacing={2}>
            <Grid item xs>
              <FormControl
                variant="outlined"
                className={clsx(classes.textField)}
                fullWidth
              >
                <InputLabel id="label-agency">Agencia</InputLabel>
                <Controller
                  name="agencyID"
                  control={control}
                  defaultValue=""
                  as={
                    <Select
                      required
                      labelId="label-agency"
                      label="Agencia"
                      error={Boolean(errors.agencyID)}
                    >
                      <MenuItem disabled value="">
                        Selecciona una opción
                      </MenuItem>
                      {renderAgencyOptions()}
                    </Select>
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </ShouldItRender>
        <Grid container spacing={2}>
          <Grid item xs>
            <Controller
              name="email"
              control={control}
              rules={{
                required: true,
                pattern: emailRegex(),
              }}
              as={
                <TextField
                  required
                  fullWidth
                  type="email"
                  label="Email"
                  variant="outlined"
                  className={classes.textField}
                  error={Boolean(errors.email)}
                />
              }
            />
          </Grid>
          <Grid item xs>
            <FormGroup row className={classes.justifyContentCenter}>
              <FormControlLabel
                name="activated"
                inputRef={register}
                control={
                  <PurpleSwitch
                    checked={activated}
                    onChange={({ target }) =>
                      setValue('activated', target.checked)
                    }
                  />
                }
                label="Estatus"
                labelPlacement="start"
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs>
            <Controller
              name="priority"
              control={control}
              rules={{
                required: true,
              }}
              as={
                <TextField
                  required
                  fullWidth
                  type="text"
                  label="Prioridad"
                  variant="outlined"
                  className={classes.textField}
                  error={Boolean(errors.priority)}
                />
              }
            />
          </Grid>
          <Grid item xs>
            <FormControl
              fullWidth
              required
              className={clsx(classes.textField, classes.select)}
              variant="outlined"
            >
              <InputLabel id="label-adviser-type">
                Tipo de asesor
              </InputLabel>
              <Controller
                name="assessorType"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                as={
                  <Select
                    labelId="label-adviser-type"
                    label="Tipo de asesor"
                    error={Boolean(errors.assessorType)}
                  >
                    <MenuItem disabled value="">
                      Selecciona una opción
                    </MenuItem>
                    {Object.keys(advisorTypes).map((key) => (
                      <MenuItem key={key} value={key}>
                        {advisorTypes[key]}
                      </MenuItem>
                    ))}
                  </Select>
                }
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs>
            <Controller
              name="text"
              control={control}
              as={
                <TextField
                  multiline
                  rows="2"
                  fullWidth
                  type="text"
                  label="Mensaje de invitación"
                  variant="outlined"
                  className={classes.textField}
                  error={Boolean(errors.text)}
                />
              }
            />
          </Grid>
        </Grid>
        <Grid
          xs={12}
          item
          className={clsx(classes.checkBoxSubtitle, classes.margiTop)}
        >
          <Typography variant="h6">
            Configuraciones del asesor
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <CheckBoxRHFMUI
            name="whatsConversations"
            label="Asesor de WhatsApp"
            control={control}
          />
        </Grid>
        <Grid item xs={4}>
          <CheckBoxRHFMUI
            name="fbConversations"
            label="Asesor de Facebook"
            control={control}
          />
        </Grid>
        <Grid container spacing={2}>
          <Grid
            xs={12}
            item
            className={clsx(classes.subtitle, classes.margiTop)}
          >
            <Typography variant="h6">Horarios del asesor</Typography>
          </Grid>
          <Grid container spacing={2}>
            <DayCards
              days={days}
              setDays={setDays}
              activeDays={activeDays}
              setActiveDays={setActiveDays}
            />
          </Grid>
        </Grid>

        <div className={classes.submitbutton}>
          <Button type="submit" variant="contained" color="primary">
            {renderUpdateOrCreateText()}
          </Button>
        </div>
      </form>
    </>
  );
};

export default InviteAdvisorForm;
