import { FormattedMessage, injectIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';

import { Alert } from 'react-bootstrap';
import Input from 'app/components/Input';
import React from 'react';
import { closeModals } from 'app/features/modals/modalsSlice';
import { confirmPasswordSchema } from '../ConfirmEmail/schema';
import { connect } from 'react-redux';
import { getNested } from 'app/utils';
import { passwordReset } from '../../authSlice';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';

function NewPassword(props) {
  const { history } = props;
  const searchParams = new URLSearchParams(useLocation().search);
  const token = searchParams.get('token');

  const { handleSubmit, register, errors } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(confirmPasswordSchema),
  });

  const handleCloseModals = () => {
    props.closeModals();
  };

  const renderError = () => {
    try {
      const { errorModal } = props.auth;
      if (errorModal && 'show' in errorModal && errorModal.show) {
        const { show, message } = props.auth['errorModal'];
        return (
          <Alert
            show={show}
            variant="danger"
            onClose={handleCloseModals}
            dismissible
          >
            <Alert.Heading>{message.desc}</Alert.Heading>
          </Alert>
        );
      }
      return null;
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  const dismissAlert = () => {
    window.setTimeout(() => {
      props.closeModals();
    }, 7000);
  };

  const handleConfirmPassword = (formData) => {
    const data = {
      token: token,
      password: formData.password,
      history,
    };
    props.passwordReset(data);
    dismissAlert();
  };

  return (
    <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__title">
            <h4>
              <FormattedMessage id="AUTH.RESET.TITLE" />
            </h4>
            <h6>
              <FormattedMessage id="AUTH.RESET.DESC" />
            </h6>
          </div>
          <form
            onSubmit={handleSubmit(handleConfirmPassword)}
            className="kt-form"
            autoComplete="off"
            noValidate
          >
            {renderError()}

            <div className="form-group">
              <Input
                required
                ref={register({
                  required: true,
                })}
                type="password"
                label="Introduzca una nueva contraseña"
                margin="normal"
                name="password"
                error={!!errors.password}
                helperText={getNested(
                  null,
                  errors,
                  'password',
                  'message',
                )}
              />

              <Input
                required
                ref={register({
                  required: true,
                })}
                type="password"
                label="Confirme su contraseña"
                name="rePassword"
                margin="normal"
                error={!!errors.rePassword}
                helperText={getNested(
                  null,
                  errors,
                  'rePassword',
                  'message',
                )}
              />
            </div>

            <div className="kt-login__actions">
              <Link to="/auth">
                <button
                  type="button"
                  onClick={handleCloseModals}
                  className="btn btn-secondary btn-elevate kt-login__btn-secondary"
                >
                  Volver
                </button>
              </Link>

              <button
                type="submit"
                className="btn btn-primary btn-elevate kt-login__btn-primary"
              >
                Enviar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {
  passwordReset: passwordReset,
  closeModals: closeModals,
};

export default withRouter(
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(NewPassword),
  ),
);
