/* eslint-disable react/display-name */
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { Col, Row } from 'react-bootstrap';
import React, { useEffect } from 'react';
import {
  cleanEmptyKeys,
  filterCaseInsensitive,
} from '../../../../utils';
import {
  closeModals,
  createAgency,
  getAgencies,
  updloadLogo,
} from '../../../agencies/actions';

import Add from '@material-ui/icons/Add';
import Check from '@material-ui/icons/Check';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import Modal from '../../../../components/Modal';
import { PropTypes } from 'prop-types';
import ReactTable from 'react-table';
import { agencySchema } from './schema';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { green } from '@material-ui/core/colors';
import { injectIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  paper: {
    widht: '100%',
    marginBottom: theme.spacing(0),
  },
  paperAdd: {
    widht: '100%',
  },
  margin: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  textField: {
    margin: theme.spacing(1),
    width: '95%',
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function AgenciesTable(props) {
  const classes = useStyles();
  const [agencies, setAgencies] = React.useState([]);
  const [showAddAgencyModal, setShowAddAgencyModal] = React.useState(
    false,
  );
  const buttonClassname = clsx({
    [classes.buttonSuccess]: props.agencies.logoUploaded,
  });
  const [sizeError, setSizeError] = React.useState(false);

  useEffect(() => {
    props.setActionButton(
      <Button
        variant="contained"
        className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
        onClick={toogleAddAgencyModal}
      >
        Crear agencia
        <Add className="kt-margin-l-10" />
      </Button>,
    );
    props.getAgencies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (Array.isArray(props.agencies.fromdb)) {
      const cleanAgencies = props.agencies.fromdb.map((agency) => {
        return {
          id: agency.id !== null ? agency.id : '-',
          name: agency.name !== null ? agency.name : '-',
          phoneNumber:
            agency.phoneNumber !== null ? agency.phoneNumber : '-',
          phoneID: agency.phoneID,
        };
      });
      setAgencies(cleanAgencies);
    }
  }, [props.agencies.fromdb]);

  const toogleAddAgencyModal = () =>
    setShowAddAgencyModal(!showAddAgencyModal);

  function handleCloseModals() {
    props.closeModals();
  }

  const columns = [
    {
      Header: () => <strong>ID</strong>,
      accessor: 'id',
      Cell: (row) => (
        <Link to={`/detail-agency/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>Nombre</strong>,
      accessor: 'name',
      Cell: (row) => (
        <Link to={`/detail-agency/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>Teléfono</strong>,
      accessor: 'phoneNumber',
      Cell: (row) => (
        <Link to={`/detail-agency/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>Phone Id</strong>,
      accessor: 'phoneID',
      Cell: (row) => {
        return (
          <Link to={`/detail-agency/${row.original.id}`}>
            <div
              style={{
                textAlign: 'center',
              }}
            >
              {row.value}
            </div>
          </Link>
        );
      },
    },
  ];

  const handleFile = (selectedFiles) => {
    setSizeError(false);
    if (!selectedFiles || selectedFiles.length === 0) return;

    if (selectedFiles[0].size > 5000000) {
      setSizeError(true);
      return;
    }

    if (selectedFiles[0] != null) {
      if (selectedFiles[0].size !== 0) {
        const formData = new FormData();
        formData.append(`file0`, selectedFiles[0]);
        return props.updloadLogo(formData);
      }
    }
    return;
  };

  const renderSizeError = () => {
    return sizeError ? (
      <p className="custom-error">Tu archivo pesa más de 5MB</p>
    ) : null;
  };

  const renderAddAgencyDialog = () => {
    return (
      <div>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={showAddAgencyModal}
          onClose={toogleAddAgencyModal}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Crear Nueva Agencia
          </DialogTitle>
          <Formik
            initialValues={{
              name: '',
              phoneNumber: '',
              phoneID: '',
              email: '',
            }}
            validationSchema={agencySchema}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              let cleanValues;
              props.agencies.logoUploaded
                ? (cleanValues = {
                    ...values,
                    logo: props.agencies.logoURL,
                  })
                : (cleanValues = {
                    ...values,
                  });
              cleanValues = cleanEmptyKeys(cleanValues);
              props.createAgency({ ...cleanValues });
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form
                className={classes.container}
                onSubmit={handleSubmit}
                noValidate
                autoComplete="off"
              >
                <DialogContent>
                  <Row>
                    <Col>
                      <TextField
                        required
                        name="name"
                        type="text"
                        label="Nombre"
                        variant="outlined"
                        className={classes.textField}
                        value={values.name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                      />
                    </Col>
                    <Col>
                      <TextField
                        required
                        name="phoneNumber"
                        type="text"
                        label="Número telefónico"
                        variant="outlined"
                        className={classes.textField}
                        value={values.phoneNumber}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          if (/^[0-9]*$/.test(e.target.value)) {
                            handleChange(e);
                          }
                        }}
                        error={Boolean(
                          touched.phoneNumber && errors.phoneNumber,
                        )}
                        helperText={
                          touched.phoneNumber && errors.phoneNumber
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <TextField
                        required
                        name="phoneID"
                        type="text"
                        label="Phone ID"
                        variant="outlined"
                        className={classes.textField}
                        value={values.rfc}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.rfc && errors.rfc)}
                        helperText={touched.rfc && errors.rfc}
                      />
                    </Col>
                    <Col>
                      <TextField
                        required
                        name="email"
                        type="text"
                        label="Correo electrónico"
                        variant="outlined"
                        className={classes.textField}
                        value={values.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </Col>
                  </Row>
                  <p className="text-align-dialog">
                    Solamente se aceptan imágenes JPEG, PNG y SVG de
                    máximo 5mb
                  </p>
                  <div className={classes.buttonGroup}>
                    <input
                      accept="image/*"
                      hidden
                      id="raised-button-file"
                      type="file"
                      onChange={(e) => handleFile(e.target.files)}
                    />
                    <label
                      htmlFor="raised-button-file"
                      className={classes.wrapper}
                    >
                      <Button
                        variant="contained"
                        component="span"
                        color="primary"
                        className={buttonClassname}
                        disabled={props.agencies.isLoading}
                      >
                        {props.agencies.logoUploaded
                          ? 'Logo Cargado'
                          : 'Cargar un logo de agencia'}
                        {props.agencies.logoUploaded ? (
                          <Check className={classes.rightIcon} />
                        ) : (
                          <CloudUploadIcon
                            className={classes.rightIcon}
                          />
                        )}
                      </Button>
                      {props.agencies.isLoading && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </label>
                  </div>
                  {renderSizeError()}
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={toogleAddAgencyModal}
                    color="primary"
                  >
                    Cancelar
                  </Button>
                  <Button
                    disabled={props.agencies.isLoading}
                    type="submit"
                    color="primary"
                  >
                    Crear
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </Dialog>
      </div>
    );
  };

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = props.agencies;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message } = props.agencies[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={handleCloseModals}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={handleCloseModals}
          />
        );
      }
      return null;
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <ReactTable
          NoDataComponent={() => (
            <div className="rt-noData">Sin resultados</div>
          )}
          data={agencies}
          columns={columns}
          filterable
          defaultFilterMethod={filterCaseInsensitive}
          className="-striped -highlight"
          previousText="Anterior"
          nextText="Siguiente"
          loading={props.agencies.isLoading}
          loadingText="Cargando datos..."
        />
      </Paper>
      {renderAddAgencyDialog()}
      {renderFeedBackModal()}
    </div>
  );
}

AgenciesTable.propTypes = {
  agencies: PropTypes.object,
  getAgencies: PropTypes.func,
  createAgency: PropTypes.func,
  updloadLogo: PropTypes.func,
  closeModals: PropTypes.func,
};

const mapStateToProps = (state) => ({
  agencies: state.agencies,
});

const mapDispatchToProps = {
  getAgencies,
  createAgency,
  updloadLogo,
  closeModals,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(AgenciesTable),
);
