import { createSelector } from 'reselect';
import { initialState } from './initialState';

export const selectClients = (state) => state.clients || initialState;

export const makeSelectClientsFromdb = createSelector(
  selectClients,
  (clientsState) => clientsState.fromdb,
);

export const makeSelectClientsToDownload = createSelector(
  selectClients,
  (clientsState) => clientsState.toDownload,
);

export const makeSelectClientsDetails = createSelector(
  selectClients,
  (clientsState) => clientsState.clientDetails,
);

export const makeSelectClientsIsLoading = createSelector(
  selectClients,
  (clientsState) => clientsState.isLoading,
);
