import { get, groupBy } from 'lodash';

import MenuConfig from '../../_metronic/layout/MenuConfig';
import moment from 'moment';
import numeral from 'numeral';

export function getAssesorType(type) {
  const formated = {
    ventas: 'Ventas',
    servicio: 'Servicio',
  };
  return formated[type];
}

export function getBadgeColor(status) {
  const colored = {
    'En curso': '#5578eb',
    'Asesor Asignado': '#ffb822',
    'Conversacion con Asesor iniciada': '#0abb87',
    Pendiente: '#fd397a',
    Ignorado: '#0B1D3F',
    Calificacion: '#0abb87',
    'SIN INTERACCION': '#5f9ea0',
    'telefono pendiente': 'darkgoldenrod',
    Prospecto: '#0B1D3F',
    'Prospecto perdido': '#fd397a',
    'Información Incompleta': '#fd397a',
    'Venta facturada': '#0b9494',
  };
  return colored[status];
}

export const filterCaseInsensitive = (filter, row) => {
  const id = filter.pivotId || filter.id;
  if (row[id] !== null && typeof row[id] === 'string') {
    return row[id] !== undefined
      ? String(row[id].toLowerCase()).includes(
          filter.value.toLowerCase(),
        )
      : true;
  } else {
    return String(row[filter.id]).includes(String(filter.value));
  }
};

export function formatNumber(number) {
  return numeral(number).format('$0,0.00');
}

export const getColumnWidth = (data, accessor, headerText) => {
  const cellLength = Math.max(
    ...data.map((row) => {
      let value = '';

      if (typeof accessor === 'string') {
        value = get(row, accessor);
      } else {
        value = accessor(row);
      }

      if (typeof value === 'number') return value.toString().length;
      return (value || '').length;
    }),
    headerText.length,
  );

  const magicSpacing = 8;
  return cellLength * magicSpacing;
};

export const getMainRoute = (role) => {
  let mainRoute = '/';
  if (`items${role}` in MenuConfig.aside) {
    MenuConfig.aside[`items${role}`].forEach((routeRole) => {
      if (routeRole.main) {
        const route = `/${routeRole.page}`;
        mainRoute = route;
      }
    });
  }
  return mainRoute;
};

export const cleanEmptyKeys = (object, originalObject = {}) => {
  const tempObject = { ...object };
  Object.keys(tempObject).forEach((key) => {
    if (originalObject[key] && tempObject[key] === '') {
      tempObject[key] = null;
    } else if (!originalObject[key] && tempObject[key] === '') {
      tempObject[key] = undefined;
    }
  });
  return tempObject;
};

export const withOutAccent = (string) =>
  string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const emailRegex = () =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const emailRegexString = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isNumeric = (value) => {
  return !isNaN(parseFloat(value)) && isFinite(value);
};

export const formatTime = (value) => {
  return value < 10 ? `0${value}` : value;
};

export const getNested = (isUndefined, obj, ...args) => {
  return (
    args.reduce((obj, level) => obj && obj[level], obj) || isUndefined
  );
};

export const menuOptionsMapped = {
  agendarCita: 'Servicio',
  agendarCitaAcued: 'Servicio',
  agendarCitaAmericas: 'Servicio',
  agendarCitaPlasencia: 'Servicio',
  agendarCitaFord: 'Servicio',
  cotiza: 'Ventas (cotizar)',
  cotizaGenerico: 'Ventas',
  cotizaAmericas: 'Ventas',
  cotizaPlasencia: 'Ventas',
  cotizaAcued: 'Ventas',
  cotizaFord: 'Ventas',
  asesorFord: 'Ventas (Asesor)',
  cotizaRefaccionesAcued: 'Refacciones',
  cotizaRefaccionesAmericas: 'Refacciones',
  cotizaRefaccionesPlasencia: 'Refacciones',
  cotizaSeminuevoAcued: 'Seminuevos',
  cotizaSeminuevoAmericas: 'Seminuevos',
  cotizaSeminuevoPlasencia: 'Seminuevos',
  prueba: 'Prueba de manejo',
  pruebaFord: 'Prueba de manejo',
  seguimiento: 'Recepción',
};

export const compareDates = (firstTime, secondTime) => {
  return (
    Date.parse(`01/01/2011 ${firstTime}`) >=
    Date.parse(`01/01/2011 ${secondTime}`)
  );
};

export const getAdvisorData = (agencyID, agencyUsers, value) => {
  if (Array.isArray(agencyUsers) && agencyUsers.length > 0) {
    for (let agencyUser of agencyUsers) {
      if (agencyUser.agencyID === agencyID) {
        return getNested('-', agencyUser.user[value]);
      }
    }
  }
  return '-';
};

export const getResponseTime = (
  repliedAt,
  createdAt,
  assignedAt,
  manuallyAssigned,
  format = 'mm:ss',
) => {
  repliedAt = repliedAt ? moment(repliedAt) : null;
  createdAt = createdAt ? moment(createdAt) : null;
  assignedAt = assignedAt ? moment(assignedAt) : null;
  if (assignedAt && repliedAt) {
    const duration = moment.duration(repliedAt.diff(assignedAt));
    return moment(duration.as('milliseconds')).format(format);
  } else if (manuallyAssigned && repliedAt) {
    const duration = moment.duration(repliedAt.diff(createdAt));
    return moment(duration.as('milliseconds')).format(format);
  } else {
    return '-';
  }
};

export const getWatingTime = (
  createdAt,
  repliedAt,
  assignedAt,
  manuallyAssigned,
  calculateCreated,
) => {
  let duration;
  createdAt = createdAt ? moment(createdAt) : null;
  repliedAt = repliedAt ? moment(repliedAt) : null;
  assignedAt = assignedAt ? moment(assignedAt) : null;

  if (assignedAt && repliedAt) {
    duration = moment.duration(repliedAt.diff(assignedAt));
    return roundValue(duration.as('minutes'), 1);
  } else if (manuallyAssigned && repliedAt) {
    duration = moment.duration(repliedAt.diff(createdAt));
    return roundValue(duration.as('minutes'), 1);
  } else if (calculateCreated) {
    duration = moment.duration(moment().diff(createdAt));
    return roundValue(duration.as('minutes'), 0);
  } else {
    return 0;
  }
};

export const cloneObjectLevels = (objectToClone) => {
  return JSON.parse(JSON.stringify(objectToClone));
};

export const advisorTypes = {
  ventas: 'Ventas',
  recepcion: 'Recepción',
  servicio: 'Servicio',
  refacciones: 'Refacciones',
  seminuevos: 'Seminuevos',
  financiamiento: 'Financiamiento',
  'SIN INTERACCION': 'Sin interacción',
};

export function isSameDayAndMonth(date1, date2) {
  const dateMoment1 = moment(date1);
  const dateMoment2 = moment(date2);
  return (
    dateMoment1.date() === dateMoment2.date() &&
    dateMoment1.month() === dateMoment2.month()
  );
}

export function dateIsBetween(startDate, endDate, compareDate) {
  const startDateMoment = moment(startDate).startOf('day');
  const endDateMoment = moment(endDate).endOf('day');
  const compateDateMoment = moment(compareDate);
  return compateDateMoment.isBetween(startDateMoment, endDateMoment);
}

export const groupTimeTables = (timeTablesRaw) => {
  const timeTablesPresetGrouped = groupBy(timeTablesRaw, 'name');
  const timeTablesMapped = Object.keys(timeTablesPresetGrouped).map(
    (key) => timeTablesPresetGrouped[key],
  );
  return timeTablesMapped;
};

export const mapNamesDay = {
  lunes: 1,
  martes: 2,
  miercoles: 3,
  jueves: 4,
  viernes: 5,
  sabado: 6,
  domingo: 7,
};

export const traduceMinutesTime = (minutes) => {
  if (minutes < 60) return `${minutes} m`;
  else if (minutes > 60 && minutes < 1440)
    return `${Math.floor(minutes / 60)} h`;
  else return `${Math.floor(minutes / 1440)} d`;
};

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const formatDatesForFilter = (
  startDate,
  endDate = moment(startDate),
) => {
  if (startDate)
    return {
      $gt: startDate.startOf('day'),
      $lt: endDate.endOf('day'),
    };
  return undefined;
};

export const formatDate = (date) => {
  return moment(date).format('DD/MM/YYYY hh:mm a');
};

export const roundValue = (num, decimals = 2) => {
  let scaling = 10 ** decimals;
  return Math.round((num + Number.EPSILON) * scaling) / scaling;
};
