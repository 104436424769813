import { FormattedMessage, injectIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';

import { Alert } from 'react-bootstrap';
import Input from 'app/components/Input';
import { Link } from 'react-router-dom';
import { closeModals } from 'app/features/modals/modalsSlice';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { getNested } from 'app/utils';
import { login as loginAction } from '../../authSlice';
import { loginSchema } from './schema';
import { makeStyles } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

const useStyles = makeStyles((theme) => ({
  alert: {
    marginLeft: theme.spacing(2),
  },
}));

function Login(props) {
  const { auth, agencies } = props;
  const classes = useStyles();
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: '2.5rem',
  });

  const { handleSubmit, register, errors } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(loginSchema),
  });

  useEffect(() => {
    if (props.auth.isLoading)
      setLoadingButtonStyle({ paddingRight: '3.5rem' });
    else setLoadingButtonStyle({ paddingRight: '2.5rem' });
  }, [props.auth.isLoading]); // Only re-run the effect if count changes

  const handleCloseModals = () => {
    props.closeModals();
  };

  const renderError = () => {
    try {
      const { errorModal } = props.auth;
      if (errorModal && 'show' in errorModal && errorModal.show) {
        const { show, message } = props.auth['errorModal'];
        return (
          <Alert
            show={show}
            variant="danger"
            onClose={handleCloseModals}
            dismissible
          >
            <Alert.Heading>{message.desc}</Alert.Heading>
          </Alert>
        );
      }
      return null;
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  const renderSuccess = () => {
    return (
      <Alert
        show={props.auth.successModal.show}
        variant="success"
        onClose={handleCloseModals}
        dismissible
      >
        <Alert.Heading>Éxito</Alert.Heading>
        <p className={classes.alert}>
          {getNested(
            '',
            props.auth,
            'successModal',
            'message',
            'desc',
          )}
        </p>
      </Alert>
    );
  };

  const dismissAlert = () => {
    window.setTimeout(() => {
      props.closeModals();
    }, 7000);
  };

  const handleLogin = (formData) => {
    props.loginAction({
      email: formData.email,
      password: formData.password,
    });
    dismissAlert();
  };

  return (
    <>
      <div className="kt-login__body">
        <div className="kt-login__form">
          <form
            autoComplete="off"
            className="kt-form"
            onSubmit={handleSubmit(handleLogin)}
            noValidate
          >
            {renderError()}
            {renderSuccess()}

            <div className="form-group">
              <Input
                required
                ref={register({
                  required: true,
                })}
                type="email"
                label="Correo electrónico"
                margin="normal"
                name="email"
                error={!!errors.email}
                helperText={getNested(
                  null,
                  errors,
                  'email',
                  'message',
                )}
              />
            </div>

            <div className="form-group">
              <Input
                required
                ref={register({
                  required: true,
                })}
                type="password"
                margin="normal"
                label="Constraseña"
                name="password"
                error={!!errors.password}
                helperText={getNested(
                  null,
                  errors,
                  'password',
                  'message',
                )}
              />
            </div>

            <div className="kt-login__actions">
              <Link
                to="/auth/forgot-password"
                className="kt-link kt-login__link-forgot"
              >
                <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
              </Link>

              <button
                id="kt_login_signin_submit"
                type="submit"
                disabled={props.auth.isLoading || agencies.isLoading}
                className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                  {
                    'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
                      auth.isLoading || agencies.isLoading,
                  },
                )}`}
                style={loadingButtonStyle}
              >
                Entrar
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  agencies: state.agencies,
});

const mapDispatchToProps = {
  loginAction,
  closeModals,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(Login),
);
