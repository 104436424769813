import { createAction, createSlice } from '@reduxjs/toolkit';

import { initialState } from './initialState';

const cleanStore = createAction('auth/logout');
const changeAgency = createAction('auth/changeAgency');

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    notificationSend: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    notificationSendSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    notificationSendFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    closeNotificationModals: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = initialState.successModal;
        state.errorModal = initialState.errorModal;
        state.deletedModal = false;
      },
    },
    sendNotificationsToAllUsers: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    sendNotificationsToAllUsersSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    sendNotificationsToAllUsersFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
  },
  extraReducers: {
    [cleanStore]: (state, action) => {
      return initialState;
    },
    [changeAgency]: (state, action) => {
      return initialState;
    },
  },
});

export const {
  notificationSend,
  notificationSendSuccess,
  notificationSendFail,
  sendNotificationsToAllUsers,
  sendNotificationsToAllUsersSuccess,
  sendNotificationsToAllUsersFail,
  closeNotificationModals,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
