import { createSelector } from 'reselect';
import { initialState } from './initialState';

export const selectCampaigns = (state) =>
  state.campaigns || initialState;

export const makeSelectCampaignsFromdb = createSelector(
  selectCampaigns,
  (campaignState) => campaignState.fromdb,
);

export const makeSelectCampaignDetails = createSelector(
  selectCampaigns,
  (campaignState) => campaignState.campaignDetails,
);

export const makeSelectCampaignToDownload = createSelector(
  selectCampaigns,
  (campaignState) => campaignState.toDownload,
);
