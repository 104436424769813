/* eslint-disable jsx-a11y/no-onchange */
import 'moment/locale/es';

/* eslint-disable react/display-name */
import { Paper, makeStyles } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';
import {
  filterCaseInsensitive,
  getNested,
  getResponseTime,
} from '../../../../utils';
import {
  makeSelectClientsFromdb,
  selectClients,
} from 'app/features/clients/selectors';
import {
  makeSelectUserFromState,
  makeSelectUserRoleFromState,
} from '../../../auth/selectors';

import ConversationStatusBadge from 'app/components/ConversationStatusBadge';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { getAllClients } from 'app/features/clients/clientsSlice';
import { withRouter } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    widht: '100%',
    marginBottom: theme.spacing(2),
  },
}));

const ClientsTable = (props) => {
  const classes = useStyles();
  const {
    clientsState,
    getAllClients,
    role,
    user,
    clientsFromdb,
  } = props;
  const reactTable = useRef(null);

  const handleFetchData = debounce(
    (state, instance) => {
      const { page, pageSize, filtered } = state;
      getAllClients({
        page,
        pageSize,
        filtered,
      });
    },
    1000,
    { leading: false, trailing: true },
  );

  useEffect(() => {
    reactTable.current && reactTable.current.fireFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const colums = [
    {
      Header: () => <strong>Lead ID</strong>,
      accessor: 'id',
      Cell: (row) => (
        <Link to={`/client-details/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>Origen</strong>,
      accessor: 'fbID',
      Cell: (row) => {
        let origin = getNested(
          null,
          row,
          'original',
          'conversations',
          [0],
          'origin',
        );
        if (!origin) origin = row.value ? 'Facebook' : 'Whatsapp';
        return (
          <Link to={`/client-details/${row.original.id}`}>
            <div
              style={{
                textAlign: 'center',
              }}
            >
              {origin}
            </div>
          </Link>
        );
      },
      Filter: (cell) => {
        return (
          <select
            onChange={(e) => cell.onChange(e.target.value)}
            value={
              cell.filter && cell.filter.value
                ? cell.filter.value
                : ''
            }
          >
            <option value="">Todos</option>
            <option value="whatsapp">Whatsapp</option>
            <option value="facebook">Facebook</option>
            {/* <option value="Mailing">Mailing</option> */}
            <option value="Mailing (Planta)">Mailing (Planta)</option>
            <option value="Mailing (Facebook)">
              Mailing (Facebook)
            </option>
            <option value="Mailing (Web)">Mailing (Web)</option>
          </select>
        );
      },
    },
    {
      Header: () => <strong>Fuente</strong>,
      accessor: 'conversations[0].campaignClick.campaign.name',
      Cell: (row) => (
        <Link to={`/client-details/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value
              ? `Campaña (${row.value})`
              : 'Contacto directo'}
          </div>
        </Link>
      ),
      Filter: (cell) => {
        return (
          <select
            onChange={(e) => cell.onChange(e.target.value)}
            value={
              cell.filter && cell.filter.value
                ? cell.filter.value
                : ''
            }
          >
            <option value="">Todos</option>
            <option value="dc">Contacto directo</option>
            <option value="campaign">Campaña</option>
          </select>
        );
      },
      width: 160,
    },
    {
      Header: () => <strong>Categoría</strong>,
      accessor: 'conversations[0].agencyUser.type',
      Cell: (row) => (
        <Link to={`/client-details/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
    },
    // {
    //   Header: () => <strong>Fuente</strong>,
    //   accessor: 'campaignID',
    //   Cell: row => (
    //     <Link to={`/client-details/${row.original.id}`}>
    //       <div
    //         style={{
    //           textAlign: 'center',
    //         }}
    //       >
    //         {row.value
    //           ? `Campaña (${getNested(
    //               '-',
    //               row.value,
    //               'campaign',
    //               'name',
    //             )})`
    //           : 'Contacto directo'}
    //       </div>
    //     </Link>
    //   ),
    //   filterable: false,
    // },
    {
      Header: () => <strong>Asesor</strong>,
      accessor: 'conversations[0].agencyUser.user.name',
      Cell: (row) => (
        <Link to={`/client-details/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>Teléfono Cliente</strong>,
      accessor: 'phoneNumber',
      Cell: (row) => (
        <Link to={`/client-details/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>Interés</strong>,
      accessor: 'conversations[0].type',
      Cell: (row) => (
        <Link to={`/client-details/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>Tiempo de respuesta</strong>,
      accessor: 'conversations[0]',
      Cell: (row) => (
        <Link to={`/client-details/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {getResponseTime(
              row.value?.repliedAt,
              row.value?.createdAt,
              row.value?.assignedAt,
              row.value?.manuallyAssigned,
            )}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>Estatus</strong>,
      accessor: 'conversations[0].status',
      Cell: (row) => (
        <Link to={`/client-details/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <ConversationStatusBadge status={row.value} />
          </div>
        </Link>
      ),
      Filter: (cell) => {
        return (
          <select
            onChange={(e) => cell.onChange(e.target.value)}
            value={
              cell.filter && cell.filter.value
                ? cell.filter.value
                : ''
            }
          >
            <option value="">Todos</option>
            <option value="En curso">En curso</option>
            <option value="Asesor Asignado">Asesor asignado</option>
            <option value="Conversacion con Asesor iniciada">
              Conversacion con Asesor iniciada
            </option>
            <option value="Pendiente">Pendiente</option>
            <option value="Ignorado">Ignorado</option>
            <option value="Calificacion">Calificación</option>
            <option value="SIN INTERACCION">SIN INTERACCION</option>
            <option value="telefono pendiente">
              Teléfono pendiente
            </option>
          </select>
        );
      },
      width: 200,
    },
  ];

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <ReactTable
          NoDataComponent={() => (
            <div className="rt-noData">Sin resultados</div>
          )}
          ref={reactTable}
          manual
          onFetchData={handleFetchData}
          data={getNested([], clientsFromdb)}
          columns={colums}
          pages={clientsState.tablePages}
          filterable
          className="-striped -highlight"
          previousText="Anterior"
          nextText="Siguiente"
          loading={clientsState.isLoading}
          loadingText="Cargando datos..."
        />
      </Paper>
    </div>
  );
};

const mapStateToProps = (state) => ({
  clientsFromdb: makeSelectClientsFromdb(state),
  clientsState: selectClients(state),
  role: makeSelectUserRoleFromState(state),
  user: makeSelectUserFromState(state),
});

const mapDispatchToProps = {
  getAllClients,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClientsTable),
);
