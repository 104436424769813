import { Button, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
  cloneObjectLevels,
  compareDates,
  formatTime,
  getNested,
  mapNamesDay,
  withOutAccent,
} from 'app/utils';

import { Alert } from 'react-bootstrap';
import { DayCards } from 'app/features/users/components/DayCards';

const useStyles = makeStyles((theme) => ({
  commission: {
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: 'black',
  },
  tables: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  leyendsContainer: {
    padding: theme.spacing(1, 3, 4, 5),
  },
  items: {
    padding: theme.spacing(0),
  },
  createScheduleButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '8px',
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
}));

const mapDayNames = {
  1: 'Lunes',
  2: 'Martes',
  3: 'Miércoles',
  4: 'Jueves',
  5: 'Viernes',
  6: 'Sábado',
  7: 'Domingo',
};

const cleanActiveDays = {
  1: true,
  2: true,
  3: true,
  4: true,
  5: true,
  6: true,
  7: true,
};

const cleanDays = {
  1: [{ from: '00:01', to: '23:59' }],
  2: [{ from: '00:01', to: '23:59' }],
  3: [{ from: '00:01', to: '23:59' }],
  4: [{ from: '00:01', to: '23:59' }],
  5: [{ from: '00:01', to: '23:59' }],
  6: [{ from: '00:01', to: '23:59' }],
  7: [{ from: '00:01', to: '23:59' }],
};

const cleanOriginalDays = {
  1: [],
  2: [],
  3: [],
  4: [],
  5: [],
  6: [],
  7: [],
};

const ServiceSchedule = ({
  patchServiceHours,
  agencyTimeTables,
  isLoadingAgencies,
}) => {
  const classes = useStyles();
  const [days, setDays] = useState(cloneObjectLevels(cleanDays));
  const [showBadTimeAlert, setShowBadTimeAlert] = useState(false);
  const [originalDays, setOriginalDays] = useState(
    cloneObjectLevels(cleanOriginalDays),
  );
  const [activeDays, setActiveDays] = useState(
    cloneObjectLevels(cleanActiveDays),
  );

  useEffect(() => {
    loadTimeTablePreset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agencyTimeTables]);

  const loadTimeTablePreset = (idx) => {
    const tempOriginalDays = cloneObjectLevels(cleanOriginalDays);
    const tempDays = cloneObjectLevels(cleanDays);
    const tempActiveDays = cloneObjectLevels(cleanActiveDays);
    getNested([], agencyTimeTables).forEach((timeTable) => {
      tempActiveDays[mapNamesDay[timeTable.day]] =
        timeTable.activated;
      const tempDay = {
        id: timeTable.id,
        activated: timeTable.activated,
        from: `${formatTime(timeTable.hourA)}:${formatTime(
          timeTable.minutesA,
        )}`,
        to: `${formatTime(timeTable.hourB)}:${formatTime(
          timeTable.minutesB,
        )}`,
      };
      tempDays[mapNamesDay[timeTable.day]][0] = {
        ...tempDay,
      };
      tempOriginalDays[mapNamesDay[timeTable.day]].push({
        ...tempDay,
      });
    });
    setActiveDays(tempActiveDays);
    setOriginalDays(tempOriginalDays);
    setDays(tempDays);
  };

  const handleCreateOrUpdate = () => {
    contrastHours();
  };

  const contrastHours = () => {
    const copyOriginalDays = cloneObjectLevels(originalDays);
    const timeTables = [];
    try {
      Object.keys(copyOriginalDays).forEach((originalDayKey) => {
        copyOriginalDays[originalDayKey].forEach(
          (originalHours, index) => {
            let notFound = true;
            let changed = false;
            days[originalDayKey].forEach((modifiedHour) => {
              if (originalHours.id === modifiedHour.id) {
                notFound = false;
                const tempActiveDays = { ...activeDays };
                if (
                  originalHours.from !== modifiedHour.from ||
                  originalHours.to !== modifiedHour.to
                ) {
                  originalHours.from = modifiedHour.from;
                  originalHours.to = modifiedHour.to;
                  changed = true;
                }
                if (
                  originalHours.activated !==
                  tempActiveDays[parseInt(originalDayKey)]
                ) {
                  changed = true;
                }
              }
            });
            if (originalHours.id && notFound) {
              originalHours.remove = true;
            } else if (!changed && originalHours.id) {
              copyOriginalDays[originalDayKey].splice(index, 1);
            }
          },
        );
        days[originalDayKey].forEach((modifiedHour) => {
          if (
            !modifiedHour.id &&
            modifiedHour.from &&
            modifiedHour.to
          ) {
            copyOriginalDays[originalDayKey].push(modifiedHour);
          }
        });
      });

      Object.keys(copyOriginalDays).forEach((originalDayKey) => {
        copyOriginalDays[originalDayKey].forEach(
          (originalHours, index) => {
            if (compareDates(originalHours.from, originalHours.to))
              throw new Error('Bad time range');
            const day = withOutAccent(
              mapDayNames[originalDayKey].toLowerCase(),
            );
            const hourASplited = originalHours.from.split(':');
            const hourBSplited = originalHours.to.split(':');
            const hourA = parseInt(hourASplited[0]);
            const minutesA = parseInt(hourASplited[1]);
            const hourB = parseInt(hourBSplited[0]);
            const minutesB = parseInt(hourBSplited[1]);
            const activated = activeDays[originalDayKey];
            timeTables.push({
              id: originalHours.id ? originalHours.id : undefined,
              remove: originalHours.remove ? true : undefined,
              day,
              hourA,
              minutesA,
              hourB,
              minutesB,
              activated,
            });
          },
        );
      });
      patchServiceHours({
        timeTables,
      });
    } catch (e) {
      console.log(e);
      if (e.message === 'Bad time range')
        return setShowBadTimeAlert(true);
    }
  };

  const renderError = () => {
    return (
      <Alert
        show={showBadTimeAlert}
        variant="danger"
        onClose={() => setShowBadTimeAlert(false)}
        dismissible
      >
        <Alert.Heading>Horario incorrecto</Alert.Heading>
        <p className={classes.alert}>
          Has ingresado un horario erróneo, la hora del campo *desde*
          debe ser mayor a la del campo *hasta*
        </p>
      </Alert>
    );
  };

  return (
    <>
      {renderError()}
      <DayCards
        days={days}
        setDays={setDays}
        activeDays={activeDays}
        setActiveDays={setActiveDays}
        optionsHours={false}
        copySchedule={false}
      />
      <div className={classes.createScheduleButton}>
        <Button
          variant="contained"
          className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
          onClick={handleCreateOrUpdate}
        >
          Actualizar horarios de servicio
        </Button>
      </div>
    </>
  );
};

export default ServiceSchedule;
