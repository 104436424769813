import {
  ClickAwayListener,
  MuiThemeProvider,
  Tooltip,
  createMuiTheme,
} from '@material-ui/core';

import React from 'react';
import copy from 'copy-to-clipboard';

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '1em',
        color: 'lightblue',
        backgroundColor: 'green',
      },
    },
  },
});

export const CopyToClipboard = ({ textToCopy, children }) => {
  const [showTooltip, setShowTooltip] = React.useState(false);

  const handleCopyText = () => {
    setShowTooltip(true);
    copy(textToCopy);
  };

  const handleHideTooltip = () => {
    setShowTooltip(false);
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleHideTooltip}>
        <div>
          <MuiThemeProvider theme={theme}>
            <Tooltip
              className="copied-url-tooltip"
              placement="top"
              onClose={handleHideTooltip}
              open={showTooltip}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title="¡URL copiada al portapapeles!"
            >
              {React.cloneElement(children, {
                onClick: handleCopyText,
              })}
            </Tooltip>
          </MuiThemeProvider>
        </div>
      </ClickAwayListener>
    </>
  );
};
