import { FormattedMessage, injectIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import {
  confirmEmail as confirmEmailAction,
  logout,
} from '../../authSlice';

import { Alert } from 'react-bootstrap';
import Input from 'app/components/Input';
import { closeModals } from 'app/features/modals/modalsSlice';
import { confirmPasswordSchema } from './schema';
import { connect } from 'react-redux';
import { getNested } from 'app/utils';
import { selectAuth } from '../../selectors';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';

function ConfirmEmail(props) {
  const { history } = props;
  const searchParams = new URLSearchParams(useLocation().search);
  const token = searchParams.get('token');
  const userID = searchParams.get('userID');

  const { handleSubmit, register, errors } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(confirmPasswordSchema),
  });

  useEffect(() => {
    if (props.auth.user) props.logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.auth.user]);

  const handleCloseModals = () => {
    props.closeModals();
  };

  const renderError = () => {
    try {
      const { errorModal } = props.auth;
      if (errorModal && 'show' in errorModal && errorModal.show) {
        const { show, message } = props.auth['errorModal'];
        return (
          <Alert
            show={show}
            variant="danger"
            onClose={handleCloseModals}
            dismissible
          >
            <Alert.Heading>{message.desc}</Alert.Heading>
          </Alert>
        );
      }
      return null;
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  const dismissAlert = () => {
    window.setTimeout(() => {
      props.closeModals();
    }, 7000);
  };

  const handleConfirmEmail = (formData) => {
    const data = {
      userID,
      token,
      password: formData.password,
      history,
    };
    props.confirmEmailAction(data);
    dismissAlert();
  };

  return (
    <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__title">
            <h4>
              <FormattedMessage id="AUTH.CONFIRM.TITLE" />
            </h4>
            <h6>
              <FormattedMessage id="AUTH.CONFIRM.DESC" />
            </h6>
          </div>
          <form
            onSubmit={handleSubmit(handleConfirmEmail)}
            className="kt-form"
            autoComplete="off"
            noValidate
          >
            {renderError()}
            <div className="form-group">
              <Input
                required
                ref={register({
                  required: true,
                })}
                type="password"
                label="Introduzca su nueva contraseña"
                margin="normal"
                name="password"
                error={!!errors.password}
                helperText={getNested(
                  null,
                  errors,
                  'password',
                  'message',
                )}
              />

              <Input
                required
                ref={register({
                  required: true,
                })}
                type="password"
                label="Confirme su contraseña"
                margin="normal"
                name="rePassword"
                error={Boolean(errors.rePassword)}
                helperText={getNested(
                  null,
                  errors,
                  'rePassword',
                  'message',
                )}
              />
            </div>
            <div className="kt-login__actions">
              <Link to="/auth">
                <button
                  type="button"
                  className="btn btn-secondary btn-elevate kt-login__btn-secondary"
                  onClick={handleCloseModals}
                >
                  Volver
                </button>
              </Link>

              <button
                type="submit"
                className="btn btn-primary btn-elevate kt-login__btn-primary"
              >
                Enviar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: selectAuth(state),
});

const mapDispatchToProps = {
  confirmEmailAction,
  closeModals,
  logout,
};

export default withRouter(
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(ConfirmEmail),
  ),
);
