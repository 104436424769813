import React, { Fragment, useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import { makeStyles } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: 10,
    marginBottom: 10,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  title: {
    marginLeft: 10,
    padding: 0,
    fontSize: '1.3rem',
    fontWeight: 500,
    color: '#1c1c1c',
  },
  container: {
    width: '100%',
  },
}));

export const InfoDetails = (props) => {
  const { info, source } = props;
  const classes = useStyles();
  const [convertInfo, setConvertInfo] = useState(null);

  useEffect(() => {
    if (source === 'planta' || source === 'web') {
      setConvertInfo(info.split('\n\n'));
    }
    if (source === 'Facebook ADS') {
      setConvertInfo(JSON.parse(info));
    }
  }, [source]);

  const filteredInfo = (data) => {
    if (!data) return;
    if (source === 'Facebook ADS') {
      return (
        <p
          style={{
            margin: '1.5em 0em',
          }}
        >
          {JSON.stringify(data, null, 10)}
        </p>
      );
    }

    let filterInfo = data.filter((item) => {
      if (
        item.includes('Copyright') ||
        item.includes('Mozilla') ||
        item.includes('Chrome') ||
        item.includes('Utm') ||
        item.includes('Agent') ||
        item.includes('-->') ||
        item.includes('96') ||
        item.includes('Template Base') ||
        item.includes('Registro') ||
        item.includes('ID') ||
        item.includes('Source System Detail') ||
        item.includes('Tipo de') ||
        item.includes('Opt In') ||
        item.includes('Single') ||
        item.includes('hyundai.com.mx') ||
        item.includes('social') ||
        item.includes('facebook') ||
        item.includes('Distribuidor Clave') ||
        item.includes('Quote Request') ||
        item.includes('F1') ||
        item.includes('Datos Personales') ||
        item.includes('Saludo') ||
        item.includes('Srita.') ||
        item.includes('Sra.') ||
        item.includes('Sr.') ||
        item.includes('Datos del Auto') ||
        item.includes('Enviar desde') ||
        item.includes('Prospecto') ||
        item.includes('Peugeot') ||
        item.includes('Selecciona tu agencia') ||
        !item.trim().length
      )
        return false;
      return true;
    });

    return filterInfo.map((item, idx) => {
      if (
        item === 'Origen' ||
        item === 'Fecha de Creación' ||
        item === 'Distribuidor' ||
        item === 'Modelo' ||
        item === 'Version' ||
        item === 'Nombre' ||
        item === 'Telefono' ||
        item === 'Email' ||
        item === 'Codigo Postal' ||
        item === 'Source System' ||
        item === 'Version' ||
        item === 'Interes de compra' ||
        item === 'Estado' ||
        item === 'Comentarios' ||
        item === 'Información de Campaña' ||
        item === 'Nombre y Apellido' ||
        item === 'Teléfono' ||
        item === '¿Cómo deseas ser contactado?'
      ) {
        return (
          <p
            key={idx}
            style={{
              marginTop: '0.3rem',
              marginBottom: '0.3rem',
              fontWeight: 500,
            }}
          >
            {item}
          </p>
        );
      }
      return (
        <p
          key={idx}
          style={{
            marginTop: '0.2rem',
            marginBottom: '0.2rem',
          }}
        >
          {item}
        </p>
      );
    });
  };

  const renderTable = (data) => {
    if (!data) return;

    if (source === 'Facebook ADS') {
      return Object.keys(convertInfo).map((k, idx) => {
        // We dont need to show raw property from object
        if (k === 'raw') return null;

        const removeUnderscores = k.replaceAll('_', ' ');

        let formatKey = '';
        let capitalize = 0;
        let sliceNumber = 1;
        if (removeUnderscores.charAt(0) === '¿') {
          capitalize = 1;
          sliceNumber = 2;
        }
        formatKey =
          removeUnderscores.charAt(capitalize).toUpperCase() +
          removeUnderscores.slice(sliceNumber);

        if (capitalize === 1) {
          // If capitalize is 1 means is a question.
          formatKey = `¿${formatKey}`;
        }
        return (
          <tr
            key={idx}
            style={{
              borderBottom: '1px solid #EBEBEB',
            }}
          >
            <td
              style={{
                fontWeight: 500,
                padding: '0.4rem 0.5rem',
              }}
            >
              {formatKey}
            </td>
            <td
              style={{
                padding: '0.4rem 0.5rem',
              }}
            >
              {convertInfo[k]}
            </td>
          </tr>
        );
      });
    }
    let filterInfo = data.filter((item) => {
      if (
        item.includes('Copyright') ||
        item.includes('Mozilla') ||
        item.includes('Chrome') ||
        item.includes('Utm') ||
        item.includes('Agent') ||
        item.includes('-->') ||
        item.includes('96') ||
        item.includes('Template Base') ||
        item.includes('Registro') ||
        item.includes('ID') ||
        item.includes('Source System Detail') ||
        item.includes('Tipo de') ||
        item.includes('Opt In') ||
        item.includes('Single') ||
        item.includes('hyundai.com.mx') ||
        item.includes('social') ||
        item.includes('facebook') ||
        item.includes('Distribuidor Clave') ||
        item.includes('Quote Request') ||
        item.includes('F1') ||
        item.includes('Datos Personales') ||
        item.includes('Saludo') ||
        item.includes('Srita.') ||
        item.includes('Sra.') ||
        item.includes('Sr.') ||
        item.includes('Datos del Auto') ||
        item.includes('Enviar desde') ||
        item.includes('Prospecto') ||
        item.includes('Peugeot') ||
        item.includes('Selecciona tu agencia') ||
        !item.trim().length
      )
        return false;
      return true;
    });
    if (source === 'web') {
      return filterInfo.map((item, idx) => {
        let isTitleStyle = {
          padding: '0.4rem 0.5rem',
        };

        // Here we know that it's a title.
        if (idx % 2 === 0) {
          isTitleStyle = {
            fontWeight: 500,
            padding: '0.4rem 0.5rem',
          };
        }
        return (
          <Fragment key={idx}>
            <tr
              style={{
                borderBottom: '1px solid #EBEBEB',
              }}
            >
              <td style={isTitleStyle}>{item}</td>
            </tr>
          </Fragment>
        );
      });
    }

    if (source === 'planta') {
      return filterInfo.map((item, idx) => {
        let movePosition = 1;
        if (
          item === 'Origen' ||
          item === 'Source System' ||
          item === 'Fecha de Creación' ||
          item === 'Distribuidor' ||
          item === 'Modelo' ||
          item === 'Version' ||
          item === 'Interes de compra' ||
          item === 'Nombre' ||
          item === 'Telefono' ||
          item === 'Email' ||
          item === 'Codigo Postal' ||
          item === 'Estado' ||
          item === 'Comentarios' ||
          item === 'Información de Campaña'
        ) {
          if (
            item === 'Version' ||
            item === 'Interes de compra' ||
            item === 'Telefono' ||
            item === 'Email' ||
            item === 'Codigo Postal' ||
            item === 'Estado'
          ) {
            movePosition = 2;
          }
          return (
            <Fragment>
              <tr
                key={idx}
                style={{
                  borderBottom: '1px solid #EBEBEB',
                }}
              >
                <td
                  style={{
                    fontWeight: 500,
                    padding: '0.4rem 0.5rem',
                  }}
                >
                  {item}
                </td>
                <td
                  style={{
                    padding: '0.4rem 0.5rem',
                  }}
                >
                  {filterInfo[idx + movePosition]}
                </td>
              </tr>
            </Fragment>
          );
        }
      });
    }

    return null;
  };
  // const convertInfo = info.split('\n\n');
  return (
    <div className={classes.root}>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h3 className={classes.title}>Información</h3>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          {convertInfo && (
            <div className={classes.container}>
              <Table
                id="beautifyInfo"
                style={{
                  marginBottom: '3rem',
                }}
              >
                <thead>
                  <tr
                    style={{
                      borderTop: '1px solid #EBEBEB',
                      borderBottom: '1px solid #EBEBEB',
                    }}
                  >
                    <td
                      colSpan={2}
                      style={{
                        fontSize: '1.4rem',
                        fontWeight: 500,
                        textAlign: 'center',
                        padding: '0.4rem 0.5rem',
                      }}
                    >
                      Visualización de la información
                    </td>
                  </tr>
                </thead>
                <tbody>{renderTable(convertInfo)}</tbody>
              </Table>
              <Table id="rawInfo">
                <thead>
                  <tr
                    style={{
                      borderTop: '1px solid #EBEBEB',
                      borderBottom: '1px solid #EBEBEB',
                    }}
                  >
                    <td
                      colSpan={2}
                      style={{
                        fontSize: '1.4rem',
                        fontWeight: 500,
                        textAlign: 'center',
                        padding: '0.4rem 0.5rem',
                      }}
                    >
                      Visualización en crudo de la información
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    style={{
                      borderBottom: '1px solid #EBEBEB',
                    }}
                  >
                    {filteredInfo(convertInfo)}
                  </tr>
                </tbody>
              </Table>
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
