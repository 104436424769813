import * as yup from 'yup';

// const stringNotRequired = yup.string().nullable();
const stringValid = yup.string().required('Este campo es requerido');
const numberValid = yup
  .number()
  .required('Este campo es requerido')
  .positive('El valor no puede ser negativo')
  .typeError('Ingresa solamente números');

export const agencyProfileSchema = yup.object().shape({
  name: stringValid,
  phoneNumber: stringValid,
  email: stringValid,
  menuMessage: stringValid.max(
    255,
    'El mensaje de bienvenida debe ser de 255 caracteres máximo',
  ),
  advisorTimeout: numberValid,
  scoreMessage: stringValid.max(
    255,
    'El mensaje de bienvenida debe ser de 255 caracteres máximo',
  ),
  unavailableMessage: stringValid.max(
    255,
    'El mensaje de bienvenida debe ser de 255 caracteres máximo',
  ),
  inactivityMessage: stringValid.max(
    255,
    'El mensaje de bienvenida debe ser de 255 caracteres máximo',
  ),
});
