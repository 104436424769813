import * as XLSX from 'xlsx';

import React, { useState } from 'react';

import { Button } from '@material-ui/core';
import Modal from 'app/components/Modal';
import clsx from 'clsx';

export default function ButtonImportPhoneNumbers({
  buttonName,
  setExcelNumbers,
  classNameButton,
}) {
  const [showExcelFileModal, setShowExcelFileModal] = useState(false);
  const [excelFile, setExcelFile] = React.useState(null);

  const handleFile = (selectedFiles, e) => {
    e.preventDefault();
    if (!selectedFiles || selectedFiles.length === 0) return;

    if (selectedFiles[0] !== null) {
      if (selectedFiles[0].size !== 0) {
        setExcelFile(selectedFiles[0]);
        setShowExcelFileModal(true);
        e.target.value = null;
        return;
      }
    }
    return;
  };

  const handleReadExcel = () => {
    const reader = new FileReader();
    reader.onload = (evt) => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });

      const parsedPhoneNumbers = dataParse.map((phoneNumber) =>
        phoneNumber.indexOf('52') === -1
          ? `52${phoneNumber}`
          : phoneNumber,
      );

      setExcelNumbers(parsedPhoneNumbers);
    };
    reader.readAsBinaryString(excelFile);
    setShowExcelFileModal(false);
  };

  const renderUploadExcelFileDialog = () => {
    return (
      <Modal
        open={showExcelFileModal}
        closeModal={() => {
          setShowExcelFileModal(false);
          setExcelFile(null);
        }}
        dialogTitle="Importar números"
        dialogText={`Está a punto de importar los números telefónicos desde el archivo ${
          excelFile ? excelFile.name : ''
        }\n desea continuar?`}
        actionButtonText="Importar"
        onClick={() => handleReadExcel()}
      />
    );
  };

  return (
    <>
      {renderUploadExcelFileDialog()}
      <input
        accept=".xlsx, .xls, .csv"
        hidden
        id="raised-button-file"
        type="file"
        onChange={(e) => handleFile(e.target.files, e)}
      />
      <label htmlFor="raised-button-file">
        <Button
          variant="contained"
          className={clsx(
            'btn btn-label-success btn-bold btn-sm btn-icon-h',
            classNameButton,
          )}
          component="span"
        >
          {buttonName}
        </Button>
      </label>
    </>
  );
}
