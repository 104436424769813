export const initialAuthState = {
  user: null,
  agencyUsers: null,
  authToken: null,
  successModal: {
    show: false,
    message: null,
  },
  errorModal: {
    show: false,
    message: null,
  },
  isLoading: false,
  error: null,
};
