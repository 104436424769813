import { connect } from 'react-redux';
import { permissions } from '../../utils/permissions';
import { withRouter } from 'react-router';

function ShouldItRender({
  should,
  auth,
  locationPage,
  children,
  action,
}) {
  const renderChildren = () => {
    if (should) return children;
    else if (
      auth.user.role &&
      locationPage &&
      permissions[auth.user.role][locationPage].find(
        (actionAllowed) => action === actionAllowed,
      )
    ) {
      return children;
    }
    return null;
  };

  return renderChildren();
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default withRouter(
  connect(mapStateToProps, null)(ShouldItRender),
);
