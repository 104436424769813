const MenuConfig = {
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    'itemsSuper Admin': [
      {
        title: 'Dashboard',
        root: true,
        icon: 'flaticon2-dashboard',
        page: 'dashboard',
        bullet: 'dot',
      },
      {
        title: 'Agencias',
        root: true,
        icon: 'far fa-building',
        page: 'agencies',
        bullet: 'dot',
      },
      {
        title: 'Asesores',
        root: true,
        icon: 'flaticon2-user-outline-symbol',
        page: 'users',
        bullet: 'dot',
        main: true,
      },
      {
        title: 'Conversaciones',
        root: true,
        icon: 'far fa-comments',
        page: 'conversations',
        bullet: 'dot',
      },
      {
        title: 'Leads',
        root: true,
        icon: 'fas fa-users',
        page: 'clients',
        bullet: 'dot',
      },
      // {
      //   title: 'Notificaciones',
      //   root: true,
      //   icon: 'flaticon-paper-plane-1',
      //   page: 'notifications',
      //   bullet: 'dot',
      // },
      {
        title: 'Campañas',
        root: true,
        icon: 'fas fa-volume-up',
        page: 'campaigns',
        bullet: 'dot',
      },
      {
        title: 'Eventos',
        root: true,
        icon: 'far fa-calendar-alt',
        page: 'events',
        bullet: 'dot',
      },
      // {
      //   title: 'Reportes',
      //   root: true,
      //   icon: 'fas fa-align-center',
      //   page: 'reports',
      //   bullet: 'dot',
      // },
    ],
    'itemsAgency Admin': [
      {
        title: 'Dashboard',
        root: true,
        icon: 'flaticon2-dashboard',
        page: 'dashboard',
        bullet: 'dot',
        main: true,
      },
      {
        title: 'Asesores',
        root: true,
        icon: 'flaticon2-user-outline-symbol',
        page: 'users',
        bullet: 'dot',
      },
      {
        title: 'Conversaciones',
        root: true,
        icon: 'far fa-comments',
        page: 'conversations',
        bullet: 'dot',
        main: true,
      },
      // {
      //   title: 'Leads',
      //   root: true,
      //   icon: 'fas fa-users',
      //   page: 'clients',
      //   bullet: 'dot',
      // },
      // {
      //   title: 'Notificaciones',
      //   root: true,
      //   icon: 'flaticon-paper-plane-1',
      //   page: 'notifications',
      //   bullet: 'dot',
      // },
      {
        title: 'Campañas',
        root: true,
        icon: 'fas fa-volume-up',
        page: 'campaigns',
        bullet: 'dot',
      },
      {
        title: 'Eventos',
        root: true,
        icon: 'far fa-calendar-alt',
        page: 'events',
        bullet: 'dot',
      },
      {
        title: 'Setup',
        root: true,
        icon: 'fas fa-cogs',
        page: 'setup',
        bullet: 'dot',
      },
    ],
    itemsAdvisor: [
      {
        title: 'Conversaciones',
        root: true,
        icon: 'far fa-comments',
        page: 'conversations',
        bullet: 'dot',
        main: true,
      },
    ],
  },
};

export default MenuConfig;
