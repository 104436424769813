import AgenciesTable from './features/agencies/containers/AgenciesTable';
import CampaignsTable from './features/campaign/containers/CampaignsTable';
import ClientsTable from 'app/features/clients/containers/ClientsTable';
import ConversationDetail from './features/conversations/containers/ConversationDetail';
import ConversationsTable from './features/conversations/containers/ConversationsTable';
import CreateAdvisor from './features/users/containers/CreateAdvisor';
import CreateCampaign from './features/campaign/containers/CreateCampaign';
import DashBoard from 'app/features/dashboard/containers/DashBoard';
import DetailAgency from './features/agencies/containers/DetailAgency';
import DetailCampaign from './features/campaign/containers/DetailCampaign';
import DetailClient from 'app/features/clients/containers/DetailClient';
import EventDetail from 'app/features/events/containers/EventDetail';
import EventsTable from 'app/features/events/containers/EventsTable';
import ProfileAgency from './features/setup/containers/ProfileAgency';
import SendNotification from './features/notifications/containers/SendNotification';
import UsersTable from './features/users/containers/UsersTable';

export default {
  '/': DashBoard,
  '/agencies': AgenciesTable,
  '/dashboard': DashBoard,
  '/detail-agency/:id': DetailAgency,
  '/edit-advisor/:id': CreateAdvisor,
  '/invite-advisor': CreateAdvisor,
  '/users': UsersTable,
  '/create-advisor': CreateAdvisor,
  '/clients': ClientsTable,
  '/client-details/:clientID': DetailClient,
  '/events': EventsTable,
  '/event-details/:eventID': EventDetail,
  '/conversations': ConversationsTable,
  '/conversation-details/:conversationID': ConversationDetail,
  '/notifications': SendNotification,
  '/campaigns': CampaignsTable,
  '/create-campaign': CreateCampaign,
  '/detail-campaign/:campaignID': DetailCampaign,
  '/setup': ProfileAgency,
};

// export const getRouteByRoles = role => {
//   let routesByRole = {};
//   if (`items${role}` in MenuConfig.aside) {
//     MenuConfig.aside[`items${role}`].forEach(routeRole => {
//       const route = `/${routeRole.page}`;
//       if (routeRole.main) {
//         routesByRole['/'] = allRoutes[route];
//       }
//       routesByRole[route] = allRoutes[route];
//     });
//   } else routesByRole = { '/': Dashboard };
//   return routesByRole;
// };

// const allRoutes = {
//   '/': Dashboard,
//   '/serviceorders': EnhancedTable,
//   '/dashboard': Dashboard,
//   '/users': UsersTable,
//   '/detail-order': DetailOrder,
//   '/detail-order/:id/:agencyId': DetailOrder,
//   '/detail-paid-so/:id/:agencyId': DetailOrder,
//   '/edit-user/:id': DetailUser,
//   '/payments': PaymentsTable,
//   '/paymentsclear': PaymentsTableClear,
//   '/detail-payment/:id': DetailPayment,
//   '/scores': ScoreTable,
//   '/agencies': AgenciesTable,
//   '/detail-agency/:id': DetailAgency,
//   '/notifications': SendNotification,
//   '/paymentorders': PaymentOrdersTable,
//   '/add-paymentorder': AddPaymentOrder,
//   '/detail-payment-order/:id/:agencyId': DetailPaymentOrder,
//   '/detail-paid-po/:id/:agencyId': DetailPaymentOrder,
//   '/reports': ReportsTable,
//   '/setup': ProfileAgency,
// };
