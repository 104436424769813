import { all, call, put, select } from 'redux-saga/effects';
import {
  attendConversationFail,
  attendConversationSuccess,
  getAllConversationsFail,
  getAllConversationsForDownloadFail,
  getAllConversationsForDownloadSuccess,
  getAllConversationsSuccess,
  getConversationFail,
  getConversationSuccess,
  updateConversationFail,
  updateConversationSuccess,
  uploadFilesAndSendThemFail,
  uploadFilesAndSendThemSuccess,
  updateMultipleConversationsSuccess,
  updateMultipleConversationsFail,
} from './conversationsSlice.js';
import {
  makeSelectAgencyUserID,
  makeSelectUserCategories,
  makeSelectUserRole,
} from '../auth/selectors.js';

import { formatDatesForFilter } from 'app/utils/index.js';
import { getLogs } from '../logs/logsSlice.js';
import { requestHandler } from '../../services/requestHandler';
import { updateClientSaga } from 'app/features/clients/sagas.js';

export function* getAllConversationsSaga(action) {
  const role = yield select(makeSelectUserRole());
  const agencyUserID = yield select(makeSelectAgencyUserID());
  const {
    firstDate,
    secondDate,
    page,
    pageSize,
    filtered,
    prospects,
  } = action.payload;
  try {
    const categories = yield select(makeSelectUserCategories());
    const data = {
      where: {},
      pagination: {
        page,
        perPage: pageSize,
        order: [['createdAt', 'DESC']],
      },
      clients: {
        options: {
          attributes: ['name', 'phoneNumber', 'email', 'interest'],
        },
      },
      options: {
        count: true,
      },
      campaignClicks: {
        campaigns: true,
      },
      agencyUsers: {
        users: {},
      },
      agencies: {
        options: {
          attributes: ['name'],
        },
      },
    };
    if (firstDate && secondDate)
      data.where.createdAt = formatDatesForFilter(
        firstDate,
        secondDate,
      );
    if (filtered.length) {
      filtered.forEach((filter) => {
        if (filter.id === 'clientID')
          data.where[filter.id] = {
            $in: [parseInt(filter.value)],
          };
        else if (filter.id === 'client.phoneNumber')
          data.clients = {
            where: {
              phoneNumber: {
                $ilike: `%${filter.value}%`,
              },
            },
          };
        else if (filter.id === 'agencyUser.type') {
          data.agencyUsers = {
            ...data.agencyUsers,
            where: {
              type: {
                $ilike: `%${filter.value}%`,
              },
            },
          };
        } else if (filter.id === 'agencyUser.user.name') {
          data.agencyUsers = {
            ...data.agencyUsers,
            where: {
              ...data.agencyUsers.where,
              id: { $not: null },
            },
          };
          data.agencyUsers.users = {
            where: {
              ...data.agencyUsers.users.where,
              name: {
                $ilike: `%${filter.value}%`,
              },
            },
          };
        } else if (filter.id === 'agency.name') {
          data.agencies = {
            ...data.agencies,
            where: {
              name: {
                $ilike: `%${filter.value}%`,
              },
            },
          };
        } else if (filter.id === 'origin') {
          if (filter.value === 'Google Sheets') {
            data.where = {
              ...data.where,
              origin: 'Google Sheets',
            };
          } else if (filter.value === 'Sitio web distribuidor') {
            data.where = {
              ...data.where,
              origin: { $ilike: 'Mailing' },
              source: { $ilike: 'Web' },
            };
          } else if (filter.value === 'Sitio web planta') {
            data.where = {
              ...data.where,
              origin: { $ilike: 'Mailing' },
              source: { $ilike: 'Planta' },
            };
          } else if (filter.value === 'facebook') {
            data.where = {
              ...data.where,
              origin: { $or: ['Mailing', 'Facebook'] },
              source: {
                $or: [null, 'messenger', 'facebook', 'Facebook ADS'],
              },
            };
          } else if (filter.value === 'Whatsapp') {
            data.where = {
              ...data.where,
              origin: { $or: ['Whatsapp'] },
              source: { $or: [null, 'messenger', 'facebook'] },
            };
          }
        } else if (filter.id === 'source') {
          if (filter.value === 'Messenger') {
            data.where = {
              ...data.where,
              source: { $or: ['messenger'] },
            };
          } else if (filter.value === 'Formulario') {
            data.where = {
              ...data.where,
              origin: { $ilike: 'Mailing' },
              source: { $or: ['web', 'planta'] },
            };
          } else if (filter.value === 'Messenger') {
            data.where = {
              ...data.where,
              origin: { $ilike: 'Mailing' },
              source: { $or: ['messenger'] },
            };
          } else if (filter.value === 'Facebook ADS') {
            data.where = {
              ...data.where,
              origin: { $ilike: 'Facebook' },
              source: { $or: ['Facebook ADS'] },
            };
          } else if (filter.value === 'Whatsapp') {
            data.where = {
              ...data.where,
              origin: { $or: ['Whatsapp'] },
              source: { $or: [null, 'messenger', 'facebook'] },
            };
          }
        } else if (filter.id === 'campaignClick') {
          data.where = {
            ...data.where,
            campaignID: { $not: null },
          };
          data.campaigns = {
            ...data.campaigns,
            where: {
              name: {
                $ilike: `%${filter.value}%`,
              },
            },
          };
        } else if (filter.id === 'createdAt')
          data.where[filter.id] = formatDatesForFilter(filter.value);
        else data.where[filter.id] = { $ilike: `%${filter.value}%` };
      });
    }
    if (role === 'Advisor') {
      data.where = { ...data.where, agencyUserID };
    }

    if (data.where.status)
      data.where = {
        ...data.where,
        status: {
          ...data.where.status,
          $not: ['Prospecto', 'Prospecto perdido'],
        },
      };
    else if (prospects === 1)
      data.where = { ...data.where, status: 'Prospecto' };
    else if (prospects === 2)
      data.where = { ...data.where, status: 'Prospecto perdido' };
    else if (prospects === 3)
      data.where = { ...data.where, status: 'Venta facturada' };
    else if (prospects === 4)
      data.where = { ...data.where, status: 'Cliente perdido' };
    else
      data.where = {
        ...data.where,
        status: { $not: ['Prospecto', 'Prospecto perdido'] },
      };
    if (
      Array.isArray(categories) &&
      categories.length > 0 &&
      data.agencyUsers.where &&
      !data.agencyUsers.where.type
    )
      data.agencyUsers.where = {
        ...data.agencyUsers.where,
        type: {
          ...data.agencyUsers.where.type,
          $or: categories,
        },
      };

    const conversations = yield call(requestHandler, {
      method: 'POST',
      path: '/conversations/getAll',
      data,
    });
    conversations.pages = yield call(
      Math.ceil,
      conversations.count / pageSize,
    );
    if (firstDate && secondDate)
      yield call(getAllConversationsForDownloadSaga, {
        payload: { data },
      });
    yield put(getAllConversationsSuccess({ conversations }));
  } catch (e) {
    yield put(getAllConversationsFail('getUserBySuperAdmin'));
    console.log('error in get user BySuperAdmin saga', e);
  }
}

export function* getAllConversationsForDownloadSaga(action) {
  try {
    const data = { ...action.payload.data };
    delete data.pagination;
    delete data.options;
    const conversations = yield call(requestHandler, {
      method: 'POST',
      path: '/conversations/getAll',
      data,
    });
    yield put(
      getAllConversationsForDownloadSuccess({ conversations }),
    );
  } catch (e) {
    yield put(
      getAllConversationsForDownloadFail('getUserBySuperAdmin'),
    );
    console.log('error in get user BySuperAdmin saga', e);
  }
}

export function* getConversationSaga(action) {
  const { id } = action.payload;
  try {
    const data = {
      where: { id },
      clients: {
        options: {
          attributes: ['name', 'phoneNumber', 'email', 'interest'],
        },
      },
      users: true,
      agencies: true,
      customFlows: true,
      messages: true,
      agencyUsers: {
        users: true,
      },
    };
    const conversation = yield call(requestHandler, {
      method: 'POST',
      path: '/conversations/getOne',
      data,
    });
    yield put(getConversationSuccess({ conversation }));
    yield put(
      getLogs({
        object: 'conversations',
        objectID: id,
      }),
    );
  } catch (e) {
    yield put(getConversationFail('getUserBySuperAdmin'));
    console.log('error in get user BySuperAdmin saga', e);
  }
}

export function* attendConversationSaga(action) {
  try {
    const { conversationID } = action.payload;
    const data = {
      id: conversationID,
      status: 'Conversacion con Asesor iniciada',
    };
    const conversation = yield call(requestHandler, {
      method: 'POST',
      path: '/conversations/update',
      data,
    });
    const message = {
      title: 'Cliente atendido',
      desc: `La conversación se actualizó correctamente`,
    };
    yield put(attendConversationSuccess({ message, conversation }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Error al tratar de atender la conversación, por favor intenta más tarde`,
    };
    yield put(attendConversationFail({ message }));
    console.log('error in get user BySuperAdmin saga', e);
  }
}

export function* updateConversationSaga(action) {
  try {
    const { conversationID, title, ...rest } = action.payload;
    const data = {
      id: conversationID,
      ...rest,
    };
    const conversation = yield call(requestHandler, {
      method: 'POST',
      path: '/conversations/update',
      data,
    });
    const message = {
      title: title || 'Cliente prospecto',
      desc: `La conversación se actualizó correctamente`,
    };
    yield put(updateConversationSuccess({ message, conversation }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Error al tratar de actualizar la conversación, por favor intenta más tarde`,
    };
    yield put(updateConversationFail({ message }));
    console.log('error in prospect: ', e);
  }
}

export function* updateConversationClientSaga(action) {
  try {
    const { conversationID, ...rest } = action.payload;
    yield call(updateClientSaga, { payload: rest });
    yield call(getConversationSaga, {
      payload: { id: conversationID },
    });
  } catch (e) {
    console.log('error updating client: ', e);
  }
}
export function* updateMultipleConversationsSaga(action) {
  try {
    const { selectedRows, status } = action.payload;
    if (selectedRows.length > 0) {
      yield call(requestHandler, {
        method: 'POST',
        path: '/conversations/updateMultiple',
        data: {
          conversations: selectedRows,
          dataToUpdate: {
            status,
          },
        },
      });
      const message = {
        title: status,
        desc: `La conversación se actualizó correctamente`,
      };
      yield put(
        updateMultipleConversationsSuccess({ message: message.desc }),
      );
      yield call(getAllConversationsSaga, {
        payload: {
          firstDate: null,
          secondDate: null,
          page: 1,
          pageSize: 20,
          filtered: [],
          prospects: 0,
        },
      });
    }
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Error al tratar de actualizar las conversaciones, por favor intenta más tarde`,
    };
    yield put(updateMultipleConversationsFail({ message }));
  }
}

export function* assignConversationSaga(action) {
  try {
    const data = {
      ...action.payload,
    };
    const conversation = yield call(requestHandler, {
      method: 'POST',
      path: '/conversations/assign',
      data,
    });
    const message = {
      title: 'Conversación asignada',
      desc: `La conversación se asignó correctamente`,
    };
    yield put(attendConversationSuccess({ message, conversation }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Error al tratar de asignar la conversación, por favor intenta más tarde`,
    };
    yield put(attendConversationFail({ message }));
    console.log('error in assign: ', e);
  }
}

function* uploadFilesSaga(files) {
  try {
    const imagesUploaded = yield all(
      Object.keys(files).map((key) => {
        if (typeof files[key] !== 'string') {
          const formData = new FormData();
          formData.append(`file0`, files[key]);
          return call(requestHandler, {
            method: 'POST',
            path: '/conversations/upload',
            contenType: 'multipart/form-data',
            data: formData,
          });
        } else {
          return files;
        }
      }),
    );
    return imagesUploaded;
  } catch (e) {
    return [];
  }
}

export function* uploadFilesAndSendThemSaga(action) {
  try {
    const { selectedFiles, handleEmitMessage } = action.payload;
    console.log(selectedFiles);
    const filesUploaded = yield call(uploadFilesSaga, selectedFiles);
    for (const file of filesUploaded) {
      yield call(handleEmitMessage, file, 'media');
    }
    yield put(uploadFilesAndSendThemSuccess({}));
  } catch (e) {
    yield put(uploadFilesAndSendThemFail({}));
    console.log('error in assign: ', e);
  }
}
